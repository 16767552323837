(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('PendingRequest', PendingRequest)
        .factory('GiftRequest', GiftRequest);

    PendingRequest.$inject = ['$resource', 'DateUtils'];

    function PendingRequest ($resource, DateUtils) {
        var resourceUrl =  'api/pending-request';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.pubDate = DateUtils.convertDateTimeFromServer(data.pubDate);
                        data.approvalDate = DateUtils.convertDateTimeFromServer(data.approvalDate);
                    }
                    return data;
                }
            }
        });
    }

    GiftRequest.$inject = ['$resource'];

    function GiftRequest($resource) {
        var resourceUrl = 'api/new-gift-requests';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.pubDate = DateUtils.convertDateTimeFromServer(data.pubDate);
                        data.approvalDate = DateUtils.convertDateTimeFromServer(data.approvalDate);
                    }
                    return data;
                }
            }
        });
    }
})();
