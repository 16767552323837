(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('HanpassUserDetailController', HanpassUserDetailController);

    HanpassUserDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'HanpassUser', 'Customer'];

    function HanpassUserDetailController($scope, $rootScope, $stateParams, previousState, entity, HanpassUser, Customer) {
        var vm = this;

        vm.hanpassUser = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:hanpassUserUpdate', function(event, result) {
            vm.hanpassUser = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
