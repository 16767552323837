(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('MarketStatsTopSelling', MarketStatsTopSelling);

    MarketStatsTopSelling.$inject = ['$resource', 'DateUtils'];


    function MarketStatsTopSelling($resource, DateUtils) {
        var resourceUrl = 'api/market-stats/top-selling';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }
})();
