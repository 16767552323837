(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerOrderInfoController', PartnerOrderInfoController);

    PartnerOrderInfoController.$inject = ['$http', '$state', 'DateUtils', 'PartnerOrderInfo', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'PartnerRequestStorage'];

    function PartnerOrderInfoController($http, $state, DateUtils, PartnerOrderInfo, ParseLinks, AlertService, paginationConstants, pagingParams, PartnerRequestStorage) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.exportExcel = exportExcel;
        vm.disabledExcelButton = false;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.dateDiff = DateUtils.partnerOrderDateDifference;

        vm.loadMore = loadMore;
        vm.page = 0;
        vm.lastPage = false;
        vm.loading = false;
        vm.partnerOrderInfos = [];
        vm.selectAllOrders = selectAllOrders;
        vm.updateSelectedOrders = updateSelectedOrders;
        vm.newOrderIds = [];
        vm.selectedOrders = PartnerRequestStorage.getPartnerSelectedOrders();
        if (!vm.selectedOrders) {
            vm.selectedOrders = [];
        }

        loadAllNewOrderIds();

        function loadNext () {
            PartnerOrderInfo.query({
                page: vm.page,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;

                vm.page++;
                var partnerOrderInfos = data;

                if (partnerOrderInfos == null || partnerOrderInfos.length < 20) {
                    vm.lastPage = true;
                }
                partnerOrderInfos.forEach(function (partnerOrderInfo) {
                    vm.partnerOrderInfos.push(partnerOrderInfo);
                });
                vm.loading = false;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadMore() {
            if (vm.lastPage || vm.loading) {
                return null;
            } else {
                vm.loading = true;
                loadNext();
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function loadAllNewOrderIds() {
            PartnerOrderInfo.getAllNewPartnerRequestIds({}, onSuccess, onError);

            function onSuccess(data) {
                vm.newOrderIds = data;
                clearOldSelectedOrders();
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.newOrderIds = [];
            }
        }
        function exportExcel() {
            vm.disabledExcelButton = true;
            var data = vm.selectedOrders;
            $http({
                url: 'api/b2b-new-requests-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/json'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                saveAs(blob, 'partner-market-request-' + DateUtils.currentTimestamp() + '.xlsx');
                vm.disabledExcelButton = false;
            }).error(function () {
                vm.disabledExcelButton = false;
            });
        }

        function selectAllOrders(event) {
            if (!event.target.checked) {
                vm.selectedOrders = [];
                PartnerRequestStorage.setPartnerSelectedOrders(vm.selectedOrders);
            } else {
                vm.selectedOrders = [];
                vm.selectedOrders = vm.newOrderIds.slice();
                PartnerRequestStorage.setPartnerSelectedOrders(vm.selectedOrders);
            }
        }

        function updateSelectedOrders(event) {
            vm.selectedOrders = PartnerRequestStorage.getPartnerSelectedOrders();
            if (!vm.selectedOrders) {
                vm.selectedOrders = [];
            }
            if (event.target.checked) {
                if (vm.selectedOrders.indexOf(parseInt(event.target.value)) < 0) {
                    vm.selectedOrders.push(parseInt(event.target.value));
                }
            } else {
                if (vm.selectedOrders.indexOf(parseInt(event.target.value)) > -1) {
                    vm.selectedOrders.splice(vm.selectedOrders.indexOf(parseInt(event.target.value)), 1);
                }
            }
            PartnerRequestStorage.setPartnerSelectedOrders(vm.selectedOrders);
        }

        function clearOldSelectedOrders() {
            var newSelectedOrders = [];
            for (var i = 0; i < vm.selectedOrders.length; i++) {
                if (vm.newOrderIds.indexOf(vm.selectedOrders[i]) > -1) {
                    newSelectedOrders.push(vm.selectedOrders[i]);
                }
            }
            vm.selectedOrders = newSelectedOrders;
            PartnerRequestStorage.setPartnerSelectedOrders(vm.selectedOrders);
        }
    }
})();
