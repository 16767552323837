(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('ProviderCountrySort', ProviderCountrySort)
        .factory('CountryStorage', CountryStorage);

    ProviderCountrySort.$inject = ['$resource'];

    function ProviderCountrySort($resource) {
        var resourceUrl = 'api/update-provider-country-positions';

        return $resource(resourceUrl, {}, {
            'getAsList': {
                method: 'GET',
                isArray: true,
                url: 'api/country-list'
            },
            'update': {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
    }

    CountryStorage.$inject = ['$resource', '$localStorage'];

    function CountryStorage($resource, $localStorage) {
        return {
            setCountry: function (country) {
                $localStorage.replaceCountry = country;
            },
            getCountry: function () {
                return $localStorage.replaceCountry;
            }
        }
    }
})();
