(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('GiftDetailController', GiftDetailController);

    GiftDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Gift', 'GiftImage', 'GiftCategory'];

    function GiftDetailController($scope, $rootScope, $stateParams, previousState, entity, Gift, GiftImage, GiftCategory) {
        var vm = this;

        vm.gift = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:giftUpdate', function(event, result) {
            vm.gift = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
