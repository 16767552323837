(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('GiftCategoryController', GiftCategoryController);

    GiftCategoryController.$inject = ['GiftCategory'];

    function GiftCategoryController(GiftCategory) {

        var vm = this;

        vm.giftCategories = [];

        loadAll();

        function loadAll() {
            GiftCategory.query(function(result) {
                vm.giftCategories = result;
                vm.searchQuery = null;
            });
        }
    }
})();
