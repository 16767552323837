(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('StatsAgent', StatsAgent)
        .factory('UserAgents', UserAgents);

    StatsAgent.$inject = ['$resource', 'DateUtils'];
    UserAgents.$inject = ['$resource'];

    function StatsAgent ($resource, DateUtils) {
        var resourceUrl =  'api/stats-agent';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.registrationDate = DateUtils.convertDateTimeFromServer(data.registrationDate);
                    }
                    return data;
                },
                isArray: true
            }
        });
    }

    function UserAgents ($resource){
        var resourceUrl =  'api/agents';

        return $resource(resourceUrl, {}, {
            'get': {method: 'GET', isArray: true}
        });
    }

})();
