(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PurchasingAgentDetailController', PurchasingAgentDetailController);

    PurchasingAgentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PurchasingAgent'];

    function PurchasingAgentDetailController($scope, $rootScope, $stateParams, previousState, entity, PurchasingAgent) {
        var vm = this;

        vm.purchasingAgent = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:purchasingAgentUpdate', function(event, result) {
            vm.purchasingAgent = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
