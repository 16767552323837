(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerOrderInfoDeleteController',PartnerOrderInfoDeleteController);

    PartnerOrderInfoDeleteController.$inject = ['$uibModalInstance', 'entity', 'PartnerOrderInfo'];

    function PartnerOrderInfoDeleteController($uibModalInstance, entity, PartnerOrderInfo) {
        var vm = this;

        vm.partnerOrderInfo = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PartnerOrderInfo.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
