(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('ShippingData', ShippingData);

    ShippingData.$inject = ['$resource'];

    function ShippingData ($resource) {
        var resourceUrl =  'api/shipping-data';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

})();
