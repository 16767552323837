(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('StatsMerchantController', StatsMerchantController);

    StatsMerchantController.$inject = ['$http', 'StatsMerchant', 'AlertService', '$filter', '$rootScope', 'DateUtils', 'ProviderCountry', 'ProviderCategory', 'Provider', 'ProviderSegment'];

    function StatsMerchantController($http, StatsMerchant, AlertService, $filter, $rootScope, DateUtils, ProviderCountry, ProviderCategory, Provider, ProviderSegment) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.clear = clear;
        vm.search = search;
        vm.excel = excel;
        vm.providerCountries = ProviderCountry.getAsList();
        vm.providerCategories = [];
        vm.providers = [];
        vm.providerSegments = [];
        vm.searchQuery = {
            merchants: null,
            fromDate: null,
            toDate: null,
            gateways: null,
            serviceTypes: null,
            transactionModes: null,
            valueInt1: null,
            valueLong1: null,
            providerCountryId: null,
            providerCategoryId: null,
            providerId: null,
            providerSegmentId: null
        };
        vm.getProviderCategories = getProviderCategories;
        vm.getProviders = getProviders;
        vm.getProviderSegments = getProviderSegments;


        setTimeout(function () {
            translateMultiSelect();

            $('#gateways').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: all,
                nonSelectedText: noneSelected,
                allSelectedText: allSelected,
                maxHeight: 300
            });

            $('#serviceType').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: all,
                nonSelectedText: noneSelected,
                allSelectedText: allSelected,
                maxHeight: 300
            });

            $('#mode').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics.all'),
                nonSelectedText: translate('statistics.noneSelected'),
                allSelectedText: translate('statistics.allSelected')
            });
            $('#merchants').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics.all'),
                nonSelectedText: translate('statistics.noneSelected'),
                allSelectedText: translate('statistics.allSelected')
            });

        }, 0);


        initDatePicker();

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function load() {
            StatsMerchant.query(vm.searchQuery, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.transactions = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });

            load();
        }

        function clear() {
            vm.searchQuery = {
                merchants: null,
                fromDate: null,
                toDate: null,
                gateways: null,
                serviceTypes: null,
                transactionModes: null,
                valueInt1: null,
                valueLong1: null
            };
            $('#gateways').multiselect('deselectAll', false);
            $('#gateways').multiselect('updateButtonText');

            $('#serviceType').multiselect('deselectAll', false);
            $('#serviceType').multiselect('updateButtonText');

            $('#mode').multiselect('deselectAll', false);
            $('#mode').multiselect('updateButtonText');

            initDatePicker();
        }

        function translateMultiSelect() {
            all = translate('statistics.all');
            noneSelected = translate('statistics.noneSelected');
            allSelected = translate('statistics.allSelected');
        }

        $rootScope.$on('$translateChangeSuccess', function () {
            translateMultiSelect();

            $('#gateways').multiselect(
                'setOptions',
                {
                    selectAllText: all,
                    nonSelectedText: noneSelected,
                    allSelectedText: allSelected
                });
            $('#gateways').multiselect('rebuild');

            $('#serviceType').multiselect(
                'setOptions',
                {
                    selectAllText: all,
                    nonSelectedText: noneSelected,
                    allSelectedText: allSelected
                });
            $('#serviceType').multiselect('rebuild');

            $('#mode').multiselect(
                'setOptions',
                {
                    selectAllText: all,
                    nonSelectedText: noneSelected,
                    allSelectedText: allSelected
                });
            $('#mode').multiselect('rebuild');

            $('#merchants').multiselect(
                'setOptions',
                {
                    selectAllText: all,
                    nonSelectedText: noneSelected,
                    allSelectedText: allSelected
                });
            $('#merchants').multiselect('rebuild');
        });

        function excel() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = new Date($(this).val());
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = new Date($(this).val());
                }
            });

            vm.searchQuery.fromDate = DateUtils.converterDateFormat(vm.searchQuery.fromDate);
            vm.searchQuery.toDate = DateUtils.converterDateFormat(vm.searchQuery.toDate);

            $http({
                url: 'api/stats-merchant-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: vm.searchQuery, //this is your json data string
                headers: {
                    'Content-type': 'application/json'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'statistics-by-merchant-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {

            });
        }
        function getProviderCategories() {
            if (!vm.searchQuery.providerCountryId) {
                vm.providerCategories = [];
                return;
            }
            ProviderCategory.getAllByCountry({
                id: vm.searchQuery.providerCountryId
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.providerCategories = data;
            }

            function onError() {
                vm.providerCategories = [];
            }
        }

        function getProviders() {
            if (!vm.searchQuery.providerCategoryId) {
                vm.providers = [];
                return;
            }
            Provider.getAllByCategory({
                id: vm.searchQuery.providerCategoryId
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.providers = data;
            }

            function onError() {
                vm.providers = [];
            }
        }

        function getProviderSegments() {
            if (!vm.searchQuery.providerId) {
                vm.providerSegments = [];
                return;
            }
            ProviderSegment.getAllByProvider({
                id: vm.searchQuery.providerId
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.providerSegments = data;
            }

            function onError() {
                vm.providerSegments = [];
            }
        }
    }
})();
