(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductPriceChangeLogDetailController', ProductPriceChangeLogDetailController);

    ProductPriceChangeLogDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ProductPriceChangeLog'];

    function ProductPriceChangeLogDetailController($scope, $rootScope, $stateParams, previousState, entity, ProductPriceChangeLog) {
        var vm = this;

        vm.productPriceChangeLog = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:productPriceChangeLogUpdate', function(event, result) {
            vm.productPriceChangeLog = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
