(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileDetailController', ProfileDetailController);

    ProfileDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$http', '$translate', 'previousState', 'entity', 'Profile', 'Customer', 'ProfileChangePassword', 'DataUtils', 'CustomerStatusLog'];

    function ProfileDetailController($scope, $rootScope, $stateParams, $http, $translate, previousState, entity, Profile, Customer, ProfileChangePassword, DataUtils, CustomerStatusLog) {
        var vm = this;

        vm.profile = entity;
        vm.previousState = previousState.name;
        vm.save = save;
        vm.changePassword = changePassword;
        vm.getCustomerStatusLogsByCustomerId = getCustomerStatusLogsByCustomerId;
        vm.getCurrencyNameForClient = DataUtils.getCurrencyNameForClient;
        vm.newPassword = null;

        vm.dateOfBirth = vm.profile.customerDateOfBirth !== null ? new Date(vm.profile.customerDateOfBirth) : null;

        loadNationalities();

        $rootScope.$on('$translateChangeSuccess', function () {
            loadNationalities();
        });

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:profileUpdate', function (event, result) {
            vm.profile = result;
        });

        $scope.$on('$destroy', unsubscribe);

        function loadNationalities() {
            $http.get('i18n/' + $translate.use() + '/nationalityList.json').success(function (nationalities) {
                vm.nationalities = nationalities;
            });
        }

        function save() {
            vm.customer = {
                id: vm.profile.customerId,
                fullName: vm.profile.customerFullName,
                status: vm.profile.customerStatus,
                bankId: vm.profile.customerBankId,
                dateOfBirth: vm.dateOfBirth,
                nationality: vm.profile.customerNationality,
                email: vm.profile.customerEmail,
                gender: vm.profile.customerGender,
                defaultAddressId: vm.profile.defaultAddressId,
                avatarUrl: vm.profile.customerAvatarUrl,
                isPhoneVerified: vm.profile.isPhoneVerified
            };
            vm.isSaving = true;
            if (vm.profile.customerId !== null) {
                Customer.update(vm.customer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            vm.profile.customerFullName = result.fullName;
            vm.profile.customerStatus = result.status;
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function changePassword() {
            vm.isSaving = true;
            ProfileChangePassword.save({id: vm.profile.id, password: vm.newPassword}, function (result) {
                vm.isSaving = false;
            }, function (err) {
                vm.isSaving = false;
            })
        }

        function getCustomerStatusLogsByCustomerId() {
            CustomerStatusLog.query({customerId: vm.profile.customerId}, function (data) {
                vm.customerStatusLogs = data;
            });
        }
    }
})();
