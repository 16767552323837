(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TelegramUserDialogController', TelegramUserDialogController);

    TelegramUserDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TelegramUser', 'SellerUser'];

    function TelegramUserDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, TelegramUser, SellerUser) {
        var vm = this;

        vm.telegramUser = entity;
        vm.clear = clear;
        vm.save = save;

        getSellers();
        function getSellers() {
            SellerUser.query(onSuccess);

            function onSuccess(data) {
                vm.users = data;
            }
        }

        vm.langs = [
            {
                value: 'uz',
                text: 'Ўзбекча'
            }, {
                value: 'en',
                text: 'English'
            }
        ];

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.telegramUser.id !== null) {
                TelegramUser.update(vm.telegramUser, onSaveSuccess, onSaveError);
            } else {
                TelegramUser.save(vm.telegramUser, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bozorakaAdminApp:telegramUserUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
