(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MobileOperatorDialogController', MobileOperatorDialogController);

    MobileOperatorDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MobileOperator', 'Country'];

    function MobileOperatorDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, MobileOperator, Country) {
        var vm = this;

        vm.mobileOperator = entity;
        vm.clear = clear;
        vm.save = save;
        vm.countries = Country.query();
        vm.countrySelected = countrySelected;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.mobileOperator.id !== null) {
                MobileOperator.update(vm.mobileOperator, onSaveSuccess, onSaveError);
            } else {
                MobileOperator.save(vm.mobileOperator, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:mobileOperatorUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function getCountryById(countryId) {
            for (var index in vm.countries) {
                var country = vm.countries[index];
                if (country.id == countryId)
                    return country;
            }
        }

        function countrySelected (countryId) {
            vm.mobileOperator.phonePattern = getCountryById(countryId).phonePattern;
        }
    }
})();
