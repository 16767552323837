(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MileageDialogController', MileageDialogController);

    MileageDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Mileage', 'Profile', 'Transaction'];

    function MileageDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Mileage, Profile, Transaction) {
        var vm = this;

        vm.mileage = entity;
        vm.clear = clear;
        vm.save = save;
        vm.profiles = Profile.query();
        vm.transactions = Transaction.query({filter: 'mileage-is-null'});
        $q.all([vm.mileage.$promise, vm.transactions.$promise]).then(function() {
            if (!vm.mileage.transaction || !vm.mileage.transaction.id) {
                return $q.reject();
            }
            return Transaction.get({id : vm.mileage.transaction.id}).$promise;
        }).then(function(transaction) {
            vm.transactions.push(transaction);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.mileage.id !== null) {
                Mileage.update(vm.mileage, onSaveSuccess, onSaveError);
            } else {
                Mileage.save(vm.mileage, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:mileageUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
