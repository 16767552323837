(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TransactionDetailController', TransactionDetailController);

    TransactionDetailController.$inject = ['$scope', '$http', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'DateUtils', 'DataUtils', '$filter'];

    function TransactionDetailController($scope, $http, $rootScope, $state, $stateParams, previousState, entity, DateUtils, DataUtils, $filter) {
        var vm = this;
        var translate = $filter('translate');
        vm.transaction = entity;
        vm.previousState = previousState.name;
        vm.dateDiff = DateUtils.transactionDateDifference;
        vm.getDeductedAmount = DataUtils.getDeductedAmount;
        vm.getSentAmount = DataUtils.getSentAmount;
        vm.getErrorMessage = getErrorMessage;
        vm.collapse = collapse;
        vm.shouldCollapse = false;
        vm.serverResponse = DataUtils.getResponse(vm.transaction);
        vm.isEditable = isEditable;
        vm.isRefundable = isRefundable;
        vm.getDisplayAmount = getDisplayAmount;

        boxColor();

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:transactionUpdate', function (event, result) {
            vm.transaction = result;
            boxColor();
        });
        $scope.$on('$destroy', unsubscribe);

        function boxColor() {
            var status = vm.transaction.status;
            if (status == 'SUCCESS') {
                vm.statusColor = 'green-seagreen';
            } else if (status == 'FAILURE') {
                vm.statusColor = 'red-mint';
            } else if (status == 'INPROGRESS') {
                vm.statusColor = 'yellow-saffron';
            }else if (status == 'REFUND') {
                vm.statusColor = 'purple';
            }
        }

        function isEditable() {
            return (vm.transaction.status == 'SUCCESS' || vm.transaction.status == 'FAILURE') && vm.transaction.transactionType == 'WITHDRAW';
        }

        function isRefundable() {
            return vm.transaction.transactionNumber != null
                && vm.transaction.status == 'SUCCESS'
                && vm.transaction.transactionType == 'DEPOSIT_CARD';
        }

        function getErrorMessage(transaction) {
            return translate('transaction.dialog.fail.' + transaction.errorMessage);
        }

        function collapse() {
            vm.shouldCollapse = !vm.shouldCollapse;
        }

        function getDisplayAmount() {
            var discount = 1;
            if (vm.transaction.client === 'hanpass' || vm.transaction.client === '010pay') {
                discount = 0.96;
            }
            return ((vm.transaction.amount + vm.transaction.directAmount + vm.transaction.usedBonusAmount) / discount) * vm.transaction.providerSegmentDisplayRate;
        }
    }
})();
