(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductVariantDetailController', ProductVariantDetailController);

    ProductVariantDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ProductVariant', 'ProductOptionValue'];

    function ProductVariantDetailController($scope, $rootScope, $stateParams, previousState, entity, ProductVariant, ProductOptionValue) {
        var vm = this;

        vm.productVariant = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:productVariantUpdate', function(event, result) {
            vm.productVariant = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
