(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('VendorController', VendorController);

    VendorController.$inject = ['$state', 'Vendor', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function VendorController($state, Vendor, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.loadAll = loadAll;
        vm.search = search;
        vm.clear = clear;
        vm.searchQuery = {
            name: pagingParams.name,
            contactPoint: pagingParams.contactPoint,
            phone: pagingParams.phone,
            status: pagingParams.status
        };

        loadAll();

        function search(){
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'name';
            vm.reverse = true;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'name';
            vm.reverse = true;
            vm.searchQuery = {
                name: null,
                contactPoint: null,
                phone: null,
                status: 'ACTIVE'
            };
            vm.transition();
        }

        function loadAll () {
            Vendor.query({
                name : vm.searchQuery.name,
                contactPoint : vm.searchQuery.contactPoint,
                phone : vm.searchQuery.phone,
                status : vm.searchQuery.status,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'name') {
                    result.push('name');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.vendors = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                name: vm.searchQuery.name,
                contactPoint: vm.searchQuery.contactPoint,
                phone: vm.searchQuery.phone,
                status: vm.searchQuery.status
            });
        }
    }
})();
