(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('MarketStatsDaily', MarketStatsDaily);

    MarketStatsDaily.$inject = ['$resource', 'DateUtils'];


    function MarketStatsDaily($resource, DateUtils) {
        var resourceUrl = 'api/market-stats/daily';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: false},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                    }
                    return data;
                }
            },
            'getByMerchant': {
                method: 'GET',
                url: 'api/market-stats/daily/app-client',
                isArray: true
            },
            'getByQuantity': {
                method: 'GET',
                url: 'api/market-stats/daily/product-quantity',
                isArray: true
            },
            'getByAmount': {
                method: 'GET',
                url: 'api/market-stats/daily/order-amount',
                isArray: true
            },
            'getByUser': {
                method: 'GET',
                url: 'api/market-stats/daily/customer',
                isArray: true
            },
            'getBySales': {
                method: 'GET',
                url: 'api/market-stats/daily/orders-and-sales',
                isArray: true
            }
        });
    }
})();
