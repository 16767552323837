(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerController', PartnerController);

    PartnerController.$inject = ['$state', '$filter', 'Partner', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Tier'];
    function PartnerController($state, $filter, Partner, ParseLinks, AlertService, paginationConstants, pagingParams, Tier) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.tiers = Tier.query(function () {
            vm.tiers.unshift({
                id: 0,
                name: "All"
            });
        });
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.clear = clear;
        vm.search = search;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.searchQuery = {
            field: pagingParams.field,
            balanceStatuses: pagingParams.balanceStatuses,
            valueLong1: pagingParams.valueLong1,
            customerStatus: pagingParams.customerStatus
        };
        vm.loadMore = loadMore;
        vm.page = 0;
        vm.lastPage = false;
        vm.loading = false;
        vm.partners = [];

        function loadNext () {
            Partner.query({
                page: vm.page,
                size: vm.itemsPerPage,
                sort: sort(),
                field: vm.searchQuery.field,
                balanceStatuses: vm.searchQuery.balanceStatuses,
                valueLong1: vm.searchQuery.valueLong1,
                customerStatus: vm.searchQuery.customerStatus
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'login') {
                    result.push('login');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;

                vm.page++;
                var partners = data;

                if (partners == null || partners.length < 20) {
                    vm.lastPage = true;
                }
                partners.forEach(function (partner) {
                    vm.partners.push(partner);
                });
                vm.loading = false;

            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadMore() {
            if (vm.lastPage || vm.loading) {
                return null;
            } else {
                vm.loading = true;
                vm.totalDebt = Partner.getTotalDebt({field: vm.searchQuery.field,
                    balanceStatuses: vm.searchQuery.balanceStatuses,
                    valueLong1: vm.searchQuery.valueLong1,
                    customerStatus: vm.searchQuery.customerStatus});
                loadNext();
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                field: vm.searchQuery.field,
                balanceStatuses: vm.searchQuery.balanceStatuses,
                valueLong1: vm.searchQuery.valueLong1,
                customerStatus: vm.searchQuery.customerStatus
            });
        }

        function search() {
            vm.partners = [];
            vm.page = 0;
            vm.lastPage = false;
            vm.loading = false;
            vm.totalDebt = Partner.getTotalDebt({field: vm.searchQuery.field,
                balanceStatuses: vm.searchQuery.balanceStatuses,
                valueLong1: vm.searchQuery.valueLong1,
                customerStatus: vm.searchQuery.customerStatus});
            loadNext();
        }

        function clear() {
            vm.partners = [];
            vm.page = 0;
            vm.lastPage = false;
            vm.loading = false;

            vm.links = null;
            vm.predicate = 'login';
            vm.reverse = true;
            vm.searchQuery = {
                field: null,
                balanceStatuses: ['negative', 'zero', 'positive'],
                valueLong1: 0,
                customerStatus: 'ACTIVE',
            };
            $('#field_balance_statuses').multiselect('selectAll', false);
            $('#field_balance_statuses').multiselect('refresh');
            vm.totalDebt = Partner.getTotalDebt({field: vm.searchQuery.field,
                balanceStatuses: vm.searchQuery.balanceStatuses,
                valueLong1: vm.searchQuery.valueLong1,
                customerStatus: vm.searchQuery.customerStatus});
            loadNext();
        }
        setTimeout(function () {
            translateMultiSelect();
            $('#field_balance_statuses').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected'),
                maxHeight: 300
            });

        }, 0);

        function translateMultiSelect() {
            all = translate('product.all');
            noneSelected = translate('product.noneSelected');
            allSelected = translate('product.allSelected');
        }
    }
})();
