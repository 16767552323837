(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('UploadExcelService', UploadExcelService);

    UploadExcelService.$inject = ['$resource'];

    function UploadExcelService($resource) {
        var resourceUrl = 'api/upload-excel';

        return $resource(resourceUrl, {}, {
            'uploadMarket': {url: 'api/order-info/upload-excel', method: 'POST', isArray: true},
            'uploadB2B': {url: 'api/partner-order-info/upload-excel', method: 'POST', isArray: true}
        });
    }
})();
