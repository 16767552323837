(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('GiftOrderStatusChangeDialogController', GiftOrderStatusChangeDialogController);

    GiftOrderStatusChangeDialogController.$inject = ['GiftOrderHistory', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'GiftOrder', '$translate'];

    function GiftOrderStatusChangeDialogController(GiftOrderHistory, $timeout, $scope, $stateParams, $uibModalInstance, entity, GiftOrder, $translate) {
        var vm = this;

        vm.giftOrder = entity;
        vm.transactions = vm.giftOrder.transaction;
        vm.clear = clear;
        vm.save = save;
        var headers = {
            lang: $translate.use()
        };
        boxColor();
        statusDisabled();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function save() {
            vm.isSaving = true;
            vm.giftOrder.status = $stateParams.status;
            if (vm.giftOrder.id !== null) {
                GiftOrder.update(vm.giftOrder, onSaveSuccess, onSaveError);
            } else {
                GiftOrder.save(vm.giftOrder, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            GiftOrder.get({id: vm.giftOrder.id}, function (data) {
                vm.giftOrder = data;
                boxColor();
                $scope.$emit('bozorakaAdminApp:orderInfoUpdate', result);
                $uibModalInstance.close(result);
                vm.isSaving = false;
                statusDisabled();
                saveGiftOrderHistory(vm.giftOrder);
            });
        }
        function onSaveError() {
            vm.isSaving = false;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
        function boxColor() {
            if (vm.transactions != undefined) {
                var status = vm.transactions.status;
                if (status == 'SUCCESS') {
                    vm.statusColor = 'green-seagreen';
                } else if (status == 'FAILURE') {
                    vm.statusColor = 'red-mint';
                } else if (status == 'INPROGRESS') {
                    vm.statusColor = 'yellow-saffron';
                }
            }
        }
        function statusDisabled() {
            if (vm.giftOrder.status == 'SHIPPED' || vm.giftOrder.status == 'CANCELED'){
                vm.status  = true;
            }
        }

        function saveGiftOrderHistory(giftOrder) {
            var  giftOrderHistory = {
                giftOrderId: giftOrder.id,
                status: giftOrder.status,
                event: 'ORDER_STATUS_CHANGED'

            };
            GiftOrderHistory.save(giftOrderHistory, function (data) {
                vm.giftOrder = GiftOrder.get({id: vm.giftOrder.id});
            });

        }
    }
})();

