(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('AddressCloudImageDetailController', AddressCloudImageDetailController);

    AddressCloudImageDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'AddressCloudImage', 'OrderInfo'];

    function AddressCloudImageDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, AddressCloudImage, OrderInfo) {
        var vm = this;

        vm.addressCloudImage = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:addressCloudImageUpdate', function(event, result) {
            vm.addressCloudImage = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
