(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('WaivedProductGroupController', WaivedProductGroupController);

    WaivedProductGroupController.$inject = ['WaivedProductGroup'];

    function WaivedProductGroupController(WaivedProductGroup) {

        var vm = this;

        vm.waivedProductGroups = [];

        loadAll();

        function loadAll() {
            WaivedProductGroup.query(function(result) {
                vm.waivedProductGroups = result;
                vm.searchQuery = null;
            });
        }
    }
})();
