(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('GiftRequestController', GiftRequestController);

    GiftRequestController.$inject = ['$scope', '$state', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$interval', 'DateUtils', 'DataUtils', 'PendingRequest', 'GiftRequest'];

    function GiftRequestController($scope, $state, ParseLinks, AlertService, paginationConstants, pagingParams, $interval, DateUtils, DataUtils, PendingRequest, GiftRequest) {
        var vm = this;

        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.activeTab = pagingParams.activeTab;
        vm.activateTab = activateTab;

        vm.predicateNewReq = pagingParams.predicateNewReq;
        vm.reverseNewReq = pagingParams.ascendingNewReq;
        vm.transitionNewReq = transitionNewReq;
        vm.loadAllNewReq = loadAllNewReq;

        vm.predicatePendingReq = pagingParams.predicatePendingReq;
        vm.reversePendingReq = pagingParams.ascendingPendingReq;
        vm.loadAllPendingReq = loadAllPendingReq;

        vm.dateDiff = DateUtils.transactionDateDifference;
        vm.getDeductedAmount = DataUtils.getDeductedAmount;
        vm.getSentAmount = DataUtils.getSentAmount;

        loadAllNewReq();
        loadAllPendingReq();

        function loadAllNewReq() {
            GiftRequest.query({
                page: pagingParams.pageNewReq - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicateNewReq + ',' + (vm.reverseNewReq ? 'asc' : 'desc')];
                if (vm.predicateNewReq !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.linksNewReq = ParseLinks.parse(headers('link'));
                vm.totalItemsNewReq = headers('X-Total-Count');
                vm.queryCountNewReq = vm.totalItemsNewReq;
                vm.transactionsNewReq = data;
                vm.pageNewReq = pagingParams.pageNewReq;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionNewReq() {
            $state.transitionTo($state.$current, {
                pageNewReq: vm.pageNewReq,
                sortNewReq: vm.predicateNewReq + ',' + (vm.reverseNewReq ? 'asc' : 'desc'),
                pagePendingReq: vm.pagePendingReq,
                sortPendingReq: vm.predicatePendingReq + ',' + (vm.reversePendingReq ? 'asc' : 'desc'),
                activeTab: vm.activeTab
            });
        }

        function loadAllPendingReq() {
            PendingRequest.query({
                page: pagingParams.pagePendingReq - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicatePendingReq + ',' + (vm.reversePendingReq ? 'asc' : 'desc')];
                if (vm.predicatePendingReq !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.linksPendingReq = ParseLinks.parse(headers('link'));
                vm.totalItemsPendingReq = headers('X-Total-Count');
                vm.queryCountPendingReq = vm.totalItemsPendingReq;
                vm.transactionsPendingReq = data;
                vm.pagePendingReq = pagingParams.pagePendingReq;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function activateTab(tab) {
            vm.activeTab = tab;
        }
    }

})();
