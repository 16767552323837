(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('ProductTag', ProductTag);

    ProductTag.$inject = ['$resource'];

    function ProductTag ($resource) {
        var resourceUrl =  'api/product-tags/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAsList': {
                method: 'GET',
                isArray: true,
                url: 'api/product-tag-list'
            },
            'update': { method:'PUT' },
            'add': { method:'POST', url: 'api/product-tags/add'},
            'remove': { method:'POST', url: 'api/product-tags/remove'}
        });
    }
})();
