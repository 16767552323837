(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .factory('OrderHistorySearch', OrderHistorySearch);

    OrderHistorySearch.$inject = ['$resource'];

    function OrderHistorySearch($resource) {
        var resourceUrl =  'api/_search/order-histories/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
