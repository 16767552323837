(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('Profile', Profile)
        .factory('CustomerStatusLog', CustomerStatusLog)
        .factory('ProfileChangePhone', ProfileChangePhone)
        .factory('ProfileIdByAccount', ProfileIdByAccount)
        .factory('ProfileIdByPhone', ProfileIdByPhone);

    Profile.$inject = ['$resource', 'DateUtils'];

    function Profile($resource, DateUtils) {
        var resourceUrl = 'api/profiles/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.registrationDate = DateUtils.convertDateTimeFromServer(data.registrationDate);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }

    CustomerStatusLog.$inject = ['$resource'];

    function CustomerStatusLog($resource) {
        var resourceUrl = 'api/customer-status-logs';
        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

    ProfileChangePhone.$inject = ['$resource'];

    function ProfileChangePhone($resource) {
        var resourceUrl = 'api/customers/change-phone';
        return $resource(resourceUrl, {}, {
            'update': {method: 'PUT'}
        })

    }

    ProfileIdByAccount.$inject = ['$resource'];

    function ProfileIdByAccount($resource) {
        var resourceUrl = 'api/profiles/id/account/:accountNumber';
        return $resource(resourceUrl, {}, {
            'get': {method: 'GET', isArray: false},
        })
    }
    ProfileIdByPhone.$inject = ['$resource'];

    function ProfileIdByPhone ($resource) {
        var resourceUrl =  'api/profiles/id/phone/:phoneNumber';
        return $resource(resourceUrl, {}, {
            'get': { method: 'GET', isArray: false}
        });
    }
})();
