(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('product-price-change-log', {
            parent: 'entity',
            url: '/product-price-change-log?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'bozorakaAdminApp.productPriceChangeLog.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-price-change-log/product-price-change-logs.html',
                    controller: 'ProductPriceChangeLogController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productPriceChangeLog');
                    $translatePartialLoader.addPart('priceChangeEvent');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('products-price-change-log', {
            parent: 'entity',
            url: '/product/{productId}/price-change-log?page&sort&search&name&variantName&sku',
            data: {
                authorities: ['ROLE_MANAGER','ROLE_USER'],
                pageTitle: 'bozorakaAdminApp.productPriceChangeLog.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-price-change-log/product-price-change-logs-product.html',
                    controller: 'ProductPriceChangeLogsProductController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'modifiedDate,desc',
                    squash: true
                },
                search: null,
                productVariantId: null,
                eventType: null,
                fromDate: null,
                toDate: null,
                name: "",
                variantName: "",
                sku: ""
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        productVariantId: $stateParams.productVariantId,
                        eventType: $stateParams.eventType,
                        fromDate: $stateParams.fromDate,
                        toDate: $stateParams.toDate,
                        name: $stateParams.name,
                        variantName: $stateParams.variantName,
                        sku: $stateParams.sku
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productPriceChangeLog');
                    $translatePartialLoader.addPart('priceChangeEvent');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('product-price-change-log-detail', {
            parent: 'entity',
            url: '/product-price-change-log/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'bozorakaAdminApp.productPriceChangeLog.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-price-change-log/product-price-change-log-detail.html',
                    controller: 'ProductPriceChangeLogDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productPriceChangeLog');
                    $translatePartialLoader.addPart('priceChangeEvent');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ProductPriceChangeLog', function($stateParams, ProductPriceChangeLog) {
                    return ProductPriceChangeLog.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'product-price-change-log',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('product-price-change-log-detail.edit', {
            parent: 'product-price-change-log-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product-price-change-log/product-price-change-log-dialog.html',
                    controller: 'ProductPriceChangeLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ProductPriceChangeLog', function(ProductPriceChangeLog) {
                            return ProductPriceChangeLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product-price-change-log.new', {
            parent: 'product-price-change-log',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product-price-change-log/product-price-change-log-dialog.html',
                    controller: 'ProductPriceChangeLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                productId: null,
                                productVariantId: null,
                                fromPrice: null,
                                toPrice: null,
                                eventType: null,
                                modifiedBy: null,
                                modifiedDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('product-price-change-log', null, { reload: 'product-price-change-log' });
                }, function() {
                    $state.go('product-price-change-log');
                });
            }]
        })
        .state('product-price-change-log.edit', {
            parent: 'product-price-change-log',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product-price-change-log/product-price-change-log-dialog.html',
                    controller: 'ProductPriceChangeLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ProductPriceChangeLog', function(ProductPriceChangeLog) {
                            return ProductPriceChangeLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-price-change-log', null, { reload: 'product-price-change-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product-price-change-log.delete', {
            parent: 'product-price-change-log',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product-price-change-log/product-price-change-log-delete-dialog.html',
                    controller: 'ProductPriceChangeLogDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ProductPriceChangeLog', function(ProductPriceChangeLog) {
                            return ProductPriceChangeLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-price-change-log', null, { reload: 'product-price-change-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
