(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('PayFormBalance', PayFormBalance)
        .factory('HermesBalance', HermesBalance);

    PayFormBalance.$inject = ['$resource'];

    function PayFormBalance($resource) {
        var resourceUrl = 'api/payform-balance';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET'}

        });
    }

    HermesBalance.$inject = ['$resource'];

    function HermesBalance($resource) {
        var resourceUrl = 'api/hermes-balance';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}

        });
    }

})();
