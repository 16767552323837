(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('SalesByProduct', SalesByProduct);

    SalesByProduct.$inject = ['$resource', 'DateUtils'];

    function SalesByProduct($resource) {
        var resourceUrl = 'api/sales-by-product';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
