(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('CareerDetailController', CareerDetailController);

    CareerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Career'];

    function CareerDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Career) {
        var vm = this;

        vm.career = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:careerUpdate', function(event, result) {
            vm.career = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
