(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('partner-order-info', {
            parent: 'entity',
            url: '/partner-order-info?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_WAREHOUSE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.partnerOrderInfo.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/partner-order-info/partner-order-infos.html',
                    controller: 'PartnerOrderInfoController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'createdDate,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('partnerOrderInfo');
                    $translatePartialLoader.addPart('orderStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('agent-order-info', {
            parent: 'entity',
            url: '/agent-order-info?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_WAREHOUSE_MANAGER'],
                pageTitle: 'global.menu.agent.requests'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/partner-order-info/agent-order-infos.html',
                    controller: 'AgentOrderInfoController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'createdDate,asc',
                    squash: true
                },
                pageNewReq: {
                    value: '1',
                    squash: true
                },
                sortNewReq: {
                    value: 'createdDate,asc',
                    squash: true
                },
                pagePendingReq: {
                    value: '1',
                    squash: true
                },
                sortPendingReq: {
                    value: 'createdDate,asc',
                    squash: true
                },
                search: null,
                activeTab: 'tab_new_1',
                sellerId: 0
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,

                        pageNewReq: PaginationUtil.parsePage($stateParams.pageNewReq),
                        sortNewReq: $stateParams.sortNewReq,
                        predicateNewReq: PaginationUtil.parsePredicate($stateParams.sortNewReq),
                        ascendingNewReq: PaginationUtil.parseAscending($stateParams.sortNewReq),
                        pagePendingReq: PaginationUtil.parsePage($stateParams.pagePendingReq),
                        sortPendingReq: $stateParams.sortPendingReq,
                        predicatePendingReq: PaginationUtil.parsePredicate($stateParams.sortPendingReq),
                        ascendingPendingReq: PaginationUtil.parseAscending($stateParams.sortPendingReq),
                        activeTab: $stateParams.activeTab,
                        sellerId: $stateParams.sellerId
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('partnerOrderInfo');
                    $translatePartialLoader.addPart('orderStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'agent-order-info',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('b2b-order-info', {
            parent: 'entity',
            url: '/b2b-order-info?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ANALYST'],
                pageTitle: 'global.menu.partner.allOrders'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/partner-order-info/b2b-order-infos.html',
                    controller: 'B2BOrderInfoController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null,
                firstRun: true,
                field: null,
                fromDate: null,
                toDate: null,
                phone: null,
                orderStatus: null,
                paymentStatus: null,
                authority: 'ALL',
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        firstRun: $stateParams.firstRun,
                        field: $stateParams.field,
                        fromDate: $stateParams.fromDate,
                        toDate: $stateParams.toDate,
                        phone: $stateParams.phone,
                        orderStatus: $stateParams.orderStatus,
                        paymentStatus: $stateParams.paymentStatus,
                        authority: $stateParams.authority
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('partnerOrderInfo');
                    $translatePartialLoader.addPart('orderStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('partner-order-info-detail', {
            parent: 'entity',
            url: '/partner-order-info/{id}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_WAREHOUSE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.partnerOrderInfo.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/partner-order-info/partner-order-info-detail.html',
                    controller: 'PartnerOrderInfoDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('partnerOrderInfo');
                    $translatePartialLoader.addPart('orderInfo');
                    $translatePartialLoader.addPart('orderStatus');
                    $translatePartialLoader.addPart('orderTracking');
                    $translatePartialLoader.addPart('courier');
                    $translatePartialLoader.addPart('delivery');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PartnerOrderInfo', function($stateParams, PartnerOrderInfo) {
                    return PartnerOrderInfo.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'partner-order-info',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('partner-order-info-detail.edit-shipping-address', {
            parent: 'partner-order-info-detail',
            url: '/edit-shipping-address',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/partner-order-info/shipping-details-dialog.html',
                    controller: 'ShippingDetailsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['PartnerOrderInfo', function(PartnerOrderInfo) {
                            return PartnerOrderInfo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('partner-order-info.new', {
            parent: 'partner-order-info',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/partner-order-info/partner-order-info-dialog.html',
                    controller: 'PartnerOrderInfoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                fullName: null,
                                phone: null,
                                address: null,
                                totalPrice: null,
                                totalShippingFee: null,
                                status: null,
                                createdBy: null,
                                createdDate: null,
                                lastModifiedBy: null,
                                lastModifiedDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('partner-order-info', null, { reload: 'partner-order-info' });
                }, function() {
                    $state.go('partner-order-info');
                });
            }]
        })
        .state('partner-order-info-detail.status', {
            parent: 'partner-order-info-detail',
            url: '/{status}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_MARKET_MANAGER', 'ROLE_WAREHOUSE_MANAGER']
            },
            onEnter: ['$translate', '$stateParams', '$state', '$uibModal', function($translate, $stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/partner-order-info/partner-order-info-status-dialog.html',
                    controller: 'PartnerOrderInfoStatusChangeDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['$stateParams', 'PartnerOrderInfo', function($stateParams, PartnerOrderInfo) {
                            return PartnerOrderInfo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('partner-order-info-detail.paymentStatus', {
            parent: 'partner-order-info-detail',
            params: {
                paymentStatus: null
            },
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_MARKET_MANAGER', 'ROLE_WAREHOUSE_MANAGER']
            },
            onEnter: ['$translate', '$stateParams', '$state', '$uibModal', function($translate, $stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/partner-order-info/partner-order-payment-status-dialog.html',
                    controller: 'PartnerOrderPaymentStatusChangeDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['$stateParams', 'PartnerOrderInfo', function($stateParams, PartnerOrderInfo) {
                            return PartnerOrderInfo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }

})();
