(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('product-tier-group', {
            parent: 'entity',
            url: '/product-tier-group?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.productTier.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product-tier-group/product-tier-groups.html',
                    controller: 'ProductTierGroupController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                name: null,
                tierGroups: undefined,
                categoryId: null,
                waivedProductGroup: 0,
                statuses: ["AVAILABLE", "OUT_OF_STOCK", "DISABLED"],
                fistRun : true
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        name: $stateParams.name,
                        tierGroups: $stateParams.tierGroups,
                        categoryId: $stateParams.categoryId,
                        waivedProductGroup: $stateParams.waivedProductGroup,
                        statuses: $stateParams.statuses,
                        fistRun: $stateParams.fistRun
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('productTier');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('productStatus');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
