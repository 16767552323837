(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('StatsWeeklyController', StatsWeeklyController);

    StatsWeeklyController.$inject = ['StatsWeekly', 'AlertService', 'DateUtils', 'StatsWeeklyBonus', '$stateParams'];

    function StatsWeeklyController(StatsWeekly, AlertService, DateUtils, StatsWeeklyBonus, $stateParams) {

        var vm = this;
        vm.search = search;
        vm.client = 'bozoraka';
        var today = new Date();
        var mon = '';
        var tue = '';
        var wed = '';
        var thu = '';
        var fri = '';
        var sat = '';
        var sun = '';
        var selectedDate = '';
        var transactionExistingDateList = [];
        var amountPlusBonus = [];
        var amountMinusBonus = [];
        var countTransaction = [];
        var amountTransaction = [];
        getDateRange(today);

        function search() {
            StatsWeekly.query(vm.searchQuery, onSuccess, onError);
            StatsWeeklyBonus.query(vm.searchQuery, onSuccessBonus, onError);

            function onSuccess(data, headers) {
                $.when(prepareAmountObjectAsArray(data)).done(function () {
                    highCharts();
                });
            }

            function onSuccessBonus(data) {
                $.when(prepareBonusObjectAsArray(data)).done(function () {
                    highchartBonus();
                });
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function highCharts() {
            Highcharts.chart('highchart', {
                title: {
                    text: 'Weekly TRANSACTIONS and SALES'
                },
                xAxis: [{
                    categories: ['Mon (' + mon + ')', 'Tue (' + tue + ')', 'Wed (' + wed + ')',
                        'Thu (' + thu + ')', 'Fri (' + fri + ')', 'Sat (' + sat + ')',
                        'Sun (' + sun + ')'],
                    crosshair: true
                }],
                yAxis: [{ // Primary yAxis
                    labels: {
                        format: '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[5]
                        }
                    },
                    title: {
                        text: 'TRANSACTIONS',
                        style: {
                            color: Highcharts.getOptions().colors[5]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: 'SALES',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: Highcharts.valueOf().value,
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'center',
                    x: 120,
                    verticalAlign: 'top',
                    y: 100,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
                },
                series: [{
                    name: 'Sales',
                    type: 'column',
                    yAxis: 1,
                    data: amountTransaction,
                    tooltip: {
                        valueSuffix: ''
                    }

                }, {
                    name: 'Transactions',
                    type: 'spline',
                    color: 'red',
                    data: countTransaction,
                    tooltip: {
                        valueSuffix: ''
                    }
                }]
            });
        }

        function highchartBonus() {
            Highcharts.chart('highchartBonus', {
                title: {
                    text: 'Weekly BONUS Plus and Minus'
                },
                xAxis: [{
                    categories: ['Mon (' + mon + ')', 'Tue (' + tue + ')', 'Wed (' + wed + ')',
                        'Thu (' + thu + ')', 'Fri (' + fri + ')', 'Sat (' + sat + ')',
                        'Sun (' + sun + ')'],
                    crosshair: true
                }],
                yAxis: [{ // Primary yAxis
                    labels: {
                        format: '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[5]
                        }
                    },
                    title: {
                        text: 'MINUS',
                        style: {
                            color: Highcharts.getOptions().colors[5]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: 'PLUS',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: Highcharts.valueOf().value,
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'center',
                    x: 120,
                    verticalAlign: 'top',
                    y: 100,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
                },
                series: [{
                    name: 'Plus',
                    type: 'spline',
                    yAxis: 1,
                    data: amountPlusBonus,
                    tooltip: {
                        valueSuffix: ''
                    }

                }, {
                    name: 'Minus',
                    type: 'spline',
                    color: 'red',
                    data: amountMinusBonus,
                    tooltip: {
                        valueSuffix: ''
                    }
                }]
            });
        }

        function getDateRange(date) {
            selectedDate = date;
            var fromDate = new Date(moment(selectedDate).day(1));
            var toDate = new Date(moment(selectedDate).day(7));
            $('#datetimepicker').datetimepicker({weekStart: 1});
            var firstDateToShow = moment(selectedDate).day(1).format("MM/DD/YYYY");
            var lastDateToShow = moment(selectedDate).day(7).format("MM/DD/YYYY");
            $("#week").val(firstDateToShow + " ~ " + lastDateToShow);
            mon = moment(selectedDate).day(1).format("DD/MMM");
            tue = moment(selectedDate).day(2).format("DD/MMM");
            wed = moment(selectedDate).day(3).format("DD/MMM");
            thu = moment(selectedDate).day(4).format("DD/MMM");
            fri = moment(selectedDate).day(5).format("DD/MMM");
            sat = moment(selectedDate).day(6).format("DD/MMM");
            sun = moment(selectedDate).day(7).format("DD/MMM");

            vm.searchQuery = {
                fromDate: DateUtils.converterDateFormat(fromDate),
                toDate: DateUtils.converterDateFormat(toDate),
                client: vm.client
            };
            transactionExistingDateList = [];
            search();
        }

        function prepareAmountObjectAsArray(data) {
            countTransaction = [];
            amountTransaction = [];
            for (var day = 1; day <= 7; day++) {
                var index = getExistingWeekdayIndex(day, data);
                if (index >= 0) {
                    countTransaction.push(data[index].countTransaction);
                    amountTransaction.push(data[index].amountTransaction);
                } else {
                    countTransaction.push(0);
                    amountTransaction.push(0);
                }
            }
        }

        function prepareBonusObjectAsArray(data) {
            amountPlusBonus = [];
            amountMinusBonus = [];
            for (var day = 1; day <= 7; day++) {
                var index = getExistingWeekdayIndex(day, data);
                if (index >= 0) {
                    amountPlusBonus.push(data[index].amountPlusBonus);
                    amountMinusBonus.push(data[index].amountMinusBonus);
                } else {
                    amountPlusBonus.push(0);
                    amountMinusBonus.push(0);
                }
            }
        }

        function getExistingWeekdayIndex(day, data) {
            if (day == 7) {
                day = 0;
            }
            for (var i = 0; i < data.length; i++) {
                var existingWeekDay = new Date(data[i].date).getDay();
                if (existingWeekDay == day) {
                    return i;
                }
            }
            return -1;
        }

        $('#datetimepicker')
            .datetimepicker()
            .on('changeDate', function (ev) {
                var value = new Date($("#week").val());
                $('#datetimepicker').datetimepicker('hide');
                getDateRange(value);
            });

        vm.appNameChanged = function () {
            vm.searchQuery.client = vm.client;
            search();
        }
    }
})();
