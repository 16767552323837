(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('product-box', {
                parent: 'entity',
                url: '/product-box',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA'],
                    pageTitle: 'global.menu.market.homePage'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-box/product-box.html',
                        controller: 'ProductBoxController',
                        controllerAs: 'vm'

                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('product-box-edit', {
                parent: 'product-box',
                url: '/{categoryId}/edit',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA'],
                    pageTitle: 'global.menu.market.homePage'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product-box/product-box-edit.html',
                        controller: 'ProductBoxEditController',
                        controllerAs: 'vm'

                    }
                },
                params: {
                    langKey: 'uz',
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProductBoxCategory', function($stateParams, ProductBoxCategory) {
                        return ProductBoxCategory.get({categoryId: $stateParams.categoryId, langKey: $stateParams.langKey}).$promise;
                    }],
                    categoryId: ['$stateParams', function($stateParams) {
                        return  $stateParams.categoryId;
                    }],
                    langKey: ['$stateParams', function($stateParams) {
                        return  $stateParams.langKey;
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }]
                }
            })
    }
})();
