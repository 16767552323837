(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('inventory-history', {
                parent: 'entity',
                url: '/inventory-history?page&sort&search',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_USER'],
                    pageTitle: 'bozorakaAdminApp.inventoryHistory.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/inventory-history/inventory-histories.html',
                        controller: 'InventoryHistoryController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('inventoryHistory');
                        $translatePartialLoader.addPart('inventoryEvent');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('inventory-history-detail', {
                parent: 'inventory-history',
                url: '/inventory-history/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'bozorakaAdminApp.inventoryHistory.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/inventory-history/inventory-history-detail.html',
                        controller: 'InventoryHistoryDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('inventoryHistory');
                        $translatePartialLoader.addPart('inventoryEvent');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'InventoryHistory', function ($stateParams, InventoryHistory) {
                        return InventoryHistory.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'inventory-history',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('inventory-history-detail.edit', {
                parent: 'inventory-history-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/inventory-history/inventory-history-dialog.html',
                        controller: 'InventoryHistoryDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['InventoryHistory', function (InventoryHistory) {
                                return InventoryHistory.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('inventory-history.new', {
                parent: 'inventory-history',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/inventory-history/inventory-history-dialog.html',
                        controller: 'InventoryHistoryDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    event: null,
                                    adjustment: null,
                                    quantity: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('inventory-history', null, {reload: 'inventory-history'});
                    }, function () {
                        $state.go('inventory-history');
                    });
                }]
            })
            .state('inventory-history.edit', {
                parent: 'inventory-history',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/inventory-history/inventory-history-dialog.html',
                        controller: 'InventoryHistoryDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['InventoryHistory', function (InventoryHistory) {
                                return InventoryHistory.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('inventory-history', null, {reload: 'inventory-history'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('inventory-history.delete', {
                parent: 'inventory-history',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/inventory-history/inventory-history-delete-dialog.html',
                        controller: 'InventoryHistoryDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['InventoryHistory', function (InventoryHistory) {
                                return InventoryHistory.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('inventory-history', null, {reload: 'inventory-history'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('product-inventory-history', {
                parent: 'entity',
                url: '/products/{id}/inventory-history?page&sort&search&name&sku',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SELLER', 'ROLE_MARKET_MANAGER', 'ROLE_WAREHOUSE_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.inventoryHistory.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/inventory-history/inventory-histories.html',
                        controller: 'InventoryHistoryController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null,
                    field: null,
                    valueLong1: null,
                    valueString1: null,
                    fromDate: null,
                    toDate: null,
                    name: "",
                    sku: ""
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            field: $stateParams.field,
                            valueLong1: $stateParams.variantId,
                            valueString1: $stateParams.valueString1,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            name: $stateParams.name,
                            sku: $stateParams.sku
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('inventoryHistory');
                        $translatePartialLoader.addPart('inventoryEvent');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('product-variant-inventory-history', {
                parent: 'entity',
                url: '/products/{id}/product-variants/{variantId}/inventory-history?page&sort&search&name&variantName&sku',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SELLER', 'ROLE_MARKET_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.inventoryHistory.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/inventory-history/inventory-histories.html',
                        controller: 'InventoryHistoryController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null,
                    field: null,
                    valueLong1: null,
                    valueString1: null,
                    fromDate: null,
                    toDate: null,
                    name: "",
                    variantName: "",
                    sku: ""
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            field: $stateParams.field,
                            valueLong1: $stateParams.variantId,
                            valueString1: $stateParams.valueString1,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            name: $stateParams.name,
                            variantName: $stateParams.variantName,
                            sku: $stateParams.sku
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('inventoryHistory');
                        $translatePartialLoader.addPart('inventoryEvent');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('inventory-edit-history', {
                parent: 'entity',
                url: '/inventory-edit-history',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_SELLER', 'ROLE_MARKET_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.inventoryHistory.editHome.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/inventory-history/inventory-edit-histories.html',
                        controller: 'InventoryEditHistoryController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null,
                    field: null,
                    valueString1: null,
                    users: null,
                    fromDate: null,
                    toDate: null,
                    firstRun: true,
                    usersList: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            field: $stateParams.field,
                            valueString1: $stateParams.valueString1,
                            users: $stateParams.users,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            firstRun: $stateParams.firstRun,
                            usersList: $stateParams.usersList
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('inventoryHistory');
                        $translatePartialLoader.addPart('inventoryEvent');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
