(function () {
    'use strict';

    angular.module('bozorakaAdminApp')
        .directive('transactionStatus', transactionStatus);

    var statuses = {
        SUCCESS: {
            name: 'global.transaction.status.success',
            style: 'btn btn-xs green-seagreen'
        },
        INPROGRESS: {
            name: 'global.transaction.status.inprogress',
            style: 'btn btn-xs yellow-saffron'
        },
        FAILURE: {
            name: 'global.transaction.status.failure',
            style: 'btn btn-xs red-mint'
        },
        WAITING_FOR_PAYMENT: {
            name: 'global.transaction.status.waitingForPayment',
            style: 'btn btn-xs yellow-casablanca'
        },
        REFUND: {
            name: 'global.transaction.status.refund',
            style: 'btn btn-xs purple'
        }
    };

    function transactionStatus() {
        var directive = {
            restrict: 'EA',
            controller: TransactionStatusController,
            controllerAs: 'vm',
            bindToController: true,
            template: '<a class="{{vm.style}}" style="pointer-events: none">' +
            '{{vm.name | translate}}' +
            '</a>',
            scope: {
                status: '<'
            }
        };

        return directive;
    }

    function TransactionStatusController() {
        var vm = this;

        setStatus();

        function setStatus() {
            var status = statuses[vm.status];
            vm.name = status.name;
            vm.style = status.style;
        }
    }
})();
