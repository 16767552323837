(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileDialogController', ProfileDialogController);

    ProfileDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Profile', 'Customer'];

    function ProfileDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Profile, Customer) {
        var vm = this;

        vm.profile = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.customers = Customer.query({filter: 'profile-is-null'});
        $q.all([vm.profile.$promise, vm.customers.$promise]).then(function () {
            if (!vm.profile.customerId) {
                return $q.reject();
            }
            return Customer.get({id: vm.profile.customerId}).$promise;
        }).then(function (customer) {
            vm.customers.push(customer);
        });

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.profile.id !== null) {
                Profile.update(vm.profile, onSaveSuccess, onSaveError);
            } else {
                Profile.save(vm.profile, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bozorakaAdminApp:profileUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.registrationDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
