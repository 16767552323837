(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .controller('StockManagementController', StockManagementController);

    StockManagementController.$inject = ['$http', '$filter', '$rootScope', 'AlertService', 'DateUtils', 'pagingParams', 'StockManagement', 'Category', '$translate', 'Vendor'];
    function StockManagementController($http, $filter, $rootScope, AlertService, DateUtils, pagingParams, StockManagement, Category, $translate, Vendor) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.clear = clear;
        vm.excel = excel;
        vm.search = search;
        vm.sortCurrentStock = sortCurrentStock;
        vm.sortProductName = sortProductName;
        vm.onSelectCategory = onSelectCategory;
        vm.categories = [];
        vm.categoryTree = [];
        vm.childCategoryList = pagingParams.childCategoryList;
        vm.vendorsList = pagingParams.vendorsList;
        vm.parentCategoryField = pagingParams.parentCategoryField;
        vm.childCategoryField = pagingParams.childCategoryField;
        vm.searchQuery = {
            categoryId: pagingParams.categoryId,
            productName: pagingParams.productName,
            vendorId: pagingParams.vendorId,
            sku: pagingParams.sku,
            productStatuses: pagingParams.productStatuses,
            stockStatus: pagingParams.stockStatus,
            averageWeeksCount: pagingParams.averageWeeksCount,
            forecastWeeksCount: pagingParams.forecastWeeksCount,
            vendors: pagingParams.vendors,
            parentCategories: pagingParams.parentCategories,
            childCategories: pagingParams.childCategories,
            productAssortment: pagingParams.productAssortment,
            language: $translate.use()
        };
        vm.categories = [];
        vm.parentCategory = null;
        vm.childCategory = null;
        vm.stockManagements = [];
        vm.currentStockSort = 'none';
        vm.productNameSort = 'none';
        loadCategories();
        getAllVendors();

        function clear() {
            vm.parentCategory = null;
            vm.childCategory = null;
            vm.searchQuery = {
                categoryId: null,
                stockStatus: null,
                averageWeeksCount: '1',
                forecastWeeksCount: '1',
                productName: null,
                vendors: vm.vendorsList,
                sku: "",
                productStatuses: ['AVAILABLE', 'OUT_OF_STOCK'],
                parentCategories: null,
                childCategories: null,
                productAssortment: null,
                language: $translate.use()
            };
            vm.parentCategoryField= null;
            vm.childCategoryField = null;
            vm.childCategoryList = null;
            $('#field_parent_category').multiselect('deselectAll', false);
            $('#field_parent_category').multiselect('updateButtonText');
            $('#field_child_category').multiselect('deselectAll', false);
            $('#field_child_category').multiselect('updateButtonText');
            $('#field_vendor').multiselect('deselectAll', false);
            $('#field_vendor').multiselect('updateButtonText');
            $('#productStatuses').multiselect('deselectAll', false);
            $('#productStatuses').multiselect('updateButtonText');
            $('#field_product_assortment').multiselect('deselectAll', false);
            $('#field_product_assortment').multiselect('updateButtonText');
            vm.stockManagements = [];
            vm.totalOverStock = 0;
            vm.totalOverStockValue = 0;
            vm.totalOverStockCost = 0;
            vm.totalStockOut = 0;
            vm.totalStockOutValue = 0;
            vm.totalStockOutCost = 0;
            vm.totalWeeklyGrossProfit = 0;
            vm.stockManagements = [];

            setTimeout(function () {
                $('#field_child_category').multiselect(
                    'setOptions',
                    {
                        selectAllText: all,
                        nonSelectedText: noneSelected,
                        allSelectedText: allSelected
                    });
                $('#field_child_category').multiselect('rebuild');
                $('#productStatuses').multiselect(
                    'setOptions',
                    {
                        selectAllText: all,
                        nonSelectedText: noneSelected,
                        allSelectedText: allSelected
                    });
                $('#productStatuses').multiselect('rebuild');
            }, 0);
        }

        function excel() {
            setCategory();
            defineLanguage();
            var data = "parentCategories=" + (vm.searchQuery.parentCategories === null ? '' : vm.searchQuery.parentCategories)
                + "&childCategories=" + (vm.searchQuery.childCategories === null ? '' : vm.searchQuery.childCategories)
                + "&stockStatus=" + (vm.searchQuery.stockStatus === null ? '' : vm.searchQuery.stockStatus)
                + "&productAssortment=" + (vm.searchQuery.productAssortment === null ? '' : vm.searchQuery.productAssortment)
                + "&productName=" + (vm.searchQuery.productName === null ? 'UNKNOWN' : vm.searchQuery.productName)
                + "&vendors=" + (vm.searchQuery.vendors === null || vm.searchQuery.vendors === undefined ? '' : vm.searchQuery.vendors)
                + "&sku=" + (vm.searchQuery.sku === "" ? '' : vm.searchQuery.sku)
                + "&productStatuses=" + (vm.searchQuery.productStatuses === null ? '' : vm.searchQuery.productStatuses)
                + "&averageWeeksCount=" + vm.searchQuery.averageWeeksCount
                + "&forecastWeeksCount=" + vm.searchQuery.forecastWeeksCount
                + "&language=" + vm.searchQuery.language
                + "&productNameSort=" + vm.productNameSort
                + "&currentStockSort=" + vm.currentStockSort;

            $http({
                url: 'api/stock-management-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'stock-management-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {

            });
        }

        function sortCurrentStock() {
            function compare(a, b) {
                if (a.currentStock < b.currentStock) {
                    return -1;
                }
                if (a.currentStock > b.currentStock) {
                    return 1;
                }
                return 0;
            }

            function compare2(a, b) {
                if (a.currentStock < b.currentStock) {
                    return 1;
                }
                if (a.currentStock > b.currentStock) {
                    return -1;
                }
                return 0;
            }

            vm.productNameSort = 'none';
            if (vm.currentStockSort === 'desc') {
                vm.currentStockSort = 'asc'
                vm.stockManagements.sort(compare);
            } else {
                vm.currentStockSort = 'desc'
                vm.stockManagements.sort(compare2);
            }
        }

        function sortProductName() {
            function compare3(a, b) {
                if (a.productName < b.productName) {
                    return -1;
                }
                if (a.productName > b.productName) {
                    return 1;
                }
                return 0;
            }

            function compare4(a, b) {
                if (a.productName < b.productName) {
                    return 1;
                }
                if (a.productName > b.productName) {
                    return -1;
                }
                return 0;
            }

            vm.currentStockSort = 'none';
            if (vm.productNameSort === 'asc') {
                vm.productNameSort = 'desc'
                vm.stockManagements.sort(compare4);
            } else {
                vm.productNameSort = 'asc'
                vm.stockManagements.sort(compare3);
            }
        }

        function search() {
            setCategory();
            defineLanguage();
            StockManagement.query(vm.searchQuery, onSuccess, onError);

            function onSuccess(data) {
                vm.stockManagements = data;
                vm.currentStockSort = 'none';
                vm.productNameSort = 'none';
                calculateTotalDeadStock();
            }

            function onError(error) {
                AlertService.error(error);
            }

        }

        function defineLanguage() {
            vm.searchQuery.language = $translate.use();
        }

        function getAllVendors() {
            Vendor.getAsList({}, onSuccess, onError);

            function onSuccess(data) {
                vm.vendors = data;
                vm.vendors.push({id: -1, name: "Not Specified"});
                setTimeout(function () {
                    $('#field_vendor').multiselect(
                        'setOptions',
                        {
                            selectAllText: all,
                            nonSelectedText: noneSelected,
                            allSelectedText: allSelected
                        });
                    $('#field_vendor').multiselect('rebuild');
                }, 0);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getVendorIds(vendors) {
            vm.vendorsList = [];
            vendors.forEach(function (vendor) {
                vm.vendorsList.push(vendor.id.toString());
            });
            vm.searchQuery.vendors = vm.vendorsList;
        }

        function loadCategories() {
            Category.getAllCategories({}, function (data) {
                vm.categories = data;
                translateCategoryName();
                vm.categories.sort(function (a, b) {
                    return a.parentId > b.parentId ? 1 : -1;
                });
                vm.categoryTree = [];
                for (var j = 0; j < vm.categories.length; j++) {
                    if (vm.categories[j].parentId === null) {
                        vm.categoryTree.push(vm.categories[j]);
                    } else {
                        var index = vm.categoryTree.findIndex(function (a) {
                            return a.id === vm.categories[j].parentId;
                        });
                        if (index > -1) {
                            if (vm.categoryTree[index].children === undefined) {
                                vm.categoryTree[index].children = [];
                            }
                            vm.categoryTree[index].children.push(vm.categories[j]);
                        }

                    }
                }

                setTimeout(function () {
                    $('#field_parent_category').multiselect(
                        'setOptions',
                        {
                            selectAllText: all,
                            nonSelectedText: noneSelected,
                            allSelectedText: allSelected
                        });
                    $('#field_parent_category').multiselect('rebuild');
                }, 0);
            });
        }

        function translateCategoryName() {
            var currentLang = $translate.use();
            for (var i = 0; i < vm.categories.length; i++) {
                if (currentLang === 'en') {
                    vm.categories[i]['name'] = vm.categories[i]['nameEng'];
                } else {
                    vm.categories[i]['name'] = vm.categories[i]['nameUzb'];
                }
            }
        }

        function calculateTotalDeadStock() {
            vm.totalOverStock = 0;
            vm.totalOverStockValue = 0;
            vm.totalOverStockCost = 0;
            vm.totalStockOut = 0;
            vm.totalStockOutValue = 0;
            vm.totalStockOutCost = 0;
            vm.totalWeeklyGrossProfit = 0;
            for (var i = 0; i < vm.stockManagements.length; i++) {
                var stockManagement = vm.stockManagements[i];
                vm.totalOverStock += (stockManagement.stockForecast < 0 ? 0 : stockManagement.stockForecast);
                vm.totalOverStockValue += (stockManagement.stockValue < 0 ? 0 : stockManagement.stockValue);
                vm.totalOverStockCost += (stockManagement.stockCost < 0 ? 0 : stockManagement.stockCost);
                vm.totalStockOut += (stockManagement.stockForecast > 0 ? 0 : stockManagement.stockForecast);
                vm.totalStockOutValue += (stockManagement.stockValue > 0 ? 0 : stockManagement.stockValue);
                vm.totalStockOutCost += (stockManagement.stockCost > 0 ? 0 : stockManagement.stockCost);
                vm.totalWeeklyGrossProfit += (stockManagement.weeklyGrossProfit);
            }
        }



        setTimeout(function () {
            translateMultiSelect();
            $('#productStatuses').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('stock-management.form.all'),
                nonSelectedText: translate('stock-management.form.noneSelected'),
                allSelectedText: translate('stock-management.form.allSelected')
            });
        }, 0);

        function setCategory() {
            if (vm.parentCategoryField != null) {
                vm.searchQuery.parentCategories = [];
                vm.parentCategoryField.forEach(function (category) {
                    category = angular.fromJson(category.toString());
                    vm.searchQuery.parentCategories.push(category.id);
                });
            }
            if (vm.childCategoryField != null) {
                vm.searchQuery.childCategories = [];
                vm.childCategoryField.forEach(function (category) {
                    category = angular.fromJson(category.toString());
                    vm.searchQuery.childCategories.push(category.id);
                });
            }
        }

        function onSelectCategory() {
            var newChildCategoryList = [];
            vm.parentCategoryField.forEach(function (element) {
                var category = angular.fromJson(element.toString());
                newChildCategoryList.push.apply(newChildCategoryList, category.children);
            });
            if (vm.childCategoryList !== null && newChildCategoryList.length < vm.childCategoryList.length && vm.childCategoryField !== null) {
                vm.childCategoryList = vm.childCategoryList.filter(function(element) {
                    return !newChildCategoryList.some(function (child){ return (child.id === element.id)});
                });
                vm.childCategoryField = vm.childCategoryField.filter(function(element) {
                    element = angular.fromJson(element.toString());
                    return !vm.childCategoryList.some(function (child){ return (child.id === element.id)});
                });
            }
            vm.childCategoryList = newChildCategoryList;
            setTimeout(function () {
                $('#field_child_category').multiselect(
                    'setOptions',
                    {
                        selectAllText: all,
                        nonSelectedText: noneSelected,
                        allSelectedText: allSelected
                    });
                $('#field_child_category').multiselect('rebuild');
            }, 0);
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#field_vendor').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('stock-management.form.all'),
                nonSelectedText: translate('stock-management.form.noneSelected'),
                allSelectedText: translate('stock-management.form.allSelected'),
                maxHeight: 300
            });

            $('#field_parent_category').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('stock-management.form.all'),
                nonSelectedText: translate('stock-management.form.noneSelected'),
                allSelectedText: translate('stock-management.form.allSelected'),
                maxHeight: 300
            });

            $('#field_child_category').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('stock-management.form.all'),
                nonSelectedText: translate('stock-management.form.noneSelected'),
                allSelectedText: translate('stock-management.form.allSelected'),
                maxHeight: 300
            });

            $('#field_product_assortment').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('stock-management.form.all'),
                nonSelectedText: translate('stock-management.form.noneSelected'),
                allSelectedText: translate('stock-management.form.allSelected'),
                maxHeight: 300
            });

        }, 0);

        function translateMultiSelect() {
            all = translate('stock-management.form.all');
            noneSelected = translate('stock-management.form.noneSelected');
            allSelected = translate('stock-management.form.allSelected');
        }
    }

})();
