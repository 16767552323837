(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'xeditable',
            'summernote',
            'frapontillo.bootstrap-switch',
            'froala',
            'ngTagsInput',
            'angularMask'
        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler', 'editableOptions'];

    function run(stateHandler, translationHandler, editableOptions) {
        stateHandler.initialize();
        translationHandler.initialize();
        editableOptions.theme = 'bs3';
    }
})();
