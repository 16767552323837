(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('merchant', {
                parent: 'entity',
                url: '/merchant',
                data: {
                    authorities: ['ROLE_MERCHANT'],
                    pageTitle: 'bozorakaAdminApp.profile.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/merchant/merchant-detail.html',
                        controller: 'MerchantDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Merchant', function ($stateParams, Merchant) {
                        return Merchant.get().$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'profile',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('merchant-transaction', {
                parent: 'entity',
                url: '/merchant/transactions?page&sort',
                data: {
                    authorities: ['ROLE_MERCHANT'],
                    pageTitle: 'bozorakaAdminApp.profile.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/merchant/merchant-transaction.html',
                        controller: 'MerchantTransactionController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    pageTranHist: {
                        value: '1',
                        squash: true
                    },
                    sortTranHist: {
                        value: 'pubDate,desc',
                        squash: true
                    },
                    pageBalHist: {
                        value: '1',
                        squash: true
                    },
                    sortBalHist: {
                        value: 'pubDate,desc',
                        squash: true
                    },
                    activeTab: 'tab_1',
                    field: null,
                    fromDate: null,
                    toDate: null,
                    fromZonedDate: null,
                    toZonedDate: null,
                    serviceType: null,
                    transactionStatus: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            pageTranHist: PaginationUtil.parsePage($stateParams.pageTranHist),
                            sortTranHist: $stateParams.sortTranHist,
                            predicateTranHist: PaginationUtil.parsePredicate($stateParams.sortTranHist),
                            ascendingTranHist: PaginationUtil.parseAscending($stateParams.sortTranHist),
                            pageBalHist: PaginationUtil.parsePage($stateParams.pageBalHist),
                            sortBalHist: $stateParams.sortBalHist,
                            predicateBalHist: PaginationUtil.parsePredicate($stateParams.sortBalHist),
                            ascendingBalHist: PaginationUtil.parseAscending($stateParams.sortBalHist),
                            activeTab: $stateParams.activeTab,
                            field: $stateParams.field,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            fromZonedDate: $stateParams.fromZonedDate,
                            toZonedDate: $stateParams.toZonedDate,
                            serviceType: $stateParams.serviceType,
                            transactionStatus: $stateParams.transactionStatus
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        $translatePartialLoader.addPart('transaction');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Merchant', function ($stateParams, Merchant) {
                        return Merchant.get().$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'profile-transaction',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            });
    }

})();
