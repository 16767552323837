(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('SettleVirtualBankCount', SettleVirtualBankCount)
        .factory('SettleVirtualBankTransaction', SettleVirtualBankTransaction)
        .factory('SettleVirtualBankAvailability', SettleVirtualBankAvailability)
        .factory('SettleVirtualBankTransactionTotalAmount', SettleVirtualBankTransactionTotalAmount);

    SettleVirtualBankCount.$inject = ['$resource'];

    function SettleVirtualBankCount ($resource) {
        var resourceUrl =  'api/settle-virtual-bank/count';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    SettleVirtualBankTransaction.$inject = ['$resource'];

    function SettleVirtualBankTransaction ($resource) {
        var resourceUrl =  'api/settle-virtual-bank/transactions';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    SettleVirtualBankAvailability.$inject = ['$resource'];

    function SettleVirtualBankAvailability ($resource) {
        var resourceUrl =  'api/settle-virtual-bank/availability';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    SettleVirtualBankTransactionTotalAmount.$inject = ['$resource'];

    function SettleVirtualBankTransactionTotalAmount ($resource) {
        var resourceUrl =  'api/settle-virtual-bank/transactions/total-amount';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET', isArray: false}
        });
    }
})();
