(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('PartnerBalanceHistory', PartnerBalanceHistory);

    PartnerBalanceHistory.$inject = ['$resource', 'DateUtils'];

    function PartnerBalanceHistory ($resource, DateUtils) {
        var resourceUrl =  'api/partner-balance-histories/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getByPartner' : {
                method: 'GET',
                id: '@id',
                url: 'api/partners/:id/balance-histories',
                isArray: true
            }
        });
    }

})();
