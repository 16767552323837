(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('ActiveUsers', ActiveUsers);

    ActiveUsers.$inject = ['$resource'];

    function ActiveUsers($resource) {
        var resourceUrl = 'api/active-users';

        return $resource(resourceUrl, {}, {
            'getByOS': {
                method: 'GET',
                isArray: true,
                url: 'api/active-users-os'
            },
            'getByLanguage': {
                method: 'GET',
                isArray: true,
                url: 'api/active-users-language'
            },
            'getByLanguageOnlyDeposit': {
                method: 'GET',
                isArray: true,
                url: 'api/active-users-language-by-deposit'
            }
        });
    }
})();
