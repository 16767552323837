(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('SellerProductBoxController', SellerProductBoxController);

    SellerProductBoxController.$inject = ['SellerShippingFee', 'SellerHomePage', 'Principal', 'pagingParams', 'paginationConstants', 'ParseLinks', '$state', 'ProductStorage'];

    function SellerProductBoxController(SellerShippingFee, SellerHomePage, Principal, pagingParams, paginationConstants, ParseLinks, $state, ProductStorage) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.clearProduct = clearProduct;
        vm.onChange = onChange;
        vm.save = save;
        vm.language = pagingParams.langKey;
        vm.selectedProduct = ProductStorage.getProduct();
        var replacedProduct = null;
        var sellerSortOrders = [];
        var productSortOrders = ProductStorage.getProductSortOrders();

        Principal.identity().then(function (account) {
            vm.account = account;
        });

        loadAll();

        function loadAll() {
            sellerSortOrders = [];
            SellerShippingFee.query({}, onSuccess);

            function onSuccess(data) {
                vm.users = data;
                vm.sellerShippingFee = getSellerShippingFeeByUserId(pagingParams.sellerShippingFee);
                if (vm.users.length > 0) {
                    if (vm.sellerShippingFee == null) {
                        clearProduct();
                        clearProductSortOrdersInStorage();
                        vm.sellerShippingFee = vm.users[0];
                    }
                    load();
                    loadProductSortOrders();
                }
            }
        }

        function getSellerShippingFeeByUserId(sellerShippingFee) {
            if (sellerShippingFee != null) {
                for (var i = 0; i < vm.users.length; i++) {
                    var user = vm.users[i];
                    if (user.userId == sellerShippingFee.userId) {
                        return user;
                    }
                }
            }
            return null;
        }


        function loadProductSortOrders() {
            if (ProductStorage.getProductSortOrders() === null || angular.isUndefined(ProductStorage.getProductSortOrders())) {
                SellerHomePage.getProductSortOrders({
                    sellerId: vm.sellerShippingFee.userId,
                    langKey: vm.language
                }, onSuccess);
            }

            function onSuccess(data) {
                productSortOrders = data;
                ProductStorage.setProductSortOrders(productSortOrders);
            }
        }


        function load() {
            sellerSortOrders = [];
            SellerHomePage.query({
                sellerId: vm.sellerShippingFee.userId,
                langKey: vm.language,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess);
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.products = data;
            vm.page = pagingParams.page;

            for (var i = 0; i < vm.products.length; i++) {
                var product = vm.products[i];
                sellerSortOrders.push(product.sellerSortOrder);
            }
        }

        function sort() {
            var result = [vm.predicate + ',' + ('asc')];
            if (vm.predicate !== 'sellerSortOrder') {
                result.push('sellerSortOrder');
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            sellerSortOrders = [];
            $state.transitionTo($state.$current, {
                sellerShippingFee: vm.sellerShippingFee,
                langKey: vm.language,
                page: vm.page,
                sort: vm.predicate + ',' + ('asc')
            });
        }

        function clear() {
            vm.page = 1;
            vm.transition();
        }

        Sortable.create(product, {
            ghostClass: 'ghost',
            sort: true
        });

        function onChange() {
            clear();
            clearProduct();
            clearProductSortOrdersInStorage();
        }


        vm.onChangeLanguage = function () {
            loadAll();
            clearProduct();
            clearProductSortOrdersInStorage();
            loadProductSortOrders();
        };

        vm.select = function (product, index) {
            ProductStorage.setProduct(product);
            vm.selectedProductIndex = index;
            vm.selectedProduct = ProductStorage.getProduct();
        };

        vm.replace = function (product, replacedProductIndex) {
            var selectedProductSortOrderIndex = 0;
            var replacedProductSortOrderIndex = 0;
            if (!angular.isUndefined(vm.selectedProductIndex)) {
                vm.products[vm.selectedProductIndex] = product;
                vm.products[replacedProductIndex] = vm.selectedProduct;
                selectedProductSortOrderIndex = findIndexByProductId(vm.products[vm.selectedProductIndex].id);
                replacedProductSortOrderIndex = findIndexByProductId(vm.products[replacedProductIndex].id);
            } else {
                selectedProductSortOrderIndex = findIndexByProductId(vm.selectedProduct.id);
                replacedProductSortOrderIndex = findIndexByProductId(vm.products[replacedProductIndex].id);
                vm.products[replacedProductIndex] = vm.selectedProduct;
                product['sellerSortOrder'] = vm.selectedProduct['sellerSortOrder'];
                replacedProduct = product;
            }
            var sellerSortOrder = productSortOrders[selectedProductSortOrderIndex].sellerSortOrder;
            productSortOrders[selectedProductSortOrderIndex].sellerSortOrder = productSortOrders[replacedProductSortOrderIndex].sellerSortOrder;
            productSortOrders[replacedProductSortOrderIndex].sellerSortOrder = sellerSortOrder;
            productSortOrders.sort(compareTo("sellerSortOrder"));
            ProductStorage.setProductSortOrders(productSortOrders);
            productSortOrders = ProductStorage.getProductSortOrders();
            clearProduct();
        };


        vm.insert = function (product, insertedProductIndex) {
            var selectedProductSortOrderIndex = findIndexByProductId(vm.selectedProduct.id);
            var insertedProductSortOrderIndex = findIndexByProductId(vm.products[insertedProductIndex].id);
            if (insertedProductSortOrderIndex > selectedProductSortOrderIndex) {
                var insertedProductSortOrder = productSortOrders[insertedProductSortOrderIndex].sellerSortOrder;
                for (var i = insertedProductSortOrderIndex; i > selectedProductSortOrderIndex; i--) {
                    productSortOrders[i].sellerSortOrder = productSortOrders[i - 1].sellerSortOrder;
                }
                productSortOrders[selectedProductSortOrderIndex].sellerSortOrder = insertedProductSortOrder;

                var beginIndex = 0;
                if (!angular.isUndefined(vm.selectedProductIndex)) {
                    beginIndex = vm.selectedProductIndex;
                }
                for (var i = beginIndex; i < insertedProductIndex; i++) {
                    vm.products[i] = vm.products[i + 1];
                }
                vm.products[insertedProductIndex] = vm.selectedProduct;
            } else {
                var insertedProductSortOrder = productSortOrders[insertedProductSortOrderIndex].sellerSortOrder;
                for (var i = insertedProductSortOrderIndex; i < selectedProductSortOrderIndex; i++) {
                    productSortOrders[i].sellerSortOrder = productSortOrders[i + 1].sellerSortOrder;
                }
                productSortOrders[selectedProductSortOrderIndex].sellerSortOrder = insertedProductSortOrder;

                var beginIndex = vm.products.length;
                if (!angular.isUndefined(vm.selectedProductIndex)) {
                    beginIndex = vm.selectedProductIndex;
                }

                for (var i = beginIndex; i > insertedProductIndex; i--) {
                    vm.products[i] = vm.products[i - 1];
                }
                vm.products[insertedProductIndex] = vm.selectedProduct;
            }
            productSortOrders.sort(compareTo("sellerSortOrder"));
            ProductStorage.setProductSortOrders(productSortOrders);
            productSortOrders = ProductStorage.getProductSortOrders();
            clearProduct();
        };

        function save() {
            makeProductList();
            SellerHomePage.save(ProductStorage.getProductSortOrders(), saveSuccess);

            function saveSuccess() {
                load();
                clearProductSortOrdersInStorage();
                loadProductSortOrders();
            }
        }

        function clearProduct() {
            ProductStorage.setProduct(null);
            vm.selectedProduct = ProductStorage.getProduct();
        }

        function clearProductSortOrdersInStorage() {
            ProductStorage.setProductSortOrders(null);
        }

        function makeProductList() {
            var dataList = $("#product .item-pro-ajax").map(function () {
                return $(this).data("id");
            }).get();
            for (var i = 0; i < dataList.length; i++) {
                for (var j = 0; j < productSortOrders.length; j++) {
                    var productSortOrder = productSortOrders[j];
                    if (productSortOrder.id === dataList[i]) {
                        productSortOrder.sellerSortOrder = sellerSortOrders[i];
                        break;
                    }
                }
            }
        }

        function findIndexByProductId(productId) {
            for (var i = 0; i < productSortOrders.length; i++) {
                if (productSortOrders[i].id === productId) {
                    return i;
                }
            }
        }

        function compareTo(property) {
            var sortOrder = 1;
            if (property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a, b) {
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }
    }
})();
