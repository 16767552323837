(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('MarketStatsWeekly', MarketStatsWeekly);

    MarketStatsWeekly.$inject = ['$resource', 'DateUtils'];

    function MarketStatsWeekly($resource, DateUtils) {
        var resourceUrl = 'api/market-stats/weekly/sales';

        return $resource(resourceUrl, {}, {
            'getBySales': {method: 'GET', url: 'api/market-stats/weekly/sale', isArray: true},
            'getByProfit': {method: 'GET', url: 'api/market-stats/weekly/profit', isArray: true}
        });
    }

})();
