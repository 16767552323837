(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PrivacyController', PrivacyController);

    PrivacyController.$inject = ['Privacy', '$scope', 'SitePage', 'pagingParams'];

    function PrivacyController(Privacy, $scope, SitePage, pagingParams) {

        var vm = this;
        vm.appName = pagingParams.appName;
        vm.save = save;

        load("uz");
        load("en");
        load("ru");
        load("ko");
        editorOptions();

        function load(lang) {
            Privacy.get({
                language: lang,
                appName: vm.appName
            }, onSuccess);
            function onSuccess(data) {
                if (lang == "uz") {
                    vm.privacyUz = data;
                } else if (lang == "en") {
                    vm.privacyEn = data;
                } else if (lang == "ko") {
                    vm.privacyKo = data;
                } else {
                    vm.privacyRu = data;
                }
            }
        }

        function editorOptions() {
            $scope.options = {
                height: 400
            };
        }

        function save (privacy) {
            vm.isSaving = true;

            SitePage.update(privacy, onSuccess, onError);

            function onSuccess (result) {
                $scope.$emit('bozorakaAdminApp:sitePageUpdate', result);
                vm.isSaving = false;
            }

            function onError () {
                vm.isSaving = false;
            }
        }


    }
})();
