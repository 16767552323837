(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('CoupangOrderInfoDeleteController',CoupangOrderInfoDeleteController);

    CoupangOrderInfoDeleteController.$inject = ['$uibModalInstance', 'entity', 'CoupangOrderInfo'];

    function CoupangOrderInfoDeleteController($uibModalInstance, entity, CoupangOrderInfo) {
        var vm = this;

        vm.coupangOrderInfo = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CoupangOrderInfo.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
