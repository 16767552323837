(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('SmsKoreaBalance', SmsKoreaBalance);

    SmsKoreaBalance.$inject = ['$resource'];

    function SmsKoreaBalance ($resource) {
        var resourceUrl =  'api/sms-kr/balance';
        return $resource(resourceUrl, {}, {});
    }
})();
