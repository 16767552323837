(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('OrderedProductVariantController', OrderedProductVariantController);

    OrderedProductVariantController.$inject = ['OrderedProductVariant'];

    function OrderedProductVariantController(OrderedProductVariant) {

        var vm = this;

        vm.orderedProductVariants = [];

        loadAll();

        function loadAll() {
            OrderedProductVariant.query(function(result) {
                vm.orderedProductVariants = result;
                vm.searchQuery = null;
            });
        }
    }
})();
