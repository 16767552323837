(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ShippingDataController', ShippingDataController);

    ShippingDataController.$inject = ['$http', '$filter', '$rootScope', 'Principal', 'AlertService', 'DateUtils', 'DataUtils', 'SellerUser', 'ShippingData'];

    function ShippingDataController($http, $filter, $rootScope, Principal, AlertService, DateUtils, DataUtils, SellerUser, ShippingData) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.clear = clear;
        vm.excel = excel;
        vm.search = search;
        vm.getDeductedAmount = DataUtils.getDeductedAmount;
        vm.getSentAmount = DataUtils.getSentAmount;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.fromZonedDate = false;
        vm.datePickerOpenStatus.toZonedDate = false;
        vm.shippingData = [];
        vm.merchants = [
            {id: "bozoraka", name: "Bozoraka"},
            {id: "mobpay", name: "MobPay"}
        ];

        var clients = ['bozoraka', 'mobpay'];

        if (!Principal.hasAnyAuthority(['ROLE_SELLER'])) {
            vm.merchants.push({id: "b2b", name: "Bozoraka B2B"})
            clients.push('b2b')
            getAllSellers();
        }

        vm.searchQuery = {
            clients: clients,
            field: "",
            trackingNumber: "",
            sellerId: -1,
            fromDate: null,
            toDate: null,
            fromZonedDate: null,
            toZonedDate: null
        };

        initDatePicker();

        function initDatePicker() {
            if (vm.searchQuery.toZonedDate === null) {
                vm.searchQuery.toZonedDate = new Date();
            }
            if (vm.searchQuery.fromZonedDate === null) {
                vm.searchQuery.fromZonedDate = new Date();
                vm.searchQuery.fromZonedDate.setMonth(vm.searchQuery.fromZonedDate.getMonth() - 1)
            }
            $('.date-picker').datepicker({autoclose: true});
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        getDateTimeFromPicker();

        function getAllSellers() {
            SellerUser.query(onSuccess);

            function onSuccess(data) {
                vm.users = [];
                angular.forEach(data, function (seller) {
                    if (seller.activated) {
                        vm.users.push(seller);
                    }
                });
            }
        }

        function getDateTimeFromPicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'fromZonedDate') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'toZonedDate') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }

        function clear() {
            vm.shippingData = [];
            vm.searchQuery = {
                clients: clients,
                field: "",
                trackingNumber: "",
                sellerId: -1,
                fromDate: null,
                toDate: null,
                fromZonedDate: null,
                toZonedDate: null
            };

            getDateTimeFromPicker();
        }

        function excel() {
            getDateTimeFromPicker();
            if (vm.searchQuery.fromDate) {
                vm.searchQuery.fromDate = DateUtils.converterDateTimeFormat(vm.searchQuery.fromZonedDate);
            }
            if (vm.searchQuery.toDate) {
                vm.searchQuery.toDate = DateUtils.converterDateTimeFormat(vm.searchQuery.toZonedDate);
            }
            var data = "clients=" + vm.searchQuery.clients + "&field=" + vm.searchQuery.field + "&trackingNumber=" + vm.searchQuery.trackingNumber + "&sellerId=" + vm.searchQuery.sellerId + "&fromDate=" + vm.searchQuery.fromDate + '&toDate=' + vm.searchQuery.toDate;

            $http({
                url: 'api/shipping-data-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'shipping-data-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {

            });
        }

        function search() {
            getDateTimeFromPicker();
            if (vm.searchQuery.fromDate) {
                vm.searchQuery.fromDate = DateUtils.converterDateTimeFormat(vm.searchQuery.fromZonedDate);
            }
            if (vm.searchQuery.toDate) {
                vm.searchQuery.toDate = DateUtils.converterDateTimeFormat(vm.searchQuery.toZonedDate);
            }
            ShippingData.query(vm.searchQuery, function (response) {
                vm.shippingData = response.data;
            })
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#customerClient').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('shipping-data.form.all'),
                nonSelectedText: translate('shipping-data.form.noneSelected'),
                allSelectedText: translate('shipping-data.form.allSelected')
            });

        }, 0);

        function translateMultiSelect() {
            all = translate('shipping-data.form.all');
            noneSelected = translate('shipping-data.form.noneSelected');
            allSelected = translate('shipping-data.form.allSelected');
        }
    }

})();
