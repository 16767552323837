/**
 * Created by Gayratjon Rayimjonov on 5/17/2017.
 */
(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .factory('SellerUser', SellerUser);

    SellerUser.$inject = ['$resource'];

    function SellerUser ($resource) {
        var service = $resource('api/sellers', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();
