(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('AddressCloudImageDeleteController',AddressCloudImageDeleteController);

    AddressCloudImageDeleteController.$inject = ['$uibModalInstance', 'entity', 'AddressCloudImage'];

    function AddressCloudImageDeleteController($uibModalInstance, entity, AddressCloudImage) {
        var vm = this;

        vm.addressCloudImage = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AddressCloudImage.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
