(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MarketStatsShippingFeeController', MarketStatsShippingFeeController);

    MarketStatsShippingFeeController.$inject = ['$http', '$filter', '$rootScope', 'AlertService', 'DateUtils', 'DataUtils', 'MarketStatsShippingFee'];

    function MarketStatsShippingFeeController($http, $filter, $rootScope, AlertService, DateUtils, DataUtils, MarketStatsShippingFee) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.clear = clear;
        vm.excel = excel;
        vm.search = search;
        vm.searchQuery = {
            clients: ['b2b', 'bozoraka', 'mobpay'],
            field: "",
            trackingNumber: "",
            sellerId: -1,
            fromDate: null,
            toDate: null,
            fromZonedDate: null,
            toZonedDate: null
        };
        vm.getDeductedAmount = DataUtils.getDeductedAmount;
        vm.getSentAmount = DataUtils.getSentAmount;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.fromZonedDate = false;
        vm.datePickerOpenStatus.toZonedDate = false;
        vm.totalShippingFee = 0;
        vm.shippingData = [];

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        getDateTimeFromPicker();

        function getDateTimeFromPicker() {
            if (vm.searchQuery.toZonedDate === null) {
                vm.searchQuery.toZonedDate = moment().toDate();
            }
            if (vm.searchQuery.fromZonedDate === null) {
                vm.searchQuery.fromZonedDate = moment().subtract(1, "months").toDate();
            }
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'fromZonedDate') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'toZonedDate') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }

        function clear() {
            vm.shippingData = [];
            vm.searchQuery = {
                clients: ['b2b', 'bozoraka', 'mobpay'],
                field: "",
                trackingNumber: "",
                sellerId: -1,
                fromDate: null,
                toDate: null,
                fromZonedDate: null,
                toZonedDate: null
            };

            getDateTimeFromPicker();
        }

        function excel() {
            getDateTimeFromPicker();
            if (vm.searchQuery.fromDate) {
                vm.searchQuery.fromDate = DateUtils.converterDateTimeFormat(vm.searchQuery.fromZonedDate);
            }
            if (vm.searchQuery.toDate) {
                vm.searchQuery.toDate = DateUtils.converterDateTimeFormat(vm.searchQuery.toZonedDate);
            }
            var data = "clients=" + vm.searchQuery.clients + "&field=" + vm.searchQuery.field + "&trackingNumber=" + vm.searchQuery.trackingNumber + "&sellerId=-1&fromDate=" + vm.searchQuery.fromDate + '&toDate=' + vm.searchQuery.toDate;

            $http({
                url: 'api/shipping-fee-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'shipping-fee-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {

            });
        }

        function search() {
            getDateTimeFromPicker();
            if (vm.searchQuery.fromDate) {
                vm.searchQuery.fromDate = DateUtils.converterDateTimeFormat(vm.searchQuery.fromZonedDate);
            }
            if (vm.searchQuery.toDate) {
                vm.searchQuery.toDate = DateUtils.converterDateTimeFormat(vm.searchQuery.toZonedDate);
            }
            MarketStatsShippingFee.query(vm.searchQuery, function (response) {
                vm.shippingData = response.data;
                vm.totalShippingFee = 0;
                angular.forEach(vm.shippingData, function (item) {
                    vm.totalShippingFee += item.shippingFee;
                });
            })
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#customerClient').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('shipping-data.form.all'),
                nonSelectedText: translate('shipping-data.form.noneSelected'),
                allSelectedText: translate('shipping-data.form.allSelected')
            });

        }, 0);

        function translateMultiSelect() {
            all = translate('shipping-data.form.all');
            noneSelected = translate('shipping-data.form.noneSelected');
            allSelected = translate('shipping-data.form.allSelected');
        }
    }

})();
