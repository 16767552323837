(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('SettlebankPaymentRefundController',SettlebankPaymentRefundController);

    SettlebankPaymentRefundController.$inject = ['$uibModalInstance', 'entity', 'SettlebankPayment'];

    function SettlebankPaymentRefundController($uibModalInstance, entity, SettlebankPayment) {
        var vm = this;
        vm.settlebankPayment = entity;
        vm.clear = clear;
        vm.confirmRefund = confirmRefund;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmRefund() {
            SettlebankPayment.refund({paymentId: vm.settlebankPayment.paymentId},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
