(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('HanpassUserController', HanpassUserController);

    HanpassUserController.$inject = ['$state', 'HanpassUser', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'ProfileIdByPhone'];

    function HanpassUserController($state, HanpassUser, ParseLinks, AlertService, paginationConstants, pagingParams, ProfileIdByPhone) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.openProfile = openProfile;
        vm.search = search;
        vm.clear = clear;
        vm.totalReceivables = 0;
        vm.totalGivenBonus = 0;
        vm.getTotals = getTotals;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.searchQuery = {
            field: pagingParams.field,
            client: pagingParams.client,
            hanpassUserStatus: pagingParams.hanpassUserStatus,
            fromZonedDate: pagingParams.fromZonedDate,
            toZonedDate: pagingParams.toZonedDate,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
        };

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.fromZonedDate = false;
        vm.datePickerOpenStatus.toZonedDate = false;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getDateTimeFromPicker() {
            $('.input-daterange input').each(function() {
                if ($(this).attr('name') == 'fromZonedDate') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'toZonedDate') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }

        loadAll();

        function search() {
            getDateTimeFromPicker();
            vm.transition();
        }

        function clear() {
            vm.searchQuery.field = '';
            vm.searchQuery.client = '';
            vm.searchQuery.hanpassUserStatus = 'ALL';
            vm.searchQuery.toDate = null;
            vm.searchQuery.fromDate = null;
            vm.searchQuery.toZonedDate = null;
            vm.searchQuery.fromZonedDate = null;
            transition();
        }

        function loadAll () {
            HanpassUser.query({
                field: pagingParams.field,
                client: pagingParams.client,
                valueString1: pagingParams.hanpassUserStatus,
                fromZonedDate: pagingParams.fromZonedDate,
                toZonedDate: pagingParams.toZonedDate,
                fromDate: pagingParams.fromDate,
                toDate: pagingParams.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.hanpassUsers = data;
                vm.page = pagingParams.page;
                getTotals();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getTotals() {
            HanpassUser.getTotals({
                fromZonedDate: pagingParams.fromZonedDate,
                toZonedDate: pagingParams.toZonedDate,
                fromDate: pagingParams.fromDate,
                toDate: pagingParams.toDate,
            }, function (data) {
                vm.totalReceivables = data.receivables;
                vm.totalGivenBonus = data.totalGivenBonus;
                vm.totalPreRegistered = data.totalPreRegistered;
                vm.totalRegistered = data.totalRegistered;
                vm.totalBankRegistered = data.totalBankRegistered;
                vm.totalTransferred = data.totalTransferred;
            })
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                field: vm.searchQuery.field,
                client: vm.searchQuery.client,
                hanpassUserStatus: vm.searchQuery.hanpassUserStatus,
                fromZonedDate: vm.searchQuery.fromZonedDate,
                toZonedDate: vm.searchQuery.toZonedDate,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function openProfile(phoneNumber) {
            ProfileIdByPhone.get({phoneNumber: phoneNumber}, onSuccess);

            function onSuccess(data) {
                $state.go('profile-detail', {id: data.profileId});
            }
        }
    }
})();
