(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileChangePhoneDialogController', ProfileChangePhoneDialogController);

    ProfileChangePhoneDialogController.$inject = ['$scope', '$timeout', '$uibModalInstance', 'entity', 'ProfileChangePhone', 'AlertService'];

    function ProfileChangePhoneDialogController($scope, $timeout, $uibModalInstance, entity, ProfileChangePhone, AlertService) {
        var vm = this;

        vm.profile = entity;
        vm.clear = clear;
        vm.save = save;
        vm.isValidPhonePrefix = isValidPhonePrefix;
        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.changePhone = {
                phone: vm.newPhone,
                profileId: vm.profile.id
            };

            ProfileChangePhone.update(vm.changePhone, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.profile.customerFullName = result.fullName;
            vm.profile.customerStatus = result.status;
            vm.profile.accountNo = result.accountNo;
            vm.profile.balance = result.balance;
            vm.profile.totalMileage = result.totalMileage;
            vm.profile.customerPhone = result.phone;

            $scope.$emit('bozorakaAdminApp:profileUpdate', vm.profile);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError(error) {
            console.error(error);
            vm.isSaving = false;
        }

        function isValidPhonePrefix() {
            return !(vm.newPhone.startsWith('010') || vm.newPhone.startsWith('7'));
        }
    }
})();
