(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ActiveUsersController', ActiveUsersController);

    ActiveUsersController.$inject = ['$filter', '$http', 'DateUtils', 'AlertService', 'ActiveUsers', 'StatsPerTime', 'DataUtils'];

    function ActiveUsersController($filter, $http, DateUtils, AlertService, ActiveUsers, StatsPerTime, DataUtils) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;
        vm.search = search;
        vm.activateTab = activateTab;
        vm.activeTab = 'tab_withdraw_1';
        vm.searchQuery = {
            fromDate: DateUtils.currentDate(),
            toDate: DateUtils.currentDate(),
            clients: ['b2b', 'bozoraka', 'mobpay']
        };
        initDatePicker();

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#customerClient').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics.all'),
                nonSelectedText: translate('statistics.noneSelected'),
                allSelectedText: translate('statistics.allSelected')
            });

        }, 0);

        function translateMultiSelect() {
            all = translate('statistics.all');
            noneSelected = translate('statistics.noneSelected');
            allSelected = translate('statistics.allSelected');
        }

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') === 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') === 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            ActiveUsers.getByOS(vm.searchQuery, onSuccessByOS, onError);
            ActiveUsers.getByLanguage(vm.searchQuery, onSuccessByLanguage, onError);
            ActiveUsers.getByLanguageOnlyDeposit(vm.searchQuery, onSuccessByLanguageOnlyDeposit, onError);
        }

        function onSuccessByOS(data, headers) {
            makePieChartByOS(data);
        }

        function onSuccessByLanguage(data, headers) {
            makePieChartByLanguage(data);
        }

        function onSuccessByLanguageOnlyDeposit(data, headers) {
            makePieChartByLanguageForOnlyDeposit(data);
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function makePieChartByOS(data) {
            var chart = AmCharts.makeChart("pie-chart-os", {
                "type": "pie",
                "theme": "light",
                "dataProvider": data,
                "valueField": "numberOfUsers",
                "titleField": "title",
                "balloon": {
                    "fixedPosition": true
                },
                "export": {
                    "enabled": true
                },
                "radius": "100"
            });
        }

        function makePieChartByLanguage(data) {
            var chart = AmCharts.makeChart("pie-chart-withdraw", {
                "type": "pie",
                "theme": "light",
                "dataProvider": data,
                "valueField": "numberOfUsers",
                "titleField": "title",
                "balloon": {
                    "fixedPosition": true
                },
                "export": {
                    "enabled": true
                },
                "radius": "100"
            });
        }

        function makePieChartByLanguageForOnlyDeposit(data) {
            var chart = AmCharts.makeChart("pie-chart-deposit", {
                "type": "pie",
                "theme": "light",
                "dataProvider": data,
                "valueField": "numberOfUsers",
                "titleField": "title",
                "balloon": {
                    "fixedPosition": true
                },
                "export": {
                    "enabled": true
                },
                "radius": "100"
            });
        }

        function activateTab(tab) {
            vm.activeTab = tab;
        }
    }
})();
