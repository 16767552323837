(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('MerchantTransaction', MerchantTransaction)
        .factory('MerchantBalance', MerchantBalance);


    MerchantTransaction.$inject = ['$resource'];

    function MerchantTransaction($resource) {
        var resourceUrl = 'api/merchant-transaction-history';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

    MerchantBalance.$inject = ['$resource'];

    function MerchantBalance($resource) {
        var resourceUrl = 'api/merchant-balance-history';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

})();
