(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('PendingRequest', PendingRequest)
        .factory('MarketRequestStorage', MarketRequestStorage)
        .factory('MarketRequest', MarketRequest);

    PendingRequest.$inject = ['$resource', 'DateUtils'];

    function PendingRequest ($resource, DateUtils) {
        var resourceUrl =  'api/pending-request';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.pubDate = DateUtils.convertDateTimeFromServer(data.pubDate);
                        data.approvalDate = DateUtils.convertDateTimeFromServer(data.approvalDate);
                    }
                    return data;
                }
            }
        });
    }

    MarketRequest.$inject = ['$resource'];

    function MarketRequest($resource) {
        var resourceUrl = 'api/new-market-requests';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.pubDate = DateUtils.convertDateTimeFromServer(data.pubDate);
                        data.approvalDate = DateUtils.convertDateTimeFromServer(data.approvalDate);
                    }
                    return data;
                }
            },
            'getAllNewOrderIds':{
                method: 'GET',
                url :'api/new-market-request-ids',
                isArray: true
            }
        });
    }

    MarketRequestStorage.$inject = ['$resource', '$localStorage'];

    function MarketRequestStorage($resource, $localStorage) {
        return {
            setSelectedOrders: function (selectedOrders) {
                $localStorage.selectedOrders = selectedOrders;
            },
            getSelectedOrders: function () {
                return $localStorage.selectedOrders;
            }

        }
    }
})();
