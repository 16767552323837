(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('FaqMobpayController', FaqMobpayController);

    FaqMobpayController.$inject = ['DataUtils', 'Faq'];

    function FaqMobpayController(DataUtils, Faq) {

        var vm = this;

        vm.faqsInUz = [];
        vm.faqsInEn = [];
        vm.faqsInRu = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.appName = "mobpay";

        loadAll();

        function loadAll() {
            Faq.query({
                lang: 'uz',
                appName: 'mobpay'
            }, function (result) {
                vm.faqsInUz = result;
            });

            Faq.query({
                lang: 'en',
                appName: 'mobpay'
            }, function (result) {
                vm.faqsInEn = result;
            });

            Faq.query({
                lang: 'ru',
                appName: 'mobpay'
            }, function (result) {
                vm.faqsInRu = result;
            });
        }
    }
})();
