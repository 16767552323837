(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('coupang-order-info', {
            parent: 'entity',
            url: '/coupang-order-info?page&sort&search',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER', 'ROLE_SELLER', 'ROLE_WAREHOUSE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.coupangOrderInfo.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/coupang-order-info/coupang-order-infos.html',
                    controller: 'CoupangOrderInfoController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                field: null,
                fromCreatedDate: null,
                toCreatedDate: null,
                fromPaidDate: null,
                toPaidDate: null,
                status: null,
                firstRun: true
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        field: $stateParams.field,
                        partnerOrderId: $stateParams.partnerOrderId,
                        fromDate: $stateParams.fromDate,
                        toDate: $stateParams.toDate,
                        fromPaidDate: $stateParams.fromPaidDate,
                        toPaidDate: $stateParams.toPaidDate,
                        status: $stateParams.status,
                        firstRun: $stateParams.firstRun
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('coupangOrderInfo');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('coupang-order-info-detail', {
            parent: 'coupang-order-info',
            url: '/coupang-order-info/{id}',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER', 'ROLE_SELLER', 'ROLE_WAREHOUSE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.coupangOrderInfo.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/coupang-order-info/coupang-order-info-detail.html',
                    controller: 'CoupangOrderInfoDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('coupangOrderInfo');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'CoupangOrderInfo', function($stateParams, CoupangOrderInfo) {
                    return CoupangOrderInfo.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'coupang-order-info',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('coupang-order-info-detail.edit', {
            parent: 'coupang-order-info-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER', 'ROLE_SELLER', 'ROLE_WAREHOUSE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/coupang-order-info/coupang-order-info-dialog.html',
                    controller: 'CoupangOrderInfoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CoupangOrderInfo', function(CoupangOrderInfo) {
                            return CoupangOrderInfo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('coupang-order-info.new', {
            parent: 'coupang-order-info',
            url: '/new',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER', 'ROLE_SELLER', 'ROLE_WAREHOUSE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/coupang-order-info/coupang-order-info-dialog.html',
                    controller: 'CoupangOrderInfoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                orderId: null,
                                shipmentBoxId: null,
                                coupangStatus: null,
                                status: null,
                                address1: null,
                                address2: null,
                                rawJson: null,
                                errorMassage: null,
                                cancelReasonCode: null,
                                orderCreatedDate: null,
                                orderPaidDate: null,
                                createdDate: null,
                                createdBy: null,
                                lastModifiedDate: null,
                                lastModifiedBy: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('coupang-order-info', null, { reload: 'coupang-order-info' });
                }, function() {
                    $state.go('coupang-order-info');
                });
            }]
        })
        .state('coupang-order-info.edit', {
            parent: 'coupang-order-info',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER', 'ROLE_SELLER', 'ROLE_WAREHOUSE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/coupang-order-info/coupang-order-info-dialog.html',
                    controller: 'CoupangOrderInfoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['CoupangOrderInfo', function(CoupangOrderInfo) {
                            return CoupangOrderInfo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('coupang-order-info', null, { reload: 'coupang-order-info' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('coupang-order-info.delete', {
            parent: 'coupang-order-info',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER', 'ROLE_SELLER', 'ROLE_WAREHOUSE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/coupang-order-info/coupang-order-info-delete-dialog.html',
                    controller: 'CoupangOrderInfoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CoupangOrderInfo', function(CoupangOrderInfo) {
                            return CoupangOrderInfo.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('coupang-order-info', null, { reload: 'coupang-order-info' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('coupang-order-info.cancel', {
                parent: 'coupang-order-info',
                url: '/{id}/cancel',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER', 'ROLE_SELLER', 'ROLE_WAREHOUSE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/coupang-order-info/coupang-order-info-cancel-dialog.html',
                        controller: 'CoupangOrderInfoCancelController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['CoupangOrderInfo', function(CoupangOrderInfo) {
                                return CoupangOrderInfo.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('coupang-order-info', null, { reload: 'coupang-order-info' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
        }

})();
