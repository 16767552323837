(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('Tier', Tier);

    Tier.$inject = ['$resource', 'DateUtils'];

    function Tier ($resource, DateUtils) {
        var resourceUrl =  'api/tiers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'changeStatus': {
                method: 'PUT',
                url: 'api/tiers/:id/:status',
                params: {
                    'id': '@id',
                    'status': '@status'
                }
            }
        });
    }
})();
