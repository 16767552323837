(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfilePromoCodeDialogController', ProfilePromoCodeDialogController);

    ProfilePromoCodeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PromoCode', 'Profile'];

    function ProfilePromoCodeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PromoCode, Profile) {
        var vm = this;

        vm.profileId = $stateParams.id;
        vm.promoCode = entity;
        defineCustomerId();
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function defineCustomerId() {
            Profile.get({id: vm.profileId}).$promise.then(function (profile) {
                vm.promoCode.appName = profile.customerClient;
                vm.promoCode.customerId = profile.customerId;
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.promoCode.id !== null) {
                PromoCode.update(vm.promoCode, onSaveSuccess, onSaveError);
            } else {
                PromoCode.save(vm.promoCode, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:promoCodeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
