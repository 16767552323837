(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('OrderInfoDialogController', OrderInfoDialogController);

    OrderInfoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'OrderInfo', 'Transaction', 'OrderedProduct', 'OrderHistory', '$translate'];

    function OrderInfoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, OrderInfo, Transaction, OrderedProduct, OrderHistory, $translate) {
        var vm = this;

        vm.orderInfo = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        var headers = {
            lang: $translate.use()
        };
        vm.transactions = Transaction.query({filter: 'orderinfo-is-null'});
        $q.all([vm.orderInfo.$promise, vm.transactions.$promise]).then(function() {
            if (!vm.orderInfo.transactionId) {
                return $q.reject();
            }
            return Transaction.get({id : vm.orderInfo.transactionId}).$promise;
        }).then(function(transaction) {
            vm.transactions.push(transaction);
        });
        vm.orderedproducts = OrderedProduct.query();
        vm.orderhistories = OrderHistory.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.orderInfo.id !== null) {
                OrderInfo(headers).update(vm.orderInfo, onSaveSuccess, onSaveError);
            } else {
                OrderInfo(headers).save(vm.orderInfo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:orderInfoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setAddressImage = function ($file, orderInfo) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        orderInfo.addressImage = base64Data;
                        orderInfo.addressImageContentType = $file.type;
                    });
                });
            }
        };

    }
})();
