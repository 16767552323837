(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('RequestController', RequestController);

    RequestController.$inject = ['$scope', '$state', 'Request', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$interval', 'DateUtils', 'DataUtils'];

    function RequestController($scope, $state, Request, ParseLinks, AlertService, paginationConstants, pagingParams, $interval, DateUtils, DataUtils) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.loadAll = loadAll;
        vm.dateDiff = DateUtils.transactionDateDifference;
        vm.getDeductedAmount = DataUtils.getDeductedAmount;
        vm.getSentAmount = DataUtils.getSentAmount;

        loadAll();

        function loadAll() {
            Request.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.transactions = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        //Put in interval, first trigger after 10 seconds
        var theInterval = $interval(function(){
            vm.loadAll();
        }.bind(this), 60000);

        $scope.$on('$destroy', function () {
            $interval.cancel(theInterval)
        });
    }
})();
