(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .factory('DateUtils', DateUtils);

    DateUtils.$inject = ['$filter'];

    function DateUtils ($filter) {

        var service = {
            convertDateTimeFromServer : convertDateTimeFromServer,
            convertLocalDateFromServer : convertLocalDateFromServer,
            convertLocalDateToServer : convertLocalDateToServer,
            dateformat : dateformat,
            transactionDateDifference: transactionDateDifference,
            partnerOrderDateDifference: partnerOrderDateDifference,
            currentTimestamp:currentTimestamp,
            currentDate:currentDate,
            converterDateFormat:converterDateFormat,
            converterDateTimeFormat:converterDateTimeFormat,
            currentDateFirstMonth:converterDateFirstMonth,
            addDate:addDate
        };

        return service;

        function convertDateTimeFromServer (date) {
            if (date) {
                return new Date(date);
            } else {
                return null;
            }
        }

        function convertLocalDateFromServer (date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer (date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        }

        function transactionDateDifference(transaction) {
            return moment(transaction.approvalDate).diff(moment(transaction.pubDate));
        }
        function partnerOrderDateDifference(partnerOrderInfo) {
            return moment().diff(moment(partnerOrderInfo.createdDate));
        }
        function currentTimestamp() {
            return moment().format("YYYYMMDDHHmmss");
        }
        function currentDate() {
            return moment().format("YYYY-MM-DD");
        }
        function converterDateFormat(date) {
            return moment(date).format('YYYY-MM-DD')
        }

        function converterDateTimeFormat(date) {
            return moment(date).format('YYYY-MM-DD HH:mm')
        }

        function converterDateFirstMonth() {
            return moment().format("MM/DD/YYYY");
        }

        function addDate(date, day) {
            return moment(date).add(day, 'days').format("MM/DD/YYYY")
        }

    }

})();
