(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('stats-monthly-balance', {
                parent: 'entity',
                url: '/stats-monthly-balance?page&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ANALYST'],
                    pageTitle: 'global.menu.stats.monthly'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stats-monthly-balance/stats-monthly-balance.html',
                        controller: 'StatsMonthlyBalanceController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'profile,desc',
                        squash: true
                    },
                    field: "",
                    fromDate: null,
                    toDate: null,
                    client: "",
                    firstRun: true
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            field: $stateParams.field,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            date: $stateParams.date,
                            client: $stateParams.client,
                            firstRun: $stateParams.firstRun
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('statsMonthlyBalance');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
