(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('AgentController', AgentController);

    AgentController.$inject = ['$state', 'Agent', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function AgentController($state, Agent, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.loadByStatus = loadByStatus;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.searchQuery = {
            field: pagingParams.field,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            status: pagingParams.status
        };
        vm.loadMore = loadMore;
        vm.page = 0;
        vm.lastPage = false;
        vm.loading = false;
        vm.partners = [];

        function loadByStatus() {
            vm.page = 0;
            vm.lastPage = false;
            vm.partners = [];
            vm.loading = true;
            loadNext();
        }

        function loadNext () {
            Agent.query({
                status : vm.searchQuery.status,
                page: vm.page,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'login') {
                    result.push('login');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;

                vm.page++;
                var partners = data;

                if (partners == null || partners.length < 20) {
                    vm.lastPage = true;
                }
                partners.forEach(function (partner) {
                    vm.partners.push(partner);
                });
                vm.loading = false;

            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadMore() {
            if (vm.lastPage || vm.loading) {
                return null;
            } else {
                vm.loading = true;
                loadNext();
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                status: vm.status
            });
        }
    }
})();
