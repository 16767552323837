(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('B2BNewsDialogController', B2BNewsDialogController);

    B2BNewsDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'DataUtils', 'entity', 'News'];

    function B2BNewsDialogController ($timeout, $scope, $uibModalInstance, DataUtils, entity, News) {
        var vm = this;

        vm.news = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.isAppsOnForNews = isAppsOnForNews;

        editorOptions();
        toggleSwitchApps();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            getSwitchedOnAppsValue();
            if (vm.news.id !== null) {
                News.update(vm.news, onSaveSuccess, onSaveError);
            } else {
                News.save(vm.news, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:newsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.pubDate = false;
        vm.datePickerOpenStatus.lastEdited = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function editorOptions() {
            $scope.options = {
                height: 400,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
                    ['color', ['color']],
                    ['fontsize', ['fontsize']],
                    ['picture', ['picture']],
                    ['video', ['video']],
                    ['fontface', ['fontname']],
                    ['insert', ['link']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['height', ['height']]
                ]
            };
        }

        function isAppsOnForNews(lang) {
            if (lang === "uz")
                return vm.partnerUzb || vm.agentUzb;
            else if (lang === "en")
                return vm.partnerEng || vm.agentEng;
            else if (lang === "ru")
                return vm.partnerRus || vm.agentRus;
        }

        function toggleSwitchApps() {
            vm.partnerUzb = toggleSwitchForApp(512);
            vm.agentUzb = toggleSwitchForApp(1024);

            vm.partnerEng = toggleSwitchForApp(2048);
            vm.agentEng = toggleSwitchForApp(4096);

            vm.partnerRus = toggleSwitchForApp(8192);
            vm.agentRus = toggleSwitchForApp(16384);
        }

        function toggleSwitchForApp(value) {
            return (vm.news.shouldDisplay & value) > 0;
        }

        function getSwitchedOnAppsValue() {
            vm.news.shouldDisplay = 0;
            sumShouldDisplay(vm.partnerUzb, 512);
            sumShouldDisplay(vm.agentUzb, 1024);

            sumShouldDisplay(vm.partnerEng, 2048);
            sumShouldDisplay(vm.agentEng, 4096);

            sumShouldDisplay(vm.partnerRus, 8192);
            sumShouldDisplay(vm.agentRus, 16384);
        }

        function sumShouldDisplay(isAppOn, value) {
            if (isAppOn) {
                vm.news.shouldDisplay += value;
            }
        }
    }
})();
