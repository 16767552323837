(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ReactivateUserRunController',ReactivateUserRunController);

    ReactivateUserRunController.$inject = ['$uibModalInstance', 'ReactivateUser', 'month'];

    function ReactivateUserRunController($uibModalInstance, ReactivateUser, month) {
        var vm = this;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.isSave = false;
        vm.month = month;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            vm.isSave = true;
            ReactivateUser.runScript({month: vm.month}, onSuccess, onError);
            function onSuccess() {
                vm.isSave = false;
                $uibModalInstance.close(true);
            }
            function onError(error) {
                vm.isSave = false;
                AlertService.error(error.data.message);
            }
        }
    }
})();
