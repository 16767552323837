(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService'];

    function UserManagementDialogController ($stateParams, $uibModalInstance, entity, User, JhiLanguageService) {
        var vm = this;

        vm.authorities = ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_AGENT_UZB', 'ROLE_AGENT_KOR', 'ROLE_MERCHANT', 'ROLE_SELLER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER', 'ROLE_HANPASS_MANAGER', 'ROLE_ANALYST', 'ROLE_WAREHOUSE_MANAGER'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;


        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
