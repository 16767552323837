(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('OrderedProductController', OrderedProductController);

    OrderedProductController.$inject = ['$scope', '$state', 'OrderedProduct', 'OrderedProductSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function OrderedProductController($scope, $state, OrderedProduct, OrderedProductSearch, ParseLinks, AlertService, paginationConstants, pagingParams) {
        var vm = this;
        var productId = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.descending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.productId = pagingParams.productId;



        loadAll();

        function loadAll() {
            OrderedProduct.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                productId: vm.productId
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.orderedProducts = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                productId:vm.productId
            });
        }


        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
