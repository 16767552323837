(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('NewsDialogController', NewsDialogController);

    NewsDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'DataUtils', 'entity', 'News'];

    function NewsDialogController ($timeout, $scope, $uibModalInstance, DataUtils, entity, News) {
        var vm = this;

        vm.news = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.isAppsOnForNews = isAppsOnForNews;

        editorOptions();
        toggleSwitchApps();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            getSwitchedOnAppsValue();
            if (vm.news.id !== null) {
                News.update(vm.news, onSaveSuccess, onSaveError);
            } else {
                News.save(vm.news, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:newsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.pubDate = false;
        vm.datePickerOpenStatus.lastEdited = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function editorOptions() {
            $scope.options = {
                height: 400,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
                    ['color', ['color']],
                    ['fontsize', ['fontsize']],
                    ['picture', ['picture']],
                    ['video', ['video']],
                    ['fontface', ['fontname']],
                    ['insert', ['link']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['height', ['height']]
                ]
            };
        }

        function isAppsOnForNews(lang) {
            if (lang == "uz")
                return vm.bozorakaUzb || vm.mobPayUzb;
            else if (lang == "en")
                return vm.bozorakaEng || vm.mobPayEng;
            else if (lang == "ru")
                return vm.bozorakaRus || vm.mobPayRus;
        }

        function toggleSwitchApps() {
            vm.bozorakaUzb = toggleSwitchForApp(1);
            vm.mobPayUzb = toggleSwitchForApp(4);

            vm.bozorakaEng = toggleSwitchForApp(8);
            vm.mobPayEng = toggleSwitchForApp(32);

            vm.bozorakaRus = toggleSwitchForApp(64);
            vm.mobPayRus = toggleSwitchForApp(256);
        }

        function toggleSwitchForApp(value) {
            return (vm.news.shouldDisplay & value) > 0;
        }

        function getSwitchedOnAppsValue() {
            vm.news.shouldDisplay = 0;
            sumShouldDisplay(vm.bozorakaUzb, 1);
            sumShouldDisplay(vm.mobPayUzb, 4);

            sumShouldDisplay(vm.bozorakaEng, 8);
            sumShouldDisplay(vm.mobPayEng, 32);

            sumShouldDisplay(vm.bozorakaRus, 64);
            sumShouldDisplay(vm.mobPayRus, 256);
        }

        function sumShouldDisplay(isAppOn, value) {
            if (isAppOn) {
                vm.news.shouldDisplay += value;
            }
        }
    }
})();
