(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('StatsDailyController', StatsDailyController);

    StatsDailyController.$inject = ['DateUtils', 'StatsDaily', 'AlertService', 'StatsService', 'StatsActiveUserOS', 'StatsActiveUserMerchant', 'StatsPerTime', 'DataUtils'];

    function StatsDailyController(DateUtils, StatsDaily, AlertService, StatsService, StatsActiveUserOS, StatsActiveUserMerchant, StatsPerTime, DataUtils) {
        var vm = this;
        vm.search = search;
        vm.activateTab = activateTab;
        vm.toggleTransactionDetails = toggleTransactionDetails;
        vm.expandTransactionDetails = false;
        vm.getCurrencySymbolForClient = DataUtils.getCurrencySymbolForClient;
        vm.searchQuery = {
            fromDate: DateUtils.currentDate(),
            client: 'bozoraka'
        };
        search();

        function toggleTransactionDetails() {
            vm.expandTransactionDetails = !vm.expandTransactionDetails;
        }

        var options = {
            onSelectedDateChanged: function (event, date) {
                vm.searchQuery = {
                    fromDate: DateUtils.converterDateFormat(date),
                    client: vm.searchQuery.client
                };
            }
        };

        function search() {
            StatsDaily.query(vm.searchQuery, onSuccess, onError);
            StatsService.query(vm.searchQuery, onSuccessService, onError);
            StatsService.getByGateway(vm.searchQuery, onSuccessServiceByGateway, onError);
            StatsActiveUserOS.query(vm.searchQuery, onSuccessActiveUserOS, onError);
            StatsActiveUserMerchant.query(vm.searchQuery, onSuccessActiveMerchant, onError);
            StatsPerTime.query(vm.searchQuery, onSuccessTransactionPerTime, onError);
            vm.activeTab = 'tab_gateway_1';
            function onSuccess(data, headers) {
                vm.stats = data;
            }

            function onSuccessService(data, headers) {
                makeServiceTypesPieChart(data);
            }

            function onSuccessServiceByGateway(data, headers) {
                makeGatewaysPieChart(data);
            }

            function onSuccessActiveUserOS(data, headers) {
                makeActiveUserOSPieChart(data);
            }

            function onSuccessActiveMerchant(data, headers) {
                makeActiveUserMerchantPieChart(data);
            }

            function onSuccessTransactionPerTime(data, headers) {
                makeTransactionPerTimeChart(data);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        };

        $('#paginator').datepaginator(options);

        function makeServiceTypesPieChart(data) {
            var chart_by_service = AmCharts.makeChart("piechart_service_type", {
                "type": "pie",
                "startDuration": 0,
                "theme": "light",
                "addClassNames": true,
                "legend": {
                    "position": "right",
                    "marginRight": 100,
                    "autoMargins": false
                },
                "innerRadius": "30%",
                "defs": {
                    "filter": [{
                        "id": "shadow",
                        "width": "200%",
                        "height": "200%",
                        "feOffset": {
                            "result": "offOut",
                            "in": "SourceAlpha",
                            "dx": 0,
                            "dy": 0
                        },
                        "feGaussianBlur": {
                            "result": "blurOut",
                            "in": "offOut",
                            "stdDeviation": 5
                        },
                        "feBlend": {
                            "in": "SourceGraphic",
                            "in2": "blurOut",
                            "mode": "normal"
                        }
                    }]
                },
                "dataProvider": data,
                "valueField": "amount",
                "titleField": "titleField",
                "export": {
                    "enabled": true
                }
            });

            chart_by_service.addListener("init", handleInit);

            chart_by_service.addListener("rollOverSlice", function (e) {
                handleRollOver(e);
            });
        }

        function makeGatewaysPieChart(data) {
            var chart_by_gateway = AmCharts.makeChart("piechart_gateway", {
                "type": "pie",
                "startDuration": 0,
                "theme": "light",
                "addClassNames": true,
                "legend": {
                    "position": "right",
                    "marginRight": 100,
                    "autoMargins": false
                },
                "innerRadius": "30%",
                "defs": {
                    "filter": [{
                        "id": "shadow",
                        "width": "200%",
                        "height": "200%",
                        "feOffset": {
                            "result": "offOut",
                            "in": "SourceAlpha",
                            "dx": 0,
                            "dy": 0
                        },
                        "feGaussianBlur": {
                            "result": "blurOut",
                            "in": "offOut",
                            "stdDeviation": 5
                        },
                        "feBlend": {
                            "in": "SourceGraphic",
                            "in2": "blurOut",
                            "mode": "normal"
                        }
                    }]
                },
                "dataProvider": data,
                "valueField": "amount",
                "titleField": "titleField",
                "export": {
                    "enabled": true
                }
            });

            chart_by_gateway.addListener("init", handleInit);

            chart_by_gateway.addListener("rollOverSlice", function (e) {
                handleRollOver(e);
            });
        }

        function handleInit() {
            chart1.legend.addListener("rollOverItem", handleRollOver);
        }

        function handleRollOver(e) {
            var wedge = e.dataItem.wedge.node;
            wedge.parentNode.appendChild(wedge);
        }


        function makeActiveUserOSPieChart(data) {
            var chart = AmCharts.makeChart("piechart2", {
                "type": "pie",
                "theme": "light",
                "dataProvider": data,
                "valueField": "numberOfUsers",
                "titleField": "OSType",
                "descriptionField": "description",
                "balloon": {
                    "fixedPosition": true
                },
                "export": {
                    "enabled": true
                },
                "radius": "100"
            });
        }

        function makeActiveUserMerchantPieChart(data) {
            var chart4 = AmCharts.makeChart("piechart3", {
                "type": "pie",
                "theme": "light",
                "dataProvider": data,
                "valueField": "numberOfUsers",
                "titleField": "MerchantType",
                "descriptionField": "description",
                "balloon": {
                    "fixedPosition": true
                },
                "export": {
                    "enabled": true
                },
                "radius": "100"
            });
        }

        function makeTransactionPerTimeChart(data) {
            var chart = AmCharts.makeChart("barchart", {
                "type": "serial",
                "addClassNames": true,
                "theme": "light",
                "autoMargins": false,
                "marginLeft": 30,
                "marginRight": 8,
                "marginTop": 10,
                "marginBottom": 26,
                "balloon": {
                    "adjustBorderColor": false,
                    "horizontalPadding": 10,
                    "verticalPadding": 8,
                    "color": "#ffffff"
                },

                "dataProvider": data,
                "valueAxes": [{
                    "axisAlpha": 0,
                    "position": "left"
                }],
                "startDuration": 1,
                "graphs": [{
                    "alphaField": "alpha",
                    "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "fillAlphas": 1,
                    "title": "Success",
                    "type": "column",
                    "valueField": "successes",
                    "dashLengthField": "dashLengthColumn"
                }, {
                    "id": "graph2",
                    "balloonText": "<span style='font-size:12px;'>[[title]] in [[category]]:<br><span style='font-size:20px;'>[[value]]</span> [[additional]]</span>",
                    "bullet": "round",
                    "lineThickness": 4,
                    "bulletSize": 20,
                    "bulletBorderAlpha": 4,
                    "bulletColor": "red",
                    "useLineColorForBulletBorder": false,
                    "bulletBorderThickness": 3,
                    "fillAlphas": 0,
                    "lineAlpha": 1,
                    "title": "Failure",
                    "valueField": "failures",
                    "dashLengthField": "dashLengthLine"
                }],
                "categoryField": "timeSlot",
                "categoryAxis": {
                    "gridPosition": "start",
                    "axisAlpha": 0,
                    "tickLength": 0
                },
                "export": {
                    "enabled": true
                }
            });
        }

        function activateTab(tab) {
            vm.activeTab = tab;
        }
    }
})();
