(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('config', {
                parent: 'entity',
                url: '/config',
                data: {
                    authorities: ['ROLE_MANAGER'],
                    pageTitle: 'global.menu.configuration.service.rates'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/config/configs.html',
                        controller: 'ConfigController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('config');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('config-detail', {
                parent: 'config',
                url: '/config/{id}',
                data: {
                    authorities: ['ROLE_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.config.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/config/config-detail.html',
                        controller: 'ConfigDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('config');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Config', function ($stateParams, Config) {
                        return Config.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'config',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('config-detail.edit', {
                parent: 'config-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/config/config-dialog.html',
                        controller: 'ConfigDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Config', function (Config) {
                                return Config.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('config.new', {
                parent: 'config',
                url: '/new',
                data: {
                    authorities: ['ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/config/config-dialog.html',
                        controller: 'ConfigDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    title: null,
                                    name: null,
                                    value: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('config', null, {reload: 'config'});
                    }, function () {
                        $state.go('config');
                    });
                }]
            })
            .state('config.edit', {
                parent: 'config',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/config/config-dialog.html',
                        controller: 'ConfigDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Config', function (Config) {
                                return Config.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('config', null, {reload: 'config'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('config.delete', {
                parent: 'config',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/config/config-delete-dialog.html',
                        controller: 'ConfigDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Config', function (Config) {
                                return Config.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('config', null, {reload: 'config'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('config.view', {
                parent: 'config',
                url: '/view',
                data: {
                    authorities: ['ROLE_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.config.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/config/configs-view.html',
                        controller: 'ConfigController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('config');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('app-version-codes', {
                parent: 'entity',
                url: '/app-version-codes',
                data: {
                    authorities: ['ROLE_MANAGER'],
                    pageTitle: 'global.menu.configuration.appVersionCode'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/config/app-version-codes.html',
                        controller: 'ConfigController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('config');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('api-keys', {
                parent: 'entity',
                url: '/api-keys',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                    pageTitle: 'global.menu.configuration.apiKeys'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/config/keys.html',
                        controller: 'ConfigController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('config');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('sns-followers', {
                parent: 'entity',
                url: '/sns-followers',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                    pageTitle: 'global.menu.configuration.snsFollowers'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/config/sns-followers.html',
                        controller: 'ConfigController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('config');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
