(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PromoCodeController', PromoCodeController);

    PromoCodeController.$inject = ['$state', '$filter', 'PromoCode', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function PromoCodeController($state, $filter, PromoCode, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;


        vm.search = search;
        vm.clear = clear;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.searchQuery = {
            code: pagingParams.code,
            referrerPhone: pagingParams.referrerPhone,
            clients: pagingParams.clients,
            discountType: pagingParams.discountType,
            status: pagingParams.status,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };

        initDatePicker();
        loadAll();

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function loadAll () {
            PromoCode.query({
                code: vm.searchQuery.code,
                referrerPhone: vm.searchQuery.referrerPhone,
                clients: vm.searchQuery.clients,
                discountType: vm.searchQuery.discountType,
                status: vm.searchQuery.status,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.promoCodes = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                code: vm.searchQuery.code,
                referrerPhone: vm.searchQuery.referrerPhone,
                clients: vm.searchQuery.clients,
                discountType: vm.searchQuery.discountType,
                status: vm.searchQuery.status,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.searchQuery = {
                code: "",
                referrerPhone: null,
                clients: ['bozoraka', 'mobpay'],
                discountType: null,
                status: null,
                fromDate: null,
                toDate: null
            };
            vm.transition();
        }
        setTimeout(function () {
            translateMultiSelect();

            $('#client').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('profile.all'),
                nonSelectedText: translate('profile.noneSelected'),
                allSelectedText: translate('profile.allSelected')
            });
        }, 0);

        function translateMultiSelect() {
            all = translate('profile.all');
            noneSelected = translate('profile.noneSelected');
            allSelected = translate('profile.allSelected');
        }

    }
})();
