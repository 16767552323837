(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('gift-request', {
                parent: 'entity',
                url: '/gift-request',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN'],
                    pageTitle: 'global.menu.gift.request'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/gift-request/gift-request.html',
                        controller: 'GiftRequestController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'pubDate,asc',
                        squash: true
                    },
                    pageNewReq: {
                        value: '1',
                        squash: true
                    },
                    sortNewReq: {
                        value: 'pubDate,desc',
                        squash: true
                    },
                    pagePendingReq: {
                        value: '1',
                        squash: true
                    },
                    sortPendingReq: {
                        value: 'pubDate,desc',
                        squash: true
                    },
                    activeTab: 'tab_new_1'
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),

                            pageNewReq: PaginationUtil.parsePage($stateParams.pageNewReq),
                            sortNewReq: $stateParams.sortNewReq,
                            predicateNewReq: PaginationUtil.parsePredicate($stateParams.sortNewReq),
                            ascendingNewReq: PaginationUtil.parseAscending($stateParams.sortNewReq),
                            pagePendingReq: PaginationUtil.parsePage($stateParams.pagePendingReq),
                            sortPendingReq: $stateParams.sortPendingReq,
                            predicatePendingReq: PaginationUtil.parsePredicate($stateParams.sortPendingReq),
                            ascendingPendingReq: PaginationUtil.parseAscending($stateParams.sortPendingReq),
                            activeTab: $stateParams.activeTab
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('transaction');
                        $translatePartialLoader.addPart('transactionType');
                        $translatePartialLoader.addPart('transactionStatus');
                        $translatePartialLoader.addPart('serviceType');
                        $translatePartialLoader.addPart('transactionMode');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'market-request',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
    }

})();
