(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProviderCountryPreviewController', ProviderCountryPreviewController);

    ProviderCountryPreviewController.$inject = ['pagingParams', 'paginationConstants', 'ParseLinks', '$state', 'CountryStorage', 'ProviderCountrySort'];

    function ProviderCountryPreviewController(pagingParams, paginationConstants, ParseLinks, $state, CountryStorage, ProviderCountrySort) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.lang = pagingParams.langKey;
        vm.save = save;
        vm.clearCountry = clearCountry;
        vm.selectedCountry = CountryStorage.getCountry();
        vm.appName = pagingParams.appName;
        var replacedCountry = null;
        loadAll();


        function loadAll() {
            if (vm.appName === 'bozoraka' && vm.lang !== 'uz' && vm.lang !== 'en' && vm.lang !== 'ru'&& vm.lang !== 'ko'){
                vm.lang = 'uz';
            }
            ProviderCountrySort.getAsList({
                appName: vm.appName,
                langKey: vm.lang
            }, onSuccess, onError);

            return null;
        }

        function onSuccess(data) {
            vm.countries = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.onChangeAppName = function () {
            loadAll();
            clearCountry();
        };

        vm.onChangeLanguage = function () {
            loadAll();
            clearCountry();
        };

        Sortable.create(country, {
            ghostClass: 'ghost',
            sort: true
        });

        function save() {
            var newSortingOrder = makeCountryList();
            ProviderCountrySort.update(newSortingOrder, saveSuccess);

            function saveSuccess() {
                loadAll();
            }
        }

        function makeCountryList() {
            var newSortingOrder = [];
            var dataList = $("#country .item-pro-ajax").map(function () {
                return $(this).data("id");
            }).get();
            for (var i = 0; i < dataList.length; i++) {
                newSortingOrder[i] = {
                    appName: vm.appName,
                    providerCountryId: dataList[i],
                    lang: vm.lang,
                    sortOrder: i
                };
            }
            return newSortingOrder;
        }

        vm.replace = function (country, replacedCountryIndex) {
            if (!angular.isUndefined(vm.selectedCountryIndex)) {
                vm.countries[vm.selectedCountryIndex] = country;
                vm.countries[replacedCountryIndex] = vm.selectedCountry;
            } else {
                vm.countries[replacedCountryIndex] = vm.selectedCountry;
                replacedCountry = country;
            }
            clearCountry();
        };

        vm.insert = function (country, insertedCountryIndex) {
            var selectedCountrySortOrderIndex = findIndexByCountryId(vm.selectedCountry.id);
            var insertedCountrySortOrderIndex = findIndexByCountryId(vm.countries[insertedCountryIndex].id);
            if (insertedCountrySortOrderIndex > selectedCountrySortOrderIndex) {
                var beginIndex = 0;
                if (!angular.isUndefined(vm.selectedCountryIndex)) {
                    beginIndex = vm.selectedCountryIndex;
                }
                for (var i = beginIndex; i < insertedCountryIndex; i++) {
                    vm.countries[i] = vm.countries[i + 1];
                }
                vm.countries[insertedCountryIndex] = vm.selectedCountry;
            } else {
                var beginIndex = vm.countries.length;
                if (!angular.isUndefined(vm.selectedCountryIndex)) {
                    beginIndex = vm.selectedCountryIndex;
                }
                for (var i = beginIndex; i > insertedCountryIndex; i--) {
                    vm.countries[i] = vm.countries[i - 1];
                }
                vm.countries[insertedCountryIndex] = vm.selectedCountry;
            }
            clearCountry();
        };

        vm.select = function (country, index) {
            CountryStorage.setCountry(country);
            vm.selectedCountryIndex = index;
            vm.selectedCountry = CountryStorage.getCountry();
        };

        function clearCountry() {
            CountryStorage.setCountry(null);
            vm.selectedCountry = CountryStorage.getCountry();
        }

        function findIndexByCountryId(countryId) {
            var dataList = $("#country .item-pro-ajax").map(function () {
                return $(this).data("id");
            }).get();

            for (var i = 0; i < dataList.length; i++) {
                if (dataList[i] === countryId) {
                    return i;
                }
            }
        }

    }
})();
