(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('terms', {
                parent: 'entity',
                url: '/terms',
                data: {
                    pageTitle: 'bozorakaAdminApp.sitePage.terms'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/terms/terms.html',
                        controller: 'TermsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            language: $stateParams.language,
                            appName: 'bozoraka'
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sitePage');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('terms-mobpay', {
                parent: 'entity',
                url: '/terms-mobpay',
                data: {
                    pageTitle: 'bozorakaAdminApp.sitePage.terms'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/terms/terms.html',
                        controller: 'TermsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            language: $stateParams.language,
                            appName: 'mobpay'
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sitePage');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
