(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('Partner', Partner)
        .factory('Agent', Agent);

    Partner.$inject = ['$resource', 'DateUtils'];

    function Partner ($resource, DateUtils) {
        var resourceUrl =  'api/partners/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'changeBalance': {
                method: 'POST',
                url: 'api/partner/change-balance',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    }
                    return data;
                }
            },
            'changePassword': {
                method: 'POST',
                url: 'api/partner/:id/change-password',
                params: {
                    'id': '@id',
                    'newPassword': '@newPassword'
                },
            },
            'getTotalDebt': {
                method: 'GET',
                isArray: false,
                url: 'api/partners/total-debt'
            }
        });
    }

    Agent.$inject = ['$resource', 'DateUtils'];

    function Agent ($resource, DateUtils) {
        var resourceUrl =  'api/partners/agents/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
