(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileBalanceDialogController', ProfileBalanceDialogController);

    ProfileBalanceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Profile', 'Customer', 'Transaction'];

    function ProfileBalanceDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Profile, Customer, Transaction) {
        var vm = this;

        vm.profile = entity;
        vm.transaction = {
            amount: null,
            transactionType: null,
            description: null,
            profileId: vm.profile.id
        };
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            Transaction.save(vm.transaction, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            Profile.get({id: vm.profile.id}).$promise.then(function (profile) {
                $scope.$emit('bozorakaAdminApp:profileUpdate', profile);
                $uibModalInstance.close(result);
                vm.isSaving = false;
            });
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
