(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileBonusDialogController', ProfileBonusDialogController);

    ProfileBonusDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Profile', 'Customer', 'Mileage'];

    function ProfileBonusDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Profile, Customer, Mileage) {
        var vm = this;

        vm.profile = entity;
        vm.mileage = {
            amount: null,
            type: "PLUS_MANUALLY",
            description: null,
            profile: {
                id: vm.profile.id
            }
        };
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            Mileage.save(vm.mileage, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            Profile.get({id: vm.profile.id}).$promise.then(function (profile) {
                $scope.$emit('bozorakaAdminApp:profileUpdate', profile);
                $uibModalInstance.close(result);
                vm.isSaving = false;
            });
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
