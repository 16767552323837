(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ShippingDetailsDialogController', ShippingDetailsDialogController);

    ShippingDetailsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PartnerOrderInfo'];

    function ShippingDetailsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PartnerOrderInfo) {
        var vm = this;

        vm.partnerOrderInfo = {
            id: entity.id,
            fullName: entity.fullName,
            phone: entity.phone,
            address: entity.address
        };
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            PartnerOrderInfo.updateShippingDetails(vm.partnerOrderInfo, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:partnerOrderInfoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
