(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('Terms', Terms);

    Terms.$inject = ['$resource'];

    function Terms ($resource) {
        var resourceUrl =  'api/site-pages/terms';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
