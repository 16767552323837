(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MerchantDetailController', MerchantDetailController);

    MerchantDetailController.$inject = ['entity'];

    function MerchantDetailController(entity) {
        var vm = this;

        vm.profile = entity;
        vm.customer = {
            id: vm.profile.customerId,
            fullName: vm.profile.customerFullName,
            status: vm.profile.customerStatus
        };
    }
})();
