(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ReactivateUserController', ReactivateUserController);

    ReactivateUserController.$inject = ['$state', 'ParseLinks', 'AlertService', 'ReactivateUser'];

    function ReactivateUserController($state, ParseLinks, AlertService, ReactivateUser) {

        var vm = this;
        vm.month = 18;
        vm.reactivateUsers = [];

        loadAll();

        function loadAll() {
            ReactivateUser.get(function (result) {
                vm.reactivateUsers = result;
            });
        }
    }
})();
