(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('SellerInvoiceIssueController', SellerInvoiceIssueController);

    SellerInvoiceIssueController.$inject = ['$http', '$scope', '$state', 'AlertService', 'DateUtils', 'SellerUser', 'SellerInvoiceIssue'];

    function SellerInvoiceIssueController($http, $scope, $state, AlertService, DateUtils, SellerUser, SellerInvoiceIssue) {
        var vm = this;
        vm.clear = clear;
        vm.search = search;
        vm.confirmedInvoice = confirmedInvoice;
        vm.paidInvoice = paidInvoice;
        vm.downloadInvoice = downloadInvoice;

        vm.sellerInvoiceIssues = [];
        vm.searchQuery = {
            sellerId: null,
            invoiceStatus: null,
            paymentStatus: null,
            fromDate: null,
            toDate: null
        };

        initDatePicker();
        getSellers();
        search();

        function initDatePicker() {
            if (vm.searchQuery.toDate === null) {
                vm.searchQuery.toDate = moment().format('MM/DD/YYYY');
            }
            if (vm.searchQuery.fromDate === null) {
                vm.searchQuery.fromDate = moment(vm.searchQuery.toDate, 'MM/DD/YYYY').subtract(1, "months").format('MM/DD/YYYY');
            }
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function getSellers() {
            SellerUser.query(onSuccess);

            function onSuccess(data) {
                vm.sellers = data;
            }
        }

        function getDateFromField() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }

        function search() {
            getDateFromField();
            SellerInvoiceIssue.query(vm.searchQuery, function (result) {
                vm.sellerInvoiceIssues = result;
                createPopover();
            });
        }

        function clear() {
            vm.searchQuery = {
                sellerId: null,
                invoiceStatus: null,
                paymentStatus: null,
                fromDate: null,
                toDate: null
            };
            initDatePicker();
        }

        function downloadInvoice(sellerInvoice, langKey) {
            $http({
                url: 'api/seller-invoice-issues/' + sellerInvoice.id + '/pdf/' + langKey,
                method: 'GET',
                responseType: 'arraybuffer'
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/pdf'
                });
                saveAs(blob, 'seller-invoice-' + sellerInvoice.issueDate + '_' + DateUtils.currentTimestamp() + '.pdf');
            }).error(function () {
            });
        }

        function confirmedInvoice(invoiceId) {
            SellerInvoiceIssue.confirmedInvoice({id: invoiceId}, onSuccess, onError);
        }

        function paidInvoice(invoiceId) {
            SellerInvoiceIssue.paidInvoice({id: invoiceId}, onSuccess, onError);
        }

        function onSuccess() {
            search();
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function createPopover() {
            $(document).ready(function () {
                $('[data-toggle="popover"]').each(function () {
                    var invoiceId = $(this).attr('id');
                    $(this).popover({
                        html: 'true',
                        title: '',
                        content: '<div style="background: #f8e8af"><p style="padding-top: 5px; padding-left: 5px; padding-right: 5px;">Once you confirm this invoice, we will wire the payment within the next five business days. Would you like to confirm?</p>' +
                            '<button style="width: 45%; margin-left: 5px; margin-bottom: 5px; background: #f5eec2" class="confirm-invoice"  id=' + invoiceId + '>Yes</button>' +
                            '<button style="width: 45%; margin-right: 5px; margin-bottom: 5px; float: right; background: #f5eec2" id="close-popover">Cancel</button></div>'
                    });
                });
                $('[data-toggle="pay-popover"]').each(function () {
                    var invoiceId = $(this).attr('id');
                    $(this).popover({
                        html: 'true',
                        title: '',
                        content: '<div style="background: #f8e8af"><p style="padding-top: 5px; padding-left: 5px; padding-right: 5px;">Would you like to confirm the payment?</p>' +
                            '<button style="width: 45%; margin-left: 5px; margin-bottom: 5px; background: #f5eec2" class="pay-invoice"  id=' + invoiceId + '>Yes</button>' +
                            '<button style="width: 45%; margin-right: 5px; margin-bottom: 5px; float: right; background: #f5eec2" id="close-popover">Cancel</button></div>'
                    });
                });
                $(document).on('click', '#close-popover', function () {
                    $('[data-toggle="popover"]').popover('hide');
                    $('[data-toggle="pay-popover"]').popover('hide');
                });
                $(document).on('click', '.confirm-invoice', function () {
                    vm.confirmedInvoice($(this).attr('id'));
                });
                $(document).on('click', '.pay-invoice', function () {
                    vm.paidInvoice($(this).attr('id'));
                });
                $('[data-toggle="popover"]').on('click', function (e) {
                    $('[data-toggle="popover"]').not(this).popover('hide');
                    $('[data-toggle="pay-popover"]').not(this).popover('hide');
                });
                $('[data-toggle="pay-popover"]').on('click', function (e) {
                    $('[data-toggle="popover"]').not(this).popover('hide');
                    $('[data-toggle="pay-popover"]').not(this).popover('hide');
                });
                $('body').on('hidden.bs.popover', function (e) {
                    $(e.target).data("bs.popover").inState.click = false;
                });

            });
        }
    }
})();
