(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('SortByOrders', SortByOrders);

    SortByOrders.$inject = ['$resource', 'DateUtils'];


    function SortByOrders($resource, DateUtils) {
        var resourceUrl = 'api/market-stats/sort-by-orders';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
        });
    }
})();
