(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('image-storage', {
            parent: 'entity',
            url: '/image-storage?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_MANAGER','ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA','ROLE_MARKET_MANAGER'],
                pageTitle: 'bozorakaAdminApp.imageStorage.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/image-storage/image-storages.html',
                    controller: 'ImageStorageController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('imageStorage');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('image-storage-detail', {
            parent: 'entity',
            url: '/image-storage/{id}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_MANAGER','ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA','ROLE_MARKET_MANAGER'],
                pageTitle: 'bozorakaAdminApp.imageStorage.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/image-storage/image-storage-detail.html',
                    controller: 'ImageStorageDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('imageStorage');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ImageStorage', function($stateParams, ImageStorage) {
                    return ImageStorage.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'image-storage',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('image-storage-detail.edit', {
            parent: 'image-storage-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_MANAGER','ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA','ROLE_MARKET_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/image-storage/image-storage-dialog.html',
                    controller: 'ImageStorageDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ImageStorage', function(ImageStorage) {
                            return ImageStorage.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('image-storage.new', {
            parent: 'image-storage',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_MANAGER','ROLE_CONTENT_EDITOR_MOBPAY','ROLE_CONTENT_EDITOR_BOZORAKA','ROLE_MARKET_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/image-storage/image-storage-dialog.html',
                    controller: 'ImageStorageDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                data: null,
                                dataContentType: null,
                                sortOrder: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('image-storage', null, { reload: 'image-storage' });
                }, function() {
                    $state.go('image-storage');
                });
            }]
        })
        .state('image-storage.edit', {
            parent: 'image-storage',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_MANAGER','ROLE_CONTENT_EDITOR_MOBPAY','ROLE_CONTENT_EDITOR_BOZORAKA','ROLE_MARKET_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/image-storage/image-storage-dialog.html',
                    controller: 'ImageStorageDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ImageStorage', function(ImageStorage) {
                            return ImageStorage.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('image-storage', null, { reload: 'image-storage' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('image-storage.delete', {
            parent: 'image-storage',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_MANAGER','ROLE_CONTENT_EDITOR_MOBPAY','ROLE_CONTENT_EDITOR_BOZORAKA','ROLE_MARKET_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/image-storage/image-storage-delete-dialog.html',
                    controller: 'ImageStorageDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ImageStorage', function(ImageStorage) {
                            return ImageStorage.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('image-storage', null, { reload: 'image-storage' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
