(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('ProductCloudImage', ProductCloudImage);

    ProductCloudImage.$inject = ['$resource'];

    function ProductCloudImage ($resource) {
        var resourceUrl =  'api/product-cloud-images/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'product': {
                method: 'GET',
                url: 'api/products/:id/cloud-images/:size',
                isArray: true
            },
            'primary': {
                method: 'GET',
                url: 'api/product-cloud-images/:id/primary'
            }
        });
    }
})();
