(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('PartnerOrderInfo', PartnerOrderInfo)
        .factory('PartnerRequestStorage', PartnerRequestStorage)
        .factory('AgentRequestStorage', AgentRequestStorage)
        .factory('PartnerOrderInfoDelivery', PartnerOrderInfoDelivery)
        .factory('PartnerOrderInfoAddressChangeLog', PartnerOrderInfoAddressChangeLog);

    PartnerOrderInfo.$inject = ['$resource', 'DateUtils'];

    function PartnerOrderInfo ($resource, DateUtils) {
        var resourceUrl =  'api/partner-order-infos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    }
                    return data;
                }
            },
            'allOrders': {
                url: 'api/b2b-orders',
                method: 'GET',
                isArray: true
            },
            'update': { method:'PUT' },
            'updateStatus': {
                url: 'api/partner-order-info-status',
                method:'PUT'
            },
            'getByPartner' : {
                method: 'GET',
                id: '@id',
                url: 'api/partners/:id/orders-histories',
                isArray: true
            },
            'getAllUnpaidOrder' : {
                method: 'GET',
                id: '@id',
                url: 'api/partners/:id/unpaid-orders',
                isArray: true
            },
            'updateShippingDetails': {
                url: 'api/partner-order-info/update-shipping-details',
                method: 'PUT',

            },
            'agentNewRequests': {
                method: 'GET',
                url: 'api/agent-new-requests',
                isArray: true
            },
            'agentPendingRequests': {
                method: 'GET',
                url: 'api/agent-pending-requests',
                isArray: true
            },
            'getAllNewAgentRequestIds':{
                method: 'GET',
                url :'api/agent-new-request-ids',
                isArray: true
            },
            'getAllNewPartnerRequestIds':{
                method: 'GET',
                url :'api/partner-new-request-ids',
                isArray: true
            },
            'updatePaymentStatus' : {
                method: 'PUT',
                url: 'api/partner-order-infos/:id/update-payment-status',
                params: {
                    'id': '@id',
                    'paymentStatus': '@paymentStatus',
                    'shouldChangeBalance': '@shouldChangeBalance'
                }
            }
        });
    }

    PartnerOrderInfoDelivery.$inject = ['$resource', 'DateUtils'];

    function PartnerOrderInfoDelivery ($resource, DateUtils) {
        var resourceUrl =  'api/partner-order-infos/:id/delivery';

        return $resource(resourceUrl, {"id": "@id"}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    PartnerRequestStorage.$inject = ['$resource', '$localStorage'];

    function PartnerRequestStorage($resource, $localStorage) {
        return {
            setPartnerSelectedOrders: function (partnerSelectedOrders) {
                $localStorage.partnerSelectedOrders = partnerSelectedOrders;
            },
            getPartnerSelectedOrders: function () {
                return $localStorage.partnerSelectedOrders;
            }

        }
    }

    AgentRequestStorage.$inject = ['$resource', '$localStorage'];

    function AgentRequestStorage($resource, $localStorage) {
        return {
            setAgentSelectedOrders: function (agentSelectedOrders) {
                $localStorage.agentSelectedOrders = agentSelectedOrders;
            },
            getAgentSelectedOrders: function () {
                return $localStorage.agentSelectedOrders;
            }

        }
    }

    PartnerOrderInfoAddressChangeLog.$inject = ['$resource'];

    function PartnerOrderInfoAddressChangeLog($resource) {
        var resourceUrl = 'api/partner/order-info/:id/address-change-logs';

        return $resource(resourceUrl, {"id": "@id"}, {
            'get': { method: 'GET', isArray: true }
        });
    }

})();
