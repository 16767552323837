(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileVOCController', ProfileVOCController);

    ProfileVOCController.$inject = ['$state', 'entity', 'ProfileVOC', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function ProfileVOCController($state, entity, ProfileVOC, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.profile = entity;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicateVOC;
        vm.reverse = pagingParams.ascendingVOC;
        vm.transition = transition;
        vm.itemsPerPage = 50;

        loadAll();

        function loadAll () {
            ProfileVOC.query({
                profileId: vm.profile.id,
                page: pagingParams.pageVOC - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.vOCS = data;
                vm.page = pagingParams.pageVOC;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                id: vm.profile.id,
                pageVOC: vm.page,
                sortVOC: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
