(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('SitePageController', SitePageController);

    SitePageController.$inject = ['DataUtils', 'SitePage'];

    function SitePageController(DataUtils, SitePage) {

        var vm = this;

        vm.sitePages = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll() {
            SitePage.query(function(result) {
                vm.sitePages = result;
                vm.searchQuery = null;
            });
        }
    }
})();
