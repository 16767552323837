(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('reactivate-user', {
                parent: 'entity',
                url: '/reactivate-user',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'reactivateUsers.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/reactivate-user/reactivate-user.html',
                        controller: 'ReactivateUserController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    month: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reactivateUsers');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('reactivate-user.run', {
                parent: 'reactivate-user',
                url: '/run',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/reactivate-user/reactivate-user-run-dialog.html',
                        controller: 'ReactivateUserRunController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            month: [function () {
                                return $stateParams.month;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', null, { reload: 'reactivate-user' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
