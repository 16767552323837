(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('SortByPayment', SortByPayment);

    SortByPayment.$inject = ['$resource', 'DateUtils'];


    function SortByPayment($resource, DateUtils) {
        var resourceUrl = 'api/market-stats/sort-by-payment';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }
})();
