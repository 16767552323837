(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('SortByPaymentController', SortByPaymentController);

    SortByPaymentController.$inject = ['$state', '$filter', '$http', 'DateUtils', 'ParseLinks', 'SortByPayment', 'AlertService', 'DataUtils', 'Tier', 'pagingParams'];

    function SortByPaymentController($state, $filter, $http, DateUtils, ParseLinks, SortByPayment, AlertService, DataUtils, Tier, pagingParams) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.data = [];
        var translate = $filter('translate'), all, noneSelected, allSelected;
        vm.tiers = Tier.query();
        vm.search = search;
        vm.excel = excel;
        vm.searchQuery = {
            fromDate: pagingParams.fromDate ? pagingParams.fromDate : DateUtils.currentDate(),
            toDate: pagingParams.toDate ? pagingParams.toDate : DateUtils.currentDate(),
            clients: pagingParams.clients,
            field: pagingParams.field,
            b2bClients: pagingParams.b2bClients,
            tiers: pagingParams.tiers
        };
        vm.loadMore = loadMore;
        vm.transition = transition;
        vm.firstRun = true;
        vm.page = 0;
        vm.loading = false;
        initDatePicker();

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#customerClient').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics.all'),
                nonSelectedText: translate('statistics.noneSelected'),
                allSelectedText: translate('statistics.allSelected')
            });

            $('#b2bClient').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics.all'),
                nonSelectedText: translate('statistics.noneSelected'),
                allSelectedText: translate('statistics.allSelected')
            });

            $('#tier').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics.all'),
                nonSelectedText: translate('statistics.noneSelected'),
                allSelectedText: translate('statistics.allSelected')
            });

        }, 1000);

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            vm.data = [];
            vm.page = 0;
            vm.lastPage = false;
            vm.loading = false;
            vm.firstRun = false;
            loadNext();

        }

        function loadNext() {
            SortByPayment.query({
                page: vm.page,
                size: vm.itemsPerPage,
                sort: sort(),
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                clients: vm.searchQuery.clients,
                field: vm.searchQuery.field,
                b2bClients: vm.searchQuery.b2bClients,
                tiers: vm.searchQuery.tiers
            }, onSuccess, onError);
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'partner') {
                result.push('partner');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page++;
            var result = data;

            if (result == null || result.length < 20) {
                vm.lastPage = true;
            }
            result.forEach(function (o) {
                vm.data.push(o);
            });
            vm.loading = false;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function translateMultiSelect() {
            all = translate('statistics.all');
            noneSelected = translate('statistics.noneSelected');
            allSelected = translate('statistics.allSelected');
        }

        function loadMore() {
            if (vm.lastPage || vm.loading) {
                return null;
            } else {
                vm.loading = true;
                loadNext();
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                clients: vm.searchQuery.clients,
                field: vm.searchQuery.field,
                b2bClients: vm.searchQuery.b2bClients,
                tiers: vm.searchQuery.tiers
            });
        }

        function excel() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            var data = "fromDate=" + vm.searchQuery.fromDate + "&toDate=" + vm.searchQuery.toDate + "&field=" + (vm.searchQuery.field === null ? "" : vm.searchQuery.field) + "&b2bClients=" + (vm.searchQuery.b2bClients.length === 0 ? [] : vm.searchQuery.b2bClients) + "&tiers=" + (vm.searchQuery.tiers.length === 0 ? [] : vm.searchQuery.tiers) + "&clients=" + (vm.searchQuery.clients.length === 0 ? [] : vm.searchQuery.clients);
            $http({
                url: 'api/market-stats/sort-by-payment/excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                saveAs(blob, 'b2b-sales-sort-by-payment-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {
            });
        }
    }
})();
