(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductBoxEditController', ProductBoxEditController);

    ProductBoxEditController.$inject = ['$state', '$translate', 'entity', 'pagingParams', 'categoryId', 'langKey', 'ProductCategory', 'paginationConstants', 'ParseLinks', 'Category', 'ProductBoxCategory'];

    function ProductBoxEditController($state, $translate, entity, pagingParams, categoryId, langKey, ProductCategory, paginationConstants, ParseLinks, Category, ProductBoxCategory) {
        var vm = this;
        var SALE_CATEGORY_ID = -1;
        var NEW_CATEGORY_ID = -2;

        vm.productBoxes = entity;
        vm.lang = langKey;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.loadPage = loadPage;
        vm.onLanguageChange = onLanguageChange;
        vm.categoryName = getCategoryName();
        vm.save = save;

        loadAll();

        function loadAll() {
            ProductCategory.query({
                categoryId: categoryId,
                langKey: vm.lang,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.products = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                categoryId: categoryId,
                langKey: vm.lang
            });
        }

        function onLanguageChange() {
            vm.productBoxes = ProductBoxCategory.get({categoryId: categoryId, langKey: vm.lang});
            loadAll();
        }

        function getCategoryName() {
            if (categoryId == SALE_CATEGORY_ID) {
                return {nameUzb: "ЧЕГИРМАЛАР", nameEng: "Sale", nameRus: "РАСПРОДАЖА"};
            } else if (categoryId == NEW_CATEGORY_ID) {
                return {nameUzb: "Yangi", nameEng: "New", nameRus: "НОВЫЕ"};
            }
            return getCategory();
        }

        function getCategory() {
            Category.get({id: categoryId}, onSuccess);
            function onSuccess(data) {
                vm.categoryName = data;
            }
            return vm.categoryName;
        }

        function save() {
            var productBoxList = makeProductBoxList();
            ProductBoxCategory.save({categoryId: categoryId, langKey: vm.lang}, productBoxList, onSuccess);

            function onSuccess() {
                vm.productBoxes = ProductBoxCategory.get({categoryId: categoryId, langKey: vm.lang});
                loadAll();
            }
        }

        function makeProductBoxList() {
            var dataList = $("#productBox .product-box").map(function () {
                return $(this).data("id");
            }).get();
            var productBoxList = [];
            for (var i = 0; i < dataList.length; i++) {
                var productBox = {
                    categoryId: categoryId,
                    productId: dataList[i],
                    lang: vm.lang
                };
                productBoxList.push(productBox);
            }
            return removeDuplicateProduct(productBoxList);
        }

        function removeDuplicateProduct(productBoxList) {
            var products = [];
            angular.forEach(productBoxList, function (product, key) {
                var hasSameProduct = false;
                angular.forEach(products, function (newProduct, key) {
                    if (angular.equals(product.productId, newProduct.productId)) {
                        hasSameProduct = true;
                    }
                });
                if (hasSameProduct == false && product.productId != "") {
                    products.push(product);
                }
            });
            return products;
        }

        Sortable.create(productBox, {
            disabled: false,
            ghostClass: 'ghost',
            group: {
                name: 'productBox',
                put: ['product']
            }
        });

        Sortable.create(product, {
            ghostClass: 'ghost',
            sort: false,
            group: {
                name: 'product',
                put: ['productBox']
            }
        });
    }
})();
