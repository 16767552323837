(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('kakao-payment', {
            parent: 'entity',
            url: '/kakao-payment?page&sort&search&field',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.kakaoPayment.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/kakao-payment/kakao-payments.html',
                    controller: 'KakaoPaymentController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                field: null,
                storeId: null,
                status: null,
                systemPaymentId: "",
                fromDate: null,
                toDate: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        field: $stateParams.field,
                        storeId: $stateParams.storeId,
                        status: $stateParams.status,
                        systemPaymentId: $stateParams.systemPaymentId,
                        fromDate: $stateParams.fromDate,
                        toDate: $stateParams.toDate,
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('kakaoPayment');
                    $translatePartialLoader.addPart('settlebankPayment');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
            .state('kakao-payment.refund', {
                parent: 'kakao-payment',
                url: '/{id}/refund',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/kakao-payment/kakao-payment-refund-dialog.html',
                        controller: 'KakaoPaymentRefundController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: {paymentId: $stateParams.id}
                        }
                    }).result.then(function() {
                        $state.go('kakao-payment', null, { reload: 'kakao-payment' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
        .state('kakao-payment-detail', {
            parent: 'kakao-payment',
            url: '/kakao-payment/{id}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.kakaoPayment.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/kakao-payment/kakao-payment-detail.html',
                    controller: 'KakaoPaymentDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('kakaoPayment');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'KakaoPayment', function($stateParams, KakaoPayment) {
                    return KakaoPayment.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'kakao-payment',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('kakao-payment-detail.edit', {
            parent: 'kakao-payment-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/kakao-payment/kakao-payment-dialog.html',
                    controller: 'KakaoPaymentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['KakaoPayment', function(KakaoPayment) {
                            return KakaoPayment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('kakao-payment.new', {
            parent: 'kakao-payment',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/kakao-payment/kakao-payment-dialog.html',
                    controller: 'KakaoPaymentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                storeId: null,
                                paymentId: null,
                                sysPaymentId: null,
                                totalAmount: null,
                                status: null,
                                errorCode: null,
                                message: null,
                                hash: null,
                                rawJson: null,
                                appVersionName: null,
                                createdBy: null,
                                createdDate: null,
                                lastModifiedBy: null,
                                lastModifiedDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('kakao-payment', null, { reload: 'kakao-payment' });
                }, function() {
                    $state.go('kakao-payment');
                });
            }]
        })
        .state('kakao-payment.edit', {
            parent: 'kakao-payment',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/kakao-payment/kakao-payment-dialog.html',
                    controller: 'KakaoPaymentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['KakaoPayment', function(KakaoPayment) {
                            return KakaoPayment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('kakao-payment', null, { reload: 'kakao-payment' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('kakao-payment.delete', {
            parent: 'kakao-payment',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/kakao-payment/kakao-payment-delete-dialog.html',
                    controller: 'KakaoPaymentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['KakaoPayment', function(KakaoPayment) {
                            return KakaoPayment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('kakao-payment', null, { reload: 'kakao-payment' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
