(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('CategoryController', CategoryController);

    CategoryController.$inject = ['$scope', '$state', '$stateParams', 'Category', 'SpecialCategory', 'CategorySearch', 'AlertService'];

    function CategoryController ($scope, $state, $stateParams, Category, SpecialCategory, CategorySearch, AlertService) {
        var vm = this;

        vm.categories = [];
        vm.isSpecial = $stateParams.isSpecial;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.getParentCategoryName = getParentCategoryName;

        loadAll();

        function loadAll() {
            if (vm.isSpecial) {
                SpecialCategory.query(function(result) {
                    vm.categories = result;
                    vm.searchQuery = null;
                });
            } else {
                Category.query(function(result) {
                    vm.categories = result;
                    vm.searchQuery = null;
                });
            }
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            CategorySearch.query({query: vm.searchQuery}, function(result) {
                vm.categories = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }

        function getParentCategoryName(parentId) {
            if (!parentId) {
                return;
            }
            for (var i = 0; i < vm.categories.length; i++) {
                if (vm.categories[i]['id'] === parentId) {
                    return vm.categories[i]['nameEng'];
                }
            }
        }
    }
})();
