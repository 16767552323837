(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('OrderGiftItemDeleteController',OrderGiftItemDeleteController);

    OrderGiftItemDeleteController.$inject = ['$uibModalInstance', 'entity', 'OrderGiftItem'];

    function OrderGiftItemDeleteController($uibModalInstance, entity, OrderGiftItem) {
        var vm = this;

        vm.orderGiftItem = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            OrderGiftItem.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
