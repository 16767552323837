(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('InventoryHistory', InventoryHistory)
        .factory('ProductInventoryHistory', ProductInventoryHistory)
        .factory('ProductEditInventoryHistory', ProductEditInventoryHistory);

    InventoryHistory.$inject = ['$resource'];

    function InventoryHistory ($resource) {
        var resourceUrl =  'api/inventory-histories/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    ProductInventoryHistory.$inject = ['$resource'];

    function ProductInventoryHistory ($resource) {
        var resourceUrl =  'api/products/:id/inventory-histories';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    ProductEditInventoryHistory.$inject = ['$resource'];

    function ProductEditInventoryHistory ($resource) {
        var resourceUrl =  'api/inventory-edit-histories';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }

})();
