(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('StockManagement', StockManagement);

    StockManagement.$inject = ['$resource'];

    function StockManagement ($resource) {
        var resourceUrl =  'api/stock-management';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }

})();
