(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('CardPaymentController', CardPaymentController);

    CardPaymentController.$inject = ['$scope', '$state', 'CardPayment', 'CardPaymentSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams' ,'CardPaymentTotalAmount', 'ProfileIdByPhone'];

    function CardPaymentController ($scope, $state, CardPayment, CardPaymentSearch, ParseLinks, AlertService, paginationConstants, pagingParams, CardPaymentTotalAmount, ProfileIdByPhone) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.openProfile = openProfile;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.searchQuery = {
            field: pagingParams.field,
            payStates: pagingParams.payStates,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };

        initDatePicker();
        loadAll();
        getTotalAmount();

        function loadAll () {
            CardPayment.query({
                field: pagingParams.field,
                payStates: pagingParams.payStates,
                fromDate: pagingParams.fromDate,
                toDate: pagingParams.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.cardPayments = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                field: vm.searchQuery.field,
                payStates: vm.searchQuery.payStates,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
            });
        }

        function search(searchQuery) {
            $('.input-daterange input').each(function() {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery ={
                field: null,
                payStates: 0,
                fromDate: null,
                toDate: null

            };
            vm.transition();
        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function getTotalAmount() {
            CardPaymentTotalAmount.query({
                field: vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate
            }, success);

            function success(data) {
                vm.totalAmount = data.totalAmount;
            }
        }

        function openProfile(phoneNumber) {
            ProfileIdByPhone.get({phoneNumber: phoneNumber}, onSuccess);

            function onSuccess(data) {
                $state.go('profile-detail', {id: data.profileId});
            }
        }

    }
})();
