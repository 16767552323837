/**
 * Created by Gayratjon Rayimjonov on 5/29/2017.
 */
(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('StatsWeekly', StatsWeekly)
        .factory('StatsWeeklyBonus', StatsWeeklyBonus);

    StatsWeekly.$inject = ['$resource', 'DateUtils'];

    function StatsWeekly ($resource, DateUtils) {
        var resourceUrl =  'api/stats-weekly';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateFromServer(data.toDate);
                    }
                    return data;
                }
            }
        });
    }

    StatsWeeklyBonus.$inject = ['$resource', 'DateUtils'];

    function StatsWeeklyBonus ($resource, DateUtils) {
        var resourceUrl =  'api/stats-weekly-bonus';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                        data.toDate = DateUtils.convertLocalDateFromServer(data.toDate);
                    }
                    return data;
                }
            }
        });
    }
})();
