(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('ProfileTransaction', ProfileTransaction)
        .factory('ProfileBalance', ProfileBalance)
        .factory('ProfileBonus', ProfileBonus)
        .factory('ProfileTransactionAmount', ProfileTransactionAmount)
        .factory('ProfileBalanceAmount', ProfileBalanceAmount);

    ProfileTransaction.$inject = ['$resource'];

    function ProfileTransaction($resource) {
        var resourceUrl = 'api/profile-transaction-history';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

    ProfileBalance.$inject = ['$resource'];

    function ProfileBalance($resource) {
        var resourceUrl = 'api/profile-balance-history';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

    ProfileBonus.$inject = ['$resource'];

    function ProfileBonus($resource) {
        var resourceUrl = 'api/profile-bonus-history';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

    ProfileTransactionAmount.$inject = ['$resource'];

    function ProfileTransactionAmount($resource) {
        var resourceUrl = 'api/profiles/:profileId/total-transaction-amount';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false }
        });
    }

    ProfileBalanceAmount.$inject = ['$resource'];

    function ProfileBalanceAmount($resource) {
        var resourceUrl = 'api/profiles/:profileId/total-balance-amount';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false}
        });
    }

})();
