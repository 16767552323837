(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('shipping-data', {
                parent: 'entity',
                url: '/shipping-data',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_SELLER', 'ROLE_WAREHOUSE_MANAGER'],
                    pageTitle: 'shipping-data.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/shipping-data/shipping-data.html',
                        controller: 'ShippingDataController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('shippingData');
                        $translatePartialLoader.addPart('transaction');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
