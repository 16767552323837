(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('SalesByProductController', SalesByProductController);

    SalesByProductController.$inject = ['$state', 'DateUtils', '$http', 'DataUtils', 'SalesByProduct', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function SalesByProductController($state, DateUtils, $http, DataUtils, SalesByProduct, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.search = search;
        vm.clear = clear;
        vm.excel = excel;
        vm.searchQuery = {
            field: pagingParams.field,
            client: pagingParams.client,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };

        initDatePicker();

        if (!pagingParams.firstRun) {
            loadAll();
        }

        function loadAll() {
            if (vm.searchQuery.field == null || vm.searchQuery.fromDate == null || vm.searchQuery.toDate == null) {
                return
            }
            SalesByProduct.query({
                field: vm.searchQuery.field,
                client: vm.searchQuery.client,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.salesByProduct = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                field: vm.searchQuery.field,
                client: vm.searchQuery.client,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                firstRun: false
            });
        }

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });

            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = {
                field: null,
                client: 'all',
                fromDate: null,
                toDate: null
            };
            vm.transition();
        }

        function excel() {
            if (vm.searchQuery.field == null || vm.searchQuery.fromDate == null || vm.searchQuery.toDate == null) {
                return
            }
            var data = "field=" + encodeURIComponent(vm.searchQuery.field) + "&client=" + vm.searchQuery.client + "&fromDate=" + vm.searchQuery.fromDate + "&toDate=" + vm.searchQuery.toDate;

            $http({
                url: 'api/sales-by-product-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'sales-by-product-' + vm.searchQuery.field + '-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {
            });

        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }
    }
})();
