(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MarketStatsTopSellingController', MarketStatsTopSellingController);

    MarketStatsTopSellingController.$inject = ['$filter', '$http', 'DateUtils', 'MarketStatsTopSelling', 'AlertService', 'DataUtils', 'Category'];

    function MarketStatsTopSellingController($filter, $http, DateUtils, MarketStatsTopSelling, AlertService, DataUtils, Category) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.search = search;
        vm.clear = clear;
        vm.exportExcel = exportExcel;
        vm.getCategories = getCategories;
        vm.orderBy = 'productId';
        vm.orderByAsc = true;
        vm.totalAmount = 0;
        vm.updateOrderBy = updateOrderBy;
        vm.getCurrencySymbolForClient = DataUtils.getCurrencySymbolForClient;
        vm.categories = [];
        vm.parentCategory = null;
        vm.childCategory = null;
        vm.fromDate = DateUtils.currentDateFirstMonth();
        vm.toDate = DateUtils.currentDateFirstMonth();
        vm.searchQuery = {
            productName: null,
            productId: null,
            fromDate: DateUtils.currentDate(),
            toDate: DateUtils.currentDate(),
            clients: ['b2b', 'bozoraka', 'mobpay'],
            categoryId : null,
            vatList : ['true', 'false']
        };

        loadCategories();
        initDatePicker();

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') === 'from') {
                    $(this).val(vm.fromDate);
                } else if ($(this).attr('name') === 'to') {
                    $(this).val(vm.toDate);
                }
                $(this).datepicker('update');
            });

            $('.date-picker').datepicker({autoclose: true});
        }
        function loadCategories() {
            Category.getAllCategories({}, function (data) {
                vm.categories = data;
                vm.categories.sort(function (a, b) {
                    return a.parentId > b.parentId ? 1 : -1;
                });
                vm.categoryTree = [];
                for (var j = 0; j < vm.categories.length; j++) {
                    if (vm.categories[j].parentId === null){
                        vm.categoryTree.push(vm.categories[j]);
                    } else {
                        var index = vm.categoryTree.findIndex(function (a) {
                            return a.id === vm.categories[j].parentId;
                        });
                        if (index > -1) {
                            if (vm.categoryTree[index].children === undefined) {
                                vm.categoryTree[index].children = [];
                            }
                            vm.categoryTree[index].children.push(vm.categories[j]);
                        }

                    }
                }
            });
        }

        function clear() {
            vm.stats = [];
            vm.parentCategory = null;
            vm.childCategory = null;
            vm.fromDate = DateUtils.currentDateFirstMonth();
            vm.toDate = DateUtils.currentDateFirstMonth();
            vm.searchQuery = {
                productName: null,
                productId: null,
                fromDate: DateUtils.currentDate(),
                toDate: DateUtils.currentDate(),
                clients: ['b2b', 'bozoraka', 'mobpay'],
                categoryId : null,
                vatList : ['true', 'false']
            };

            initDatePicker();

            $('#customerClient').multiselect('selectAll', false);
            $('#customerClient').multiselect('refresh');

            $('#vatList').multiselect('selectAll', false);
            $('#vatList').multiselect('refresh');
        }
        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.toDate = $(this).val();
                }
            });
            vm.searchQuery.fromDate = moment(vm.fromDate).format("YYYY-MM-DD");
            vm.searchQuery.toDate = moment(vm.toDate).format("YYYY-MM-DD");
            if (vm.parentCategory !== null) {
                vm.searchQuery.categoryId = vm.parentCategory.id;
                if (vm.childCategory !== null && vm.childCategory.parentId === vm.parentCategory.id) {
                    vm.searchQuery.categoryId = vm.childCategory.id;
                }
            } else {
                vm.searchQuery.categoryId = null;
            }
            MarketStatsTopSelling.query(vm.searchQuery, onSuccess, onError);
        }

        function onSuccess(data) {
            vm.stats = data;
        }

        function onError(error) {
            console.error(error);
            AlertService.error(error.data.message);
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#customerClient').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics.all'),
                nonSelectedText: translate('statistics.noneSelected'),
                allSelectedText: translate('statistics.allSelected')
            });

            $('#vatList').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics.all'),
                nonSelectedText: translate('statistics.noneSelected'),
                allSelectedText: translate('statistics.allSelected')
            });

        }, 0);

        function translateMultiSelect() {
            all = translate('statistics.all');
            noneSelected = translate('statistics.noneSelected');
            allSelected = translate('statistics.allSelected');
        }

        function updateOrderBy(orderBy) {
            vm.orderBy = orderBy;
            vm.orderByAsc = !vm.orderByAsc;
        }

        function exportExcel() {
            if (vm.parentCategory !== null) {
                vm.searchQuery.categoryId = vm.parentCategory.id;
                if (vm.childCategory !== null && vm.childCategory.parentId === vm.parentCategory.id) {
                    vm.searchQuery.categoryId = vm.childCategory.id;
                }
            } else {
                vm.searchQuery.categoryId = null;
            }
            var data = vm.searchQuery;
            $http({
                url: 'api/market-stats/top-selling/excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/json'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                saveAs(blob, 'market-stats-top-selling-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {
            });
        }

        Array.prototype.sum = function (prop) {
            var total = 0;
            for (var i = 0, _len = this.length; i < _len; i++) {
                total += this[i][prop];
            }
            return total;
        };

        function getCategories(stat) {
            var categories = stat.categories;
            var categoriesStr = "";
            for (var i = 0; i < categories.length; i++) {
                var category = categories[i];
                if (i == categories.length - 1) {
                    categoriesStr += category.nameEng;
                } else {
                    categoriesStr += category.nameEng + ", ";
                }
            }
            return categoriesStr;
        }
    }
})();
