(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('CareerDialogController', CareerDialogController);

    CareerDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Career'];

    function CareerDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Career) {
        var vm = this;

        vm.career = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.editorOption = {};

        editorOptions();
        function editorOptions() {
            vm.editorOption = {
                height: 400,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
                    ['color', ['color']],
                    ['fontsize', ['fontsize']],
                    ['picture', ['picture']],
                    ['video', ['video']],
                    ['fontface', ['fontname']],
                    ['insert', ['link']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['height', ['height']]
                ]
            };
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.career.id !== null) {
                Career.update(vm.career, onSaveSuccess, onSaveError);
            } else {
                Career.save(vm.career, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:careerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
