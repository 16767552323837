(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .factory('DataUtils', DataUtils);

    DataUtils.$inject = ['$window', '$filter', '$sce'];

    function DataUtils($window, $filter, $sce) {

        var service = {
            abbreviate: abbreviate,
            byteSize: byteSize,
            openFile: openFile,
            toBase64: toBase64,
            getDirectAmount: getDirectAmount,
            getDeductedAmount: getDeductedAmount,
            getUsedBonusAmount: getUsedBonusAmount,
            getSentAmount: getSentAmount,
            getResponse: getResponse,
            renderToHtml: renderToHtml,
            getCurrencyNameForClient: getCurrencyNameForClient,
            getCurrencySymbolForClient: getCurrencySymbolForClient,
            getBanks: getBanks,
            getBankNameByIndex: getBankNameByIndex
        };

        return service;

        function abbreviate(text) {
            if (!angular.isString(text)) {
                return '';
            }
            if (text.length < 30) {
                return text;
            }
            return text ? (text.substring(0, 15) + '...' + text.slice(-10)) : '';
        }

        function byteSize(base64String) {
            if (!angular.isString(base64String)) {
                return '';
            }

            function endsWith(suffix, str) {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            }

            function paddingSize(base64String) {
                if (endsWith('==', base64String)) {
                    return 2;
                }
                if (endsWith('=', base64String)) {
                    return 1;
                }
                return 0;
            }

            function size(base64String) {
                return base64String.length / 4 * 3 - paddingSize(base64String);
            }

            function formatAsBytes(size) {
                return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
            }

            return formatAsBytes(size(base64String));
        }

        function openFile(type, data) {
            $window.open('data:' + type + ';base64,' + data, '_blank', 'height=300,width=400');
        }

        function toBase64(file, cb) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                cb(base64Data);
            };
        }

        function getDirectAmount(transaction) {
            var number = $filter('number');
            var currency = getCurrencyNameForClient(transaction.customerClient);
            if (transaction.localCurrency != null){
                currency = transaction.localCurrency;
            }
            return number(transaction.directAmount == null ? 0 : transaction.directAmount) + ' ' + currency;
        }

        function getDeductedAmount(transaction) {
            var number = $filter('number');
            var currency = getCurrencyNameForClient(transaction.customerClient);
            if (transaction.localCurrency != null){
                currency = transaction.localCurrency;
            }
            return number(transaction.amount) + ' ' + currency;
        }

        function getUsedBonusAmount(transaction) {
            var number = $filter('number');
            var currency = getCurrencyNameForClient(transaction.customerClient);
            if (transaction.localCurrency != null){
                currency = transaction.localCurrency;
            }
            return number(transaction.usedBonusAmount == null ? 0 : transaction.usedBonusAmount) + ' ' + currency;
        }

        function getCurrencyNameForClient(customerClient) {
            return 'won';
        }

        function getSentAmount(transaction) {
            var number = $filter('number');
            if (transaction.serviceCurrency != null){
                return number(transaction.amountInSom) + " " + transaction.serviceCurrency;
            }else if (transaction.serviceType == 'SERVICE_UZB_PHONE'
                || transaction.serviceType == 'SERVICE_UZB_INTERNET'
                || transaction.serviceType == 'SERVICE_UZB_UTILITY'
                || transaction.serviceType == 'SERVICE_KASH_PAYNET') {
                return number(transaction.amountInSom) + " so'm";
            } else if (transaction.serviceType == 'SERVICE_VOIP') {
                return number(transaction.voipEuro) + " euro";
            } else if (transaction.serviceType == 'SERVICE_VOIP_UZTEL') {
                return number(transaction.amountInSom) + " dollar";
            } else if (transaction.serviceType == 'SERVICE_RUS_PHONE') {
                return number(transaction.amountInSom) + (transaction.client == "010pay" ? " dollar" : " ruble");
            } else if (transaction.serviceType == 'SERVICE_KAZ_PHONE'
                || transaction.serviceType == 'SERVICE_KGZ_PHONE'
                || transaction.serviceType == 'SERVICE_TJK_PHONE'
                || transaction.serviceType == 'SERVICE_UKR_PHONE'
                || transaction.serviceType == 'SERVICE_PAYFORM_KAZ_TELE2'
                || transaction.serviceType == 'SERVICE_PAYFORM_KAZ_BEELINE'
                || transaction.serviceType == 'SERVICE_PAYFORM_KAZ_KCELL'
                || transaction.serviceType == 'SERVICE_PAYFORM_KAZ_ALTEL'
                || transaction.serviceType == 'SERVICE_PAYFORM_KAZ_ACTIV'
                || transaction.serviceType == 'SERVICE_PAYFORM_TJK_BEELINE'
                || transaction.serviceType == 'SERVICE_PAYFORM_TJK_MEGAFON'
                || transaction.serviceType == 'SERVICE_PAYFORM_TJK_INDIGO'
                || transaction.serviceType == 'SERVICE_PAYFORM_TJK_VAVILONMOBILE'
                || transaction.serviceType == 'SERVICE_PAYFORM_KGZ_BEELINE'
                || transaction.serviceType == 'SERVICE_PAYFORM_KGZ_NURTELECOM'
                || transaction.serviceType == 'SERVICE_PAYFORM_KGZ_MEGACOM'
                || transaction.serviceType == 'SERVICE_PAYFORM_RUS_MTC'
                || transaction.serviceType == 'SERVICE_PAYFORM_RUS_BEELINE'
                || transaction.serviceType == 'SERVICE_PAYFORM_RUS_MEGAFON'
                || transaction.serviceType == 'SERVICE_PAYFORM_RUS_TELE2'
                || transaction.serviceType == 'SERVICE_PAYFORM_UKR_MTC'
                || transaction.serviceType == 'SERVICE_PAYFORM_UKR_KYEVSTAR'
                || transaction.serviceType == 'SERVICE_PAYFORM_UKR_LIFE') {
                return number(transaction.amountInSom) + " dollar";
            } else if (transaction.serviceType == 'SERVICE_OK_CASH') {
                return number(transaction.amountInSom) + " rouble";
            } else if (transaction.serviceType == 'SERVICE_MYS_PHONE'
                || transaction.serviceType == 'SERVICE_NPL_PHONE'
                || transaction.serviceType == 'SERVICE_MMR_PHONE'
                || transaction.serviceType == 'SERVICE_IDN_PHONE'
                || transaction.serviceType == 'SERVICE_VIE_PHONE'
                || transaction.serviceType == 'SERVICE_KHM_PHONE'
                || transaction.serviceType == 'SERVICE_THA_PHONE'
                || transaction.serviceType == 'SERVICE_BGD_PHONE'
                || transaction.serviceType == 'SERVICE_LKA_PHONE'
                || transaction.serviceType == 'SERVICE_PHL_PHONE'
                || transaction.serviceType == 'SERVICE_IND_PHONE'
                || transaction.serviceType == 'SERVICE_PAK_PHONE'
                || transaction.serviceType == 'SERVICE_SGP_PHONE'
                || transaction.serviceType == 'SERVICE_CHN_PHONE'
                || transaction.serviceType == 'SERVICE_TUR_PHONE') {
                return number(transaction.amountInSom) + (transaction.status == 'SUCCESS' ? " dollar" : "");
            } else if (transaction.serviceType == 'SERVICE_THIS_BONUS') {
                return number(transaction.amountInSom) + " won";
            } else if (transaction.serviceType == 'SERVICE_KOR_CARDPHONE_VOICE'
                || transaction.serviceType == 'SERVICE_KOR_INTL_CARD') {
                if (transaction.amountInSom == 0) {
                    return number(transaction.amount + transaction.usedMileage) + " won";
                } else {
                    return number(transaction.amountInSom) + " won";
                }
            } else {
                return number(transaction.amount + transaction.usedMileage) + " " + getCurrencyNameForClient(transaction.customerClient);
            }
        }

        function getFormattedResponse(isJson, response) {
            if (angular.isUndefined(response)) {
                return "";
            }

            if (isJson) {
                return response
                        .replace(/{"/g, '{\n "')
                        .replace(/"},/g, '"\n },')
                        .replace(/,"/g, ',\n "')
                        .replace(/"}/g, '"\n}')
                        .trim();
            } else if (response.includes('xml version')) {
                return response
                        .replace(/></g, '>\n<')
                        .trim();
            }

            return response;
        }

        function getResponse(transaction) {
            var response;
            var isJson = true;
            var gw = transaction.gateway;
            switch (gw) {
                case 'paynet':
                case 'voip_uztel':
                case 'this':
                case 'this_bonus':
                    response = transaction.autoModeResponse;
                    break;
                case 'yandex':
                case 'payform':
                case 'hermes':
                case 'voip':
                    isJson = ('yandex' === gw);
                    if (transaction.requestPaymentResponse !== null) {
                        response = "Request payment response:\n" + transaction.requestPaymentResponse;
                    }
                    if (transaction.processPaymentResponse !== null) {
                        response += "\n\nProcess payment response:\n" + transaction.processPaymentResponse;
                    }
                    break;
                case '010pay':
                case 'the_plus':
                case '00301':
                    response = transaction.thePayAutoModeResponse;
                    break;
                case '010pay':
                case 'the_plus':
                case '00301':
                case 'mango_intl_card':
                case 'mango_card_phone':
                case 'mango_other_card':
                    response = transaction.mangoAutoModeResponse;
                    break;
                default:
                    response = "";
                    break;
            }
            if (response) {
                return getFormattedResponse(isJson, response);
            }

            switch (transaction.serviceType) {
                case 'SERVICE_UZB_PHONE':
                case 'SERVICE_UZB_INTERNET':
                case 'SERVICE_THIS_BONUS':
                case 'SERVICE_VOIP_UZTEL':
                case 'SERVICE_UZB_UTILITY':
                case 'SERVICE_KASH_PAYNET':
                    response = transaction.autoModeResponse;
                    break;
                case 'SERVICE_KOR_CARDPHONE_VOICE':
                    if (transaction.mangoAutoModeResponse !== null) {
                        response = "Mango auto response:\n" + transaction.mangoAutoModeResponse;
                    }
                    if (transaction.thePayAutoModeResponse !== null) {
                        response += "\n\n010Pay auto response:\n" + transaction.thePayAutoModeResponse;
                    }
                    break;
                case 'SERVICE_KOR_INTL_CARD':
                    if (transaction.description.toLowerCase().includes('this')) {
                        response = transaction.autoModeResponse;
                    } else if (transaction.description.toLowerCase().includes('the+')
                        || transaction.description.includes('00301')) {
                        response = transaction.thePayAutoModeResponse;
                    } else {
                        response = transaction.mangoAutoModeResponse;
                    }
                    break;
                case 'SERVICE_VOIP':
                    response = transaction.processPaymentResponse;
                    break;
                default:
                    if (transaction.requestPaymentResponse !== null) {
                        response += "Request payment response:\n" + transaction.requestPaymentResponse;
                    }
                    if (transaction.processPaymentResponse !== null) {
                        response += "\n\nProcess payment response:\n" + transaction.processPaymentResponse;
                    }
            }
            if (response) {
                response = getFormattedResponse(isJson, response);
            }

            return response;
        }

        function renderToHtml(value) {
            return $sce.trustAsHtml(value);
        }

        function getCurrencySymbolForClient(client) {
            if (client == null || client == undefined) {
                return '';
            }
            return 'fa fa-won';
        }

        function getBanks() {
            var banks = [
                {id: '1', name: '기업은행 (IBK)', code: '003'},
                {id: '2', name: '경남은행 (Kyongnam)', code: '039'},
                {id: '3', name: '우리은행 (Woori)', code: '020'},
                {id: '4', name: 'KEB하나은행 (KEBHana)', code: '081'},
                {id: '5', name: '신한은행 (Shinhan)', code: '088'},
                {id: '6', name: '국민은행 (Kookmin)', code: '004'},
                {id: '7', name: '농협은행 (NH)', code: '011'},
                {id: '8', name: '제일은행 (SC)', code: '023'}
            ];
            return banks;
        }

        function getBankNameByIndex(index) {
            if (index == null) {
                index = '0';
            }
            var banks = [
                {id: '0', name: 'No bank', code: '000'},
                {id: '1', name: '기업은행 (IBK)', code: '003'},
                {id: '2', name: '경남은행 (Kyongnam)', code: '039'},
                {id: '3', name: '우리은행 (Woori)', code: '020'},
                {id: '4', name: 'KEB하나은행 (KEBHana)', code: '081'},
                {id: '5', name: '신한은행 (Shinhan)', code: '088'},
                {id: '6', name: '국민은행 (Kookmin)', code: '004'},
                {id: '7', name: '농협은행 (NH)', code: '011'},
                {id: '8', name: '제일은행 (SC)', code: '023'}
            ];
            return banks[index].name;
        }
    }
})();
