(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('OrderCustomerInfoDialog', OrderCustomerInfoDialog);

    OrderCustomerInfoDialog.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'OrderInfo', '$translate'];

    function OrderCustomerInfoDialog ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, OrderInfo, $translate) {
        var vm = this;

        vm.orderInfo = entity;
        vm.clear = clear;
        vm.save = save;
        var headers = {
            lang: $translate.use()
        };

        vm.phone = vm.orderInfo.phone;
        vm.address = vm.orderInfo.address;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            var data = 'id=' + vm.orderInfo.id + '&phone=' + vm.phone + '&address=' + vm.address;
            OrderInfo(headers).updateCustomerInfo(data, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:orderInfoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
