(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('OrderedProductDetailController', OrderedProductDetailController);

    OrderedProductDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'OrderedProduct', 'OrderInfo', 'Product'];

    function OrderedProductDetailController($scope, $rootScope, $stateParams, previousState, entity, OrderedProduct, OrderInfo, Product) {
        var vm = this;

        vm.orderedProduct = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:orderedProductUpdate', function(event, result) {
            vm.orderedProduct = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
