(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('WaivedProductGroupDetailController', WaivedProductGroupDetailController);

    WaivedProductGroupDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'WaivedProductGroup'];

    function WaivedProductGroupDetailController($scope, $rootScope, $stateParams, previousState, entity, WaivedProductGroup) {
        var vm = this;

        vm.waivedProductGroup = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:waivedProductGroupUpdate', function(event, result) {
            vm.waivedProductGroup = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
