(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('profile', {
                parent: 'entity',
                url: '/profile?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY'],
                    pageTitle: 'profile.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/profile/profiles.html',
                        controller: 'ProfileController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    field: null,
                    fromDate: null,
                    toDate: null,
                    lastTransactionFromDate: null,
                    lastTransactionToDate: null,
                    statuses: ['ACTIVE', 'DEACTIVATED', 'BANNED', 'REACTIVATED'],
                    clients: ['bozoraka', 'mobpay'],
                    nationalities: [],
                    genders: ['F', 'M', 'U'],
                    firstRun: true
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            field: $stateParams.field,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            lastTransactionFromDate: $stateParams.lastTransactionFromDate,
                            lastTransactionToDate: $stateParams.lastTransactionToDate,
                            statuses: $stateParams.statuses,
                            clients: $stateParams.clients,
                            nationalities: $stateParams.nationalities,
                            genders: $stateParams.genders,
                            firstRun: $stateParams.firstRun
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('profile-detail', {
                parent: 'entity',
                url: '/profile/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY', 'ROLE_MARKET_MANAGER'],
                    pageTitle: 'profile.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/profile/profile-detail.html',
                        controller: 'ProfileDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Profile', function ($stateParams, Profile) {
                        return Profile.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'profile',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('profile.phone', {
                url: '/profile/{phone}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'CUSTOMER_SUPPORT_BOZORAKA', 'CUSTOMER_SUPPORT_MOBPAY']
                },
                views: {
                    'content@': {
                        controller: 'ProfilePhoneRedirectController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('profile-transaction', {
                parent: 'entity',
                url: '/profile/{id}/transactions?page&sort',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY', 'ROLE_MARKET_MANAGER'],
                    pageTitle: 'profile.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/profile/profile-transaction.html',
                        controller: 'ProfileTransactionController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    pageTranHist: {
                        value: '1',
                        squash: true
                    },
                    sortTranHist: {
                        value: 'pubDate,desc',
                        squash: true
                    },
                    pageBalHist: {
                        value: '1',
                        squash: true
                    },
                    sortBalHist: {
                        value: 'pubDate,desc',
                        squash: true
                    },
                    pageBonHist: {
                        value: '1',
                        squash: true
                    },
                    sortBonHist: {
                        value: 'id,desc',
                        squash: true
                    },
                    activeTab: 'tab_1',
                    firstRun: true
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            pageTranHist: PaginationUtil.parsePage($stateParams.pageTranHist),
                            sortTranHist: $stateParams.sortTranHist,
                            predicateTranHist: PaginationUtil.parsePredicate($stateParams.sortTranHist),
                            ascendingTranHist: PaginationUtil.parseAscending($stateParams.sortTranHist),
                            pageBalHist: PaginationUtil.parsePage($stateParams.pageBalHist),
                            sortBalHist: $stateParams.sortBalHist,
                            predicateBalHist: PaginationUtil.parsePredicate($stateParams.sortBalHist),
                            ascendingBalHist: PaginationUtil.parseAscending($stateParams.sortBalHist),
                            pageBonHist: PaginationUtil.parsePage($stateParams.pageBonHist),
                            sortBonHist: $stateParams.sortBonHist,
                            predicateBonHist: PaginationUtil.parsePredicate($stateParams.sortBonHist),
                            ascendingBonHist: PaginationUtil.parseAscending($stateParams.sortBonHist),
                            activeTab: $stateParams.activeTab,
                            firstRun: $stateParams.firstRun
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Profile', function ($stateParams, Profile) {
                        return Profile.get({id: $stateParams.id}).$promise;
                    }]
                    ,
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'profile-transaction',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            // .state('profile-detail.edit', {
            //     parent: 'profile-detail',
            //     url: '/detail/edit',
            //     data: {
            //         authorities: ['ROLE_MANAGER']
            //     },
            //     onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
            //         $uibModal.open({
            //             templateUrl: 'app/entities/profile/profile-dialog.html',
            //             controller: 'ProfileDialogController',
            //             controllerAs: 'vm',
            //             backdrop: 'static',
            //             size: 'lg',
            //             resolve: {
            //                 entity: ['Profile', function (Profile) {
            //                     return Profile.get({id: $stateParams.id}).$promise;
            //                 }]
            //             }
            //         }).result.then(function () {
            //             $state.go('^', {}, {reload: false});
            //         }, function () {
            //             $state.go('^');
            //         });
            //     }]
            // })
            .state('profile.new', {
                parent: 'profile',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-dialog.html',
                        controller: 'ProfileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    accountNo: null,
                                    balance: null,
                                    totalMileage: null,
                                    registrationDate: null,
                                    isAgent: false,
                                    isMango: false,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('profile', null, {reload: 'profile'});
                    }, function () {
                        $state.go('profile');
                    });
                }]
            })
            .state('profile.edit', {
                parent: 'profile',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-dialog.html',
                        controller: 'ProfileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Profile', function (Profile) {
                                return Profile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile', null, {reload: 'profile'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile.delete', {
                parent: 'profile',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-delete-dialog.html',
                        controller: 'ProfileDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Profile', function (Profile) {
                                return Profile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile', null, {reload: 'profile'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-detail.editBalance', {
                parent: 'profile-detail',
                url: '/balance/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-balance-dialog.html',
                        controller: 'ProfileBalanceDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Profile', function (Profile) {
                                return Profile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-detail.editBonus', {
                parent: 'profile-detail',
                url: '/bonus/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-bonus-dialog.html',
                        controller: 'ProfileBonusDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Profile', function (Profile) {
                                return Profile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-detail.editStatus', {
                parent: 'profile-detail',
                url: '/status/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-status-dialog.html',
                        controller: 'ProfileStatusDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Profile', function (Profile) {
                                return Profile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-detail.changePhone', {
                    parent: 'profile-detail',
                    url: '/phone-number/edit',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/profile/profile-change-phone-dialog.html',
                            controller: 'ProfileChangePhoneDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                entity: ['Profile', function (Profile) {
                                    return Profile.get({id: $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('^', {}, {reload: false});
                        }, function () {
                            $state.go('^');
                        });
                    }]
            })
            .state('profile-shipping-address', {
                parent: 'entity',
                url: '/profile/{id}/address-books?page&sort',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY', 'ROLE_MARKET_MANAGER'],
                    pageTitle: 'profile.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/profile/profile-shipping-address.html',
                        controller: 'ProfileShippingAddressController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    sortShippingAddress: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            sortShippingAddress: $stateParams.sortShippingAddress,
                            predicateShippingAddress: PaginationUtil.parsePredicate($stateParams.sortShippingAddress),
                            ascendingShippingAddress: PaginationUtil.parseAscending($stateParams.sortShippingAddress),
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Profile', function ($stateParams, Profile) {
                        return Profile.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'profile-shipping-address',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('profile-shipping-address.edit', {
                parent: 'profile-shipping-address',
                url: '/{addressId}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-shipping-address-dialog.html',
                        controller: 'ProfileShippingAddressDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('shippingAddress');
                                return $translate.refresh();
                            }],
                            entity: ['ShippingAddress', function (ShippingAddress) {
                                return ShippingAddress.get({id: $stateParams.addressId}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile-shipping-address', null, {reload: 'profile-shipping-address'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-shipping-address.new', {
                parent: 'profile-shipping-address',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MARKET_MANAGER', 'ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-shipping-address-dialog.html',
                        controller: 'ProfileShippingAddressDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('shippingAddress');
                                return $translate.refresh();
                            }],
                            entity: function () {
                                return {
                                    title: null,
                                    recipientName: null,
                                    address: null,
                                    phone: null,
                                    profileId: $stateParams.id,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('profile-shipping-address', null, {reload: 'profile-shipping-address'});
                    }, function () {
                        $state.go('profile-shipping-address');
                    });
                }]
            })
            .state('profile-shipping-address.delete', {
                parent: 'profile-shipping-address',
                url: '/{addressId}/delete',
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-shipping-address-delete-dialog.html',
                        controller: 'ProfileShippingAddressDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        data: {
                            authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                        },
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('shippingAddress');
                                return $translate.refresh();
                            }],
                            entity: ['ShippingAddress', function(ShippingAddress) {
                                return ShippingAddress.get({id : $stateParams.addressId}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('profile-shipping-address', null, { reload: 'profile-shipping-address' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-detail.bankEdit', {
                parent: 'profile-detail',
                url: '/bank/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-bank-dialog.html',
                        controller: 'ProfileBankDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Profile', function(Profile) {
                                return Profile.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^', {}, { reload: false });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-voc', {
                parent: 'entity',
                url: '/profile/{id}/vocs?page&sort',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY'],
                    pageTitle: 'profile.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/profile/profile-voc.html',
                        controller: 'ProfileVOCController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    sortVOC: {
                        value: 'createdDate,desc',
                        squash: true
                    },
                    pageVOC: {
                        value: 1,
                        squash: true
                    },
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            pageVOC: PaginationUtil.parsePage($stateParams.pageVOC),
                            sortVOC: $stateParams.sortVOC,
                            predicateVOC: PaginationUtil.parsePredicate($stateParams.sortVOC),
                            ascendingVOC: PaginationUtil.parseAscending($stateParams.sortVOC),
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        $translatePartialLoader.addPart('vOC');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Profile', function ($stateParams, Profile) {
                        return Profile.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'profile-voc',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('profile-promo-code', {
                parent: 'entity',
                url: '/profile/{id}/promo-codes?page&sort',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY'],
                    pageTitle: 'profile.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/profile/profile-promo-code.html',
                        controller: 'ProfilePromoCodeController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    sortPromoCode: {
                        value: 'createdDate,desc',
                        squash: true
                    },
                    pagePromoCode: {
                        value: 1,
                        squash: true
                    },
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            pagePromoCode: PaginationUtil.parsePage($stateParams.pagePromoCode),
                            sortPromoCode: $stateParams.sortPromoCode,
                            predicatePromoCode: PaginationUtil.parsePredicate($stateParams.sortPromoCode),
                            ascendingPromoCode: PaginationUtil.parseAscending($stateParams.sortPromoCode),
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        $translatePartialLoader.addPart('promoCode');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Profile', function ($stateParams, Profile) {
                        return Profile.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'profile-promo-code',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('profile-notification', {
                parent: 'entity',
                url: '/profile/{id}/notifications?page&sort',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY'],
                    pageTitle: 'profile.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/profile/profile-notification.html',
                        controller: 'ProfileNotificationController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    sortNotification: {
                        value: 'createdDate,desc',
                        squash: true
                    },
                    pageNotification: {
                        value: 1,
                        squash: true
                    },
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            pageNotification: PaginationUtil.parsePage($stateParams.pageNotification),
                            sortNotification: $stateParams.sortNotification,
                            predicateNotification: PaginationUtil.parsePredicate($stateParams.sortNotification),
                            ascendingNotification: PaginationUtil.parseAscending($stateParams.sortNotification),
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        $translatePartialLoader.addPart('customerNotification');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Profile', function ($stateParams, Profile) {
                        return Profile.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'profile-notification',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('profile-voc.edit', {
                parent: 'profile-voc',
                url: '/{vOCId}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-voc-dialog.html',
                        controller: 'ProfileVOCDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('vOC');
                                return $translate.refresh();
                            }],
                            entity: ['VOC', function (VOC) {
                                return VOC.get({id: $stateParams.vOCId}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile-voc', null, {reload: 'profile-voc'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-promo-code.edit', {
                parent: 'profile-promo-code',
                url: '/{promoCodeId}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-promo-code-dialog.html',
                        controller: 'ProfilePromoCodeDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('promoCode');
                                $translatePartialLoader.addPart('promoCodeStatus');
                                return $translate.refresh();
                            }],
                            entity: ['PromoCode', function (PromoCode) {
                                return PromoCode.get({id: $stateParams.promoCodeId}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile-promo-code', null, {reload: 'profile-promo-code'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-notification.edit', {
                parent: 'profile-notification',
                url: '/{notificationId}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-notification-dialog.html',
                        controller: 'ProfileNotificationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customerNotification');
                                $translatePartialLoader.addPart('notificationStatus');
                                return $translate.refresh();
                            }],
                            entity: ['CustomerNotification', function (CustomerNotification) {
                                return CustomerNotification.get({id: $stateParams.notificationId}).$promise;
                            }],
                            languages: ['FirebaseToken', function (FirebaseToken) {
                                return FirebaseToken.getByProfile({profileId: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile-notification', null, {reload: 'profile-notification'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-notification.view', {
                parent: 'profile-notification',
                url: '/{notificationId}/view',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-notification-detail.html',
                        controller: 'ProfileNotificationDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customerNotification');
                                $translatePartialLoader.addPart('notificationStatus');
                                return $translate.refresh();
                            }],
                            entity: ['CustomerNotification', function (CustomerNotification) {
                                return CustomerNotification.get({id: $stateParams.notificationId}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile-notification', null, {reload: 'profile-notification'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-voc.new', {
                parent: 'profile-voc',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-voc-dialog.html',
                        controller: 'ProfileVOCDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('vOC');
                                return $translate.refresh();
                            }],
                            entity: function () {
                                return {
                                    content: null,
                                    createdBy: null,
                                    createdDate: null,
                                    customerId: $stateParams.customerId,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('profile-voc', null, {reload: 'profile-voc'});
                    }, function () {
                        $state.go('profile-voc');
                    });
                }]
            })
            .state('profile-promo-code.new', {
                parent: 'profile-promo-code',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-promo-code-dialog.html',
                        controller: 'ProfilePromoCodeDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('promoCode');
                                $translatePartialLoader.addPart('promoCodeStatus');
                                return $translate.refresh();
                            }],
                            entity: function () {
                                return {
                                    code: null,
                                    discountType: 'PERSONAL',
                                    discountValue: null,
                                    referrerRewardValue: null,
                                    status: 'ACTIVE',
                                    startDate: null,
                                    endDate: null,
                                    createdBy: null,
                                    createdDate: null,
                                    lastModifiedBy: null,
                                    lastModifiedDate: null,
                                    customerId: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('profile-promo-code', null, {reload: 'profile-promo-code'});
                    }, function () {
                        $state.go('profile-promo-code');
                    });
                }]
            })
            .state('profile-notification.new', {
                parent: 'profile-notification',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-notification-dialog.html',
                        controller: 'ProfileNotificationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customerNotification');
                                $translatePartialLoader.addPart('notificationStatus');
                                return $translate.refresh();
                            }],
                            entity: function () {
                                return {
                                    title: null,
                                    message: null,
                                    language: null,
                                    collapseKey: null,
                                    eventType: 'MESSAGE',
                                    url: null,
                                    readStatus: false,
                                    status: 'PENDING',
                                    deleted: false,
                                    pushDate: null,
                                    expiryDate: null,
                                    createdBy: null,
                                    createdDate: null,
                                    lastModifiedBy: null,
                                    lastModifiedDate: null,
                                    id: null
                                };
                            },
                            languages: ['FirebaseToken', function (FirebaseToken) {
                                return FirebaseToken.getByProfile({profileId: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile-notification', null, {reload: 'profile-notification'});
                    }, function () {
                        $state.go('profile-notification');
                    });
                }]
            })
            .state('profile-voc.delete', {
                parent: 'profile-voc',
                url: '/{vOCId}/delete',
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-voc-delete-dialog.html',
                        controller: 'ProfileVOCDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        data: {
                            authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                        },
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('vOC');
                                return $translate.refresh();
                            }],
                            entity: ['VOC', function(VOC) {
                                return VOC.get({id : $stateParams.vOCId}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('profile-voc', null, { reload: 'profile-voc' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-promo-code.delete', {
                parent: 'profile-promo-code',
                url: '/{promoCodeId}/delete',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-promo-code-delete-dialog.html',
                        controller: 'ProfilePromoCodeDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        data: {
                            authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                        },
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('promoCode');
                                return $translate.refresh();
                            }],
                            entity: ['PromoCode', function (PromoCode) {
                                return PromoCode.get({id: $stateParams.promoCodeId}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile-promo-code', null, {reload: 'profile-promo-code'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-notification.delete', {
                parent: 'profile-notification',
                url: '/{notificationId}/delete',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-notification-delete-dialog.html',
                        controller: 'ProfileNotificationDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        data: {
                            authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                        },
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customerNotification');
                                return $translate.refresh();
                            }],
                            entity: ['CustomerNotification', function (CustomerNotification) {
                                return CustomerNotification.get({id: $stateParams.notificationId}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile-notification', null, {reload: 'profile-notification'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
