(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PromoCodeHistoryDetailController', PromoCodeHistoryDetailController);

    PromoCodeHistoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PromoCodeHistory', 'Customer', 'PromoCode'];

    function PromoCodeHistoryDetailController($scope, $rootScope, $stateParams, previousState, entity, PromoCodeHistory, Customer, PromoCode) {
        var vm = this;

        vm.promoCodeHistory = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:promoCodeHistoryUpdate', function(event, result) {
            vm.promoCodeHistory = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
