(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('GiftDialogController', GiftDialogController);

    GiftDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Gift', 'GiftImage', 'GiftCategory', 'DataUtils', 'ImageStorage'];

    function GiftDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Gift, GiftImage, GiftCategory, DataUtils, ImageStorage) {
        var vm = this;

        vm.gift = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.clear = clear;
        vm.save = save;
        vm.giftimages = GiftImage.query();
        vm.giftcategories = GiftCategory.query();
        vm.giftImage = {};

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.gift.id !== null) {
                Gift.update(vm.gift, onSaveSuccess, onSaveError);
            } else {
                Gift.save(vm.gift, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bozorakaAdminApp:giftUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.setImage = function ($file, gift) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        gift.data = base64Data;
                        gift.dataContentType = $file.type;
                        gift.imageName = $file.name;
                    });
                });
            }
        };
    }
})();
