(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('GiftImageDialogController', GiftImageDialogController);

    GiftImageDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'GiftImage', 'ImageStorage', 'Gift'];

    function GiftImageDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, GiftImage, ImageStorage, Gift) {
        var vm = this;

        vm.giftImage = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.imagestorages = ImageStorage.query({filter: 'giftimage-is-null'});
        $q.all([vm.giftImage.$promise, vm.imagestorages.$promise]).then(function() {
            if (!vm.giftImage.imageStorageId) {
                return $q.reject();
            }
            return ImageStorage.get({id : vm.giftImage.imageStorageId}).$promise;
        }).then(function(imageStorage) {
            vm.imagestorages.push(imageStorage);
        });
        vm.gifts = Gift.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.giftImage.id !== null) {
                GiftImage.update(vm.giftImage, onSaveSuccess, onSaveError);
            } else {
                GiftImage.save(vm.giftImage, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:giftImageUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setImage = function ($file, giftImage) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        giftImage.image = base64Data;
                        giftImage.imageContentType = $file.type;
                    });
                });
            }
        };

    }
})();
