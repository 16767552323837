(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MarketRequestController', MarketRequestController);

    MarketRequestController.$inject = ['$http', '$scope', '$translate', '$state', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$interval', 'DateUtils', 'DataUtils', 'PendingRequest', 'MarketRequest', 'MarketRequestStorage', 'SellerUser'];

    function MarketRequestController($http, $scope, $translate, $state, ParseLinks, AlertService, paginationConstants, pagingParams, $interval, DateUtils, DataUtils, PendingRequest, MarketRequest, MarketRequestStorage, SellerUser) {
        var vm = this;

        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.activeTab = pagingParams.activeTab;
        vm.exportExcel = exportExcel;
        vm.disabledExcelButton = false;
        vm.activateTab = activateTab;
        vm.sellerId = pagingParams.sellerId;
        vm.filterBySeller = filterBySeller;
        loadNationalities();
        vm.getNationalityNameByCode = getNationalityNameByCode;

        vm.predicateNewReq = pagingParams.predicateNewReq;
        vm.reverseNewReq = pagingParams.ascendingNewReq;
        vm.transitionNewReq = transitionNewReq;
        vm.loadAllNewReq = loadAllNewReq;

        vm.predicatePendingReq = pagingParams.predicatePendingReq;
        vm.reversePendingReq = pagingParams.ascendingPendingReq;
        vm.transitionPendingReq = transitionPendingReq;
        vm.loadAllPendingReq = loadAllPendingReq;

        vm.dateDiff = DateUtils.transactionDateDifference;
        vm.getDeductedAmount = DataUtils.getDeductedAmount;
        vm.getSentAmount = DataUtils.getSentAmount;
        vm.selectAllOrders = selectAllOrders;
        vm.updateSelectedOrders = updateSelectedOrders;
        vm.newOrderIds = [];
        vm.selectedOrders = MarketRequestStorage.getSelectedOrders();
        if (!vm.selectedOrders) {
            vm.selectedOrders = [];
        }

        if (pagingParams.sellerId === 0 && !pagingParams.firstRun) {
            vm.sellerId = null;
        }
        getSellers();
        if (!pagingParams.firstRun) {
            loadAllNewReq();
            loadAllNewOrderIds();
        }
        if (vm.activeTab === 'tab_pending_1') {
            loadAllPendingReq();
        }


        function loadAllNewReq() {
            MarketRequest.query({
                sellerId: vm.sellerId,
                page: pagingParams.pageNewReq - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicateNewReq + ',' + (vm.reverseNewReq ? 'asc' : 'desc')];
                if (vm.predicateNewReq !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.linksNewReq = ParseLinks.parse(headers('link'));
                vm.totalItemsNewReq = headers('X-Total-Count');
                vm.queryCountNewReq = vm.totalItemsNewReq;
                vm.transactionsNewReq = data;
                vm.pageNewReq = pagingParams.pageNewReq;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionNewReq() {
            $state.transitionTo($state.$current, {
                pageNewReq: vm.pageNewReq,
                sortNewReq: vm.predicateNewReq + ',' + (vm.reverseNewReq ? 'asc' : 'desc'),
                pagePendingReq: vm.pagePendingReq,
                sortPendingReq: vm.predicatePendingReq + ',' + (vm.reversePendingReq ? 'asc' : 'desc'),
                activeTab: vm.activeTab,
                sellerId: vm.sellerId,
                firstRun: false
            });
        }

        function loadAllNewOrderIds() {
            MarketRequest.getAllNewOrderIds({sellerId: vm.sellerId}, onSuccess, onError);

            function onSuccess(data) {
                vm.newOrderIds = data;
                clearOldSelectedOrders();
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.newOrderIds = [];
            }
        }

        function loadAllPendingReq() {
            PendingRequest.query({
                page: pagingParams.pagePendingReq - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicatePendingReq + ',' + (vm.reversePendingReq ? 'asc' : 'desc')];
                if (vm.predicatePendingReq !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.linksPendingReq = ParseLinks.parse(headers('link'));
                vm.totalItemsPendingReq = headers('X-Total-Count');
                vm.queryCountPendingReq = vm.totalItemsPendingReq;
                vm.transactionsPendingReq = data;
                vm.pagePendingReq = pagingParams.pagePendingReq;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionPendingReq() {
            $state.transitionTo($state.$current, {
                pageNewReq: vm.pageNewReq,
                sortNewReq: vm.predicateNewReq + ',' + (vm.reverseNewReq ? 'asc' : 'desc'),
                pagePendingReq: vm.pagePendingReq,
                sortPendingReq: vm.predicatePendingReq + ',' + (vm.reversePendingReq ? 'asc' : 'desc'),
                activeTab: vm.activeTab
            });
        }

        function exportExcel() {
            vm.disabledExcelButton = true;
            var data = vm.selectedOrders;
            $http({
                url: 'api/new-market-requests-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/json'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                saveAs(blob, 'market-request-' + DateUtils.currentTimestamp() + '.xlsx');
                vm.disabledExcelButton = false;
            }).error(function () {
                vm.disabledExcelButton = false;
            });
        }

        function activateTab(tab) {
            vm.activeTab = tab;
            if (tab === 'tab_pending_1') {
                loadAllPendingReq();
            }
        }

        function selectAllOrders(event) {
            if (!event.target.checked) {
                vm.selectedOrders = [];
                MarketRequestStorage.setSelectedOrders(vm.selectedOrders);
            } else {
                vm.selectedOrders = [];
                vm.selectedOrders = vm.newOrderIds.slice();
                MarketRequestStorage.setSelectedOrders(vm.selectedOrders);
            }
        }

        function updateSelectedOrders(event) {
            vm.selectedOrders = MarketRequestStorage.getSelectedOrders();
            if (!vm.selectedOrders) {
                vm.selectedOrders = [];
            }
            if (event.target.checked) {
                if (vm.selectedOrders.indexOf(parseInt(event.target.value)) < 0) {
                    vm.selectedOrders.push(parseInt(event.target.value));
                }
            } else {
                if (vm.selectedOrders.indexOf(parseInt(event.target.value)) > -1) {
                    vm.selectedOrders.splice(vm.selectedOrders.indexOf(parseInt(event.target.value)), 1);
                }
            }
            MarketRequestStorage.setSelectedOrders(vm.selectedOrders);
        }

        function getSellers() {
            SellerUser.query(onSuccess);

            function onSuccess(data) {
                vm.sellers = data;
                if (vm.sellers.length > 1) {
                    vm.sellers.unshift({id:null, fullName: "All"});
                }
            }
        }

        function filterBySeller() {
            vm.selectedOrders = [];
            MarketRequestStorage.setSelectedOrders(vm.selectedOrders);
            loadAllNewReq();
            loadAllNewOrderIds();
        }

        function clearOldSelectedOrders() {
            var newSelectedOrders = [];
            for (var i = 0; i < vm.selectedOrders.length; i++) {
                if (vm.newOrderIds.indexOf(vm.selectedOrders[i]) > -1) {
                    newSelectedOrders.push(vm.selectedOrders[i]);
                }
            }
            vm.selectedOrders = newSelectedOrders;
            MarketRequestStorage.setSelectedOrders(vm.selectedOrders);
        }
        function loadNationalities() {
            $http.get('i18n/' + $translate.use() + '/nationalityList.json').success(function (nationalities) {
                vm.nationalities = nationalities;
            });
        }

        function getNationalityNameByCode(code) {
            if (code === null) {
                return "";
            }
            var nationality = vm.nationalities.filter(function (a) {
                return a.code === code;
            });
            return nationality[0].name;
        }
    }

})();
