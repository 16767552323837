(function () {
    'use strict';

    angular.module('bozorakaAdminApp')
        .directive('transactionGateway', transactionGateway);

    var gateways = {
        'yandex': {
            name: 'Yandex',
            style: 'btn yellow-mint btn-xs'
        },
        'paynet': {
            name: 'Paynet',
            style: 'btn btn-xs green-seagreen'
        },
        'payform': {
            name: 'Payform',
            style: 'btn btn-xs yellow-casablanca'
        },
        'voip': {
            name: 'Voip',
            style: 'btn purple-sharp btn-xs'
        },
        'voip_uztel': {
            name: 'Voip UzTel',
            style: 'btn purple-sharp btn-xs'
        },
        'this_bonus': {
            name: 'This bonus',
            style: 'btn purple-sharp btn-xs'
        },
        'mongo_or_010pay': {
            name: 'Mongo or 010pay',
            style: 'btn purple-sharp btn-xs'
        },
        'manual_mobile_data': {
            name: 'Manual mobile data',
            style: 'btn yellow-crusta btn-xs'
        },
        'this': {
            name: 'This',
            style: 'btn purple-sharp btn-xs'
        },
        'mango_card_phone': {
            name: 'Mango card phone',
            style: 'btn purple-sharp btn-xs'
        },
        'mango_intl_card': {
            name: 'Mango intl card',
            style: 'btn purple-sharp btn-xs'
        },
        'mango_other_card': {
            name: 'Mango other card',
            style: 'btn purple-sharp btn-xs'
        },
        'the_plus': {
            name: 'The plus',
            style: 'btn purple-sharp btn-xs'
        },
        '00301': {
            name: '00301',
            style: 'btn purple-sharp btn-xs'
        },
        'market': {
            name: 'Market',
            style: 'btn yellow-gold btn-xs'
        },
        'upay': {
            name: 'Upay',
            style: 'btn purple-sharp btn-xs'
        },
        undefined: {
            name: 'Undefined',
            style: 'btn btn-xs #a6a6a6'
        }
    };

    function transactionGateway() {
        var directive = {
            restrict: 'EA',
            controller: TransactionGatewayController,
            controllerAs: 'vm',
            bindToController: true,
            template: '<button type="button" class="{{vm.style}}" style="pointer-events: none">' +
                '{{vm.name}}' +
                '</button>',
            scope: {
                gateway: '<'
            }
        };

        return directive;
    }

    function TransactionGatewayController() {
        var vm = this;

        setGateway();

        function setGateway() {
            var gateway = gateways[vm.gateway];
            if (gateway === undefined) {
                if (!vm.gateway) {
                    gateway = gateways[undefined];
                } else {
                    gateway = {
                        name: vm.gateway,
                        style: 'btn btn-xs red-mint'
                    }
                }
            }
            vm.name = gateway.name;
            vm.style = gateway.style;
        }
    }
})();
