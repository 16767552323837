(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('OrderTrackingDetailController', OrderTrackingDetailController);

    OrderTrackingDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'OrderTracking', 'Delivery'];

    function OrderTrackingDetailController($scope, $rootScope, $stateParams, previousState, entity, OrderTracking, Delivery) {
        var vm = this;

        vm.orderTracking = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:orderTrackingUpdate', function(event, result) {
            vm.orderTracking = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
