(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('InventoryEditHistoryController', InventoryEditHistoryController);

    InventoryEditHistoryController.$inject = ['$state', 'ProductEditInventoryHistory', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$stateParams', 'User', '$filter', '$rootScope'];

    function InventoryEditHistoryController($state, ProductEditInventoryHistory, ParseLinks, AlertService, paginationConstants, pagingParams, $stateParams, User, $filter, $rootScope) {

        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.search = search;
        vm.clear = clear;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.productId = $stateParams.id;
        vm.variantId = $stateParams.variantId;
        vm.usersList = $stateParams.usersList;
        vm.searchQuery = {
            field: pagingParams.field,
            valueString1: pagingParams.valueString1,
            users: pagingParams.users,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };
        vm.loadAll = loadAll;

        if (pagingParams.firstRun) {
            getAllUsers();
        } else {
            loadAll();
        }
        initDatePicker();

        function loadAll() {
            ProductEditInventoryHistory.query({
                valueString1: !vm.searchQuery.valueString1 ? 'UNKNOWN' : vm.searchQuery.valueString1,
                users: !vm.searchQuery.users ? null : vm.searchQuery.users,
                field: !vm.searchQuery.field ? 'UNKNOWN' : vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate === '' ? null : vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate === '' ? null : vm.searchQuery.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                firstRun: false,
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.inventoryHistories = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                valueString1: !vm.searchQuery.valueString1 ? 'UNKNOWN' : vm.searchQuery.valueString1,
                users: !vm.searchQuery.users ? null : vm.searchQuery.users,
                field: !vm.searchQuery.field ? '' : vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate === '' ? null : vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate === '' ? null : vm.searchQuery.toDate,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                firstRun: false,
                usersList: vm.usersList
            });
        }

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.variantId = $stateParams.variantId;
            vm.searchQuery = {
                field: null,
                valueString1: null,
                users: vm.usersList,
                fromDate: null,
                toDate: null
            };
            initDatePicker();
            vm.transition();
            $('#adjustedBy_field').multiselect('updateButtonText');
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#adjustedBy_field').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('bozorakaAdminApp.inventoryHistory.all'),
                nonSelectedText: translate('bozorakaAdminApp.inventoryHistory.noneSelected'),
                allSelectedText: translate('bozorakaAdminApp.inventoryHistory.allSelected'),
                maxHeight: 300
            });

        }, 0);

        function translateMultiSelect() {
            all = translate('bozorakaAdminApp.inventoryHistory.all');
            noneSelected = translate('bozorakaAdminApp.inventoryHistory.noneSelected');
            allSelected = translate('bozorakaAdminApp.inventoryHistory.allSelected');
        }

        function getAllUsers() {
            User.getUsersList({}, onSuccess, onError);

            function onSuccess(data) {

                getLogins(data);
                setTimeout(function () {
                    $('#adjustedBy_field').multiselect(
                        'setOptions',
                        {
                            selectAllText: all,
                            nonSelectedText: noneSelected,
                            allSelectedText: allSelected
                        });
                    $('#adjustedBy_field').multiselect('rebuild');
                }, 0);
                loadAll();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getLogins(users) {
            vm.usersList = [];
            users.forEach(function (user) {
                vm.usersList.push(user.login);
            });
            vm.searchQuery.users = vm.usersList;
        }

        $rootScope.$on('$translateChangeSuccess', function () {
            translateMultiSelect();

            $('#adjustedBy_field').multiselect(
                'setOptions',
                {
                    selectAllText: all,
                    nonSelectedText: noneSelected,
                    allSelectedText: allSelected
                });
            $('#adjustedBy_field').multiselect('rebuild');
        });
    }
})();
