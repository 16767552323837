(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerBankDialogController', PartnerBankDialogController);

    PartnerBankDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'AlertService', 'entity', 'Partner', 'DataUtils', 'VirtualBankAvailability'];

    function PartnerBankDialogController ($timeout, $scope, $stateParams, $uibModalInstance, AlertService, entity, Partner, DataUtils, VirtualBankAvailability) {
        var vm = this;

        vm.partner = entity;
        vm.banks = DataUtils.getBanks();

        vm.clear = clear;
        vm.save = save;
        getAvailableBanks();

        function getAvailableBanks() {
            VirtualBankAvailability.get({}, function (data) {
                var result = data.result;
                if (result == null) {
                    vm.banks = [];
                    return
                }

                var availableBanks = [{
                    id: 0,
                    name: 'No bank'
                }];

                for (var i = 0; i < vm.banks.length; i++) {
                    var bank = vm.banks[i];
                    for (var j = 0; j < result.length; j++) {
                        var bankStatus = result[j];
                        if (bankStatus.bank_id === parseInt(bank.id) && bankStatus.available) {
                            bank.id = parseInt(bank.id);
                            availableBanks.push(bank);
                            break;
                        }
                    }
                }
                vm.banks = availableBanks;
            });
        }

        function save() {
            vm.isSaving = true;
            Partner.update(vm.partner, onSaveSuccess, onSaveError);
        }
        function onSaveSuccess (data) {
            AlertService.success('Virtual bank updated');
            $scope.$emit('bozorakaAdminApp:partnerUpdate', data);
            $uibModalInstance.close(data);
            vm.isSaving = false;
        }

        function onSaveError () {
            AlertService.error('Virtual bank update error');
            vm.isSaving = false;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
