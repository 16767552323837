(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('Postal', Postal);

    Postal.$inject = ['$resource'];

    function Postal ($resource) {
        var resourceUrl =  'api/site-pages/postal';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
