(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('CoupangOrderInfoDialogController', CoupangOrderInfoDialogController);

    CoupangOrderInfoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'CoupangOrderInfo', 'PartnerOrderInfo'];

    function CoupangOrderInfoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, CoupangOrderInfo, PartnerOrderInfo) {
        var vm = this;

        vm.coupangOrderInfo = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.partnerorderinfos = PartnerOrderInfo.query({filter: 'coupangorderinfo-is-null'});
        $q.all([vm.coupangOrderInfo.$promise, vm.partnerorderinfos.$promise]).then(function() {
            if (!vm.coupangOrderInfo.partnerOrderInfoId) {
                return $q.reject();
            }
            return PartnerOrderInfo.get({id : vm.coupangOrderInfo.partnerOrderInfoId}).$promise;
        }).then(function(partnerOrderInfo) {
            vm.partnerorderinfos.push(partnerOrderInfo);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.coupangOrderInfo.id !== null) {
                CoupangOrderInfo.update(vm.coupangOrderInfo, onSaveSuccess, onSaveError);
            } else {
                CoupangOrderInfo.save(vm.coupangOrderInfo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:coupangOrderInfoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.orderCreatedDate = false;
        vm.datePickerOpenStatus.orderPaidDate = false;
        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
