(function () {
    'use strict';

    angular
            .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('stats-daily', {
                parent: 'entity',
                url: '/stats-daily',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST'],
                    pageTitle: 'global.menu.stats.daily'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stats-daily/stats-daily.html',
                        controller: 'StatsDailyController',
                        controllerAs: 'vm'
                    }
                },

                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('statsMerchants');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
