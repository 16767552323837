(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('ReactivateUser', ReactivateUser);

    ReactivateUser.$inject = ['$resource'];

    function ReactivateUser ($resource) {
        var resourceUrl =  'api/reactivate-user/:month/run';

        return $resource(resourceUrl, {"month": "@month"}, {
            'runScript': {
                method:'PUT',
                isArray: true
            },
            'get':{
                method: 'GET',
                isArray: true,
                url: 'api/reactivate-users'
            }
        });
    }
})();
