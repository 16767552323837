/**
 * Created by Gayratjon Rayimjonov on 12/26/2017.
 */

(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .factory('SellerHomePage', SellerHomePage)
        .factory('ProductStorage', ProductStorage)
        .factory('CategoryProductSort', CategoryProductSort);

    SellerHomePage.$inject = ['$resource'];

    function SellerHomePage($resource) {
        var service = $resource('api/seller-home-page/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getProductSortOrders': {
                method: 'GET',
                isArray: true,
                url: 'api/seller/products/sort-orders'
            }
        });

        return service;
    }

    ProductStorage.$inject = ['$resource', '$localStorage'];

    function ProductStorage($resource, $localStorage) {
        return {
            setProduct: function (product) {
                $localStorage.replaceProduct = product;
            },
            getProduct: function () {
                return $localStorage.replaceProduct;
            },
            setProductSortOrders: function (productSortOrders) {
                $localStorage.productSortOrders = productSortOrders;
            },
            getProductSortOrders: function () {
                return $localStorage.productSortOrders;
            }

        }
    }

    CategoryProductSort.$inject = ['$resource'];

    function CategoryProductSort($resource) {
        var resourceUrl =  'api/update-product-positions';

        return $resource(resourceUrl, {}, {
            'update': {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        });
    }


})();
