(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('StatsByCardsController', StatsByCardsController);

    StatsByCardsController.$inject = ['StatsCard', '$http', 'DateUtils'];

    function StatsByCardsController(StatsCard, $http, DateUtils) {
        var vm = this;
        vm.clear = clear;
        vm.search = search;
        vm.excel = excel;
        var fromDate = new Date(moment());
        vm.searchQuery = {
            fromDate: dateConvert(fromDate),
            toDate: dateConvert(new Date(fromDate.setDate(fromDate.getDate() + 1))),
            merchants: ['THIS', 'MANGO', 'HIGH-KICK',
                'ХОРОШО', '00301', 'THE+', 'HIMALAYA', 'KHAN', 'JUMBO', 'NEW EASY', 'BANGKOK', 'BAN', 'SUPER WARUNG', 'SUPER STRONG', 'TNT',
                'VeryGoodChina', 'TIME', 'SUN (red)', 'SUN']
        };

        load();
        setTimeout(function () {

            $('#cardType').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                maxHeight: 300
            });

        }, 0);

        initDatePicker();

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            load();
        }

        function clear() {
            vm.searchQuery = {
                merchants: null,
                fromDate: null,
                toDate: null,
                serviceTypes: null,
                transactionModes: null,
                valueInt1: null,
                valueLong1: null
            };
            $('#cardType').multiselect('deselectAll', false);
            $('#cardType').multiselect('updateButtonText');

            initDatePicker();
        }

        function load() {
            StatsCard.query(vm.searchQuery, onSuccess);

            function onSuccess(data, headers) {
                vm.statsCards = data;
            }
        }

        function dateConvert(date) {
            if (date != undefined) {
                var month = date.getMonth() + 1;
                var dateMonth = date.getDate();

                return setZero(month) + '/' + setZero(dateMonth) + '/' + date.getFullYear();
            } else {
                return '';
            }
        }

        function setZero(param) {
            if (param < 10) {
                return '0' + param;
            } else {
                return param;
            }
        }

        function excel() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = new Date($(this).val());
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = new Date($(this).val());
                }
            });
            vm.searchQuery.fromDate = DateUtils.converterDateFormat(vm.searchQuery.fromDate);
            vm.searchQuery.toDate = DateUtils.converterDateFormat(vm.searchQuery.toDate);

            $http({
                url: 'api/stats-cards-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: vm.searchQuery, //this is your json data string
                headers: {
                    'Content-type': 'application/json'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                saveAs(blob, 'statistics-by-cards-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {

            });
        }
    }
})();
