(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('CertificateImageDialogController', CertificateImageDialogController);

    CertificateImageDialogController.$inject = ['$timeout', '$scope', 'DataUtils', '$stateParams', '$uibModalInstance', '$q', 'entity', 'CertificateImage', 'ImageStorage', 'CertificateCategory'];

    function CertificateImageDialogController ($timeout, $scope, DataUtils, $stateParams, $uibModalInstance, $q, entity, CertificateImage, ImageStorage, CertificateCategory) {
        var vm = this;

        vm.certificateImage = entity;
        vm.clear = clear;
        vm.save = save;
        vm.certificateCategories = CertificateCategory.query();
        vm.imagestorages = ImageStorage.query({filter: 'certificateimage-is-null'});
        $q.all([vm.certificateImage.$promise, vm.imagestorages.$promise]).then(function() {
            if (!vm.certificateImage.imageStorageId) {
                return $q.reject();
            }
            return ImageStorage.get({id : vm.certificateImage.imageStorageId}).$promise;
        }).then(function(imageStorage) {
            vm.imagestorages.push(imageStorage);
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.certificateImage.id !== null) {
                CertificateImage.update(vm.certificateImage, onSaveSuccess, onSaveError);
            } else {
                CertificateImage.save(vm.certificateImage, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:certificateImageUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.setData = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        vm.certificateImage.imageStorageName = $file.name;
                        vm.certificateImage.data = base64Data;
                        vm.certificateImage.dataContentType = $file.type;

                    });
                });
            }
        };

    }
})();
