(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MarketStatsDailyController', MarketStatsDailyController);

    MarketStatsDailyController.$inject = ['$filter', '$http', 'DateUtils', 'MarketStatsDaily', 'AlertService', 'StatsService', 'StatsPerTime', 'DataUtils'];

    function MarketStatsDailyController($filter, $http, DateUtils, MarketStatsDaily, AlertService, StatsService, StatsPerTime, DataUtils) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.search = search;
        vm.getProductName = getProductName;
        vm.exportExcelByQuantityAndAmount = exportExcelByQuantityAndAmount;
        vm.exportExcelByUser = exportExcelByUser;
        vm.sortStatsByQuantity = sortStatsByQuantity;
        vm.sortStatsByAmount = sortStatsByAmount;
        vm.sortStatsByUserOrderByAmount = sortStatsByUserOrderByAmount;
        vm.sortStatsByUserOrderByQuantity = sortStatsByUserOrderByQuantity;
        vm.statsByQuantitySort = 'desc';
        vm.statsByAmountSort = 'desc';
        vm.statsByUserAmountSort = 'desc';
        vm.statsByUserQuantitySort = 'desc';
        vm.getCurrencySymbolForClient = DataUtils.getCurrencySymbolForClient;
        vm.searchQuery = {
            fromDate: DateUtils.currentDate(),
            clients: ['b2b', 'bozoraka', 'mobpay'],
            sort: 'desc'
        };
        var hours = [];
        var amounts = [];
        var counts = [];
        search();

        var options = {
            onSelectedDateChanged: function (event, date) {
                vm.searchQuery = {
                    fromDate: DateUtils.converterDateFormat(date),
                    clients: vm.searchQuery.clients,
                    sort: vm.searchQuery.sort
                };
            }
        };

        function search() {
            MarketStatsDaily.query(vm.searchQuery, onSuccess, onError);
            MarketStatsDaily.getByMerchant(vm.searchQuery, onSuccessService, onError);
            vm.searchQuery['sort'] = vm.statsByQuantitySort;
            MarketStatsDaily.getByQuantity(vm.searchQuery, onSuccessByQuantity, onError);
            vm.searchQuery['sort'] = vm.statsByAmountSort;
            MarketStatsDaily.getByAmount(vm.searchQuery, onSuccessByAmount, onError);
            vm.searchQuery['sort'] = vm.statsByUserAmountSort;
            vm.searchQuery['orderBy'] = 'amount';
            MarketStatsDaily.getByUser(vm.searchQuery, onSuccessByUser, onError);
            MarketStatsDaily.getBySales(vm.searchQuery, onSuccessBySales, onError);
        }

        function onSuccess(data, headers) {
            vm.stats = data;
        }

        function onSuccessService(data, headers) {
            makeAppClientsPieChart(data);
        }

        function onSuccessByQuantity(data, headers) {
            vm.statsByQuantity = data;
        }

        function onSuccessByAmount(data, headers) {
            vm.statsByAmount = data;
        }

        function onSuccessByUser(data, headers) {
            vm.statsByUser = data;
        }

        function onSuccessBySales(data, headers) {
            $.when(prepareData(data)).done(function () {
                highCharts(data);
            });
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        $('#paginator').datepaginator(options);

        function makeAppClientsPieChart(data) {
            var chart_by_app_clients = AmCharts.makeChart("piechart_for_app_clients", {
                "type": "pie",
                "startDuration": 0,
                "theme": "light",
                "addClassNames": true,
                "legend": {
                    "position": "right",
                    "marginRight": 100,
                    "autoMargins": false,
                    "valueWidth": 90
                },
                "innerRadius": "30%",
                "defs": {
                    "filter": [{
                        "id": "shadow",
                        "width": "200%",
                        "height": "200%",
                        "feOffset": {
                            "result": "offOut",
                            "in": "SourceAlpha",
                            "dx": 0,
                            "dy": 0
                        },
                        "feGaussianBlur": {
                            "result": "blurOut",
                            "in": "offOut",
                            "stdDeviation": 5
                        },
                        "feBlend": {
                            "in": "SourceGraphic",
                            "in2": "blurOut",
                            "mode": "normal"
                        }
                    }]
                },
                "dataProvider": data,
                "valueField": "orderAmount",
                "titleField": "appClient",
                "colorField": "color",
                "export": {
                    "enabled": true
                }
            });

            chart_by_app_clients.addListener("init", handleInit);

            chart_by_app_clients.addListener("rollOverSlice", function (e) {
                handleRollOver(e);
            });
        }

        function highCharts() {
            Highcharts.chart('highchart', {
                title: {
                    text: 'Daily ORDERS and SALES'
                },
                xAxis: [{
                    categories: hours,
                    crosshair: true
                }],
                yAxis: [{ // Primary yAxis
                    labels: {
                        format: '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[5]
                        }
                    },
                    title: {
                        text: 'ORDERS',
                        style: {
                            color: Highcharts.getOptions().colors[5]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: 'SALES',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: Highcharts.valueOf().value,
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'center',
                    x: 120,
                    verticalAlign: 'top',
                    y: 100,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
                },
                series: [
                    {
                        name: 'Sales',
                        type: 'column',
                        yAxis: 1,
                        data: amounts,
                        tooltip: {
                            valueSuffix: ''
                        }

                    },
                    {
                        name: 'Orders',
                        type: 'spline',
                        color: 'red',
                        data: counts,
                        tooltip: {
                            valueSuffix: ''
                        }
                    }
                ]
            });
        }

        function handleInit() {
            chart1.legend.addListener("rollOverItem", handleRollOver);
        }

        function handleRollOver(e) {
            var wedge = e.dataItem.wedge.node;
            wedge.parentNode.appendChild(wedge);
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#customerClient').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics.all'),
                nonSelectedText: translate('statistics.noneSelected'),
                allSelectedText: translate('statistics.allSelected')
            });

        }, 0);

        function translateMultiSelect() {
            all = translate('statistics.all');
            noneSelected = translate('statistics.noneSelected');
            allSelected = translate('statistics.allSelected');
        }

        function sortStatsByQuantity() {
            if (vm.statsByQuantitySort === 'desc') {
                vm.statsByQuantitySort = 'asc';
            } else {
                vm.statsByQuantitySort = 'desc';
            }
            vm.searchQuery['sort'] = vm.statsByQuantitySort;
            MarketStatsDaily.getByQuantity(vm.searchQuery, onSuccessByQuantity, onError);
        }

        function sortStatsByAmount() {
            if (vm.statsByAmountSort === 'desc') {
                vm.statsByAmountSort = 'asc';
            } else {
                vm.statsByAmountSort = 'desc';
            }
            vm.searchQuery['sort'] = vm.statsByAmountSort;
            MarketStatsDaily.getByAmount(vm.searchQuery, onSuccessByAmount, onError);
        }

        function sortStatsByUserOrderByAmount() {
            if (vm.statsByUserAmountSort === 'desc') {
                vm.statsByUserAmountSort = 'asc';
            } else {
                vm.statsByUserAmountSort = 'desc';
            }
            vm.searchQuery['sort'] = vm.statsByUserAmountSort;
            vm.searchQuery['orderBy'] = 'amount';
            MarketStatsDaily.getByUser(vm.searchQuery, onSuccessByUser, onError);
        }

        function sortStatsByUserOrderByQuantity() {
            if (vm.statsByUserQuantitySort === 'desc') {
                vm.statsByUserQuantitySort = 'asc';
            } else {
                vm.statsByUserQuantitySort = 'desc';
            }
            vm.searchQuery['sort'] = vm.statsByUserQuantitySort;
            vm.searchQuery['orderBy'] = 'quantity';
            MarketStatsDaily.getByUser(vm.searchQuery, onSuccessByUser, onError);
        }

        function getProductName(productName, productVariantName) {
            if (productVariantName === null) {
                return productName;
            }
            return productName + "[" + productVariantName + "]";
        }

        function exportExcelByQuantityAndAmount() {
            var data = vm.searchQuery;
            $http({
                url: 'api/market-stats/excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/json'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                saveAs(blob, 'market-stats-daily-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {
            });
        }

        function exportExcelByUser() {
            var data = vm.searchQuery;
            $http({
                url: 'api/market-stats/customer/excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/json'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                saveAs(blob, 'market-stats-daily-by-user-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {
            });
        }

        function prepareData(data) {
            hours = [];
            amounts = [];
            counts = [];
            for (var i = 0; i < data.length; i++) {
                hours.push(data[i].timeSlot);
                amounts.push(data[i].amountOrder);
                counts.push(data[i].orderCounts);
            }
        }
    }
})();
