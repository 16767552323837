(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('VOCDetailController', VOCDetailController);

    VOCDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'VOC', 'Customer'];

    function VOCDetailController($scope, $rootScope, $stateParams, previousState, entity, VOC, Customer) {
        var vm = this;

        vm.vOC = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:vOCUpdate', function(event, result) {
            vm.vOC = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
