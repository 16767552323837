(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductOriginDeleteController',ProductOriginDeleteController);

    ProductOriginDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProductOrigin'];

    function ProductOriginDeleteController($uibModalInstance, entity, ProductOrigin) {
        var vm = this;

        vm.productOrigin = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ProductOrigin.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
