(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('ProductVariant', ProductVariant)
        .factory('ProductVariantGenerate', ProductVariantGenerate);

    ProductVariant.$inject = ['$resource'];

    function ProductVariant ($resource) {
        var resourceUrl =  'api/product-variants/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'findByProductId': {
                method: 'GET',
                url: 'api/products/:id/variants',
                isArray: true
            },
            'update': { method:'PUT' },
            'updateList': {
                method:'PUT' ,
                url: 'api/product-variants/update'
            }
        });
    }

    ProductVariantGenerate.$inject = ['$resource'];

    function ProductVariantGenerate ($resource) {
        var resourceUrl = 'api/products/:productId/variants';
        return $resource(resourceUrl, {"productId": "@productId"}, {
            'generate': {method: 'POST'}
        });
    }
})();
