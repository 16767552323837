(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('CertificateImageDetailController', CertificateImageDetailController);

    CertificateImageDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CertificateImage', 'ImageStorage'];

    function CertificateImageDetailController($scope, $rootScope, $stateParams, previousState, entity, CertificateImage, ImageStorage) {
        var vm = this;

        vm.certificateImage = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:certificateImageUpdate', function(event, result) {
            vm.certificateImage = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
