(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductPriceDialogController', ProductPriceDialogController);

    ProductPriceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DepoService', 'TieredDiscount', 'entity', 'productId', 'variantId', 'purchasePrice', 'retailPrice', 'salePrice', 'salePercent', 'isSale', 'vatIncluded', 'name', 'variantName', 'purchasingAgentId', 'expirationDate', 'minInventoryQuantity', 'partnerMinInventoryQuantity', 'minOrderQuantity', 'partnerMinOrderQuantity'];

    function ProductPriceDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, DepoService, TieredDiscount, entity, productId, variantId, purchasePrice, retailPrice, salePrice, salePercent, isSale, vatIncluded, name, variantName, purchasingAgentId, expirationDate, minInventoryQuantity, partnerMinInventoryQuantity, minOrderQuantity, partnerMinOrderQuantity) {
        var vm = this;

        vm.simplifiedProductTiers = entity;
        vm.name = name;
        vm.variantName = variantName.trim();
        vm.purchasePrice = purchasePrice;
        vm.retailPrice = retailPrice;
        vm.salePrice = salePrice;
        vm.salePercent = salePercent;
        vm.isSale = isSale;
        vm.vatIncluded = vatIncluded;
        vm.productId = productId;
        vm.productVariantId = variantId;
        vm.purchasingAgentId = purchasingAgentId;
        vm.expirationDate = expirationDate;
        vm.minInventoryQuantity = minInventoryQuantity;
        vm.partnerMinInventoryQuantity = partnerMinInventoryQuantity;
        vm.minOrderQuantity = minOrderQuantity;
        vm.partnerMinOrderQuantity = partnerMinOrderQuantity;
        vm.save = save;
        vm.clear = clear;
        vm.hasVariantName = hasVariantName;
        vm.calculateProfitMargin = calculateProfitMargin;
        vm.calculateSalePercent = calculateSalePercent;
        vm.collapse = collapse;
        vm.B2Bcollapse = B2Bcollapse;
        vm.checkTieredDiscount = checkTieredDiscount;
        vm.deleteTieredDiscount = deleteTieredDiscount;
        vm.updateTieredDiscounts = updateTieredDiscounts;
        vm.tieredDiscounts = [];
        vm.invalidRange = invalidRange;

        getTieredDiscounts();

        function hasVariantName() {
            return vm.variantName !== null && vm.variantName !== undefined && vm.variantName !== "";
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            updateTieredDiscounts();
            vm.isSaving = true;
            var product = {
                "id": vm.productId,
                "variantId": vm.productVariantId,
                "purchasingAgentId": vm.purchasingAgentId,
                "expirationDate": vm.expirationDate,
                "minInventoryQuantity": vm.minInventoryQuantity,
                "partnerMinInventoryQuantity": vm.partnerMinInventoryQuantity,
                "minOrderQuantity": vm.minOrderQuantity,
                "partnerMinOrderQuantity": vm.partnerMinOrderQuantity,
                "retailPrice": vm.retailPrice,
                "salePrice": vm.salePrice,
                "salePercent": vm.salePercent,
                "b2bPrice": vm.simplifiedProductTiers
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);

            function onSaveSuccess() {
                $uibModalInstance.close();
            }

            function onSaveError() {
            }

            vm.isSaving = false;
        }

        function calculateProfitMargin() {
            if (vm.isSale) {
                if (vm.vatIncluded) {
                    return (((vm.salePrice - vm.purchasePrice) / vm.salePrice) / 1.1) * 100;
                }
                return ((vm.salePrice - vm.purchasePrice) / vm.salePrice) * 100;
            } else {
                if (vm.vatIncluded) {
                    return (((vm.retailPrice - vm.purchasePrice) / vm.retailPrice) / 1.1) * 100;
                }
                return ((vm.retailPrice - vm.purchasePrice) / vm.retailPrice) * 100;
            }
        }
        function calculateSalePercent() {
            vm.salePrice = Math.round(vm.retailPrice * (100 - vm.salePercent) / 100);
        }

        function getTieredDiscounts() {
            if (vm.productId === null) return;
            TieredDiscount.getByProduct({productId: vm.productId}, onSuccess);

            function onSuccess(data) {
                vm.tieredDiscounts = data;
                addEmptyTieredDiscount("B2C");
                addEmptyTieredDiscount("B2B");
            }
        }

        function addEmptyTieredDiscount(clientType) {
            var emptyTieredDiscount = {
                id: null,
                clientType: clientType,
                fromQuantity: null,
                toQuantity: null,
                discount: null,
                visibleState: 'ACTIVE',
                productId: vm.productId,
                last: true
            }
            vm.tieredDiscounts.push(emptyTieredDiscount);
        }

        function checkTieredDiscount(tieredDiscount) {
            invalidRange();
            if (tieredDiscount.id === null && tieredDiscount.last === true && tieredDiscount.fromQuantity !== null && tieredDiscount.toQuantity !== null && tieredDiscount.discount !== null) {
                tieredDiscount.last = false;
                addEmptyTieredDiscount(tieredDiscount.clientType);
            }

        }
        function deleteTieredDiscount(tieredDiscount) {
            var index = vm.tieredDiscounts.indexOf(tieredDiscount);
            if (index > -1) {
                vm.tieredDiscounts.splice(index, 1);
            }
            TieredDiscount.delete(tieredDiscount);
        }

        function updateTieredDiscounts() {
            var requestCount = 0;
            var succesCount = 0;
            for (var i = 0; i < vm.tieredDiscounts.length; i++) {
                var tieredDiscount = vm.tieredDiscounts[i];
                if (tieredDiscount.id === null) {
                    if (tieredDiscount.fromQuantity !== null && tieredDiscount.toQuantity !== null && tieredDiscount.discount !== null) {
                        requestCount++;
                        TieredDiscount.save(tieredDiscount, onSuccess);
                    }
                } else {
                    requestCount++;
                    TieredDiscount.update(tieredDiscount, onSuccess);
                }
            }

            function onSuccess() {
                succesCount++;
                if (succesCount === requestCount) {
                    getTieredDiscounts();
                    AlertService.success("The tieredDiscounts data has been saved");
                }
            }
        }

        function invalidRange() {
            var clonedObjArray = vm.tieredDiscounts.filter(function (tieredDiscount) {
                return tieredDiscount.fromQuantity !== null && tieredDiscount.clientType === 'B2C';
            }).sort(function (a, b) {
                return a.fromQuantity - b.fromQuantity;
            });

            for (var i = 1; i < clonedObjArray.length; i++) {
                var tieredDiscount0 = clonedObjArray[i - 1];
                var tieredDiscount1 = clonedObjArray[i];
                if (tieredDiscount1.fromQuantity !== tieredDiscount0.toQuantity) {
                    vm.invalidParams = true;
                    return;
                }
            }
            var clonedObjArrayB2B = vm.tieredDiscounts.filter(function (tieredDiscount) {
                return tieredDiscount.fromQuantity !== null && tieredDiscount.clientType === 'B2B';
            }).sort(function (a, b) {
                return a.fromQuantity - b.fromQuantity;
            });

            for (var k = 1; k < clonedObjArrayB2B.length; k++) {
                var tieredDiscountB2B0 = clonedObjArrayB2B[k - 1];
                var tieredDiscountB2B1 = clonedObjArrayB2B[k];
                if (tieredDiscountB2B1.fromQuantity !== tieredDiscountB2B0.toQuantity) {
                    vm.invalidParams = true;
                    console.log(vm.invalidParams);
                    return;
                }
            }
            vm.invalidParams = false;
        }

        function collapse() {
            vm.shouldCollapse = !vm.shouldCollapse;
        }

        function B2Bcollapse() {
            vm.shouldB2BCollapse = !vm.shouldB2BCollapse;
        }
    }
})();
