(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('order-info-detail', {
            parent: 'entity',
            url: '/order-info/{id}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY','ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER', 'ROLE_WAREHOUSE_MANAGER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/order-info/order-info-detail.html',
                    controller: 'OrderInfoDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('orderInfo');
                    $translatePartialLoader.addPart('orderStatus');
                    $translatePartialLoader.addPart('transaction');
                    $translatePartialLoader.addPart('courier');
                    $translatePartialLoader.addPart('orderTracking');
                    $translatePartialLoader.addPart('delivery');

                    return $translate.refresh();
                }],
                entity: ['$translate', '$stateParams', 'OrderInfo', function($translate, $stateParams, OrderInfo) {
                    return OrderInfo({lang: $translate.use()}).get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'order-info',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('order-info-detail.status', {
            parent: 'order-info-detail',
            url: '/{status}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY','ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER']
            },
            onEnter: ['$translate', '$stateParams', '$state', '$uibModal', function($translate, $stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/order-info/order-info-status-dialog.html',
                    controller: 'OrderInfoStatusChangeDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['OrderInfo', function(OrderInfo) {
                            return OrderInfo({lang: $translate.use()}).get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }

})();
