(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', '$state', 'VirtualBankCount', 'SettleVirtualBankCount', 'DataUtils', 'SmsKoreaBalance', 'PayFormBalance', 'HermesBalance'];

    function HomeController($scope, Principal, $state, VirtualBankCount, SettleVirtualBankCount, DataUtils, SmsKoreaBalance, PayFormBalance, HermesBalance) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.getBankNameByIndex = DataUtils.getBankNameByIndex;
        vm.register = register;
        vm.isVirtualBankDecedLimit = isVirtualBankDecedLimit;
        vm.smsKoreaBalance = SmsKoreaBalance.get();

        if (Principal.hasAnyAuthority(['ROLE_MERCHANT'])) {
            $state.go('merchant');
        }

        if (Principal.hasAnyAuthority(['ROLE_ANALYST'])) {
            $state.go('market-stats-daily');
        }

        if (Principal.hasAnyAuthority(['ROLE_HANPASS_MANAGER'])) {
            $state.go('hanpass-user');
        }

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        loadVirtualBanks();
        loadSettleVirtualBanks();
        getAccount();
        getPayFormBalance();
        getHermesBalance();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function register() {
            $state.go('register');
        }

        function loadVirtualBanks() {
            VirtualBankCount.get(onSuccess);

            function onSuccess(data) {
                vm.virtualBanks = data.result;
            }
        }

        function loadSettleVirtualBanks() {
            SettleVirtualBankCount.get(onSuccess);

            function onSuccess(data) {
                vm.banks = [];
                DataUtils.getBanks().forEach(function (bank) {
                    var count = data[bank.code];
                    if (!angular.isUndefined(count)) {
                        bank['count'] = data[bank.code];
                        vm.banks.push(bank);
                    }
                });
            }
        }

        function isVirtualBankDecedLimit(count) {
            if (count <= 50) {
                return 'danger';
            } else {
                return 'info';
            }
        }

        function getPayFormBalance() {
            PayFormBalance.query(function (data) {
                vm.payFormBalance = data.agent;
            })
        }
        function getHermesBalance() {
            HermesBalance.get(function (data) {
                vm.hermesBalance = data.Balances[0].Balance;
            });
        }
    }
})();
