(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerRequestDetailController', PartnerRequestDetailController);

    PartnerRequestDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PartnerRequest'];

    function PartnerRequestDetailController($scope, $rootScope, $stateParams, previousState, entity, PartnerRequest) {
        var vm = this;

        vm.partnerRequest = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:partnerRequestUpdate', function(event, result) {
            vm.partnerRequest = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
