(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('GiftCategoryDialogController', GiftCategoryDialogController);

    GiftCategoryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'GiftCategory', 'Gift'];

    function GiftCategoryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, GiftCategory, Gift) {
        var vm = this;

        vm.giftCategory = entity;
        vm.clear = clear;
        vm.save = save;
        vm.gifts = Gift.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.giftCategory.id !== null) {
                GiftCategory.update(vm.giftCategory, onSaveSuccess, onSaveError);
            } else {
                GiftCategory.save(vm.giftCategory, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:giftCategoryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
