(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProviderSegmentDialogController', ProviderSegmentDialogController);

    ProviderSegmentDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ProviderSegment', 'Provider', 'ProviderSegmentField'];

    function ProviderSegmentDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ProviderSegment, Provider, ProviderSegmentField) {
        var vm = this;

        vm.providerSegment = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.providers = Provider.getAsList();
        vm.providerSegments = vm.providerSegment.providerId ? ProviderSegment.getAllByProvider({id: vm.providerSegment.providerId}) : [];
        vm.providerSegmentFields = vm.providerSegment.id == null ? [] : ProviderSegmentField.get({id: vm.providerSegment.id});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.providerSegment.id !== null) {
                ProviderSegment.update(vm.providerSegment, onSaveSuccess, onSaveError);
            } else {
                ProviderSegment.save(vm.providerSegment, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:providerSegmentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
