(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('HanpassUserActivityLogController', HanpassUserActivityLogController);

    HanpassUserActivityLogController.$inject = ['entity', 'HanpassActivityLog'];

    function HanpassUserActivityLogController(entity, HanpassActivityLog) {

        var vm = this;

        vm.customer = entity;
        vm.hanpassActivityLogs = [];

        loadAll();

        function loadAll() {
            HanpassActivityLog.query({customerId: vm.customer.id}, function(result) {
                vm.hanpassActivityLogs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
