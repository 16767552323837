(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileController', ProfileController);

    ProfileController.$inject = ['$http', '$filter', '$translate', '$scope', '$state', 'Profile', 'ProfileSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'DataUtils'];

    function ProfileController($http, $filter, $translate, $scope, $state, Profile, ProfileSearch, ParseLinks, AlertService, paginationConstants, pagingParams, DataUtils) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;


        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.getNationalityNameByCode = getNationalityNameByCode;
        vm.getCurrencyNameForClient = DataUtils.getCurrencyNameForClient;
        vm.searchQuery = {
            field: pagingParams.field,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            lastTransactionFromDate: pagingParams.lastTransactionFromDate,
            lastTransactionToDate: pagingParams.lastTransactionToDate,
            statuses: pagingParams.statuses,
            clients: pagingParams.clients,
            nationalities: pagingParams.nationalities,
            genders: pagingParams.genders
        };

        initDatePicker();
        loadNationalities();

        if (!pagingParams.firstRun) {
            loadAll();
        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'lastTransactionFrom') {
                    $(this).val(vm.searchQuery.lastTransactionFromDate);
                } else if ($(this).attr('name') == 'lastTransactionTo') {
                    $(this).val(vm.searchQuery.lastTransactionToDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function loadAll() {
            Profile.query({
                field: vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                lastTransactionFromDate: vm.searchQuery.lastTransactionFromDate,
                lastTransactionToDate: vm.searchQuery.lastTransactionToDate,
                customerStatuses: vm.searchQuery.statuses,
                clients: vm.searchQuery.clients,
                nationalities: vm.searchQuery.nationalities,
                genders: vm.searchQuery.genders,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.profiles = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                field: vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                lastTransactionFromDate: vm.searchQuery.lastTransactionFromDate,
                lastTransactionToDate: vm.searchQuery.lastTransactionToDate,
                statuses: vm.searchQuery.statuses,
                clients: vm.searchQuery.clients,
                nationalities: vm.searchQuery.nationalities,
                genders: vm.searchQuery.genders,
                firstRun: false
            });
        }

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'lastTransactionFrom') {
                    vm.searchQuery.lastTransactionFromDate = $(this).val();
                } else if ($(this).attr('name') == 'lastTransactionTo') {
                    vm.searchQuery.lastTransactionToDate = $(this).val();
                }
            });

            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = {
                field: null,
                fromDate: null,
                toDate: null,
                lastTransactionFromDate: null,
                lastTransactionToDate: null,
                statuses: ['ACTIVE', 'DEACTIVATED', 'BANNED', 'REACTIVATED'],
                clients: ['bozoraka', 'mobpay'],
                nationalities: [],
                genders: ['F', 'M', 'U']
            };
            for (var index = 0; index < vm.nationalities.length; index++) {
                vm.searchQuery.nationalities.push(vm.nationalities[index].code);
            }
            $('#status').multiselect('selectAll', false);
            $('#status').multiselect('updateButtonText');
            vm.transition();
        }

        function loadNationalities() {
            $http.get('i18n/' + $translate.use() + '/nationalityList.json').success(function (nationalities) {
                vm.nationalities = nationalities;
                vm.nationalities = vm.nationalities.sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                });
                vm.nationalities.unshift({
                    name: 'UNSET',
                    code: 'UN'
                });
                if (pagingParams.firstRun && (vm.searchQuery.nationalities === null || vm.searchQuery.nationalities.length === 0)) {
                    for (var index = 0; index < vm.nationalities.length; index++) {
                        vm.searchQuery.nationalities.push(vm.nationalities[index].code);
                    }
                }
                setTimeout(function () {
                    $('#nationality').multiselect(
                        'setOptions',
                        {
                            selectAllText: all,
                            nonSelectedText: noneSelected,
                            allSelectedText: allSelected
                        });
                    $('#nationality').multiselect('rebuild');
                }, 0);
            });
        }

        function getNationalityNameByCode(code) {
            if (code === null) {
                return "";
            }
            var nationality = vm.nationalities.filter(function (a) {
                return a.code === code;
            });
            return nationality[0].name;
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#client').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('profile.all'),
                nonSelectedText: translate('profile.noneSelected'),
                allSelectedText: translate('profile.allSelected')
            });

            $('#nationality').multiselect({
                maxHeight: 500,
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('profile.all'),
                nonSelectedText: translate('profile.noneSelected'),
                allSelectedText: translate('profile.allSelected')
            });

            $('#gender').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('profile.all'),
                nonSelectedText: translate('profile.noneSelected'),
                allSelectedText: translate('profile.allSelected')
            });

            $('#status').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('profile.all'),
                nonSelectedText: translate('profile.noneSelected'),
                allSelectedText: translate('profile.allSelected')
            });

        }, 0);

        function translateMultiSelect() {
            all = translate('profile.all');
            noneSelected = translate('profile.noneSelected');
            allSelected = translate('profile.allSelected');
        }
    }
})();
