(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('HanpassActivityLogController', HanpassActivityLogController);

    HanpassActivityLogController.$inject = ['HanpassActivityLog'];

    function HanpassActivityLogController(HanpassActivityLog) {

        var vm = this;

        vm.hanpassActivityLogs = [];

        loadAll();

        function loadAll() {
            HanpassActivityLog.query(function(result) {
                vm.hanpassActivityLogs = result;
                vm.searchQuery = null;
            });
        }
    }
})();
