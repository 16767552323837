(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductBoxController', ProductBoxController);

    ProductBoxController.$inject = ['ProductBox'];

    function ProductBoxController(ProductBox) {
        var vm = this;
        vm.lang = 'uz';
        vm.onLanguageChange = onLanguageChange;

        onLanguageChange();

        function onLanguageChange(){
            vm.productBoxes = ProductBox.query({langKey: vm.lang});
        }
    }
})();
