(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MarketStatsPeriodicalController', MarketStatsPeriodicalController);

    MarketStatsPeriodicalController.$inject = ['$filter', '$http', '$translate', 'DateUtils', 'MarketStatsPeriodical', 'AlertService', 'StatsService', 'StatsPerTime', 'DataUtils'];

    function MarketStatsPeriodicalController($filter, $http, $translate, DateUtils, MarketStatsPeriodical, AlertService, StatsService, StatsPerTime, DataUtils) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.search = search;
        vm.getProductName = getProductName;
        vm.exportExcelByQuantityAndAmount = exportExcelByQuantityAndAmount;
        vm.exportExcelByUser = exportExcelByUser;
        vm.sortStatsByQuantity = sortStatsByQuantity;
        vm.sortStatsByAmount = sortStatsByAmount;
        vm.sortStatsByUserOrderByAmount = sortStatsByUserOrderByAmount;
        vm.sortStatsByUserOrderByQuantity = sortStatsByUserOrderByQuantity;
        vm.statsByQuantitySort = 'desc';
        vm.statsByAmountSort = 'desc';
        vm.statsByUserAmountSort = 'desc';
        vm.statsByUserQuantitySort = 'desc';
        vm.getCurrencySymbolForClient = DataUtils.getCurrencySymbolForClient;
        vm.searchQuery = {
            fromDate: DateUtils.currentDate(),
            toDate: DateUtils.currentDate(),
            clients: ['b2b', 'bozoraka', 'mobpay'],
            sort: 'desc'
        };
        initDatePicker();
        var dates = [];
        getDateRange(vm.searchQuery.fromDate, vm.searchQuery.toDate);
        var countOrder = [];
        var amountOrder = [];

        loadNationalities();

        function loadNationalities() {
            $http.get('i18n/' + $translate.use() + '/nationalityList.json').success(function (nationalities) {
                vm.nationalities = nationalities;
            });
        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            getDateRange(vm.searchQuery.fromDate, vm.searchQuery.toDate);
            MarketStatsPeriodical.query(vm.searchQuery, onSuccess, onError);
            MarketStatsPeriodical.getByMerchant(vm.searchQuery, onSuccessService, onError);
            vm.searchQuery['sort'] = vm.statsByQuantitySort;
            MarketStatsPeriodical.getByQuantity(vm.searchQuery, onSuccessByQuantity, onError);
            vm.searchQuery['sort'] = vm.statsByAmountSort;
            MarketStatsPeriodical.getByAmount(vm.searchQuery, onSuccessByAmount, onError);
            vm.searchQuery['sort'] = vm.statsByUserAmountSort;
            vm.searchQuery['orderBy'] = 'amount';
            MarketStatsPeriodical.getByUser(vm.searchQuery, onSuccessByUser, onError);
            MarketStatsPeriodical.getBySales(vm.searchQuery, onSuccessBySales);
            MarketStatsPeriodical.getLanguageWiseCounts(vm.searchQuery, onSuccessLangWise);
            MarketStatsPeriodical.getNationalityWiseCounts(vm.searchQuery, onSuccessNationalityWise);
        }

        function onSuccess(data, headers) {
            vm.stats = data;
        }

        function onSuccessService(data, headers) {
            makeAppClientsPieChart(data);
        }

        function onSuccessByQuantity(data, headers) {
            vm.statsByQuantity = data;
        }

        function onSuccessByAmount(data, headers) {
            vm.statsByAmount = data;
        }

        function onSuccessByUser(data, headers) {
            vm.statsByUser = data;
        }

        function onSuccessBySales(data) {
            $.when(prepareAmountObjectAsArray(data)).done(function () {
                highCharts();
            });
        }

        function onSuccessLangWise(data) {
            makeLangOrdersPieChart(data);
        }

        function onSuccessNationalityWise(data) {
            mapNationalities(data);
        }

        function mapNationalities(data) {
            vm.nationalityOrders = [];
            data.forEach(function (nationalityOrder) {
                vm.nationalities.forEach(function (nationality) {
                    if (nationalityOrder.nationality === nationality.code) {
                        nationalityOrder.nationalityName = nationality.name;
                        vm.nationalityOrders.push(nationalityOrder);
                    }
                });
                if (nationalityOrder.nationality === 'Undefined') {
                    nationalityOrder.nationalityName = 'Undefined';
                    vm.nationalityOrders.push(nationalityOrder);
                }
            });
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function makeAppClientsPieChart(data) {
            var chart_by_app_clients = AmCharts.makeChart("piechart_for_app_clients", {
                "type": "pie",
                "startDuration": 0,
                "theme": "light",
                "addClassNames": true,
                "legend": {
                    "position": "bottom",
                    "marginRight": 400,
                    "autoMargins": false,
                    "valueWidth": 90
                },
                "innerRadius": "30%",
                "defs": {
                    "filter": [{
                        "id": "shadow",
                        "width": "200%",
                        "height": "200%",
                        "feOffset": {
                            "result": "offOut",
                            "in": "SourceAlpha",
                            "dx": 0,
                            "dy": 0
                        },
                        "feGaussianBlur": {
                            "result": "blurOut",
                            "in": "offOut",
                            "stdDeviation": 5
                        },
                        "feBlend": {
                            "in": "SourceGraphic",
                            "in2": "blurOut",
                            "mode": "normal"
                        }
                    }]
                },
                "dataProvider": data,
                "valueField": "orderAmount",
                "titleField": "appClient",
                "colorField": "color",
                "export": {
                    "enabled": true
                }
            });

            chart_by_app_clients.addListener("init", handleInit);

            chart_by_app_clients.addListener("rollOverSlice", function (e) {
                handleRollOver(e);
            });
        }

        function makeLangOrdersPieChart(data) {
            var chart_by_lang_wise_orders = AmCharts.makeChart("chart_by_lang_wise_orders", {
                "type": "pie",
                "startDuration": 0,
                "theme": "light",
                "addClassNames": true,
                "legend": {
                    "position": "bottom",
                    "marginRight": 400,
                    "autoMargins": false,
                    "valueWidth": 20
                },
                "innerRadius": "30%",
                "defs": {
                    "filter": [{
                        "id": "shadow",
                        "width": "200%",
                        "height": "200%",
                        "feOffset": {
                            "result": "offOut",
                            "in": "SourceAlpha",
                            "dx": 0,
                            "dy": 0
                        },
                        "feGaussianBlur": {
                            "result": "blurOut",
                            "in": "offOut",
                            "stdDeviation": 5
                        },
                        "feBlend": {
                            "in": "SourceGraphic",
                            "in2": "blurOut",
                            "mode": "normal"
                        }
                    }]
                },
                "dataProvider": data,
                "valueField": "countOrder",
                "titleField": "language",
                "colorField": "color",
                "export": {
                    "enabled": true
                }
            });

            chart_by_lang_wise_orders.addListener("init", handleInit);

            chart_by_lang_wise_orders.addListener("rollOverSlice", function (e) {
                handleRollOver(e);
            });
        }

        function handleInit() {
            chart1.legend.addListener("rollOverItem", handleRollOver);
        }

        function handleRollOver(e) {
            var wedge = e.dataItem.wedge.node;
            wedge.parentNode.appendChild(wedge);
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#customerClient').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics.all'),
                nonSelectedText: translate('statistics.noneSelected'),
                allSelectedText: translate('statistics.allSelected')
            });

        }, 0);

        function translateMultiSelect() {
            all = translate('statistics.all');
            noneSelected = translate('statistics.noneSelected');
            allSelected = translate('statistics.allSelected');
        }

        function sortStatsByQuantity() {
            if (vm.statsByQuantitySort === 'desc') {
                vm.statsByQuantitySort = 'asc';
            } else {
                vm.statsByQuantitySort = 'desc';
            }
            vm.searchQuery['sort'] = vm.statsByQuantitySort;
            MarketStatsPeriodical.getByQuantity(vm.searchQuery, onSuccessByQuantity, onError);
        }

        function sortStatsByAmount() {
            if (vm.statsByAmountSort === 'desc') {
                vm.statsByAmountSort = 'asc';
            } else {
                vm.statsByAmountSort = 'desc';
            }
            vm.searchQuery['sort'] = vm.statsByAmountSort;
            MarketStatsPeriodical.getByAmount(vm.searchQuery, onSuccessByAmount, onError);
        }

        function sortStatsByUserOrderByAmount() {
            if (vm.statsByUserAmountSort === 'desc') {
                vm.statsByUserAmountSort = 'asc';
            } else {
                vm.statsByUserAmountSort = 'desc';
            }
            vm.searchQuery['sort'] = vm.statsByUserAmountSort;
            vm.searchQuery['orderBy'] = 'amount';
            MarketStatsPeriodical.getByUser(vm.searchQuery, onSuccessByUser, onError);
        }

        function sortStatsByUserOrderByQuantity() {
            if (vm.statsByUserQuantitySort === 'desc') {
                vm.statsByUserQuantitySort = 'asc';
            } else {
                vm.statsByUserQuantitySort = 'desc';
            }
            vm.searchQuery['sort'] = vm.statsByUserQuantitySort;
            vm.searchQuery['orderBy'] = 'quantity';
            MarketStatsPeriodical.getByUser(vm.searchQuery, onSuccessByUser, onError);
        }

        function getProductName(productName, productVariantName) {
            if (productVariantName === null) {
                return productName;
            }
            return productName + "[" + productVariantName + "]";
        }

        function exportExcelByQuantityAndAmount() {
            var data = vm.searchQuery;
            $http({
                url: 'api/market-stats/excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/json'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                saveAs(blob, 'market-stats-periodical-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {
            });
        }

        function exportExcelByUser() {
            var data = vm.searchQuery;
            $http({
                url: 'api/market-stats/customer/excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/json'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                saveAs(blob, 'market-stats-periodical-by-customer-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {
            });
        }

        function highCharts() {
            Highcharts.chart('highchart', {
                title: {
                    text: 'Periodical ORDERS and SALES'
                },
                xAxis: [{
                    categories: dates,
                    crosshair: true
                }],
                yAxis: [{ // Primary yAxis
                    labels: {
                        format: '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[5]
                        }
                    },
                    title: {
                        text: 'ORDERS',
                        style: {
                            color: Highcharts.getOptions().colors[5]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: 'SALES',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: Highcharts.valueOf().value,
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'center',
                    x: 120,
                    verticalAlign: 'top',
                    y: 100,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
                },
                series: [{
                    name: 'Sales',
                    type: 'column',
                    yAxis: 1,
                    data: amountOrder,
                    tooltip: {
                        valueSuffix: ''
                    }

                }, {
                    name: 'Orders',
                    type: 'spline',
                    color: 'red',
                    data: countOrder,
                    tooltip: {
                        valueSuffix: ''
                    }
                }]
            });
        }

        function getDateRange(fromDateString, toDateString) {
            var fromDate = new Date(fromDateString);
            var toDate = new Date(toDateString);
            dates = [];
            var dayInMillis = 1000*60*60*24;

            var diff = (toDate.getTime() - fromDate.getTime())/dayInMillis;
            for(var i=0;i<=diff; i++)
            {
                var dateInMillis = fromDate.getTime() + dayInMillis * i;
                var formattedDate = moment(new Date(dateInMillis)).format("YYYY-MM-DD")

                dates.push(formattedDate);
            }
        }

        function prepareAmountObjectAsArray(data) {
            countOrder = [];
            amountOrder = [];
            for (var day = 0; day < dates.length; day++) {
                var index = getExistingDayIndex(day, data);
                if (index >= 0) {
                    countOrder.push(data[index].countOrder);
                    amountOrder.push(data[index].amountOrder);
                } else {
                    countOrder.push(0);
                    amountOrder.push(0);
                }
            }
        }

        function getExistingDayIndex(index, data) {
            var date = dates[index];
            for (var i = 0; i < data.length; i++) {
                var existingDay = new Date(data[i].date);
                var existingDayFormatted = moment(existingDay).format("YYYY-MM-DD");
                if (existingDayFormatted === date) {
                    return i;
                }
            }
            return -1;
        }
    }
})();
