(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('ordered-product', {
            parent: 'entity',
            url: '/ordered-product?page&sort&search',
            data: {
                authorities: ['ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.orderedProduct.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ordered-product/ordered-products.html',
                    controller: 'OrderedProductController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('orderedProduct');
                    $translatePartialLoader.addPart('unitType');
                    $translatePartialLoader.addPart('productStatus');
                    $translatePartialLoader.addPart('visibleState');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('ordered-product-detail.edit', {
            parent: 'ordered-product-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ordered-product/ordered-product-dialog.html',
                    controller: 'OrderedProductDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['OrderedProduct', function(OrderedProduct) {
                            return OrderedProduct.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('ordered-product.new', {
            parent: 'ordered-product',
            url: '/new',
            data: {
                authorities: ['ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ordered-product/ordered-product-dialog.html',
                    controller: 'OrderedProductDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                quantity: null,
                                totalPrice: null,
                                purchasePrice: null,
                                retailPrice: null,
                                salePrice: null,
                                salePercent: null,
                                saleStartDate: null,
                                saleEndDate: null,
                                unit: null,
                                unitType: null,
                                status: null,
                                shippingFee: null,
                                visibleState: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('ordered-product', null, { reload: 'ordered-product' });
                }, function() {
                    $state.go('ordered-product');
                });
            }]
        })
        .state('ordered-product.edit', {
            parent: 'ordered-product',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ordered-product/ordered-product-dialog.html',
                    controller: 'OrderedProductDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['OrderedProduct', function(OrderedProduct) {
                            return OrderedProduct.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('ordered-product', null, { reload: 'ordered-product' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('ordered-product.delete', {
            parent: 'ordered-product',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ordered-product/ordered-product-delete-dialog.html',
                    controller: 'OrderedProductDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['OrderedProduct', function(OrderedProduct) {
                            return OrderedProduct.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('ordered-product', null, { reload: 'ordered-product' });
                }, function() {
                    $state.go('^');
                });
            }]
        }).state('ordered-product-by-product', {
            parent: 'entity',
            url: '/ordered-product/{productId}/{page}',
            data: {
                authorities: ['ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.orderedProduct.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ordered-product/ordered-products.html',
                    controller: 'OrderedProductController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                productId: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        productId: $stateParams.productId
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('orderedProduct');
                    $translatePartialLoader.addPart('unitType');
                    $translatePartialLoader.addPart('productStatus');
                    $translatePartialLoader.addPart('visibleState');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
