(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileTransactionController', ProfileTransactionController);

    ProfileTransactionController.$inject = ['$scope', '$state', 'entity', 'ProfileTransaction', 'ProfileBalance', 'ProfileBonus', 'ProfileTransactionAmount', 'ProfileBalanceAmount', 'ParseLinks', 'AlertService', 'pagingParams', 'DateUtils', 'DataUtils'];

    function ProfileTransactionController($scope, $state, entity, ProfileTransaction, ProfileBalance, ProfileBonus, ProfileTransactionAmount, ProfileBalanceAmount, ParseLinks, AlertService, pagingParams, DateUtils, DataUtils) {
        var vm = this;

        vm.profile = entity;
        vm.itemsPerPage = 50;
        vm.activeTab = pagingParams.activeTab;
        vm.activateTab = activateTab;

        vm.predicateTranHist = pagingParams.predicateTranHist;
        vm.reverseTranHist = pagingParams.ascendingTranHist;
        vm.transitionTranHist = transitionTranHist;
        vm.loadAllTranHist = loadAllTranHist;

        vm.predicateBalHist = pagingParams.predicateBalHist;
        vm.reverseBalHist = pagingParams.ascendingBalHist;
        vm.transitionBalHist = transitionBalHist;
        vm.loadAllBalHist = loadAllBalHist;

        vm.predicateBonHist = pagingParams.predicateBonHist;
        vm.reverseBonHist = pagingParams.ascendingBonHist;
        vm.transitionBonHist = transitionBonHist;
        vm.loadAllBonHist = loadAllBonHist;
        vm.dateDiff = DateUtils.transactionDateDifference;
        vm.getDeductedAmount = DataUtils.getDeductedAmount;
        vm.getSentAmount = DataUtils.getSentAmount;
        vm.getCurrencyNameForClient = DataUtils.getCurrencyNameForClient;
        vm.getCurrencySymbolForClient = DataUtils.getCurrencySymbolForClient;

        if (!pagingParams.firstRun) {
            if (vm.activeTab === 'tab_1') {
                loadAllTranHist();
                getTotalTransactionAmount();
            } else if (vm.activeTab === 'tab_2') {
                loadAllBalHist();
                getTotalBalanceAmount();
            } else if (vm.activeTab === 'tab_3') {
                loadAllBonHist();
            }
        } else {
            loadAllTranHist();
            getTotalTransactionAmount();
        }

        function loadAllTranHist() {
            ProfileTransaction.query({
                profileId: vm.profile.id,
                page: pagingParams.pageTranHist - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicateTranHist + ',' + (vm.reverseTranHist ? 'asc' : 'desc')];
                if (vm.predicateTranHist !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.linksTranHist = ParseLinks.parse(headers('link'));
                vm.totalItemsTranHist = headers('X-Total-Count');
                vm.queryCountTranHist = vm.totalItemsTranHist;
                vm.transactionHistories = data;
                vm.pageTranHist = pagingParams.pageTranHist;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionTranHist() {
            $state.transitionTo($state.$current, {
                id: vm.profile.id,
                pageTranHist: vm.pageTranHist,
                sortTranHist: vm.predicateTranHist + ',' + (vm.reverseTranHist ? 'asc' : 'desc'),
                pageBalHist: vm.pageBalHist,
                sortBalHist: vm.predicateBalHist + ',' + (vm.reverseBalHist ? 'asc' : 'desc'),
                pageBonHist: vm.pageBonHist,
                sortBonHist: vm.predicateBonHist + ',' + (vm.reverseBonHist ? 'asc' : 'desc'),
                activeTab: vm.activeTab
            });
        }

        function loadAllBalHist() {
            ProfileBalance.query({
                profileId: vm.profile.id,
                page: pagingParams.pageBalHist - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicateBalHist + ',' + (vm.reverseBalHist ? 'asc' : 'desc')];
                if (vm.predicateBalHist !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.linksBalHist = ParseLinks.parse(headers('link'));
                vm.totalItemsBalHist = headers('X-Total-Count');
                vm.queryCountBalHist = vm.totalItemsBalHist;
                vm.balanceHistories = data;
                vm.pageBalHist = pagingParams.pageBalHist;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionBalHist() {
            $state.transitionTo($state.$current, {
                id: vm.profile.id,
                pageTranHist: vm.pageTranHist,
                sortTranHist: vm.predicateTranHist + ',' + (vm.reverseTranHist ? 'asc' : 'desc'),
                pageBalHist: vm.pageBalHist,
                sortBalHist: vm.predicateBalHist + ',' + (vm.reverseBalHist ? 'asc' : 'desc'),
                pageBonHist: vm.pageBonHist,
                sortBonHist: vm.predicateBonHist + ',' + (vm.reverseBonHist ? 'asc' : 'desc'),
                activeTab: vm.activeTab,
                firstRun: false
            });
        }

        function loadAllBonHist() {
            ProfileBonus.query({
                profileId: vm.profile.id,
                page: pagingParams.pageBonHist - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicateBonHist + ',' + (vm.reverseBonHist ? 'asc' : 'desc')];
                if (vm.predicateBonHist !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.linksBonHist = ParseLinks.parse(headers('link'));
                vm.totalItemsBonHist = headers('X-Total-Count');
                vm.queryCountBonHist = vm.totalItemsBonHist;
                vm.bonusHistories = data;
                vm.pageBonHist = pagingParams.pageBonHist;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionBonHist() {
            $state.transitionTo($state.$current, {
                id: vm.profile.id,
                pageTranHist: vm.pageTranHist,
                sortTranHist: vm.predicateTranHist + ',' + (vm.reverseTranHist ? 'asc' : 'desc'),
                pageBalHist: vm.pageBalHist,
                sortBalHist: vm.predicateBalHist + ',' + (vm.reverseBalHist ? 'asc' : 'desc'),
                pageBonHist: vm.pageBonHist,
                sortBonHist: vm.predicateBonHist + ',' + (vm.reverseBonHist ? 'asc' : 'desc'),
                activeTab: vm.activeTab,
                firstRun: false
            });
        }

        function activateTab(tab) {
            if (tab === 'tab_1') {
                loadAllTranHist();
                getTotalTransactionAmount();
            } else if (tab === 'tab_2') {
                loadAllBalHist();
                getTotalBalanceAmount();
            } else if (tab === 'tab_3') {
                loadAllBonHist();
            }
            vm.activeTab = tab;
        }

        function getTotalTransactionAmount() {
            ProfileTransactionAmount.query({
                profileId: vm.profile.id
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.totalTransactionAmount = data.totalAmount;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getTotalBalanceAmount() {
            ProfileBalanceAmount.query({
                profileId: vm.profile.id
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.totalBalanceAmount = data.totalAmount;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }

})();
