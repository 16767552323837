(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductOriginDetailController', ProductOriginDetailController);

    ProductOriginDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ProductOrigin'];

    function ProductOriginDetailController($scope, $rootScope, $stateParams, previousState, entity, ProductOrigin) {
        var vm = this;

        vm.productOrigin = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:productOriginUpdate', function(event, result) {
            vm.productOrigin = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
