(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('DirectPaymentDetailController', DirectPaymentDetailController);

    DirectPaymentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'DirectPayment', 'Customer', 'SettlebankPayment', 'Transaction'];

    function DirectPaymentDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, DirectPayment, Customer, SettlebankPayment, Transaction) {
        var vm = this;

        vm.directPayment = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:directPaymentUpdate', function(event, result) {
            vm.directPayment = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
