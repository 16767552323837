(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerOrderedProductDialogController', PartnerOrderedProductDialogController);

    PartnerOrderedProductDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PartnerOrderedProduct', 'PartnerOrderInfo', 'Product'];

    function PartnerOrderedProductDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PartnerOrderedProduct, PartnerOrderInfo, Product) {
        var vm = this;

        vm.partnerOrderedProduct = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.partnerorderinfos = PartnerOrderInfo.query();
        vm.products = Product.query({language: 'en'});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.partnerOrderedProduct.id !== null) {
                PartnerOrderedProduct.update(vm.partnerOrderedProduct, onSaveSuccess, onSaveError);
            } else {
                PartnerOrderedProduct.save(vm.partnerOrderedProduct, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:partnerOrderedProductUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
