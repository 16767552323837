(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('request', {
                parent: 'entity',
                url: '/request',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_AGENT_UZB', 'ROLE_AGENT_KOR', 'ROLE_SELLER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA'],
                    pageTitle: 'transaction.home.title.topUp.requests'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/request/requests.html',
                        controller: 'RequestController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'pubDate,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('transaction');
                        $translatePartialLoader.addPart('transactionType');
                        $translatePartialLoader.addPart('transactionStatus');
                        $translatePartialLoader.addPart('serviceType');
                        $translatePartialLoader.addPart('transactionMode');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('request-detail', {
                parent: 'entity',
                url: '/request/{id}',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_AGENT_UZB', 'ROLE_AGENT_KOR', 'ROLE_SELLER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY'],
                    pageTitle: 'transaction.detail.title.request'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/request/request-detail.html',
                        controller: 'RequestDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('transaction');
                        $translatePartialLoader.addPart('transactionType');
                        $translatePartialLoader.addPart('transactionStatus');
                        $translatePartialLoader.addPart('serviceType');
                        $translatePartialLoader.addPart('transactionMode');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Transaction', function ($stateParams, Transaction) {
                        return Transaction.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'transaction',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            });
    }

})();
