(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('CoupangOrderInfoCancelController',CoupangOrderInfoCancelController);

    CoupangOrderInfoCancelController.$inject = ['$scope', '$uibModalInstance', 'entity', 'CoupangOrderInfo'];

    function CoupangOrderInfoCancelController($scope, $uibModalInstance, entity, CoupangOrderInfo) {
        var vm = this;

        vm.coupangOrderInfo = entity;
        vm.clear = clear;
        vm.confirmCancel = confirmCancel;
        vm.cancelReasonCode = null;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmCancel (orderInfo) {
            CoupangOrderInfo.processCancelOrder({'id': orderInfo.id, 'cancelReasonCode': vm.cancelReasonCode}, onSaveSuccess, onSaveError);

            function onSaveSuccess(data) {
                // vm.coupangOrderInfos[index]=data;
                console.log(data);
                CoupangOrderInfo.get({id: vm.coupangOrderInfo.id}, function (data) {
                    vm.coupangOrderInfo = data;
                    $scope.$emit('bozorakaAdminApp:coupangOrderInfoUpdate', data);
                    $uibModalInstance.close(data);
                });
            }

            function onSaveError(error) {
                var errorMessage = error.data && error.data.message ? error.data.message : (error.data.description ? error.data.description :'An error occurred while processing the order.');
                AlertService.error(errorMessage);
            }
        }
    }
})();
