(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('CoupangOrderInfo', CoupangOrderInfo);

    CoupangOrderInfo.$inject = ['$resource', 'DateUtils'];

    function CoupangOrderInfo ($resource, DateUtils) {
        var resourceUrl =  'api/coupang-order-infos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.orderCreatedDate = DateUtils.convertDateTimeFromServer(data.orderCreatedDate);
                        data.orderPaidDate = DateUtils.convertDateTimeFromServer(data.orderPaidDate);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'processOrderInfo': {
                url: 'api/coupang-order-infos/:id/process',
                method: 'POST',
                params: {'id': '@id'}
            },
            'processCancelOrder': {
                url: 'api/coupang-order-infos/:id/process-cancel',
                method: 'POST',
                params: {
                    'id': '@id',
                    'cancelReasonCode': '@cancelReasonCode'
                }
            }
        });
    }
})();
