(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('HanpassUserBonusController',HanpassUserBonusController);

    HanpassUserBonusController.$inject = ['$uibModalInstance', 'entity'];

    function HanpassUserBonusController($uibModalInstance, entity) {
        var vm = this;

        vm.bonusList = entity.bonusHistory;
        vm.userPhone = entity.userPhone;
        vm.appName = entity.appName;

        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
