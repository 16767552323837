(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TierChangeStatusController',TierChangeStatusController);

    TierChangeStatusController.$inject = ['$uibModalInstance', 'entity', 'newStatus', 'Tier'];

    function TierChangeStatusController($uibModalInstance, entity,  newStatus, Tier) {
        var vm = this;

        vm.tier = entity;
        vm.newStatus = newStatus;
        vm.clear = clear;
        vm.changeStatus = changeStatus;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function changeStatus (id, newStatus) {
            Tier.changeStatus({id: id, status: newStatus},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
