(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('SellerSalesData', SellerSalesData);

    SellerSalesData.$inject = ['$resource', 'DateUtils'];

    function SellerSalesData($resource) {
        var resourceUrl = 'api/seller-sales-data';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
