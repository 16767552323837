(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('DeliveryDialogController', DeliveryDialogController);

    DeliveryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'Delivery', 'OrderInfo', 'Customer', 'Courier'];

    function DeliveryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, Delivery, OrderInfo, Customer, Courier) {
        var vm = this;

        vm.delivery = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.orderinfos = OrderInfo.query({filter: 'delivery-is-null'});
        $q.all([vm.delivery.$promise, vm.orderinfos.$promise]).then(function() {
            if (!vm.delivery.orderInfoId) {
                return $q.reject();
            }
            return OrderInfo.get({id : vm.delivery.orderInfoId}).$promise;
        }).then(function(orderInfo) {
            vm.orderinfos.push(orderInfo);
        });
        vm.customers = Customer.query();
        vm.couriers = Courier.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.delivery.id !== null) {
                Delivery.update(vm.delivery, onSaveSuccess, onSaveError);
            } else {
                Delivery.save(vm.delivery, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:deliveryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
