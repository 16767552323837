(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductCloudImageDialogController', ProductCloudImageDialogController);

    ProductCloudImageDialogController.$inject = ['$translate','$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'ProductCloudImage', 'Product'];

    function ProductCloudImageDialogController ($translate,$timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, ProductCloudImage, Product) {
        var vm = this;

        vm.productCloudImage = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.lang = $translate.use();
        vm.products = Product.query({
            language: vm.lang
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.productCloudImage.id !== null) {
                ProductCloudImage.update(vm.productCloudImage, onSaveSuccess, onSaveError);
            } else {
                ProductCloudImage.save(vm.productCloudImage, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:productCloudImageUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setData = function ($file, productCloudImage) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        productCloudImage.data = base64Data;
                        productCloudImage.dataContentType = $file.type;
                        vm.productCloudImage.name = $file.name;
                    });
                });
            }
        };

    }
})();
