(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('BalanceBonusHistoryController', BalanceBonusHistoryController);

    BalanceBonusHistoryController.$inject = ['$state', 'BalanceBonusHistory', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'User'];

    function BalanceBonusHistoryController($state, BalanceBonusHistory, ParseLinks, AlertService, paginationConstants, pagingParams, User) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.search = search;
        vm.clear = clear;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.users = User.getUsersList();
        vm.searchQuery = {
            customerPhone: pagingParams.customerPhone,
            type: pagingParams.type,
            createdBy: pagingParams.createdBy,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };

        initDatePicker();
        loadAll();

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function loadAll () {
            BalanceBonusHistory.query({
                customerPhone: vm.searchQuery.customerPhone,
                type: vm.searchQuery.type,
                createdBy: vm.searchQuery.createdBy,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.balanceBonusHistories = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function search(searchQuery) {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });

            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.searchQuery = {
                customerPhone: null,
                type: null,
                createdBy: null,
                fromDate: null,
                toDate: null
            };
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                customerPhone: vm.searchQuery.customerPhone,
                type: vm.searchQuery.type,
                createdBy: vm.searchQuery.createdBy,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
