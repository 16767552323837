(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('InventoryHistoryDetailController', InventoryHistoryDetailController);

    InventoryHistoryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'InventoryHistory', 'Product', 'ProductVariant', 'OrderInfo'];

    function InventoryHistoryDetailController($scope, $rootScope, $stateParams, previousState, entity, InventoryHistory, Product, ProductVariant, OrderInfo) {
        var vm = this;

        vm.inventoryHistory = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:inventoryHistoryUpdate', function(event, result) {
            vm.inventoryHistory = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
