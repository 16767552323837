(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileShippingAddressController', ProfileShippingAddressController);

    ProfileShippingAddressController.$inject = ['$scope', '$state', 'entity'];

    function ProfileShippingAddressController($scope, $state, entity) {
        var vm = this;

        vm.profile = entity;
        vm.shippingAddresses = vm.profile.shippingAddresses;
    }

})();
