(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('GiftImageDetailController', GiftImageDetailController);

    GiftImageDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'GiftImage', 'ImageStorage', 'Gift'];

    function GiftImageDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, GiftImage, ImageStorage, Gift) {
        var vm = this;

        vm.giftImage = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:giftImageUpdate', function(event, result) {
            vm.giftImage = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
