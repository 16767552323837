(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('MarketStatsPeriodical', MarketStatsPeriodical);

    MarketStatsPeriodical.$inject = ['$resource', 'DateUtils'];


    function MarketStatsPeriodical($resource, DateUtils) {
        var resourceUrl = 'api/market-stats/periodical';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: false},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                    }
                    return data;
                }
            },
            'getByMerchant': {
                method: 'GET',
                url: 'api/market-stats/periodical/app-client',
                isArray: true
            },
            'getByQuantity': {
                method: 'GET',
                url: 'api/market-stats/periodical/product-quantity',
                isArray: true
            },
            'getByAmount': {
                method: 'GET',
                url: 'api/market-stats/periodical/order-amount',
                isArray: true
            },
            'getByUser': {
                method: 'GET',
                url: 'api/market-stats/periodical/customer',
                isArray: true
            },
            'getBySales': {
                method: 'GET',
                url: 'api/market-stats/periodical/sales',
                isArray: true
            },
            'getLanguageWiseCounts': {
                method: 'GET',
                url: 'api/market-stats/periodical/count/lang-wise',
                isArray: true
            },
            'getNationalityWiseCounts': {
                method: 'GET',
                url: 'api/market-stats/periodical/count/nationality-wise',
                isArray: true
            }
        });
    }
})();
