(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PaynetModeController', PaynetModeController);

    PaynetModeController.$inject = ['$scope', '$state', 'PaynetMode', 'PaynetModeSearch', 'Config'];

    function PaynetModeController($scope, $state, PaynetMode, PaynetModeSearch, Config) {
        var vm = this;

        vm.paynetModes = [];
        vm.allConfigs = {};
        vm.allPaynetModes = {};
        vm.paynetMode = null;
        vm.save = save;
        vm.loadAll = loadAll;
        vm.saveConfig = saveConfig;

        vm.modes = [
            {value: 'AUTO', text: 'AUTO'},
            {value: 'MANUAL', text: 'MANUAL'},
            {value: 'DISABLED', text: 'DISABLED'}
        ];

        vm.cardPhoneModes = [
            {value: 'AUTO', text: 'AUTO'},
            {value: 'AUTO_010PAY', text: 'AUTO_010PAY'},
            {value: 'AUTO_MANGO', text: 'AUTO_MANGO'},
            {value: 'MANUAL', text: 'MANUAL'},
            {value: 'DISABLED', text: 'DISABLED'}
        ];

        vm.kashUzModes = [
            {value: 'AUTO_UPAY', text: 'AUTO_UPAY'},
            {value: 'AUTO_KASH', text: 'AUTO_KASH'},
            {value: 'AUTO_KASH_PAY', text: 'AUTO_KASH_PAY'},
            {value: 'MANUAL', text: 'MANUAL'},
            {value: 'DISABLED', text: 'DISABLED'}
        ];

        loadAll();

        function loadAll() {
            PaynetMode.query(function (result) {
                vm.paynetModes = result;
                for (var i = 0; i < vm.paynetModes.length; i++) {
                    vm.allPaynetModes[vm.paynetModes[i].name] = vm.paynetModes[i];
                }
            });

            Config.query(function (result) {
                vm.configs = result;
                for (var i = 0; i < vm.configs.length; i++) {
                    vm.allConfigs[vm.configs[i].name] = vm.configs[i];
                }
            });
        }


        function save(name) {
            vm.isSaving = true;
            if (vm.allPaynetModes[name].id !== null) {
                PaynetMode.update(vm.allPaynetModes[name], onSaveSuccess, onSaveError);
            } else {
                PaynetMode.save(vm.allPaynetModes[name], onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            loadAll();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function saveConfig(name) {
            vm.isSaving = true;
            if (vm.allConfigs[name].id !== null) {
                Config.update(vm.allConfigs[name], onSaveSuccess, onSaveError);
            } else {
                Config.save(vm.allConfigs[name], onSaveSuccess, onSaveError);
            }
        }
    }
})();
