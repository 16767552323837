(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('OrderedProductVariantDetailController', OrderedProductVariantDetailController);

    OrderedProductVariantDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'OrderedProductVariant', 'OrderedProduct'];

    function OrderedProductVariantDetailController($scope, $rootScope, $stateParams, previousState, entity, OrderedProductVariant, OrderedProduct) {
        var vm = this;

        vm.orderedProductVariant = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:orderedProductVariantUpdate', function(event, result) {
            vm.orderedProductVariant = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
