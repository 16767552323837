(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileVOCDialogController', ProfileVOCDialogController);

    ProfileVOCDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'VOC', 'Customer', 'Profile'];

    function ProfileVOCDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, VOC, Customer, Profile) {
        var vm = this;

        vm.profileId = $stateParams.id;
        vm.vOC = entity;
        defineCustomerId();
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.customers = Customer.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function defineCustomerId() {
            Profile.get({id: vm.profileId}).$promise.then(function (profile) {
                vm.vOC.customerId = profile.customerId;
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.vOC.id !== null) {
                VOC.update(vm.vOC, onSaveSuccess, onSaveError);
            } else {
                vm.vOC.createdBy = 'admin';
                vm.vOC.createdDate = new Date();
                VOC.save(vm.vOC, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:vOCUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
