(function () {
    'use strict';

    angular.module('bozorakaAdminApp')
        .directive('serviceSelection', serviceSelection);

    var services = {
        UNKNOWN: 'ALL',
        SERVICE_PAYMENT: 'Payment',
        SERVICE_KASH_PAYNET: 'Paynet',
        SERVICE_UZB_PHONE: 'UzTel',
        SERVICE_UZB_INTERNET: 'UzInt',
        SERVICE_UZB_UTILITY: 'UzbUtil',
        SERVICE_KOR_CARDPHONE_VOICE: 'PreCall',
        SERVICE_KOR_CARDPHONE_INTERNET: 'PreData',
        SERVICE_KOR_INTL_CARD: 'IntCard',
        SERVICE_VOIP: 'VoIP',
        SERVICE_VOIP_UZTEL: 'VoIP UzTel',
        SERVICE_MARKET: 'Market',
        SERVICE_RUS_PHONE: 'RuTel',
        SERVICE_KAZ_PHONE: 'KzTel',
        SERVICE_KGZ_PHONE: 'KgTel',
        SERVICE_TJK_PHONE: 'TjTel',
        SERVICE_UKR_PHONE: 'UkTel',
        SERVICE_MYS_PHONE: 'MysTel',
        SERVICE_NPL_PHONE: 'NplTel',
        SERVICE_MMR_PHONE: 'MmrTel',
        SERVICE_IDN_PHONE: 'IdnTel',
        SERVICE_VIE_PHONE: 'VieTel',
        SERVICE_KHM_PHONE: 'KhmTel',
        SERVICE_THA_PHONE: 'ThaTel',
        SERVICE_BGD_PHONE: 'BgdTel',
        SERVICE_LKA_PHONE: 'LkaTel',
        SERVICE_PHL_PHONE: 'PhlTel',
        SERVICE_IND_PHONE: 'IndTel',
        SERVICE_PAK_PHONE: 'PakTel',
        SERVICE_SGP_PHONE: 'SgpTel',
        SERVICE_CHN_PHONE: 'ChnTel',
        SERVICE_TUR_PHONE: 'TurTel',
        SERVICE_THIS_BONUS: 'ThisBonus',
        SERVICE_OK_CASH: 'OkCash',
        SERVICE_GIFT: 'Gift'
    };

    function serviceSelection() {
        var template =
            '<select class="form-control" name="serviceType" ng-model="vm.searchQuery.serviceType">' +
                '<option value=""></option>' +
            '<option ng-repeat="service in ownVm.serviceMap | orderBy:\'name\'" value="{{service.key}}">{{service.name}}</option>' +
            '</select>'

        var directive = {
            restrict: 'EA',
            template: template,
            controller: ServiceSelectionController,
            controllerAs: 'ownVm',
            bindToController: true
        };

        return directive;
    }

    ServiceSelectionController.$inject = ['Principal'];

    function ServiceSelectionController(Principal) {
        var ownVm = this;
        ownVm.serviceMap = [];
        if (Principal.hasAnyAuthority(['ROLE_MERCHANT'])) {
            addServices([
                'UNKNOWN',
                'SERVICE_UZB_PHONE',
                'SERVICE_UZB_INTERNET',
                'SERVICE_PAYMENT'
            ]);
        } else if (Principal.hasAnyAuthority(['ROLE_AGENT_KOR'])) {
            addServices([
                'SERVICE_KOR_CARDPHONE_VOICE',
                'SERVICE_KOR_CARDPHONE_INTERNET',
                'SERVICE_KOR_INTL_CARD',
                'SERVICE_VOIP'
            ]);
        } else if (Principal.hasAnyAuthority(['ROLE_AGENT_UZB'])) {
            addServices([
                'SERVICE_UZB_PHONE',
                'SERVICE_UZB_INTERNET',
                'SERVICE_UZB_UTILITY',
                'SERVICE_KASH_PAYNET'
        ]);
        } else if (Principal.hasAnyAuthority(['ROLE_SELLER','ROLE_MARKET_MANAGER','ROLE_WAREHOUSE_MANAGER'])) {
            addServices([
                'SERVICE_MARKET'
            ]);
        } else if (Principal.hasAnyAuthority(['ROLE_CUSTOMER_SUPPORT_BOZORAKA']) || Principal.hasAnyAuthority(['ROLE_CONTENT_EDITOR_BOZORAKA'])) {
            // Remove or comment allServices, if you want to add ROLES manually
            var allServices = Object.keys(services);
            addServices(allServices);
        } else if (Principal.hasAnyAuthority(['ROLE_CUSTOMER_SUPPORT_MOBPAY']) || Principal.hasAnyAuthority(['ROLE_CONTENT_EDITOR_MOBPAY'])) {
            // Remove or comment allServices, if you want to add ROLES manually
            var allServices = Object.keys(services);
            addServices(allServices);
        } else if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGER'])) {
            // Remove or comment allServices, if you want to add ROLES manually
            var allServices = Object.keys(services);
            addServices(allServices);
        }

        function addServices(serviceKeys) {
            for (var i = 0; i < serviceKeys.length; i++) {
                var serviceKey = serviceKeys[i];
                var item = {
                    "key": serviceKey,
                    "name": services[serviceKey]
                }
                ownVm.serviceMap.push(item);
            }
        }
    }
})();
