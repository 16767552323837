(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerOrderNoticeDetailController', PartnerOrderNoticeDetailController);

    PartnerOrderNoticeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PartnerOrderNotice', 'PartnerOrderInfo'];

    function PartnerOrderNoticeDetailController($scope, $rootScope, $stateParams, previousState, entity, PartnerOrderNotice, PartnerOrderInfo) {
        var vm = this;

        vm.partnerOrderNotice = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:partnerOrderNoticeUpdate', function(event, result) {
            vm.partnerOrderNotice = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
