(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductTierGroupController', ProductTierGroupController);

    ProductTierGroupController.$inject = ['$state', '$translate', 'ProductTierGroup', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Tier', 'Category', 'WaivedProductGroup', '$filter', '$rootScope'];

    function ProductTierGroupController($state, $translate, ProductTierGroup, ParseLinks, AlertService, paginationConstants, pagingParams, Tier, Category, WaivedProductGroup, $filter, $rootScope) {

        var translate = $filter('translate'), all, noneSelected, allSelected;
        var vm = this;
        vm.RETAIL_TIER_ID = 5;
        vm.retailCheck = [];
        vm.filterWaivedGroups = [];
        getAllTiers();

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.saveTier = saveTier;
        vm.saveWaivedProductGroup = saveWaivedProductGroup;
        vm.updateTier = updateTier;
        vm.search = search;
        vm.clear = clear;
        vm.categories = Category.query();
        vm.fistRun = pagingParams.fistRun;
        vm.searchQuery = {
            tierGroups: pagingParams.tierGroups,
            name: pagingParams.name,
            categoryId: pagingParams.categoryId,
            waivedProductGroup: pagingParams.waivedProductGroup,
            statuses: pagingParams.statuses,
            language: $translate.use()
        };

        vm.loadMore = loadMore;
        vm.searching = false;
        vm.page = 0;
        vm.lastPage = false;
        vm.loading = false;
        vm.productTierGroups = [];
        vm.waivedProductGroups = WaivedProductGroup.query({}, onSuccess);

        function onSuccess(data) {
            vm.filterWaivedGroups.push({
                id: 0,
                name: "All"
            });
            angular.forEach(data, function (value, key) {
                vm.filterWaivedGroups.push(value);
            });
            data.unshift({id: null, name: ""});
            return data;
        }

        function loadNext () {
            ProductTierGroup.query({
                page: vm.page,
                size: vm.itemsPerPage,
                sort: sort(),
                name: vm.searchQuery.name,
                tierGroups: vm.searchQuery.tierGroups,
                categoryId: vm.searchQuery.categoryId,
                waivedProductGroup: vm.searchQuery.waivedProductGroup,
                statuses: vm.searchQuery.statuses,
                language: vm.searchQuery.language,
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }
            function onSuccess(data, headers) {
                if (headers('link') !== null) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                }
                vm.page++;
                var productTierGroups = data;

                if (productTierGroups == null || productTierGroups.length < 20) {
                    vm.lastPage = true;
                }
                    productTierGroups.forEach(function (productTierGroup) {
                        var productTier = productTierGroup.productTiers.filter(function (value) {
                            return value.tierId === vm.RETAIL_TIER_ID;
                        })[0];
                        productTierGroup.retailCheck = productTierGroup.weight > 0 && (productTier === undefined || productTier.price > 0);
                        vm.productTierGroups.push(productTierGroup);
                    });
                vm.loading = false;
                vm.searching = false;
            }
            function onError(error) {
                AlertService.error(error.data.message);
                vm.searching = false;
            }
        }

        function loadMore() {
            if (vm.lastPage || vm.loading || vm.fistRun) {
                return null;
            } else {
                vm.loading = true;
                loadNext();
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                name: vm.searchQuery.name,
                tierGroups: vm.searchQuery.tierGroups,
                categoryId: vm.searchQuery.categoryId,
                waivedProductGroup: vm.searchQuery.waivedProductGroup,
                statuses: vm.searchQuery.statuses
            });
        }

        function search() {
            vm.productTierGroups = [];
            vm.page = 0;
            vm.lastPage = false;
            vm.loading = false;
            vm.searching = true;
            vm.fistRun = false;
            loadNext();
        }

        function clear() {
            vm.productTierGroups = [];
            vm.page = 0;
            vm.lastPage = false;
            vm.loading = false;
            vm.searching = false;

            vm.links = null;
            vm.searchQuery = {
                name: null,
                categoryId: null,
                waivedProductGroup: 0,
                statuses: ["AVAILABLE", "OUT_OF_STOCK", "DISABLED"],
                language: $translate.use(),
            };
            $('#field_tier').multiselect('deselectAll', false);
            $('#field_tier').multiselect('updateButtonText');
            $('#field_status').multiselect('selectAll', false);
            $('#field_status').multiselect('refresh');
            vm.predicate = 'id';
            vm.reverse = true;
            loadNext();
        }

        function saveTier(productTier, tier) {
            tier.weight = productTier.weight;
            tier.waivedProductGroup = productTier.waivedProductGroup;
            vm.isSaving = true;
            if (tier.id == null) {
                ProductTierGroup.save(tier, onSuccess, onError);
            } else {
                ProductTierGroup.update(tier, onSuccess, onError);
            }
            function onSuccess() {
                vm.isSaving = false;
                AlertService.success(productTier.productName + (productTier.variantName ? productTier.variantName : '')  + "'s "+ tier.tierName +" tier price updated");
            }
            function onError() {
                vm.isSaving = false;
            }
        }

        function saveWaivedProductGroup(productTier) {
            vm.isSaving = true;
            if (productTier.waivedProductGroup == null || productTier.waivedProductGroup.id == null){
                productTier.waivedProductGroup = null;
            }
            ProductTierGroup.updateTier(productTier, function () {
                vm.isSaving = false;
            }, function () {
                vm.isSaving = false;
            });
        }

        function updateTier(productTier) {
            vm.isSaving = true;
            ProductTierGroup.updateTier(productTier, function () {
                vm.isSaving = false;
            }, function () {
                vm.isSaving = false;
            });
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#field_status').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('bozorakaAdminApp.productTier.all'),
                nonSelectedText: translate('bozorakaAdminApp.productTier.noneSelected'),
                allSelectedText: translate('bozorakaAdminApp.productTier.allSelected')
            });

            $('#field_tier').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('bozorakaAdminApp.productTier.all'),
                nonSelectedText: translate('bozorakaAdminApp.productTier.noneSelected'),
                allSelectedText: translate('bozorakaAdminApp.productTier.allSelected'),
                maxHeight: 300
            });

        }, 0);

        function translateMultiSelect() {
            all = translate('bozorakaAdminApp.productTier.all');
            noneSelected = translate('bozorakaAdminApp.productTier.noneSelected');
            allSelected = translate('bozorakaAdminApp.productTier.allSelected');
        }

        function getAllTiers() {
            Tier.query({}, onSuccess, onError);

            function onSuccess(data) {
                vm.tiers = data;
                setTimeout(function () {
                    $('#field_tier').multiselect(
                        'setOptions',
                        {
                            selectAllText: all,
                            nonSelectedText: noneSelected,
                            allSelectedText: allSelected
                        });
                    $('#field_tier').multiselect('rebuild');
                }, 0)
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        $rootScope.$on('$translateChangeSuccess', function () {
            translateMultiSelect();

            $('#field_tier').multiselect(
                'setOptions',
                {
                    selectAllText: all,
                    nonSelectedText: noneSelected,
                    allSelectedText: allSelected
                });
            $('#field_tier').multiselect('rebuild');

            $('#field_status').multiselect(
                'setOptions',
                {
                    selectAllText: all,
                    nonSelectedText: noneSelected,
                    allSelectedText: allSelected
                });
            $('#field_status').multiselect('rebuild');
        });

    }
})();
