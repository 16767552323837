(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerOrderedProductDetailController', PartnerOrderedProductDetailController);

    PartnerOrderedProductDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PartnerOrderedProduct', 'PartnerOrderInfo', 'Product'];

    function PartnerOrderedProductDetailController($scope, $rootScope, $stateParams, previousState, entity, PartnerOrderedProduct, PartnerOrderInfo, Product) {
        var vm = this;

        vm.partnerOrderedProduct = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:partnerOrderedProductUpdate', function(event, result) {
            vm.partnerOrderedProduct = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
