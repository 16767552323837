(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProvinceController', ProvinceController);

    ProvinceController.$inject = ['Province'];

    function ProvinceController(Province) {

        var vm = this;

        vm.provinces = [];

        loadAll();

        function loadAll() {
            Province.query(function(result) {
                vm.provinces = result;
                vm.searchQuery = null;
            });
        }
    }
})();
