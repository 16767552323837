(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('HanpassUserDeleteController',HanpassUserDeleteController);

    HanpassUserDeleteController.$inject = ['$uibModalInstance', 'entity', 'HanpassUser'];

    function HanpassUserDeleteController($uibModalInstance, entity, HanpassUser) {
        var vm = this;

        vm.hanpassUser = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            HanpassUser.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
