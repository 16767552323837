(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('Category', Category)
        .factory('SpecialCategory', SpecialCategory);

    Category.$inject = ['$resource'];

    function Category ($resource) {
        var resourceUrl =  'api/categories/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'getAllCategories': {url: 'api/categories/all', method: 'GET', isArray: true}
        });
    }

    SpecialCategory.$inject = ['$resource'];

    function SpecialCategory ($resource) {
        var resourceUrl =  'api/categories/special/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

})();
