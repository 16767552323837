(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductPriceChangeLogsProductController', ProductPriceChangeLogsProductController);

    ProductPriceChangeLogsProductController.$inject = ['$scope', '$state', 'ProductPriceChangeLog', 'ProductVariant', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Tier'];

    function ProductPriceChangeLogsProductController($scope, $state, ProductPriceChangeLog, ProductVariant, ParseLinks, AlertService, paginationConstants, pagingParams, Tier) {
        var vm = this;

        vm.RETAIL_TIER_ID = 5;
        vm.loadPage = loadPage;
        vm.search = search;
        vm.clear = clear;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.name = $state.params.name;
        vm.variantName = $state.params.variantName;
        vm.sku = $state.params.sku;
        vm.productId = $state.params.productId;
        vm.productVariants = [];
        vm.searchQuery = {
            productVariantId: pagingParams.productVariantId,
            eventType: pagingParams.eventType,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };
        vm.loadAll = loadAll;
        vm.hasVariantName = hasVariantName;
        vm.hasSku = hasSku;

        getAllEventType();
        initDatePicker();
        loadAll();

        function getAllEventType() {
            Tier.query({}, onSuccess, onError);
            vm.eventTypeList = ["RETAIL_PRICE_CHANGED", "SALE_PRICE_CHANGED", "PURCHASE_PRICE_CHANGED"];

            function onSuccess(data) {
                vm.tiers = data;
                for (var i = 0; i < vm.tiers.length; i++) {
                    var tier = vm.tiers[i];
                    if (tier.id === vm.RETAIL_TIER_ID) {
                        continue;
                    }
                    var item = "B2B_" + tier.name.replaceAll(" ", "_").toUpperCase() + "_RETAIL_PRICE_CHANGED";
                    vm.eventTypeList.push(item);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        ProductVariant.findByProductId({id: vm.productId},
            function (data) {
                vm.productVariants = data;
            });

        function loadAll() {
            ProductPriceChangeLog.findByProductId({
                productId: $state.params.productId,
                productVariantId: vm.searchQuery.productVariantId,
                eventType: vm.searchQuery.eventType,
                fromDate: vm.searchQuery.fromDate === '' ? null : vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate === '' ? null : vm.searchQuery.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.productPriceChangeLogs = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function hasVariantName() {
            return vm.variantName !== null && vm.variantName !== undefined && vm.variantName !== "";
        }

        function hasSku() {
            return vm.sku !== null && vm.sku !== undefined && vm.sku !== "";
        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                productId: vm.productId,
                productVariantId: vm.searchQuery.productVariantId,
                eventType: vm.searchQuery.eventType,
                fromDate: vm.searchQuery.fromDate === '' ? null : vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate === '' ? null : vm.searchQuery.toDate,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                name: vm.name,
                variantName: vm.variantName,
                sku: vm.sku
            });
        }

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function clear() {
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.searchQuery = {
                productVariantId: null,
                eventType: null,
                fromDate: null,
                toDate: null
            };
            initDatePicker();
            vm.transition();
        }
    }
})();
