(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('SettlebankPaymentController', SettlebankPaymentController);

    SettlebankPaymentController.$inject = ['$state', 'DataUtils', 'SettlebankPayment', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'SettlebankPaymentTotalAmount', 'ProfileIdByPhone'];

    function SettlebankPaymentController($state, DataUtils, SettlebankPayment, ParseLinks, AlertService, paginationConstants, pagingParams, SettlebankPaymentTotalAmount, ProfileIdByPhone) {

        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.search = search;
        vm.clear = clear;
        vm.openProfile = openProfile;
        vm.searchQuery = {
            field: pagingParams.field,
            storeId: pagingParams.storeId,
            valueString1: pagingParams.valueString1,
            valueString2: pagingParams.valueString2,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };

        initDatePicker();
        loadAll();
        getTotalAmount();

        function loadAll () {
            SettlebankPayment.query({
                field: vm.searchQuery.field,
                storeId: vm.searchQuery.storeId,
                valueString1: vm.searchQuery.valueString1,
                valueString2: vm.searchQuery.valueString2,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.settlebankPayments = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                field: vm.searchQuery.field,
                storeId: vm.searchQuery.storeId,
                valueString1: vm.searchQuery.valueString1,
                valueString2: vm.searchQuery.valueString2,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate
            });
        }

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = {
                field: null,
                storeId: null,
                valueString1: null,
                valueString2: "",
                fromDate: null,
                toDate: null
            };
            vm.transition();
        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function getTotalAmount() {
            SettlebankPaymentTotalAmount.query({
                field: vm.searchQuery.field,
                storeId: vm.searchQuery.storeId,
                valueString1: vm.searchQuery.valueString1,
                valueString2: vm.searchQuery.valueString2,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate
            }, function (data) {
                vm.totalAmount = data.totalAmount;
            })
        }

        function openProfile(phoneNumber) {
            ProfileIdByPhone.get({phoneNumber: phoneNumber}, onSuccess);

            function onSuccess(data) {
                $state.go('profile-detail', {id: data.profileId});
            }
        }
    }
})();
