(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('DirectPaymentController', DirectPaymentController);

    DirectPaymentController.$inject = ['$state', 'DataUtils', 'DirectPayment', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'ProfileIdByPhone'];

    function DirectPaymentController($state, DataUtils, DirectPayment, ParseLinks, AlertService, paginationConstants, pagingParams, ProfileIdByPhone) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.totalPaidAmount = 0;
        vm.getTotalPaidAmount = getTotalPaidAmount;
        vm.openProfile = openProfile;
        vm.isFailedPayment = isFailedPayment;

        vm.searchQuery = {
            field: pagingParams.field,
            paymentStatus: pagingParams.paymentStatus,
            transactionStatus: pagingParams.transactionStatus,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };
        vm.search = search;
        vm.clear = clear;

        initDatePicker();
        loadAll();

        function loadAll () {
            DirectPayment.query({
                field: vm.searchQuery.field,
                valueString1: vm.searchQuery.paymentStatus,
                transactionStatus: vm.searchQuery.transactionStatus,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.directPayments = data;
                vm.page = pagingParams.page;
                getTotalPaidAmount();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getTotalPaidAmount() {
            DirectPayment.getTotalAmount({
                field: vm.searchQuery.field,
                valueString1: vm.searchQuery.paymentStatus,
                transactionStatus: vm.searchQuery.transactionStatus,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate
            }, onSuccess, onError);
            function onSuccess(data) {
                vm.totalPaidAmount = data.totalAmount;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                field: vm.searchQuery.field,
                paymentStatus: vm.searchQuery.paymentStatus,
                transactionStatus: vm.searchQuery.transactionStatus,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate
            });
        }

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = {
                field: null,
                paymentStatus: null,
                transactionStatus: 'UNKNOWN',
                fromDate: null,
                toDate: null
            };
            vm.transition();
        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function openProfile(phoneNumber) {
            ProfileIdByPhone.get({phoneNumber: phoneNumber}, onSuccess);

            function onSuccess(data) {
                $state.go('profile-detail', {id: data.profileId});
            }
        }

        function isFailedPayment(status) {
            return status === '0000' || status === '0031';
        }
    }
})();
