(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MarketStatsWeeklyController', MarketStatsWeeklyController);

    MarketStatsWeeklyController.$inject = ['AlertService', 'DateUtils', 'MarketStatsWeekly'];

    function MarketStatsWeeklyController(AlertService, DateUtils, MarketStatsWeekly) {

        var vm = this;
        vm.search = search;
        var today = moment().format("MM/DD/YYYY");
        var mon = '';
        var tue = '';
        var wed = '';
        var thu = '';
        var fri = '';
        var sat = '';
        var sun = '';
        var selectedDate = '';
        getDateRange(today);

        function search() {
            MarketStatsWeekly.getBySales(vm.searchQuery, onSuccessBySales, onError);
            MarketStatsWeekly.getByProfit(vm.searchQuery, onSuccessByProfit, onError);

            function onSuccessBySales(data, headers) {
                callChartBySales(data);
            }

            function onSuccessByProfit(data, headers) {
                callChartByProfit(data);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function callChartBySales(data) {
            Highcharts.chart('chartBySales', {
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Total Sales by clients'
                },
                xAxis: {
                    categories: ['Mon(' + mon + ')', 'Tue(' + tue + ')', 'Wed(' + wed + ')',
                        'Thu(' + thu + ')', 'Fri(' + fri + ')', 'Sat(' + sat + ')', 'Sun(' + sun + ')']
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'T o t a l ' + ' ' + ' S a l e s'
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                        }
                    }
                },
                legend: {
                    align: 'right',
                    x: -30,
                    verticalAlign: 'top',
                    y: 25,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: true
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y}<br/>'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true,
                            color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
                        }
                    }
                },
                series: data
            });
        }

        function callChartByProfit(data) {
            Highcharts.chart('chartByProfit', {
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Total Profit by clients'
                },
                xAxis: {
                    categories: ['Mon(' + mon + ')', 'Tue(' + tue + ')', 'Wed(' + wed + ')',
                        'Thu(' + thu + ')', 'Fri(' + fri + ')', 'Sat(' + sat + ')', 'Sun(' + sun + ')']
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'T o t a l ' + ' ' + ' P r o f i t'
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                        }
                    }
                },
                legend: {
                    align: 'right',
                    x: -30,
                    verticalAlign: 'top',
                    y: 25,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: true
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y}<br/>'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true,
                            color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
                        }
                    }
                },
                series: data
            });
        }

        function getDateRange(date) {
            selectedDate = moment(date).format("MM/DD/YYYY");
            var fromDate = new Date(moment(selectedDate, "MM/DD/YYYY").day(1));
            var toDate = new Date(moment(selectedDate, "MM/DD/YYYY").day(7));

            $('#datetimepicker').datetimepicker({weekStart: 1});
            var firstDateToShow = moment(selectedDate, "MM/DD/YYYY").day(1).format("MM/DD/YYYY");
            var lastDateToShow = moment(selectedDate, "MM/DD/YYYY").day(7).format("MM/DD/YYYY");
            $("#week").val(firstDateToShow + " ~ " + lastDateToShow);
            mon = moment(selectedDate, "MM/DD/YYYY").day(1).format("DD/MMM");
            tue = moment(selectedDate, "MM/DD/YYYY").day(2).format("DD/MMM");
            wed = moment(selectedDate, "MM/DD/YYYY").day(3).format("DD/MMM");
            thu = moment(selectedDate, "MM/DD/YYYY").day(4).format("DD/MMM");
            fri = moment(selectedDate, "MM/DD/YYYY").day(5).format("DD/MMM");
            sat = moment(selectedDate, "MM/DD/YYYY").day(6).format("DD/MMM");
            sun = moment(selectedDate, "MM/DD/YYYY").day(7).format("DD/MMM");
            callChartBySales();
            callChartByProfit();

            vm.searchQuery = {
                fromDate: DateUtils.converterDateFormat(fromDate),
                toDate: DateUtils.converterDateFormat(toDate)
            };
            search();
        }

        $('#datetimepicker')
            .datetimepicker()
            .on('changeDate', function (ev) {
                var value = new Date($("#week").val());
                $('#datetimepicker').datetimepicker('hide');
                getDateRange(value);
            });
    }
})();
