(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerTransactionController', PartnerTransactionController);

    PartnerTransactionController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'entity', 'pagingParams', 'ParseLinks', 'AlertService', 'Partner', 'PartnerBalanceHistory', 'PartnerOrderInfo'];

    function PartnerTransactionController($scope, $state, $rootScope, $stateParams, previousState, entity, pagingParams, ParseLinks, AlertService, Partner, PartnerBalanceHistory, PartnerOrderInfo) {
        var vm = this;
        vm.partner = entity;
        vm.previousState = previousState.name;
        vm.activeTab = pagingParams.activeTab;
        vm.activateTab = activateTab;
        vm.isAgent = isAgent;

        vm.itemsPerPage = 50;
        vm.predicateBalHist = pagingParams.predicateBalHist;
        vm.reverseBalHist = pagingParams.ascendingBalHist;
        vm.transitionBalHist = transitionBalHist;
        vm.predicateOrdHist = pagingParams.predicateOrdHist;
        vm.reverseOrdHist = pagingParams.ascendingOrdHist;
        vm.transitionOrdHist = transitionOrdHist;
        vm.unpaidOrders = [];
        if (!pagingParams.firstRun) {
            if (vm.activeTab === 'tab_balance_history') {
                loadPartnerBalanceHistories();
            } else if (vm.activeTab === 'tab_transaction_history') {
                loadPartnerOrderHistories();
            }  else if (vm.activeTab === 'tab_unpaid_order'){
                loadPartnerUnpaidOrders();
            }
        } else {
            loadPartnerOrderHistories();
        }

        function loadPartnerBalanceHistories() {
            PartnerBalanceHistory.getByPartner({
                id: vm.partner.id,
                page: pagingParams.pageBalHist - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicateBalHist + ',' + (vm.reverseBalHist ? 'asc' : 'desc')];
                if (vm.predicateBalHist !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.linksBalHist = ParseLinks.parse(headers('link'));
                vm.totalItemsBalHist = headers('X-Total-Count');
                vm.queryCountBalHist = vm.totalItemsBalHist;
                vm.balanceHistories = data;
                vm.pageBalHist = pagingParams.pageBalHist;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionBalHist() {
            $state.transitionTo($state.$current, {
                id: vm.partner.id,
                pageBalHist: vm.pageBalHist,
                sortBalHist: vm.predicateBalHist + ',' + (vm.reverseBalHist ? 'asc' : 'desc'),
                activeTab: vm.activeTab,
                firstRun: false
            });
        }

        function loadPartnerOrderHistories() {
            PartnerOrderInfo.getByPartner({
                id: vm.partner.id,
                page: pagingParams.pageOrdHist - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicateOrdHist + ',' + (vm.reverseOrdHist ? 'asc' : 'desc')];
                if (vm.predicateOrdHist !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.linksOrdHist = ParseLinks.parse(headers('link'));
                vm.totalItemsOrdHist = headers('X-Total-Count');
                vm.queryCountOrdHist = vm.totalItemsOrdHist;
                vm.orderHistories = data;
                vm.pageOrdHist = pagingParams.pageOrdHist;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPartnerUnpaidOrders() {
            PartnerOrderInfo.getAllUnpaidOrder({
                id: vm.partner.id,
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.unpaidOrders = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionOrdHist() {
            $state.transitionTo($state.$current, {
                id: vm.partner.id,
                pageOrdHist: vm.pageOrdHist,
                sortOrdHist: vm.predicateOrdHist + ',' + (vm.reverseOrdHist ? 'asc' : 'desc'),
                activeTab: vm.activeTab,
                firstRun: false
            });
        }

        function activateTab(tab) {
            if (tab === 'tab_balance_history') {
                loadPartnerBalanceHistories();
            } else if (tab === 'tab_transaction_history') {
                loadPartnerOrderHistories();
            } else if (tab === 'tab_unpaid_order'){
                loadPartnerUnpaidOrders();
            }
            vm.activeTab = tab;
        }

        function isAgent() {
            return vm.partner.authorityName === 'ROLE_AGENT';
        }

    }
})();
