(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('OrderInfoStatusChangeDialogController', OrderInfoStatusChangeDialogController);

    OrderInfoStatusChangeDialogController.$inject = ['$state', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'OrderInfo', '$translate'];

    function OrderInfoStatusChangeDialogController($state, $timeout, $scope, $stateParams, $uibModalInstance, entity, OrderInfo, $translate, Delivery) {
        var vm = this;

        vm.orderInfo = entity;
        vm.transactions = vm.orderInfo.transaction;
        vm.clear = clear;
        vm.save = save;
        vm.orderStatus = $stateParams.status;
        var headers = {
            lang: $translate.use()
        };
        boxColor();
        statusDisabled();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function save() {
            vm.isSaving = true;
            vm.orderInfoStatus = {
                status: $stateParams.status,
                orderInfoId: vm.orderInfo.id
            };
            if (vm.orderInfo.id !== null) {
                OrderInfo(headers).update(vm.orderInfoStatus, onSaveSuccess, onSaveError);
            } else {
                OrderInfo(headers).save(vm.orderInfoStatus, onSaveSuccess, onSaveError);
            }
            if ($stateParams.status !== 'SHIPPED' && $stateParams.status !== 'CANCELED') {
                $state.go('market-request', null, {reload: 'market-request'});
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            OrderInfo(headers).get({id: vm.orderInfo.id}, function (data) {
                vm.orderInfo = data;
                vm.transactions = vm.orderInfo.transaction;
                boxColor();
                $scope.$emit('bozorakaAdminApp:orderInfoUpdate', result);
                $uibModalInstance.close(result);
                vm.isSaving = false;
                statusDisabled();
            });
        }
        function onSaveError() {
            vm.isSaving = false;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
        function boxColor() {
            if (vm.transactions !== undefined) {
                var status = vm.transactions.status;
                if (status === 'SUCCESS') {
                    vm.statusColor = 'green-seagreen';
                } else if (status === 'FAILURE') {
                    vm.statusColor = 'red-mint';
                } else if (status === 'INPROGRESS') {
                    vm.statusColor = 'yellow-saffron';
                }
            }
        }
        function statusDisabled() {
            if (vm.orderInfo.status === 'SHIPPED' || vm.orderInfo.status === 'CANCELED'){
                vm.status  = true;
            }
        }
    }
})();

