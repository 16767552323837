(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileBankDialogController', ProfileBankDialogController);

    ProfileBankDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'AlertService', 'entity', 'Profile', 'DataUtils', 'VirtualBankAvailability', 'SettleVirtualBankAvailability'];

    function ProfileBankDialogController ($timeout, $scope, $stateParams, $uibModalInstance, AlertService, entity, Profile, DataUtils, VirtualBankAvailability, SettleVirtualBankAvailability) {
        var vm = this;

        vm.profile = entity;
        vm.bank = {};
        vm.banks = DataUtils.getBanks();

        vm.clear = clear;
        vm.save = save;
        getAvailableBanks();

        function getAvailableBanks() {
            if (vm.profile.customerClient === 'mobpay') {
                SettleVirtualBankAvailability.get({}, function (data) {
                    resolveAvailableBanks(data.result);
                });
            } else {
                VirtualBankAvailability.get({}, function (data) {
                    resolveAvailableBanks(data.result);
                });
            }
        }

        function resolveAvailableBanks(result) {
            if (result == null) {
                vm.banks = [];
                return
            }

            var availableBanks = [{
                id: 0,
                name: 'No bank'
            }];

            for (var i = 0; i < vm.banks.length; i++) {
                var bank = vm.banks[i];
                for (var j = 0; j < result.length; j++) {
                    var bankStatus = result[j];
                    if (bankStatus.bank_id === parseInt(bank.id) && bankStatus.available) {
                        bank.id = parseInt(bank.id);
                        availableBanks.push(bank);
                        break;
                    }
                }
            }
            vm.banks = availableBanks;
        }

        function save() {
            if (vm.bank !== null) {
                vm.profile.customerBankId = vm.bank.id;
                vm.profile.customerBank = vm.bank.name;
            }
            vm.isSaving = true;
            Profile.update(vm.profile, onSaveSuccess, onSaveError);
        }
        function onSaveSuccess (data) {
            AlertService.success('Virtual bank updated');
            $scope.$emit('bozorakaAdminApp:profileUpdate', data);
            $uibModalInstance.close(data);
            vm.isSaving = false;
        }

        function onSaveError () {
            AlertService.error('Virtual bank update error');
            vm.isSaving = false;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
