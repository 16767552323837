(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('OrderedProductDialogController', OrderedProductDialogController);

    OrderedProductDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'OrderedProduct', 'OrderInfo', 'Product'];

    function OrderedProductDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, OrderedProduct, OrderInfo, Product) {
        var vm = this;

        vm.orderedProduct = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.orderinfos = OrderInfo.query();
        vm.products = Product.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.orderedProduct.id !== null) {
                OrderedProduct.update(vm.orderedProduct, onSaveSuccess, onSaveError);
            } else {
                OrderedProduct.save(vm.orderedProduct, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:orderedProductUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.saleStartDate = false;
        vm.datePickerOpenStatus.saleEndDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
