(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .factory('ProductBox', ProductBox)
        .factory('ProductBoxCategory', ProductBoxCategory);

    ProductBox.$inject = ['$resource'];

    function ProductBox ($resource) {
        var service = $resource('api/product-boxes', {}, {
            'query': {method: 'GET', isArray: true}
        });
        return service;
    }

    ProductBoxCategory.$inject = ['$resource'];

    function ProductBoxCategory ($resource) {
        var service = $resource('api/product-boxes/:categoryId/products', {}, {
            'get': {
                method: 'GET',
                isArray: true
            }
        });
        return service;
    }
})();
