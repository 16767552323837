(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('SellerShippingFee', SellerShippingFee);

    SellerShippingFee.$inject = ['$resource'];

    function SellerShippingFee($resource) {
        var resourceUrl = 'api/seller-shipping-fee:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'update': {method: 'PUT'}
        });
    }
})();
