(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PromoCodeDetailController', PromoCodeDetailController);

    PromoCodeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PromoCode', 'Customer'];

    function PromoCodeDetailController($scope, $rootScope, $stateParams, previousState, entity, PromoCode, Customer) {
        var vm = this;

        vm.promoCode = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:promoCodeUpdate', function(event, result) {
            vm.promoCode = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
