(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('GiftOrderHistoryDialogController', GiftOrderHistoryDialogController);

    GiftOrderHistoryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'GiftOrderHistory', 'GiftOrder'];

    function GiftOrderHistoryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, GiftOrderHistory, GiftOrder) {
        var vm = this;

        vm.giftOrderHistory = entity;
        vm.clear = clear;
        vm.save = save;
        vm.giftorders = GiftOrder.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.giftOrderHistory.id !== null) {
                GiftOrderHistory.update(vm.giftOrderHistory, onSaveSuccess, onSaveError);
            } else {
                GiftOrderHistory.save(vm.giftOrderHistory, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:giftOrderHistoryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
