(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('VirtualBankCount', VirtualBankCount)
        .factory('VirtualBankAvailability', VirtualBankAvailability)
        .factory('VirtualBankTransaction', VirtualBankTransaction)
        .factory('VirtualBankTransactionTotalAmount', VirtualBankTransactionTotalAmount);

    VirtualBankCount.$inject = ['$resource'];

    function VirtualBankCount ($resource) {
        var resourceUrl =  'api/virtual-bank/count';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    VirtualBankAvailability.$inject = ['$resource'];

    function VirtualBankAvailability ($resource) {
        var resourceUrl =  'api/virtual-bank/availability';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    VirtualBankTransaction.$inject = ['$resource'];

    function VirtualBankTransaction ($resource) {
        var resourceUrl =  'api/virtual-bank/transactions';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    VirtualBankTransactionTotalAmount.$inject = ['$resource'];

    function VirtualBankTransactionTotalAmount ($resource) {
        var resourceUrl =  'api/virtual-bank/transactions/total-amount';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET', isArray: false}
        });
    }
})();
