(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('StatsAgentController', StatsAgentController);

    StatsAgentController.$inject = ['$http', 'StatsAgent', 'UserAgents', 'AlertService', '$filter', '$rootScope', 'DateUtils', 'DataUtils'];

    function StatsAgentController($http, StatsAgent, UserAgents, AlertService, $filter, $rootScope, DateUtils, DataUtils) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.clear = clear;
        vm.search = search;
        vm.excel = excel;
        vm.searchQuery = {
            agents: null,
            fromDate: null,
            toDate: null,
            fromZonedDate: null,
            toZonedDate: null,
            serviceTypes: null,
            gateways: null,
            status: null
        };

        vm.getDeductedAmount = DataUtils.getDeductedAmount;
        vm.getSentAmount = DataUtils.getSentAmount;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.fromZonedDate = false;
        vm.datePickerOpenStatus.toZonedDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#gateways').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: all,
                nonSelectedText: noneSelected,
                allSelectedText: allSelected,
                maxHeight: 300
            });

            $('#serviceType').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: all,
                nonSelectedText: noneSelected,
                allSelectedText: allSelected,
                maxHeight: 300
            });

            $('#mode').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics-agent.all'),
                nonSelectedText: translate('statistics-agent.noneSelected'),
                allSelectedText: translate('statistics-agent.allSelected')
            });
            $('#agents').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics-agent.all'),
                nonSelectedText: translate('statistics-agent.noneSelected'),
                allSelectedText: translate('statistics-agent.allSelected'),
                maxHeight: 300
            });
            $('#transactionStatus').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics-agent.all'),
                nonSelectedText: translate('statistics-agent.noneSelected'),
                allSelectedText: translate('statistics-agent.allSelected')
            });
        }, 0);

        getAllAgents();
        getDateTimeFromPicker();

        function getAllAgents() {
            UserAgents.get({}, onSuccess, onError);

            function onSuccess(data) {
                vm.agents = [];
                for (var i = 0; i < data.length; i++) {
                    var agent = {
                        "login": data[i][0],
                        "firstName": data[i][1],
                        "lastName": data[i][2]
                    }
                    vm.agents.push(agent);
                }
                setTimeout(function () {
                    $('#agents').multiselect(
                        'setOptions',
                        {
                            selectAllText: all,
                            nonSelectedText: noneSelected,
                            allSelectedText: allSelected
                        });
                    $('#agents').multiselect('rebuild');
                }, 0)
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getDateTimeFromPicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'fromZonedDate') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'toZonedDate') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }

        function load() {
            StatsAgent.get(vm.searchQuery, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.transactions = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function search() {
            getDateTimeFromPicker()
            load();
        }

        function clear() {
            vm.searchQuery = {
                merchants: null,
                fromDate: null,
                toDate: null,
                fromZonedDate: null,
                toZonedDate: null,
                serviceTypes: null,
                gateways: null,
                status: null
            };
            $('#gateways').multiselect('deselectAll', false);
            $('#gateways').multiselect('updateButtonText');

            $('#serviceType').multiselect('deselectAll', false);
            $('#serviceType').multiselect('updateButtonText');

            $('#mode').multiselect('deselectAll', false);
            $('#mode').multiselect('updateButtonText');

            $('#agents').multiselect('deselectAll', false);
            $('#agents').multiselect('updateButtonText');

            $('#transactionStatus').multiselect('deselectAll', false);
            $('#transactionStatus').multiselect('updateButtonText');

            getDateTimeFromPicker();
        }

        function translateMultiSelect() {
            all = translate('statistics-agent.all');
            noneSelected = translate('statistics-agent.noneSelected');
            allSelected = translate('statistics-agent.allSelected');
        }

        $rootScope.$on('$translateChangeSuccess', function () {
            translateMultiSelect();

            $('#gateways').multiselect(
                'setOptions',
                {
                    selectAllText: all,
                    nonSelectedText: noneSelected,
                    allSelectedText: allSelected
                });
            $('#gateways').multiselect('rebuild');

            $('#serviceType').multiselect(
                'setOptions',
                {
                    selectAllText: all,
                    nonSelectedText: noneSelected,
                    allSelectedText: allSelected
                });
            $('#serviceType').multiselect('rebuild');

            $('#mode').multiselect(
                'setOptions',
                {
                    selectAllText: all,
                    nonSelectedText: noneSelected,
                    allSelectedText: allSelected
                });
            $('#mode').multiselect('rebuild');

            $('#agents').multiselect(
                'setOptions',
                {
                    selectAllText: all,
                    nonSelectedText: noneSelected,
                    allSelectedText: allSelected
                });
            $('#agents').multiselect('rebuild');

            $('#transactionStatus').multiselect(
                'setOptions',
                {
                    selectAllText: all,
                    nonSelectedText: noneSelected,
                    allSelectedText: allSelected
                });
            $('#transactionStatus').multiselect('rebuild');
        });

        function excel() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = new Date($(this).val());
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = new Date($(this).val());
                }
            });

            vm.searchQuery.fromDate = DateUtils.converterDateFormat(vm.searchQuery.fromDate);
            vm.searchQuery.toDate = DateUtils.converterDateFormat(vm.searchQuery.toDate);

            $http({
                url: 'api/stats-merchant-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: vm.searchQuery, //this is your json data string
                headers: {
                    'Content-type': 'application/json'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'statistics-by-agent-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {

            });
        }
    }

})();
