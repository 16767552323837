(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerBalanceDialogController', PartnerBalanceDialogController);

    PartnerBalanceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'AlertService', 'entity', 'Partner'];

    function PartnerBalanceDialogController ($timeout, $scope, $stateParams, $uibModalInstance, AlertService, entity, Partner) {
        var vm = this;

        vm.partner = entity;
        vm.partnerBalance = {
            partnerId: vm.partner.id,
            oldBalance: vm.partner.balance
        };

        vm.partners = Partner.query();
        vm.clear = clear;
        vm.save = save;

        function save() {
            vm.isSaving = true;
            Partner.changeBalance(vm.partnerBalance, onSaveSuccess, onSaveError);
        }
        function onSaveSuccess (data) {
            AlertService.success('Balance updated');
            $scope.$emit('bozorakaAdminApp:partnerUpdate', data);
            $uibModalInstance.close(data);
            vm.isSaving = false;
        }

        function onSaveError () {
            AlertService.error('Balance update error');
            vm.isSaving = false;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
