(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerOrderedProductVariantDialogController', PartnerOrderedProductVariantDialogController);

    PartnerOrderedProductVariantDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PartnerOrderedProductVariant', 'PartnerOrderedProduct'];

    function PartnerOrderedProductVariantDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PartnerOrderedProductVariant, PartnerOrderedProduct) {
        var vm = this;

        vm.partnerOrderedProductVariant = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.partnerorderedproducts = PartnerOrderedProduct.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.partnerOrderedProductVariant.id !== null) {
                PartnerOrderedProductVariant.update(vm.partnerOrderedProductVariant, onSaveSuccess, onSaveError);
            } else {
                PartnerOrderedProductVariant.save(vm.partnerOrderedProductVariant, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:partnerOrderedProductVariantUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
