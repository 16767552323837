(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('CoupangOrderInfoController', CoupangOrderInfoController);

    CoupangOrderInfoController.$inject = ['$state', '$http', 'DataUtils', 'CoupangOrderInfo', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function CoupangOrderInfoController($state, $http, DataUtils, CoupangOrderInfo, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.search = search;
        vm.clear = clear;
        vm.processOrderInfo = processOrderInfo;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.searchQuery = pagingParams.search;

        vm.searchQuery = {
            field: pagingParams.field,
            frmCreatedDate: pagingParams.frmCreatedDate,
            toCreatedDate: pagingParams.toCreatedDate,
            fromPaidDate: pagingParams.fromPaidDate,
            toPaidDate: pagingParams.toPaidDate,
            status: pagingParams.status
        };


        vm.showJsonModal = function (jsonData) {
            try {
                vm.selectedJson = JSON.parse(jsonData);
            } catch (e) {
                console.error("Invalid JSON string", e);
                vm.selectedJson = {error: "Invalid JSON format"};
            }
            $('#jsonModal').modal('show');
        };

        function processOrderInfo(orderInfo, index) {
            CoupangOrderInfo.processOrderInfo({'id': orderInfo.id}, onSaveSuccess, onSaveError);

            function onSaveSuccess(data) {
                vm.coupangOrderInfos[index]=data;
            }

            function onSaveError(error) {
                var errorMessage = error.data && error.data.title ? error.data.title : (error.data.description ? error.data.description :'An error occurred while processing the order.');
                AlertService.error(errorMessage);
            }
        }

        initDatePicker();
        loadAll();

        function initDatePicker() {
            $(document).ready(function () {
                $('.date-picker input').each(function () {
                    if ($(this).attr('name') == 'fromOrdered') {
                        vm.searchQuery.frmCreatedDate = $(this).val();
                    } else if ($(this).attr('name') == 'toOrdered') {
                        vm.searchQuery.toCreatedDate = $(this).val();
                    } else if ($(this).attr('name') == 'fromPaid') {
                        vm.searchQuery.fromPaidDate = $(this).val();
                    } else if ($(this).attr('name') == 'toPaid') {
                        vm.searchQuery.toPaidDate = $(this).val();
                    }
                });
            });
            $('.date-picker').datepicker({autoclose: true});
        }

        function loadAll() {
            CoupangOrderInfo.query({
                field: vm.searchQuery.field,
                frmCreatedDate: vm.searchQuery.frmCreatedDate,
                toCreatedDate: vm.searchQuery.toCreatedDate,
                fromPaidDate: vm.searchQuery.fromPaidDate,
                toPaidDate: vm.searchQuery.toPaidDate,
                status: vm.searchQuery.status,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.coupangOrderInfos = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                field: vm.searchQuery.field,
                frmCreatedDate: vm.searchQuery.frmCreatedDate,
                toCreatedDate: vm.searchQuery.toCreatedDate,
                fromPaidDate: vm.searchQuery.fromPaidDate,
                toPaidDate: vm.searchQuery.toPaidDate,
                status: vm.searchQuery.status,
                firstRun: false
            });
        }

        function search(searchQuery) {
            $(document).ready(function () {
                $('.date-picker input').each(function () {
                    if ($(this).attr('name') == 'fromOrdered') {
                        vm.searchQuery.frmCreatedDate = $(this).val();
                    } else if ($(this).attr('name') == 'toOrdered') {
                        vm.searchQuery.toCreatedDate = $(this).val();
                    } else if ($(this).attr('name') == 'fromPaid') {
                        vm.searchQuery.fromPaidDate = $(this).val();
                    } else if ($(this).attr('name') == 'toPaid') {
                        vm.searchQuery.toPaidDate = $(this).val();
                    }
                });
            });

            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = {
                field: null,
                frmCreatedDate: null,
                toCreatedDate: null,
                fromPaidDate: null,
                toPaidDate: null,
                status: null
            };
            loadAll();
        }
    }
})();
