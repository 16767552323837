(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('GiftOrderDetailController', GiftOrderDetailController);

    GiftOrderDetailController.$inject = ['$scope', '$rootScope', 'previousState', 'DataUtils', 'entity', 'GiftOrder', 'Transaction', 'GiftOrderHistory', 'DateUtils'];

    function GiftOrderDetailController($scope, $rootScope, previousState, DataUtils, entity, GiftOrder, Transaction, GiftOrderHistory, DateUtils) {
        var vm = this;

        vm.giftOrder = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.dateDiff = DateUtils.transactionDateDifference;
        vm.saveGiftOrderHistory = saveGiftOrderHistory;
        vm.transaction = Transaction.get({id: vm.giftOrder.transactionId});
        vm.boxColor = boxColor;

        getTransaction();
        statusDisabled();

        function saveGiftOrderHistory() {
           if (vm.notify) {
               vm.giftOrderHistory.event = 'CUSTOMER_NOTIFIED';
           } else {
               vm.giftOrderHistory.event = 'COMMENTED';
           }
            var giftOrderHistory = {
                giftOrderId: vm.giftOrder.id,
                comment: vm.giftOrderHistory.comment,
                status: vm.giftOrder.status,
                event: vm.giftOrderHistory.event
            };
            GiftOrderHistory.save(giftOrderHistory, onSaveSuccess);

            function onSaveSuccess(data) {
                vm.giftOrderHistory.comment = '';
                vm.giftOrder = GiftOrder.get({id: vm.giftOrder.id});

            }
        }

        function statusDisabled() {
            if (vm.giftOrder.status == 'SHIPPED' || vm.giftOrder.status == 'CANCELED') {
                vm.status = true;
            }
        }

        function getTransaction() {
            Transaction.get({id: vm.giftOrder.transactionId},
                function (data) {
                    vm.transaction = data;
                });
        }

        function boxColor(status) {
            if (status != undefined) {
                if (status == 'SUCCESS') {
                    return 'green-seagreen';
                } else if (status == 'FAILURE') {
                    return 'red-mint';
                } else if (status == 'INPROGRESS') {
                    return 'yellow-saffron';
                }
            }
        }

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:giftOrderUpdate', function (event, result) {
            vm.giftOrder = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
