(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('OrderInfo', OrderInfo)
        .factory('OrderInfoDelivery', OrderInfoDelivery)
        .factory('OrderInfoAddressChangeLog', OrderInfoAddressChangeLog);

    OrderInfo.$inject = ['$resource'];

    function OrderInfo($resource) {
        var resourceUrl = 'api/order-infos/:id';

        return function (headers) {
            return $resource(resourceUrl, {}, {
                'query': {method: 'GET', isArray: true, headers: headers || {}},
                'get': {
                    method: 'GET',
                    headers: headers || {},
                    transformResponse: function (data) {
                        if (data) {
                            data = angular.fromJson(data);
                        }
                        return data;
                    }
                },
                'update': {method: 'PUT'},
                'updateCustomerInfo': {
                    method: 'PUT',
                    url: 'api/order-customer-infos',
                    headers: {
                        'Content-type': 'application/x-www-form-urlencoded'
                    }
                }
            });
        }
    }

    OrderInfoDelivery.$inject = ['$resource'];

    function OrderInfoDelivery($resource) {
        var resourceUrl = 'api/order-infos/:id/delivery';

        return $resource(resourceUrl, {"id": "@id"}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'}
        });
    }

    OrderInfoAddressChangeLog.$inject = ['$resource'];

    function OrderInfoAddressChangeLog($resource) {
        var resourceUrl = 'api/order-info/:id/address-change-logs';

        return $resource(resourceUrl, {"id": "@id"}, {
            'get': { method: 'GET', isArray: true }
        });
    }

})();
