(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductController', ProductController);

    ProductController.$inject = ['$scope', '$state', 'DataUtils', 'Principal', 'Product', 'ProductSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Category', '$translate'
        ,'$rootScope', 'SellerUser', '$filter', 'DepoService'];

    function ProductController($scope, $state, DataUtils, Principal, Product, ProductSearch, ParseLinks, AlertService, paginationConstants, pagingParams, Category, $translate, $rootScope, SellerUser, $filter, DepoService) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.categories = Category.getAllCategories();
        vm.getCategories = getCategories;
        vm.lang = $translate.use();
        vm.saveProductStatus = saveProductStatus;
        vm.productStatuses = ["AVAILABLE", "OUT_OF_STOCK", "DISABLED"]

        vm.searchQuery = {
            field: pagingParams.field,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            category: pagingParams.category,
            seller: pagingParams.seller,
            statuses: pagingParams.statuses
        };

        initDatePicker();
        if (!pagingParams.firstRun) {
            loadAll();
        }
        if (!Principal.hasAnyAuthority(['ROLE_SELLER'])) {
            getSellers();
        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function loadAll() {
            Product.query({
                field: vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                valueLong1: vm.searchQuery.category,
                sellerId: vm.searchQuery.seller,
                statuses: vm.searchQuery.statuses,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                language: vm.lang
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.products = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                field: vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                category: vm.searchQuery.category,
                seller: vm.searchQuery.seller,
                statuses: vm.searchQuery.statuses,
                firstRun: false
            });
        }

        function search(searchQuery) {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });


            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = {
                field: null,
                fromDate: null,
                toDate: null,
                category: -1,
                seller: -1,
                statuses: ["AVAILABLE", "OUT_OF_STOCK", "DISABLED"]
            };
            vm.transition();
        }

        function getCategories(product) {
            var categories = product.categories;
            var categoriesStr = "";
            for (var i = 0; i < categories.length; i++) {
                var category = categories[i];
                if (i == categories.length - 1) {
                    categoriesStr += category.nameUzb;
                } else {
                    categoriesStr += category.nameUzb + ", ";
                }
            }
            return categoriesStr;
        }

        function getSellers() {
            SellerUser.query(onSuccess);

            function onSuccess(data) {
                vm.sellers = data;
            }
        }

        var unsubscribe = $rootScope.$on('translateChanged', function (event, lang) {
            vm.lang = lang;
            loadAll();
        });
        $rootScope.$on('$destroy', unsubscribe);

        function saveProductStatus(id, productStatus, purchasingAgentId, expirationDate, minInventoryQuantity, partnerMinInventoryQuantity, minOrderQuantity, partnerMinOrderQuantity) {
            var product = {
                "id": id,
                "stockStatus": productStatus,
                "purchasingAgentId": purchasingAgentId,
                "expirationDate": expirationDate,
                "minInventoryQuantity": minInventoryQuantity,
                "partnerMinInventoryQuantity": partnerMinInventoryQuantity,
                "minOrderQuantity": minOrderQuantity,
                "partnerMinOrderQuantity": partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
            function onSaveSuccess() {
                loadAll();
            }
            function onSaveError() {
            }
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#productStatus').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected')
            });

        }, 0);


        function translateMultiSelect() {
            all = translate('product.all');
            noneSelected = translate('product.noneSelected');
            allSelected = translate('product.allSelected');
        }
    }
})();
