(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MobileTariffDetailController', MobileTariffDetailController);

    MobileTariffDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MobileTariff', 'MobileOperator'];

    function MobileTariffDetailController($scope, $rootScope, $stateParams, previousState, entity, MobileTariff, MobileOperator) {
        var vm = this;

        vm.mobileTariff = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:mobileTariffUpdate', function(event, result) {
            vm.mobileTariff = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
