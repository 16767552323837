(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('CategoryDialogController', CategoryDialogController);

    CategoryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Category', 'SpecialCategory'];

    function CategoryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Category, SpecialCategory) {
        var vm = this;

        vm.category = entity;
        vm.categories = [];
        vm.clear = clear;
        vm.save = save;
        vm.cleanSlugUrl = cleanSlugUrl;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.category.isSpecial) {
                if (vm.category.id !== null) {
                    SpecialCategory.update(vm.category, onSaveSuccess, onSaveError);
                } else {
                    SpecialCategory.save(vm.category, onSaveSuccess, onSaveError);
                }
            } else {
                if (vm.category.id !== null) {
                    Category.update(vm.category, onSaveSuccess, onSaveError);
                } else {
                    Category.save(vm.category, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:categoryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        loadAll();

        function loadAll() {
            if (!vm.category.isSpecial) {
                Category.query(function(result) {
                    vm.categories = result;
                    defineParentCategories();
                });
            }
        }

        function defineParentCategories() {
            vm.category['isParent'] = false;
            for (var j = 0; j < vm.categories.length; j++) {
                if (vm.category.id !== null
                    && vm.category.id === vm.categories[j]['parentId']) {
                    vm.category['isParent'] = true;
                    break;
                }
            }
        }

        function cleanSlugUrl() {
            vm.category.slugUz = vm.category.slugUz.replace(/([^a-z0-9-]+)/gi, '');
            vm.category.slugRu = vm.category.slugRu.replace(/([^a-z0-9-]+)/gi, '');
            vm.category.slugEn = vm.category.slugEn.replace(/([^a-z0-9-]+)/gi, '');
            vm.category.slugKo = vm.category.slugKo.replace(/([^a-z0-9-]+)/gi, '');
        }

    }
})();
