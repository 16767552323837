(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductDialogController', ProductDialogController);

    ProductDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Product', 'Category', 'ImageStorage', 'SellerShippingFee', 'Principal'];

    function ProductDialogController($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Product, Category, ImageStorage, SellerShippingFee, Principal) {
        var vm = this;
        var imageOrder = 0;
        vm.product = entity;
        vm.datePickerOpenStatus = {};
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.categories = Category.query();
        vm.openCalendar = openCalendar;
        vm.clear = clear;
        vm.save = save;
        vm.saveImage = saveImage;
        vm.onSaveSuccessImageStorage = onSaveSuccessImageStorage;
        vm.onSaveErrorImageStorage = onSaveErrorImageStorage;
        vm.confirmDelete = confirmDelete;

        dateFormat();
        editorOptions();
        setTimeout(function () {
            $('.date-product').datepicker({autoclose: true});
        }, 0);

        function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.imageStorage = data;
        }

        SellerShippingFee.query({}, onShippingFeeSuccess, onShippingFeeError);

        function onShippingFeeSuccess(data, headers) {
            vm.users = data;
            if (vm.product.sellerId == undefined || vm.product.sellerId == null) {
                if (Principal.hasAnyAuthority(['ROLE_SELLER'])) {
                    if (vm.users.length > 0) {
                        vm.product.sellerId = vm.users[0].userId;
                    }
                }
            }
        }

        function onShippingFeeError(error) {
            AlertService.error(error.data.message);
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == "saleStartDate") {
                    vm.product.saleStartDate = new Date($(this).val());
                } else if ($(this).attr('name') == "saleEndDate") {
                    vm.product.saleEndDate = new Date($(this).val());
                } else if ($(this).attr('name') == "availableDate") {
                    vm.product.availableDate = new Date($(this).val());
                }
            });

            vm.product.descriptionEng = $('#field_descriptionEng').summernote('code');
            vm.product.shippingEng = $('#field_shippingEng').summernote('code');
            vm.product.descriptionRus = $('#field_descriptionRus').summernote('code');
            vm.product.shippingRus = $('#field_shippingRus').summernote('code');
            vm.product.descriptionUzb = $('#field_descriptionUzb').summernote('code');
            vm.product.shippingUzb = $('#field_shippingUzb').summernote('code');

            if (vm.product.id !== null) {
                Product.update(vm.product, onSaveSuccess, onSaveError);
            } else {
                Product.save(vm.product, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bozorakaAdminApp:productUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.setData = function ($file, id, createdDate, sortOrder) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        if (id == null) {
                            imageOrder = null;
                        } else {
                            imageOrder = sortOrder;
                        }
                        var image = createDomImage(base64Data);
                        setTimeout(function () {
                            var compressedImage = getCompressedImage(image);
                            var imageStorage = makeImageStorage(id, $file, compressedImage, createdDate, sortOrder);
                            saveImage(imageStorage);
                        }, 0);
                    });
                });
            }
        };

        function createDomImage(base64Data) {
            var sourceImg = document.createElement("IMG");
            var src = "data:image/jpeg;base64,";
            src += base64Data;
            sourceImg.src = src;
            return sourceImg;
        }

        function getCompressedImage(sourceImage) {
            var quality = 30;
            var outputFormat = 'jpg';
            var compressedImage = jic.compress(sourceImage, quality, outputFormat);
            return compressedImage.src.replace("data:image/jpeg;base64,", "");
        }

        function makeImageStorage(id, $file, data, createdDate, sortOrder) {
            var imageStorage = {
                id: id,
                name: $file.name,
                data: data,
                dataContentType: $file.type,
                sortOrder: sortOrder,
                productId: vm.product.id,
                createdDate: createdDate
            };
            return imageStorage;
        }

        function saveImage(imageStorage) {
            vm.isSaving = true;
            if (imageStorage.id != null) {
                ImageStorage.update(imageStorage, onSaveSuccessImageStorage, onSaveErrorImageStorage);
            } else {
                ImageStorage.save(imageStorage, onSaveSuccessImageStorage, onSaveErrorImageStorage);
            }
        }

        function onSaveSuccessImageStorage(result) {
            vm.isSaving = false;
            vm.product = Product.get({id: $stateParams.id});
            getImageOrder();
        }

        function onSaveErrorImageStorage() {
            vm.isSaving = false;
        }

        function confirmDelete(id) {
            ImageStorage.delete({id: id},
                function () {
                    vm.product = Product.get({id: $stateParams.id});

                });
        }

        function getImageOrder() {
            var imageStorages = vm.product.imageStorages;
            if (imageStorages != null) {
                return imageStorages.length;
            } else {
                return 0;
            }
        }

        function dateFormat() {
            if (vm.product.availableDate != null) {
                vm.availableDate = new Date(vm.product.availableDate);
                vm.availableDate = dateConvert(vm.availableDate);
            }
            if (vm.product.saleStartDate != null) {
                vm.saleStartDate = new Date(vm.product.saleStartDate);
                vm.saleStartDate = dateConvert(vm.saleStartDate);
            }
            if (vm.product.saleEndDate != null) {
                vm.saleEndDate = new Date(vm.product.saleEndDate);
                vm.saleEndDate = dateConvert(vm.saleEndDate);
            }
        }

        function dateConvert(date) {
            if (date != undefined) {
                var month = date.getMonth() + 1;
                var dateMonth = date.getDate();

                return setZero(month) + '/' + setZero(dateMonth) + '/' + date.getFullYear();
            } else {
                return '';
            }
        }

        function setZero(param) {
            if (param < 10) {
                return '0' + param;
            } else {
                return param;
            }
        }

        function editorOptions() {
            $scope.options = {
                height: 400,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
                    ['color', ['color']],
                    ['fontsize', ['fontsize']],
                    ['fontface', ['fontname']],
                    ['picture', ['picture']],
                    ['insert', ['link']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['height', ['height']]
                ]
            };
        }

        vm.toggleTabs = function () {
            var description = $('.description');
            var price = $('.price');
            if (description.hasClass('active')){
                description.removeClass('active');
                price.addClass('active')
            } else if (price.hasClass('active')){
                price.removeClass('active');
                description.addClass('active')
            }
        };
    }

})();
