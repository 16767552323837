(function () {
    'use strict';

    angular
            .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('market-stats-daily', {
                parent: 'entity',
                url: '/market-stats-daily',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST'],
                    pageTitle: 'global.menu.stats.daily'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/market-stats/market-stats-daily.html',
                        controller: 'MarketStatsDailyController',
                        controllerAs: 'vm'
                    }
                },

                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('statsMerchants');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('market-stats-periodical', {
                parent: 'entity',
                url: '/market-stats-periodical',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST'],
                    pageTitle: 'global.menu.stats.periodical'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/market-stats/market-stats-periodical.html',
                        controller: 'MarketStatsPeriodicalController',
                        controllerAs: 'vm'
                    }
                },

                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('statsMerchants');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('market-stats-top-selling', {
                parent: 'entity',
                url: '/market-stats-top-selling',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST'],
                    pageTitle: 'global.menu.stats.productsSold'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/market-stats/market-stats-top-selling.html',
                        controller: 'MarketStatsTopSellingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('statsMerchants');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('market-stats-weekly', {
                parent: 'entity',
                url: '/market-stats-weekly',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST'],
                    pageTitle: 'global.menu.stats.weekly'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/market-stats/market-stats-weekly.html',
                        controller: 'MarketStatsWeeklyController',
                        controllerAs: 'vm'
                    }
                },

                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('statsMerchants');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('market-stats-shipping-fee', {
                parent: 'entity',
                url: '/market-stats-shipping-fee',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST'],
                    pageTitle: 'global.menu.stats.shippingFee'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/market-stats/market-stats-shipping-fee.html',
                        controller: 'MarketStatsShippingFeeController',
                        controllerAs: 'vm'
                    }
                },

                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('shippingData');
                        $translatePartialLoader.addPart('transaction');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
