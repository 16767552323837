(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('GiftController', GiftController);

    GiftController.$inject = ['$state', 'Gift', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'GiftCategory'];

    function GiftController($state, Gift, ParseLinks, AlertService, paginationConstants, pagingParams, GiftCategory) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.searchQuery = {
            name: pagingParams.name,
            categoryId: pagingParams.categoryId,
            status: pagingParams.status
        };

        getCategories();

        loadAll();

        function getCategories() {
            GiftCategory.query(onSuccess);

            function onSuccess(data) {
                vm.categories = data;
            }
        }

        function search() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = {
                name: null,
                categoryId: null,
                status: null
            };
            vm.transition();
        }

        function loadAll () {
            Gift.query({
                name: vm.searchQuery.name,
                categoryId: vm.searchQuery.categoryId,
                status: vm.searchQuery.status,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.gifts = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                name: vm.searchQuery.name,
                categoryId: vm.searchQuery.categoryId,
                status: vm.searchQuery.status
            });
        }
    }
})();
