(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('KakaoPaymentRefundController', KakaoPaymentRefundController);

    KakaoPaymentRefundController.$inject = ['$uibModalInstance', 'entity', 'KakaoPayment'];

    function KakaoPaymentRefundController($uibModalInstance, entity, KakaoPayment) {
        var vm = this;
        vm.kakaoPayment = entity;
        vm.clear = clear;
        vm.confirmRefund = confirmRefund;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmRefund() {
            KakaoPayment.refund({paymentId: vm.kakaoPayment.paymentId},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
