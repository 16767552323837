(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('AboutUsController', AboutUsController);

    AboutUsController.$inject = ['AboutUs', '$scope', 'SitePage'];

    function AboutUsController(AboutUs, $scope, SitePage) {

        var vm = this;
        vm.save = save;

        load("uz");
        load("en");
        load("ru");
        load("ko");
        editorOptions();

        function load(lang) {
            AboutUs.get({
                language: lang
            }, onSuccess);
            function onSuccess(data) {
                if (lang == "uz") {
                    vm.aboutUsUz = data;
                } else if (lang == "en") {
                    vm.aboutUsEn = data;
                } else if (lang == "ko") {
                    vm.aboutUsKo = data;
                } else {
                    vm.aboutUsRu = data;
                }
            }
        }

        function editorOptions() {
            $scope.options = {
                height: 400
            };
        }

        function save(aboutUs) {
            vm.isSaving = true;

            SitePage.update(aboutUs, onSuccess, onError);

            function onSuccess(result) {
                $scope.$emit('bozorakaAdminApp:sitePageUpdate', result);
                vm.isSaving = false;
            }

            function onError() {
                vm.isSaving = false;
            }
        }


    }
})();
