(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('ProfileVOC', ProfileVOC);

    ProfileVOC.$inject = ['$resource'];

    function ProfileVOC($resource) {
        var resourceUrl = 'api/profiles/:profileId/voc';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

})();
