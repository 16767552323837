(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('partner', {
            parent: 'entity',
            url: '/partner?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.partner.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/partner/partners.html',
                    controller: 'PartnerController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'login,asc',
                    squash: true
                },
                field: null,
                balanceStatuses: ['negative', 'zero', 'positive'],
                valueLong1: 0,
                customerStatus: 'ACTIVE'
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        field: $stateParams.field,
                        balanceStatuses: $stateParams.balanceStatuses,
                        valueLong1: $stateParams.valueLong1,
                        customerStatus: $stateParams.customerStatus
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('partner');
                    $translatePartialLoader.addPart('partnerStatus');
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('agent', {
            parent: 'entity',
            url: '/agent?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.partner.agent.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/partner/agents.html',
                    controller: 'AgentController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'login,asc',
                    squash: true
                },
                status: 'ACTIVE'
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        status: $stateParams.status
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('partner');
                    $translatePartialLoader.addPart('partnerStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('partner-detail', {
            parent: 'entity',
            url: '/partner/{id}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER'],
                pageTitle: 'bozorakaAdminApp.partner.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/partner/partner-detail.html',
                    controller: 'PartnerDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('partner');
                    $translatePartialLoader.addPart('partnerStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Partner', function($stateParams, Partner) {
                    return Partner.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'partner',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('partner-detail.edit', {
            parent: 'partner-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/partner/partner-dialog.html',
                    controller: 'PartnerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Partner', function(Partner) {
                            return Partner.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('partner.new', {
            parent: 'partner',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/partner/partner-dialog.html',
                    controller: 'PartnerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                firstName: null,
                                lastName: null,
                                login: null,
                                password: null,
                                accessToken: null,
                                phone: null,
                                address: null,
                                balance: null,
                                deferredPaymentLimit: null,
                                remainingDeferredPayments: null,
                                status: null,
                                createdBy: null,
                                createdDate: null,
                                lastModifiedBy: null,
                                lastModifiedDate: null,
                                id: null,
                                bankId: 0
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('partner', null, { reload: 'partner' });
                }, function() {
                    $state.go('partner');
                });
            }]
        })
        .state('agent.new', {
            parent: 'agent',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/partner/agent-dialog.html',
                    controller: 'AgentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                firstName: null,
                                lastName: null,
                                login: null,
                                password: null,
                                accessToken: null,
                                phone: null,
                                address: null,
                                balance: null,
                                deferredPaymentLimit: null,
                                remainingDeferredPayments: null,
                                status: null,
                                createdBy: null,
                                createdDate: null,
                                lastModifiedBy: null,
                                lastModifiedDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('agent', null, { reload: 'agent' });
                }, function() {
                    $state.go('agent');
                });
            }]
        })
        .state('partner.edit', {
            parent: 'partner',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/partner/partner-dialog.html',
                    controller: 'PartnerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Partner', function(Partner) {
                            return Partner.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('partner', null, { reload: 'partner' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('partner-detail.balanceEdit', {
            parent: 'partner-detail',
            url: '/balance/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/partner/partner-balance-dialog.html',
                    controller: 'PartnerBalanceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['Partner', function(Partner) {
                            return Partner.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('partner-transaction', {
            parent: 'entity',
            url: '/partner/{id}/transactions?page&sort',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER'],
                pageTitle: 'bozorakaAdminApp.partner.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/partner/partner-transaction.html',
                    controller: 'PartnerTransactionController',
                    controllerAs: 'vm'
                }
            },
            params: {
                pageBalHist: {
                    value: '1',
                    squash: true
                },
                sortBalHist: {
                    value: 'id,desc',
                    squash: true
                },
                pageOrdHist: {
                    value: '1',
                    squash: true
                },
                sortOrdHist: {
                    value: 'id,desc',
                    squash: true
                },
                activeTab: 'tab_transaction_history',
                firstRun: true
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        pageBalHist: PaginationUtil.parsePage($stateParams.pageBalHist),
                        sortBalHist: $stateParams.sortBalHist,
                        predicateBalHist: PaginationUtil.parsePredicate($stateParams.sortBalHist),
                        ascendingBalHist: PaginationUtil.parseAscending($stateParams.sortBalHist),
                        pageOrdHist: PaginationUtil.parsePage($stateParams.pageOrdHist),
                        sortOrdHist: $stateParams.sortOrdHist,
                        predicateOrdHist: PaginationUtil.parsePredicate($stateParams.sortOrdHist),
                        ascendingOrdHist: PaginationUtil.parseAscending($stateParams.sortOrdHist),
                        activeTab: $stateParams.activeTab,
                        firstRun: $stateParams.firstRun
                    }
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('partner');
                    $translatePartialLoader.addPart('partnerStatus');
                    $translatePartialLoader.addPart('orderStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Partner', function($stateParams, Partner) {
                    return Partner.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'partner',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('partner.delete', {
            parent: 'partner',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/partner/partner-delete-dialog.html',
                    controller: 'PartnerDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Partner', function(Partner) {
                            return Partner.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('partner', null, { reload: 'partner' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('partner-detail.bankEdit', {
            parent: 'partner-detail',
            url: '/bank/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/partner/partner-bank-dialog.html',
                    controller: 'PartnerBankDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['Partner', function(Partner) {
                            return Partner.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
