(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MerchantTransactionController', MerchantTransactionController);

    MerchantTransactionController.$inject = ['$http', '$scope', '$state', 'entity', 'MerchantTransaction', 'MerchantBalance', 'ParseLinks', 'AlertService', 'pagingParams', 'DateUtils', 'DataUtils'];

    function MerchantTransactionController($http, $scope, $state, entity, MerchantTransaction, MerchantBalance, ParseLinks, AlertService, pagingParams, DateUtils, DataUtils) {
        var vm = this;

        vm.profile = entity;
        vm.itemsPerPage = 10;
        vm.activeTab = pagingParams.activeTab;
        vm.activateTab = activateTab;

        vm.predicateTranHist = pagingParams.predicateTranHist;
        vm.reverseTranHist = pagingParams.ascendingTranHist;
        vm.loadPage = loadPage;
        vm.transitionTranHist = transitionTranHist;
        vm.loadAllTranHist = loadAllTranHist;
        vm.excelTranHist = excelTranHist;
        vm.clearTranHist = clearTranHist;
        vm.searchTranHist = searchTranHist;
        vm.dateChanged = dateChanged;

        vm.predicateBalHist = pagingParams.predicateBalHist;
        vm.reverseBalHist = pagingParams.ascendingBalHist;
        vm.transitionBalHist = transitionBalHist;
        vm.loadAllBalHist = loadAllBalHist;

        vm.dateDiff = DateUtils.transactionDateDifference;
        vm.getDeductedAmount = DataUtils.getDeductedAmount;
        vm.getSentAmount = DataUtils.getSentAmount;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.searchQuery = {
            field: pagingParams.field,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            fromZonedDate: pagingParams.fromZonedDate,
            toZonedDate: pagingParams.toZonedDate,
            serviceType: pagingParams.serviceType,
            transactionStatus: pagingParams.transactionStatus
        };

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.fromZonedDate = false;
        vm.datePickerOpenStatus.toZonedDate = false;
        vm.isValidDateRange = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getDateTimeFromPicker() {
            $('.input-daterange input').each(function() {
                if ($(this).attr('name') == 'fromZonedDate') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'toZonedDate') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }

        loadAllTranHist();
        loadAllBalHist();

        function loadAllTranHist() {
            MerchantTransaction.query({
                field: pagingParams.field,
                fromDate: pagingParams.fromDate,
                toDate: pagingParams.toDate,
                fromZonedDate: pagingParams.fromZonedDate,
                toZonedDate: pagingParams.toZonedDate,
                serviceType: pagingParams.serviceType,
                transactionStatus: pagingParams.transactionStatus,
                profileId: vm.profile.id,
                page: pagingParams.pageTranHist - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicateTranHist + ',' + (vm.reverseTranHist ? 'asc' : 'desc')];
                if (vm.predicateTranHist !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.linksTranHist = ParseLinks.parse(headers('link'));
                vm.totalItemsTranHist = headers('X-Total-Count');
                vm.queryCountTranHist = vm.totalItemsTranHist;
                vm.transactionHistories = data;
                vm.pageTranHist = pagingParams.pageTranHist;
                dateChanged(vm.searchQuery.fromZonedDate, vm.searchQuery.toZonedDate);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionTranHist() {
            $state.transitionTo($state.$current, {
                id: vm.profile.id,
                pageTranHist: vm.pageTranHist,
                sortTranHist: vm.predicateTranHist + ',' + (vm.reverseTranHist ? 'asc' : 'desc'),
                field: vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                fromZonedDate: vm.searchQuery.fromZonedDate,
                toZonedDate: vm.searchQuery.toZonedDate,
                serviceType: vm.searchQuery.serviceType,
                transactionStatus: vm.searchQuery.transactionStatus,
                pageBalHist: vm.pageBalHist,
                sortBalHist: vm.predicateBalHist + ',' + (vm.reverseBalHist ? 'asc' : 'desc'),
                activeTab: vm.activeTab
            });
            dateChanged(vm.searchQuery.fromZonedDate, vm.searchQuery.toZonedDate);
        }

        function searchTranHist() {
            getDateTimeFromPicker();
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'pubDate';
            vm.reverse = false;
            vm.transitionTranHist();
        }

        function excelTranHist () {
            getDateTimeFromPicker();
            if (vm.isValidDateRange) {
                var fromDate = DateUtils.converterDateTimeFormat(vm.searchQuery.fromDate);
                var toDate = DateUtils.converterDateTimeFormat(vm.searchQuery.toDate);
                var data = "request=" + vm.searchQuery.field + "&status=" + vm.searchQuery.transactionStatus + "&serviceType=" + vm.searchQuery.serviceType + "&fromDate=" + fromDate + '&toDate=' + toDate;
                $http({
                    url: 'api/merchant-transaction-history-excel',
                    method: 'POST',
                    responseType: 'arraybuffer',
                    data: data,
                    headers: {
                        'Content-type': 'application/x-www-form-urlencoded'
                    }
                }).success(function (data) {
                    var blob = new Blob([data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    });

                    saveAs(blob, 'transactions-' + DateUtils.currentTimestamp() + '.xlsx');
                }).error(function () {
                });
            }
        }

        function clearTranHist() {
            vm.links = null;
            vm.page = 1;
            vm.predicateTranHist = 'pubDate';
            vm.reverseTranHist = false;
            vm.searchQuery = {
                field: null,
                fromDate: null,
                toDate: null,
                serviceType: 'UNKNOWN',
                status: 'UNKNOWN'
            };
            vm.transitionTranHist();
        }

        function loadPage(page) {
            vm.pageTranHist = page;
            vm.transitionTranHist();
        }

        function dateChanged(fromDate, toDate) {
            if (fromDate === null || toDate === null) {
                vm.isValidDateRange = false;
                return;
            }

            var daysDuration = moment(toDate).diff(moment(fromDate), 'months');
            vm.isValidDateRange = (daysDuration >= 0 && daysDuration < 2);
        }

        function loadAllBalHist() {
            MerchantBalance.query({
                profileId: vm.profile.id,
                page: pagingParams.pageBalHist - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicateBalHist + ',' + (vm.reverseBalHist ? 'asc' : 'desc')];
                if (vm.predicateBalHist !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.linksBalHist = ParseLinks.parse(headers('link'));
                vm.totalItemsBalHist = headers('X-Total-Count');
                vm.queryCountBalHist = vm.totalItemsBalHist;
                vm.balanceHistories = data;
                vm.pageBalHist = pagingParams.pageBalHist;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionBalHist() {
            $state.transitionTo($state.$current, {
                id: vm.profile.id,
                pageTranHist: vm.pageTranHist,
                sortTranHist: vm.predicateTranHist + ',' + (vm.reverseTranHist ? 'asc' : 'desc'),
                pageBalHist: vm.pageBalHist,
                sortBalHist: vm.predicateBalHist + ',' + (vm.reverseBalHist ? 'asc' : 'desc'),
                activeTab: vm.activeTab
            });
        }


        function activateTab(tab) {
            vm.activeTab = tab;
        }
    }
})();
