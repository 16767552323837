(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MileageDetailController', MileageDetailController);

    MileageDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Mileage', 'Profile', 'Transaction'];

    function MileageDetailController($scope, $rootScope, $stateParams, previousState, entity, Mileage, Profile, Transaction) {
        var vm = this;

        vm.mileage = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:mileageUpdate', function(event, result) {
            vm.mileage = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
