(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('HanpassUser', HanpassUser);

    HanpassUser.$inject = ['$resource', 'DateUtils'];

    function HanpassUser ($resource, DateUtils) {
        var resourceUrl =  'api/hanpass-users/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.registeredDate = DateUtils.convertDateTimeFromServer(data.registeredDate);
                    }
                    return data;
                }
            },
            'userBonus': {
                method: 'GET',
                url: resourceUrl + '/bonus'
            },
            'update': { method:'PUT' },
            'getTotals': {
                url: 'api/hanpass-users-totals',
                method: 'GET'
            }
        });
    }
})();
