(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerOrderPaymentStatusChangeDialogController', PartnerOrderPaymentStatusChangeDialogController);

    PartnerOrderPaymentStatusChangeDialogController.$inject = ['$state', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PartnerOrderInfo', '$translate'];

    function PartnerOrderPaymentStatusChangeDialogController($state, $timeout, $scope, $stateParams, $uibModalInstance, entity, PartnerOrderInfo, $translate) {
        var vm = this;

        vm.partnerOrderInfo = entity;
        vm.addDeductAmount = vm.partnerOrderInfo.totalPrice;
        vm.shouldChangeBalance = false;
        vm.paymentStatus = $stateParams.paymentStatus;
        vm.clear = clear;
        vm.save = save;
        vm.isPaying = isPaying;

        function isPaying() {
            return vm.paymentStatus === 'PAID';
        }

        function save() {
            vm.isSaving = true;
            vm.partnerOrderPaymentStatus = {
                id: vm.partnerOrderInfo.id,
                paymentStatus: vm.paymentStatus,
                shouldChangeBalance: vm.shouldChangeBalance
            };
            PartnerOrderInfo.updatePaymentStatus(vm.partnerOrderPaymentStatus, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            PartnerOrderInfo.get({id: vm.partnerOrderInfo.id}, function (data) {
                vm.partnerOrderInfo = data;
                $scope.$emit('bozorakaAdminApp:partnerOrderInfoUpdate', result);
                $uibModalInstance.close(result);
                vm.isSaving = false;
            });
        }
        function onSaveError() {
            vm.isSaving = false;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();

