(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('market-request', {
                parent: 'entity',
                url: '/market-request',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER', 'ROLE_SELLER', 'ROLE_WAREHOUSE_MANAGER'],
                    pageTitle: 'transaction.home.title.market.requests'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/market-request/market-request.html',
                        controller: 'MarketRequestController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'pubDate,asc',
                        squash: true
                    },
                    pageNewReq: {
                        value: '1',
                        squash: true
                    },
                    sortNewReq: {
                        value: 'pubDate,asc',
                        squash: true
                    },
                    pagePendingReq: {
                        value: '1',
                        squash: true
                    },
                    sortPendingReq: {
                        value: 'pubDate,asc',
                        squash: true
                    },
                    activeTab: 'tab_new_1',
                    sellerId: 0,
                    firstRun: true
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),

                            pageNewReq: PaginationUtil.parsePage($stateParams.pageNewReq),
                            sortNewReq: $stateParams.sortNewReq,
                            predicateNewReq: PaginationUtil.parsePredicate($stateParams.sortNewReq),
                            ascendingNewReq: PaginationUtil.parseAscending($stateParams.sortNewReq),
                            pagePendingReq: PaginationUtil.parsePage($stateParams.pagePendingReq),
                            sortPendingReq: $stateParams.sortPendingReq,
                            predicatePendingReq: PaginationUtil.parsePredicate($stateParams.sortPendingReq),
                            ascendingPendingReq: PaginationUtil.parseAscending($stateParams.sortPendingReq),
                            activeTab: $stateParams.activeTab,
                            sellerId: $stateParams.sellerId,
                            firstRun: $stateParams.firstRun
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('transaction');
                        $translatePartialLoader.addPart('transactionType');
                        $translatePartialLoader.addPart('transactionStatus');
                        $translatePartialLoader.addPart('serviceType');
                        $translatePartialLoader.addPart('transactionMode');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'market-request',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
    }

})();
