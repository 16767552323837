(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('OptionController', OptionController);

    OptionController.$inject = ['Option'];

    function OptionController(Option) {

        var vm = this;

        vm.options = [];

        loadAll();

        function loadAll() {
            Option.query(function(result) {
                vm.options = result;
                vm.searchQuery = null;
            });
        }
    }
})();
