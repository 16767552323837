(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TerminalProfileRedirectController', TerminalProfileRedirectController);

    TerminalProfileRedirectController.$inject = ['$stateParams', '$location', 'ProfileIdByAccount'];

    function TerminalProfileRedirectController($stateParams, $location, ProfileIdByAccount) {

        ProfileIdByAccount.get({accountNumber: $stateParams.accountNumber}, onSuccess);

        function onSuccess(data) {
            $location.path("profile/" + data.profileId);
        }
    }
})();
