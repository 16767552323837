(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('UploadExcelController', UploadExcelController);

    UploadExcelController.$inject = ['$http', '$scope', '$filter', '$rootScope', 'AlertService', 'DataUtils', 'UploadExcelService'];

    function UploadExcelController($http, $scope, $filter, $rootScope, AlertService, DataUtils, UploadExcelService) {
        var vm = this;
        vm.uploadExcel = {};
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.upload = upload;
        vm.orderInfos = [];
        vm.isRunnig = false;


        function upload() {
            vm.orderInfos = [];
            vm.isRunnig = true;
            UploadExcelService.uploadMarket(vm.uploadExcel, onSuccess, onError);
        }

        function onSuccess(data) {
            AlertService.add({type: 'success', msg: 'Success B2C', timeout: 10000})
            UploadExcelService.uploadB2B(vm.uploadExcel, onSuccessB2B, onError);
            vm.orderInfos = vm.orderInfos.concat(data);
        }

        function onSuccessB2B(data) {
            AlertService.add({type: 'success', msg: 'Success B2B', timeout: 50000})
            vm.orderInfos = vm.orderInfos.concat(data);
            vm.isRunnig = false;
        }

        function onError(error) {
            vm.errorMessage = error.data.message;
            if (vm.errorMessage === null || vm.errorMessage === undefined) {
                vm.errorMessage = 'The process did not reach the end. Please try again.'
            }
            var btn = document.getElementById('myButton');
            btn.click();
            vm.isRunnig = false;
        }

        vm.setData = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        vm.uploadExcel.data = base64Data;
                        vm.uploadExcel.dataContentType = $file.type;
                    });
                });
                vm.uploadExcel.name = $file.name;
                if (vm.uploadExcel.name.length > 50) {
                    vm.uploadExcel.name = vm.uploadExcel.name.substring(vm.uploadExcel.name.length - 50);
                }
            }
        };

    }

})();
