(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('BotOrderInfoNotificationDetailController', BotOrderInfoNotificationDetailController);

    BotOrderInfoNotificationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'BotOrderInfoNotification'];

    function BotOrderInfoNotificationDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, BotOrderInfoNotification) {
        var vm = this;

        vm.botOrderInfoNotification = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:botOrderInfoNotificationUpdate', function(event, result) {
            vm.botOrderInfoNotification = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
