(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('SellerShippingFeeController', SellerShippingFeeController);

    SellerShippingFeeController.$inject = ['$state', 'SellerShippingFee', 'ParseLinks', 'AlertService', '$filter', 'SellerProduct', '$translate', '$rootScope'];

    function SellerShippingFeeController($state, SellerShippingFee, ParseLinks, AlertService, $filter, SellerProduct, $translate, $rootScope) {

        var vm = this;
        vm.loadAll = loadAll;
        vm.onChange = onChange;
        vm.onChangeSeller = onChangeSeller;
        vm.save = save;
        vm.saveSeller = saveSeller;
        vm.invalidRange = invalidRange;
        vm.removeRangeBozorAka = removeRangeBozorAka;
        vm.addRangeBozorAka = addRangeBozorAka;
        vm.removeRangeMobPay = removeRangeMobPay;
        vm.addRangeMobPay = addRangeMobPay;
        vm.lang = $translate.use();

        loadAll();

        function loadAll() {
            SellerShippingFee.query({}, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.users = data;
                if (vm.users.length > 0) {
                    vm.seller = vm.users[0];
                    onChangeSeller();
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function onChange() {
            SellerProduct.query({
                userId: vm.sellerShippingFee.userId,
                language: vm.lang
            }, onSuccess, onError);

            function clearSelect() {
                $('#multi_products').find("option").remove();
                $('#multi_products').multiSelect('refresh');
            }

            function onSuccess(data, headers) {
                clearSelect();
                vm.products = data;
                var selectedList = [];
                vm.products.forEach(function (item, i, arr) {
                    $('#multi_products').multiSelect('addOption', {value: item.id, text: item.name});
                    if (item.isCombined) {
                        selectedList.push(item.id.toString());
                    }
                });
                $('#multi_products').multiSelect('select', selectedList);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function onChangeSeller() {
            vm.shippingFeeConfig = vm.seller.shippingFeeConfig;
            generateConfigForm();
        }

        function save() {
            vm.isSaving = true;
            SellerProduct.update(vm.products, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                vm.isSaving = false;
            }

            function onSaveError() {
                vm.isSaving = false;
            }
        }

        function saveSeller() {
            vm.isSavingSeller = true;
            vm.seller.shippingFeeConfig = JSON.stringify(vm.shippingFeeConfig);
            SellerShippingFee.update(vm.seller, onSaveSuccess, onSaveError);

            function onSaveSuccess(result) {
                vm.isSavingSeller = false;
            }

            function onSaveError() {
                vm.isSavingSeller = false;
            }
        }

        function invalidRange() {
            if (!vm.shippingFeeConfig) {
                generateConfigForm();
            }
            var ranges = vm.shippingFeeConfig.bozoraka.range
            for (var index = 0; index < ranges.length; index++) {
                if (isEmpty(ranges[index].from)
                    || isEmpty(ranges[index].to)
                    || isEmpty(ranges[index].fee)){
                    return true;
                }
                for (var index2 = index + 1; index2 < ranges.length; index2++) {
                    if (ranges[index2].from < ranges[index].to && ranges[index2].from > ranges[index].from) {
                        return true;
                    }
                }
            }
            var ranges = vm.shippingFeeConfig.mobpay.range
            for (var index = 0; index < ranges.length; index++) {
                if (isEmpty(ranges[index].from)
                    || isEmpty(ranges[index].to)
                    || isEmpty(ranges[index].fee)){
                    return true;
                }
                for (var index2 = index + 1; index2 < ranges.length; index2++) {
                    if (ranges[index2].from < ranges[index].to && ranges[index2].from > ranges[index].from) {
                        return true;
                    }
                }
            }
            return false;
        }

        function isEmpty(str) {
            return (str === null || 0 === str.length);
        }

        function generateConfigForm() {
            if (!vm.shippingFeeConfig) {
                vm.shippingFeeConfig = '{' +
                    '"bozoraka": {' +
                    '   "overWeight":{' +
                    '       "stepInKg": 5,' +
                    '       "additionalFeePerStep": 1000' +
                    '   },' +
                    '   "range":[' +
                    '      {' +
                    '         "from":0,' +
                    '         "to":6,' +
                    '         "fee":2500' +
                    '      },' +
                    '      {' +
                    '         "from":6,' +
                    '         "to":11,' +
                    '         "fee":3500' +
                    '      },' +
                    '      {' +
                    '         "from":11,' +
                    '         "to":20,' +
                    '         "fee":4000' +
                    '      }' +
                    '   ]' +
                    '},' +
                    '"mobpay": {' +
                    '   "overWeight":{' +
                    '       "stepInKg": 5,' +
                    '       "additionalFeePerStep": 1000' +
                    '   },' +
                    '   "range":[' +
                    '      {' +
                    '         "from":0,' +
                    '         "to":6,' +
                    '         "fee":2500' +
                    '      },' +
                    '      {' +
                    '         "from":6,' +
                    '         "to":11,' +
                    '         "fee":3500' +
                    '      },' +
                    '      {' +
                    '         "from":11,' +
                    '         "to":20,' +
                    '         "fee":4000' +
                    '      }' +
                    '   ]' +
                    '}}'
            }
            vm.shippingFeeConfig = JSON.parse(vm.shippingFeeConfig);
        }

        function removeRangeBozorAka(range){
            var index = vm.shippingFeeConfig.bozoraka.range.indexOf(range);
            if (index > -1) {
                vm.shippingFeeConfig.bozoraka.range.splice(index, 1);
            }
        }

        function addRangeBozorAka() {
            var newRange = {
                from: vm.newFrom,
                to: vm.newTo,
                fee: vm.newFee
            }
            vm.newFrom = null;
            vm.newTo = null;
            vm.newFee = null;
            vm.shippingFeeConfig.bozoraka.range.push(newRange);
        }

        function removeRangeMobPay(range){
            var index = vm.shippingFeeConfig.mobpay.range.indexOf(range);
            if (index > -1) {
                vm.shippingFeeConfig.mobpay.range.splice(index, 1);
            }
        }

        function addRangeMobPay() {
            var newRange = {
                from: vm.mobPayNewFrom,
                to: vm.mobPayNewTo,
                fee: vm.mobPayNewFee
            }
            vm.mobPayNewFrom = null;
            vm.mobPayNewTo = null;
            vm.mobPayNewFee = null;
            vm.shippingFeeConfig.mobpay.range.push(newRange);
        }

        setTimeout(function () {
            $('#multi_products').multiSelect({
                    afterSelect: function (values) {
                        var index = parseFloat(values);
                        var single_object = $filter('filter')(vm.products, function (d) {
                            return d.id === index;
                        })[0];
                        single_object.isCombined = true;
                    },
                    afterDeselect: function (values) {
                        var index = parseFloat(values);
                        var single_object = $filter('filter')(vm.products, function (d) {
                            return d.id === index;
                        })[0];
                        single_object.isCombined = false;
                    },
                    selectableHeader: "<div class='custom-header'>Uncombined items</div>",
                    selectionHeader: "<div class='custom-header'>Combined items</div>"
                }
            );
        }, 0);

        var unsubscribe = $rootScope.$on('translateChanged', function (event, lang) {
            vm.lang = lang;
            onChange();
        });
        $rootScope.$on('$destroy', unsubscribe);

    }
})();
