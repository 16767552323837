/**
 * Created by Gayratjon Rayimjonov on 10/24/2017.
 */

(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('StatsPeriodicalController', StatsPeriodicalController);

    StatsPeriodicalController.$inject = ['$http', '$scope', '$state', 'DateUtils', 'DataUtils', 'StatsPeriodical'];

    function StatsPeriodicalController($http, $scope, $state, DateUtils, DataUtils, StatsPeriodical) {
        var vm = this;
        vm.search = search;
        vm.excel = excel;
        vm.toggleTransactionDetails = toggleTransactionDetails;
        vm.expandTransactionDetails = false;
        vm.getCurrencySymbolForClient = DataUtils.getCurrencySymbolForClient;
        vm.searchQuery = {
            client: "all"
        };
        vm.stats = {
            totalTransactionsAmount : 0,
            depositAmount: 0,
            largestTransactionsAmount: 0,
            largestDepositAmount: 0,
            newUsers: 0,
            activeUsers: 0,
            failureTransactionCount: 0,
            plusBonus: 0,
            minusBonus: 0
        };
        var amounts = [];
        var counts = [];
        var dates = [];
        getDateRange(vm.searchQuery.fromDate, vm.searchQuery.toDate);

        function toggleTransactionDetails() {
            vm.expandTransactionDetails = !vm.expandTransactionDetails;
        }

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        var today = new Date();
        vm.fromDate = new Date();
        vm.fromDate.setFullYear(today.getFullYear(), today.getMonth(), 1);
        vm.toDate = new Date();
        vm.toDate.setFullYear(today.getFullYear(), today.getMonth() + 1, 0);

        search();
        function search() {
            vm.searchQuery.fromDate = DateUtils.converterDateTimeFormat(vm.fromDate);
            vm.searchQuery.toDate = DateUtils.converterDateTimeFormat(vm.toDate);
            StatsPeriodical.query(vm.searchQuery, onSuccess);
            getDateRange(vm.searchQuery.fromDate, vm.searchQuery.toDate);
            StatsPeriodical.getBySales(vm.searchQuery, onSuccessSales);
            StatsPeriodical.getTransactionsByLanguage(vm.searchQuery, onSuccessLangWise);
            function onSuccess(data) {
                vm.stats = data;
            }

            function onSuccessSales(data) {
                $.when(prepareAmountObjectAsArray(data)).done(function () {
                    highCharts();
                });
            }

            function onSuccessLangWise(data) {
                makeLangOrdersPieChart(data);
            }
        }

        function excel() {
            vm.searchQuery.fromDate = DateUtils.converterDateTimeFormat(vm.fromDate);
            vm.searchQuery.toDate = DateUtils.converterDateTimeFormat(vm.toDate);
            var data = "client=" + vm.searchQuery.client + "&fromDate=" + vm.searchQuery.fromDate + '&toDate=' + vm.searchQuery.toDate;

            $http({
                url: 'api/stats-periodical-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'periodical-statistics-' + DateUtils.currentTimestamp() + '-' + vm.searchQuery.client + '.xlsx');
            }).error(function () {
            });

        }

        function highCharts() {
            Highcharts.chart('highchart', {
                title: {
                    text: 'Periodical TRANSACTIONS and AMOUNTS'
                },
                xAxis: [{
                    categories: dates,
                    crosshair: true
                }],
                yAxis: [{ // Primary yAxis
                    labels: {
                        format: '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[5]
                        }
                    },
                    title: {
                        text: 'TRANSACTIONS',
                        style: {
                            color: Highcharts.getOptions().colors[5]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: 'AMOUNTS',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: Highcharts.valueOf().value,
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'center',
                    x: 120,
                    verticalAlign: 'top',
                    y: 100,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
                },
                series: [{
                    name: 'Amounts',
                    type: 'column',
                    yAxis: 1,
                    data: amounts,
                    tooltip: {
                        valueSuffix: ''
                    }

                }, {
                    name: 'Transactions',
                    type: 'spline',
                    color: 'red',
                    data: counts,
                    tooltip: {
                        valueSuffix: ''
                    }
                }]
            });
        }

        function makeLangOrdersPieChart(data) {
            var chart_by_lang_wise_transactions = AmCharts.makeChart("chart_by_lang_wise_transactions", {
                "type": "pie",
                "startDuration": 0,
                "theme": "light",
                "addClassNames": true,
                "legend": {
                    "position": "right",
                    "marginRight": 10,
                    "marginTop": 300,
                    "autoMargins": false,
                    "valueWidth": 70
                },
                "innerRadius": "30%",
                "defs": {
                    "filter": [{
                        "id": "shadow",
                        "width": "200%",
                        "height": "200%",
                        "feOffset": {
                            "result": "offOut",
                            "in": "SourceAlpha",
                            "dx": 0,
                            "dy": 0
                        },
                        "feGaussianBlur": {
                            "result": "blurOut",
                            "in": "offOut",
                            "stdDeviation": 5
                        },
                        "feBlend": {
                            "in": "SourceGraphic",
                            "in2": "blurOut",
                            "mode": "normal"
                        }
                    }]
                },
                "dataProvider": data,
                "valueField": "count",
                "titleField": "language",
                "colorField": "color",
                "export": {
                    "enabled": true
                }
            });

            chart_by_lang_wise_transactions.addListener("init", handleInit);

            chart_by_lang_wise_transactions.addListener("rollOverSlice", function (e) {
                handleRollOver(e);
            });
        }

        function handleInit() {
            chart1.legend.addListener("rollOverItem", handleRollOver);
        }

        function handleRollOver(e) {
            var wedge = e.dataItem.wedge.node;
            wedge.parentNode.appendChild(wedge);
        }

        function getDateRange(fromDateString, toDateString) {
            var fromDate = new Date(fromDateString);
            var toDate = new Date(toDateString);
            dates = [];
            var dayInMillis = 1000*60*60*24;

            var diff = (toDate.getTime() - fromDate.getTime())/dayInMillis;
            for(var i=0;i<=diff; i++)
            {
                var dateInMillis = fromDate.getTime() + dayInMillis * i;
                var formattedDate = moment(new Date(dateInMillis)).format("YYYY-MM-DD")
                dates.push(formattedDate);
            }
        }

        function prepareAmountObjectAsArray(data) {
            counts = [];
            amounts = [];
            for (var day = 0; day < dates.length; day++) {
                var index = getExistingDayIndex(day, data);
                if (index >= 0) {
                    counts.push(data[index].count);
                    amounts.push(data[index].amount);
                } else {
                    counts.push(0);
                    amounts.push(0);
                }
            }

        }

        function getExistingDayIndex(index, data) {
            var date = dates[index];
            for (var i = 0; i < data.length; i++) {
                var existingDay = new Date(data[i].date);
                var existingDayFormatted = moment(existingDay).format("YYYY-MM-DD");
                if (existingDayFormatted === date) {
                    return i;
                }
            }
            return -1;
        }
    }
})();

