(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfilePhoneRedirectController', ProfilePhoneRedirectController);

    ProfilePhoneRedirectController.$inject = ['$stateParams', '$location', 'ProfileIdByPhone'];

    function ProfilePhoneRedirectController($stateParams, $location, ProfileIdByPhone) {

        ProfileIdByPhone.get({phoneNumber: $stateParams.phone}, onSuccess);

        function onSuccess(data) {
            $location.path("profile/" + data.profileId);
        }

    }
})();
