(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('sort-by-orders', {
                parent: 'entity',
                url: '/sort-by-orders',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ANALYST'],
                    pageTitle: 'global.menu.partner.sortByOrders'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/b2b-sales/sort-by-orders.html',
                        controller: 'SortByOrdersController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null,
                    firstRun: true,
                    fromDate: null,
                    toDate: null,
                    clients: ['b2b'],
                    field: null,
                    b2bClients: [],
                    tiers: []
                },

                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            firstRun: $stateParams.firstRun,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            clients: $stateParams.clients,
                            field: $stateParams.field,
                            b2bClients: $stateParams.b2bClients,
                            tiers: $stateParams.tiers
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('b2bSales');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('sort-by-payment', {
                parent: 'entity',
                url: '/sort-by-payment',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ANALYST'],
                    pageTitle: 'global.menu.partner.sortByPayment'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/b2b-sales/sort-by-payment.html',
                        controller: 'SortByPaymentController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'partner,asc',
                        squash: true
                    },
                    search: null,
                    firstRun: true,
                    fromDate: null,
                    toDate: null,
                    clients: ['b2b'],
                    field: null,
                    b2bClients: [],
                    tiers: []
                },

                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            firstRun: $stateParams.firstRun,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            clients: $stateParams.clients,
                            field: $stateParams.field,
                            b2bClients: $stateParams.b2bClients,
                            tiers: $stateParams.tiers
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('b2bSales');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
