(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .factory('MobileOperatorSearch', MobileOperatorSearch);

    MobileOperatorSearch.$inject = ['$resource'];

    function MobileOperatorSearch($resource) {
        var resourceUrl =  'api/_search/mobile-operators/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
