(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductOptionValueDetailController', ProductOptionValueDetailController);

    ProductOptionValueDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ProductOptionValue', 'ProductOption'];

    function ProductOptionValueDetailController($scope, $rootScope, $stateParams, previousState, entity, ProductOptionValue, ProductOption) {
        var vm = this;

        vm.productOptionValue = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:productOptionValueUpdate', function(event, result) {
            vm.productOptionValue = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
