(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('AgentOrderInfoController', AgentOrderInfoController);

    AgentOrderInfoController.$inject = ['$http', '$state', 'DateUtils', 'PartnerOrderInfo', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'AgentRequestStorage', 'SellerUser'];

    function AgentOrderInfoController($http, $state, DateUtils, PartnerOrderInfo, ParseLinks, AlertService, paginationConstants, pagingParams, AgentRequestStorage, SellerUser) {

        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.dateDiff = DateUtils.partnerOrderDateDifference;
        vm.activeTab = pagingParams.activeTab;
        vm.activateTab = activateTab;
        vm.exportExcel = exportExcel;
        vm.disabledExcelButton = false;
        vm.sellerId = pagingParams.sellerId;
        vm.filterBySeller = filterBySeller;

        vm.predicateNewReq = pagingParams.predicateNewReq;
        vm.reverseNewReq = pagingParams.ascendingNewReq;
        vm.transitionNewReq = transitionNewReq;
        vm.loadNewRequests = loadNewRequests;

        vm.predicatePendingReq = pagingParams.predicatePendingReq;
        vm.reversePendingReq = pagingParams.ascendingPendingReq;
        vm.transitionPendingReq = transitionPendingReq;
        vm.loadPendingRequests = loadPendingRequests;

        vm.loadMoreNewRequests = loadMoreNewRequests;
        vm.pageNewRequests = 0;
        vm.lastPageNewRequests = false;
        vm.loadingNewRequests = false;
        vm.agentNewRequests = [];

        vm.selectAllOrders = selectAllOrders;
        vm.updateSelectedOrders = updateSelectedOrders;
        vm.newOrderIds = [];
        vm.selectedOrders = AgentRequestStorage.getAgentSelectedOrders();
        if (!vm.selectedOrders) {
            vm.selectedOrders = [];
        }

        function loadMoreNewRequests() {
            if (vm.activeTab === 'tab_pending_1' || vm.lastPageNewRequests || vm.loadingNewRequests) {
                return null;
            } else {
                vm.loadingNewRequests = true;
                loadNewRequests();
            }
        }

        getSellers();

        vm.loadMorePendingRequests = loadMorePendingRequests;
        vm.pagePendingRequests = 0;
        vm.lastPagePendingRequests = false;
        vm.loadingPendingRequests = false;
        vm.agentPendingRequests = [];

        function loadMorePendingRequests() {
            if (vm.activeTab === 'tab_new_1' || vm.lastPagePendingRequests || vm.loadingPendingRequests) {
                return null;
            } else {
                vm.loadingPendingRequests = true;
                loadPendingRequests();
            }
        }

        function loadNewRequests () {
            PartnerOrderInfo.agentNewRequests({
                sellerId: vm.sellerId,
                page: vm.pageNewRequests,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicateNewReq + ',' + (vm.reverseNewReq ? 'asc' : 'desc')];
                if (vm.predicateNewReq !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.linksNewReq = ParseLinks.parse(headers('link'));
                vm.totalItemsNewReq = headers('X-Total-Count');
                vm.queryCountNewReq = vm.totalItemsNewReq;

                vm.pageNewRequests++;
                var agentNewRequests = data;

                if (agentNewRequests == null || agentNewRequests.length < vm.itemsPerPage) {
                    vm.lastPageNewRequests = true;
                }
                agentNewRequests.forEach(function (agentNewRequest) {
                    vm.agentNewRequests.push(agentNewRequest);
                });
                vm.loadingNewRequests = false;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionNewReq() {
            $state.transitionTo($state.$current, {
                sellerId: vm.sellerId,
                pageNewReq: vm.pageNewReq,
                sortNewReq: vm.predicateNewReq + ',' + (vm.reverseNewReq ? 'asc' : 'desc'),
                pagePendingReq: vm.pagePendingReq,
                sortPendingReq: vm.predicatePendingReq + ',' + (vm.reversePendingReq ? 'asc' : 'desc'),
                activeTab: vm.activeTab
            });
        }

        function loadPendingRequests () {
            PartnerOrderInfo.agentPendingRequests({
                page: vm.pagePendingRequests,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicatePendingReq + ',' + (vm.reversePendingReq ? 'asc' : 'desc')];
                if (vm.predicatePendingReq !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.linksPendingReq = ParseLinks.parse(headers('link'));
                vm.totalItemsPendingReq = headers('X-Total-Count');
                vm.queryCountPendingReq = vm.totalItemsPendingReq;

                vm.pagePendingRequests++;
                var agentPendingRequests = data;

                if (agentPendingRequests == null || agentPendingRequests.length < vm.itemsPerPage) {
                    vm.lastPagePendingRequests = true;
                }
                agentPendingRequests.forEach(function (agentPendingRequest) {
                    vm.agentPendingRequests.push(agentPendingRequest);
                });
                vm.loadingPendingRequests = false;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionPendingReq() {
            $state.transitionTo($state.$current, {
                pageNewReq: vm.pageNewReq,
                sortNewReq: vm.predicateNewReq + ',' + (vm.reverseNewReq ? 'asc' : 'desc'),
                pagePendingReq: vm.pagePendingReq,
                sortPendingReq: vm.predicatePendingReq + ',' + (vm.reversePendingReq ? 'asc' : 'desc'),
                activeTab: vm.activeTab
            });
        }

        function activateTab(tab) {
            vm.activeTab = tab;
            if (tab === 'tab_pending_1') {
                loadPendingRequests();
            }
        }

        function loadAllNewOrderIds() {
            PartnerOrderInfo.getAllNewAgentRequestIds({sellerId: vm.sellerId}, onSuccess, onError);

            function onSuccess(data) {
                vm.newOrderIds = data;
                clearOldSelectedOrders();
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.newOrderIds = [];
            }
        }

        function exportExcel() {
            vm.disabledExcelButton = true;
            var data = vm.selectedOrders;
            $http({
                url: 'api/b2b-new-requests-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/json'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                saveAs(blob, 'agent-market-request-' + DateUtils.currentTimestamp() + '.xlsx');
                vm.disabledExcelButton = false;
            }).error(function () {
                vm.disabledExcelButton = false;
            });
        }

        function selectAllOrders(event) {
            if (!event.target.checked) {
                vm.selectedOrders = [];
                AgentRequestStorage.setAgentSelectedOrders(vm.selectedOrders);
            } else {
                vm.selectedOrders = [];
                vm.selectedOrders = vm.newOrderIds.slice();
                AgentRequestStorage.setAgentSelectedOrders(vm.selectedOrders);
            }
        }

        function updateSelectedOrders(event) {
            vm.selectedOrders = AgentRequestStorage.getAgentSelectedOrders();
            if (!vm.selectedOrders) {
                vm.selectedOrders = [];
            }
            if (event.target.checked) {
                if (vm.selectedOrders.indexOf(parseInt(event.target.value)) < 0) {
                    vm.selectedOrders.push(parseInt(event.target.value));
                }
            } else {
                if (vm.selectedOrders.indexOf(parseInt(event.target.value)) > -1) {
                    vm.selectedOrders.splice(vm.selectedOrders.indexOf(parseInt(event.target.value)), 1);
                }
            }
            AgentRequestStorage.setAgentSelectedOrders(vm.selectedOrders);
        }

        function getSellers() {
            SellerUser.query(onSuccess);

            function onSuccess(data) {
                vm.sellers = data;
                vm.sellers.unshift({id:null, fullName: "All"});
            }
        }

        function filterBySeller() {
            if (vm.sellerId !== 0) {
                vm.selectedOrders = [];
                AgentRequestStorage.setAgentSelectedOrders(vm.selectedOrders);
                vm.itemsPerPage = paginationConstants.itemsPerPage;
                vm.pageNewRequests = 0;
                vm.lastPageNewRequests = false;
                vm.loadingNewRequests = false;
                vm.agentNewRequests = [];
                loadNewRequests();
                loadAllNewOrderIds();
            }
        }

        function clearOldSelectedOrders() {
            var newSelectedOrders = [];
            for (var i = 0; i < vm.selectedOrders.length; i++) {
                if (vm.newOrderIds.indexOf(vm.selectedOrders[i]) > -1) {
                    newSelectedOrders.push(vm.selectedOrders[i]);
                }
            }
            vm.selectedOrders = newSelectedOrders;
            AgentRequestStorage.setAgentSelectedOrders(vm.selectedOrders);

        }
    }
})();
