(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('B2BOrderInfoController', B2BOrderInfoController);

    B2BOrderInfoController.$inject = ['$state', '$http', 'DateUtils', 'PartnerOrderInfo', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function B2BOrderInfoController($state, $http, DateUtils, PartnerOrderInfo, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;
        vm.loading = false;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.onChangeRole = onChangeRole;
        vm.generateOrderInvoice = generateOrderInvoice;

        vm.searchQuery = {
            field: pagingParams.field,
            phone: pagingParams.phone,
            orderStatus: pagingParams.orderStatus,
            paymentStatus: pagingParams.paymentStatus,
            authority: pagingParams.authority,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };
        vm.loadMore = loadMore;
        vm.firstRun = true;
        vm.page = 0;
        vm.lastPage = false;
        vm.loading = false;
        vm.partnerOrderInfos = [];

        function onChangeRole() {
            if (vm.searchQuery.authority !== "ROLE_PARTNER") {
                vm.searchQuery.paymentStatus = null;
                if (vm.searchQuery.orderStatus === "ORDERED") {
                    vm.searchQuery.orderStatus = null;
                }
            } else {
                if (vm.searchQuery.orderStatus === "WAITING_FOR_PAYMENT" || vm.searchQuery.orderStatus === "PAID") {
                    vm.searchQuery.orderStatus = null;
                }
            }
        }

        initDatePicker();

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function loadNext () {
            PartnerOrderInfo.allOrders({
                page: vm.page,
                size: vm.itemsPerPage,
                sort: sort(),
                field: vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                shippingPhone: vm.searchQuery.phone,
                orderStatus: vm.searchQuery.orderStatus,
                paymentStatus: vm.searchQuery.paymentStatus,
                authorityName: vm.searchQuery.authority,
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page++;
                var partnerOrderInfos = data;

                if (partnerOrderInfos == null || partnerOrderInfos.length < 20) {
                    vm.lastPage = true;
                }
                partnerOrderInfos.forEach(function (partnerOrderInfo) {
                    vm.partnerOrderInfos.push(partnerOrderInfo);
                });
                vm.loading = false;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadMore() {
            if (vm.lastPage || vm.loading) {
                return null;
            } else {
                vm.loading = true;
                loadNext();
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                field: vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                phone: vm.searchQuery.phone,
                orderStatus: vm.searchQuery.orderStatus,
                paymentStatus: vm.searchQuery.paymentStatus,
                authority: vm.searchQuery.authority
            });
        }

        function search() {
            $('.input-daterange input').each(function() {
                if ($(this).attr('name') === 'from') {
                    if ($(this).val() === '') {
                        vm.searchQuery.fromDate = null;
                    } else {
                        vm.searchQuery.fromDate = $(this).val();
                    }
                } else if ($(this).attr('name') === 'to') {
                    if ($(this).val() === '') {
                        vm.searchQuery.toDate = null;
                    } else {
                        vm.searchQuery.toDate = $(this).val();
                    }
                }
            });

            vm.partnerOrderInfos = [];
            vm.page = 0;
            vm.lastPage = false;
            vm.loading = false;
            vm.firstRun = false;
            loadNext();
        }

        function clear() {
            vm.partnerOrderInfos = [];
            vm.page = 0;
            vm.lastPage = false;
            vm.loading = false;
            vm.firstRun = false;
            vm.searchQuery = {
                field: null,
                phone: null,
                fromDate: null,
                toDate: null,
                orderStatus: null,
                paymentStatus: null,
                authority: 'ALL'
            };
            loadNext();
        }

        function generateOrderInvoice(id) {
            $http({
                url: 'api/v2/partner-order-infos/' + id + '/order-invoice',
                method: 'GET',
                responseType: 'arraybuffer'
            }).success(function (response) {
                var blob = new Blob([response], {
                    type: 'application/pdf'
                });
                saveAs(blob, 'v2-order-invoice_' + id + '.pdf');
            }).error(function () {
            });
        }
    }
})();
