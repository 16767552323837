(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .factory('ProfileChangePassword', ProfileChangePassword);

    ProfileChangePassword.$inject = ['$resource'];

    function ProfileChangePassword($resource) {
        var service = $resource('api/profiles/change_password', {}, {});

        return service;
    }
})();
