(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MobileTariffDialogController', MobileTariffDialogController);

    MobileTariffDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MobileTariff', 'MobileOperator'];

    function MobileTariffDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, MobileTariff, MobileOperator) {
        var vm = this;

        vm.mobileTariff = entity;
        vm.clear = clear;
        vm.save = save;
        vm.mobileoperators = MobileOperator.query({
            size: 1000
        });

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.mobileTariff.id !== null) {
                MobileTariff.update(vm.mobileTariff, onSaveSuccess, onSaveError);
            } else {
                MobileTariff.save(vm.mobileTariff, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:mobileTariffUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
