(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerOrderedProductVariantDetailController', PartnerOrderedProductVariantDetailController);

    PartnerOrderedProductVariantDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PartnerOrderedProductVariant', 'PartnerOrderedProduct'];

    function PartnerOrderedProductVariantDetailController($scope, $rootScope, $stateParams, previousState, entity, PartnerOrderedProductVariant, PartnerOrderedProduct) {
        var vm = this;

        vm.partnerOrderedProductVariant = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:partnerOrderedProductVariantUpdate', function(event, result) {
            vm.partnerOrderedProductVariant = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
