(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('SellerInvoiceIssue', SellerInvoiceIssue);

    SellerInvoiceIssue.$inject = ['$resource', 'DateUtils'];

    function SellerInvoiceIssue($resource, DateUtils) {
        var resourceUrl = 'api/seller-invoice-issues/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'confirmedInvoice': {
                url: 'api/seller-invoice-issues/:id/confirmed',
                method: 'PUT',
                params: {'id': '@id'}
            },
            'paidInvoice': {
                url: 'api/seller-invoice-issues/:id/paid',
                method: 'PUT',
                params: {'id': '@id'}
            }
        });
    }
})();
