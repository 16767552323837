(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerOrderNoticeDialogController', PartnerOrderNoticeDialogController);

    PartnerOrderNoticeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PartnerOrderNotice', 'PartnerOrderInfo'];

    function PartnerOrderNoticeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PartnerOrderNotice, PartnerOrderInfo) {
        var vm = this;

        vm.partnerOrderNotice = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.partnerorderinfos = PartnerOrderInfo.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.partnerOrderNotice.id !== null) {
                PartnerOrderNotice.update(vm.partnerOrderNotice, onSaveSuccess, onSaveError);
            } else {
                PartnerOrderNotice.save(vm.partnerOrderNotice, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:partnerOrderNoticeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
